import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { isNil, upperFirst } from 'lodash'
import { observer } from 'mobx-react-lite'

import { FieldingPositions } from '../../data/reference'
import type { FieldingPositionPositionsInterface } from '../../types'
import type { FieldingPlacementsPositionsProps } from '../../types/props'
import { Button } from '../Buttons/Button'

export const FieldingPlacementsPositions = observer(
  ({
    selectingPositionParams,
    viablePositions,
    handleSelectPosition,
    closeSelectPosition,
  }: FieldingPlacementsPositionsProps) => {
    return (
      <Modal
        isOpen={!isNil(selectingPositionParams) && !isNil(selectingPositionParams.x) ? true : false}
        onClose={() => closeSelectPosition()}
        size="md"
      >
        <ModalOverlay />
        <ModalContent
          position="absolute"
          w={300}
          top={selectingPositionParams?.y ? selectingPositionParams?.y - 30 : undefined}
          left={selectingPositionParams?.x ? selectingPositionParams?.x - 150 : undefined}
        >
          <ModalHeader>{selectingPositionParams?.playerMpName || 'Select Position'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex w="100%" h="100%" direction="column" paddingBottom="14px">
              <>
                {viablePositions?.map((position: FieldingPositionPositionsInterface) => {
                  return (
                    <Box key={`positionD_${position.name}`} w="100%" paddingY="4px">
                      <Button
                        onClick={() =>
                          handleSelectPosition({
                            playerMpId: selectingPositionParams?.playerMpId,
                            fieldingPositionId: position.id,
                            isWicketkeeper:
                              selectingPositionParams &&
                              !isNil(selectingPositionParams.fieldingPositionId) &&
                              FieldingPositions[selectingPositionParams.fieldingPositionId] === 'WICKET_KEEPER'
                                ? true
                                : false,
                          })
                        }
                        w="100%"
                        data-testid={`${upperFirst(position.name.replace(/_/g, ' ').toLowerCase())}Button`}
                      >
                        {upperFirst(position.name.replace(/_/g, ' ').toLowerCase())}
                      </Button>
                    </Box>
                  )
                })}
                {selectingPositionParams &&
                  (isNil(selectingPositionParams.fieldingPositionId) ||
                    (!isNil(selectingPositionParams.fieldingPositionId) &&
                      FieldingPositions[selectingPositionParams.fieldingPositionId] !== 'WICKET_KEEPER')) && (
                    <Box key={`positionD_Custom`} w="100%" paddingY="4px">
                      <Button
                        onClick={() =>
                          handleSelectPosition({
                            playerMpId: selectingPositionParams.playerMpId,
                            fieldingPositionId: null,
                          })
                        }
                        w="100%"
                        data-testid="selectCustomFielderPositionButton"
                      >
                        Custom
                      </Button>
                    </Box>
                  )}
              </>
            </Flex>
          </ModalBody>
          <ModalFooter padding="0 7px 7px 0" />
        </ModalContent>
      </Modal>
    )
  }
)
