import { Box, Flex } from '@chakra-ui/react'
import type { IFieldingPlayerModel, IFieldingPositionModel } from '@clsplus/cls-plus-data-models'
import Konva from 'konva'
import type { KonvaEventObject } from 'konva/lib/Node'
import { each, find, indexOf, isNil, round } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { Circle, Group, Image, Layer, Line, Stage, Text as KText } from 'react-konva'
import { useParams } from 'react-router-dom'
import useImage from 'use-image'
import { v4 as uuidV4 } from 'uuid'

import { FieldingPositions, HandedTypeOptions, WicketKeeperPositionOptions } from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import { fielderHasMoved, getAngleAndLength, getViablePositions, getXandY } from '../../helpers/fieldingHelpers'
import Theme from '../../theme/theme'
import type {
  FieldingPositionPositionsInterface,
  HandleSelectPositionArgs,
  HandleUpdatePlacementsArgs,
} from '../../types'
import type { FieldingPlacementsProps, PositionParamsProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import SubHeading from '../Headings/SubHeading'
import ProgressSteps from '../ProgressSteps/ProgressSteps'
import Field from './Field.svg'
import { FieldingPlacementsFielder } from './FieldingPlacementsFielder'
import { FieldingPlacementsFields } from './FieldingPlacementsFields'
import { FieldingPlacementsOverlay } from './FieldingPlacementsOverlay'
import { FieldingPlacementsPositions } from './FieldingPlacementsPositions'

export const FieldingPlacements = observer(
  ({
    ball,
    lastBall,
    triggerNewBowler,
    triggerNewFielder,
    inBall,
    editBall,
    fieldHasChanged,
    setFieldHasChanged,
    handleCreateFielder,
    handleRunOutRoles,
    completeChangeBowler,
    wagonWheeling,
    setWagonWheeling,
    resetCoordinates,
    setResetCoordinates,
    inBallBasicsEventsDone,
    inBallShotLocationDone,
    inBallFielderInfoDone,
    inBallNonFieldedWicket,
    isCurrentInnings,
    actionsDisabled,
  }: FieldingPlacementsProps) => {
    const { fieldingPlacements, appSettings } = useMst()
    const matchSettings = appSettings.getMatchSettings(ball.getMatchId || '')
    const [isDraggingPlayer, setIsDraggingPlayer] = useState(null)
    const [isEditingWithoutWagonWheel, setIsEditingWithoutWagonWheel] = useState(false)
    const [selectingPositionParams, setSelectingPositionParams] = useState<PositionParamsProps | null>(null)
    const [selectingPlayerParams, setSelectingPlayerParams] = useState<PositionParamsProps | null>(null)
    const [viablePositions, setViablePositions] = useState<FieldingPositionPositionsInterface[] | null>(null)

    const stageRef = useRef<Konva.Stage>(null)
    const layerRef = useRef<Konva.Layer>(null)
    const [field] = useImage(Field)
    const stageDimensionsMaxWidth = 670
    const heightOffset = 130
    const stageDimensions = {
      width: (window.innerHeight - heightOffset) * 0.8432,
      height: window.innerHeight - heightOffset,
    }
    if (stageDimensions.width > stageDimensionsMaxWidth) {
      stageDimensions.width = stageDimensionsMaxWidth
      stageDimensions.height = stageDimensions.width / 0.8432
    }
    const stageOrigin = {
      x: stageDimensions.width / 2,
      y: matchSettings.activeFieldRotation === 180 ? stageDimensions.height * 0.565 : stageDimensions.height * 0.435,
    }

    const urlParams = useParams()

    const clearComponent = (type?: string, clearData?: boolean) => {
      const stage = stageRef.current
      if (!stage) return

      if (!type || type === 'lines') {
        stage.find('.lines').forEach(item => item.destroy())
      }
      if (!type || type === 'circles') {
        stage.find('.circles').forEach(item => item.destroy())
      }
      stage.draw()

      if (clearData && ball) {
        if (editBall && ball.battingAnalysis && !isEditingWithoutWagonWheel && setResetCoordinates) {
          // if we are editing ball that has previously-synced wagon wheel coordinates ...
          // ... then store it for now as we may need to restore it
          setResetCoordinates(toJS(ball.battingAnalysis.shots.wagonWheel))
        }
        ball.battingAnalysis?.shots.updateWagonWheel()
        if (setWagonWheeling) setWagonWheeling(true)
      }
    }
    const clearSelectPlayer = () => {
      if (selectingPlayerParams?.playerMpId) {
        ball.fieldingAnalysis?.removeFieldingPlayer(selectingPlayerParams.playerMpId)
      }
    }
    const closeSelectPlayer = () => {
      setSelectingPlayerParams(null)
      setViablePositions(null)
    }
    // TODO: layerX and layerY are being deprecated, so we should look at switching
    // them out for for offsetX and offsetY - this will also fix these TS issues
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleStageClick = (event: any) => {
      const stage = stageRef.current
      if (!stage) return

      if (wagonWheeling) {
        const sP = getAngleAndLength(
          event.evt.layerX,
          event.evt.layerY,
          stageOrigin.x,
          stageOrigin.y,
          stageDimensions.width,
          stageDimensions.height,
          matchSettings.activeFieldRotation === 180,
          matchSettings.activeFieldHanded === 'LEFT'
        )
        const coords = getXandY(
          round((event.evt.layerX / stage.attrs.width) * 100, 2),
          round((event.evt.layerY / stage.attrs.height) * 100, 2),
          matchSettings.activeFieldRotation === 180
        )
        ball.battingAnalysis?.shots.updateWagonWheel({
          id: resetCoordinates?.id,
          x: coords.x,
          y: coords.y,
          angle: sP.angle,
          length: sP.length,
        })

        if (resetCoordinates && setResetCoordinates) setResetCoordinates(null)
        if (setWagonWheeling) setWagonWheeling(false)
      } else if (!event.target.attrs.placement) {
        closeSelectPlayer()
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handlePlacementClick = (event: any) => {
      setSelectingPlayerParams({
        playerMpId: event.target.parent.attrs.playerMpId,
        playerMpName: event.target.parent.attrs.playerMpName,
        fieldingPositionId: event.target.parent.attrs.fieldingPositionId,
        shirtNumber: event.target.parent.attrs.shirtNumber,
      })
      if (
        isNil(event.target.parent.attrs.fieldingPositionId) ||
        (!isNil(event.target.parent.attrs.fieldingPositionId) &&
          FieldingPositions[event.target.parent.attrs.fieldingPositionId] !== 'WICKET_KEEPER' &&
          FieldingPositions[event.target.parent.attrs.fieldingPositionId] !== 'BOWLER')
      ) {
        const layerCoords = event.target.getAbsolutePosition(stageRef)
        setViablePositions(
          getViablePositions(
            layerCoords.x || event.evt.layerX,
            layerCoords.y || event.evt.layerY,
            stageOrigin.x,
            stageOrigin.y,
            stageDimensions.width,
            stageDimensions.height,
            matchSettings.activeFieldRotation === 180,
            matchSettings.activeFieldHanded === 'LEFT'
          )
        )
      } else {
        setViablePositions(null)
      }
    }
    const handleDragStart = (event: KonvaEventObject<DragEvent>) => {
      setIsDraggingPlayer(event.target.attrs.playerMpId)
      setSelectingPositionParams({
        playerMpId: event.target.attrs.playerMpId,
        playerMpName: event.target.attrs.playerMpName,
        fieldingPositionId: event.target.attrs.fieldingPositionId,
      })
      event.target.setAttrs({
        scaleX: 1.1,
        scaleY: 1.1,
      })
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleDragEnd = (event: any) => {
      event.target.to({
        duration: 0.5,
        easing: Konva.Easings.ElasticEaseOut,
        scaleX: 1,
        scaleY: 1,
      })

      if (selectingPositionParams) {
        let placementViablePositions: FieldingPositionPositionsInterface[] = []
        let isWicketkeeper = false
        if (
          isNil(selectingPositionParams?.fieldingPositionId) ||
          (!isNil(selectingPositionParams?.fieldingPositionId) &&
            FieldingPositions[selectingPositionParams.fieldingPositionId] !== 'WICKET_KEEPER')
        ) {
          placementViablePositions = getViablePositions(
            event.evt.layerX,
            event.evt.layerY,
            stageOrigin.x,
            stageOrigin.y,
            stageDimensions.width,
            stageDimensions.height,
            matchSettings.activeFieldRotation === 180,
            matchSettings.activeFieldHanded === 'LEFT'
          )
        } else if (
          !isNil(selectingPositionParams?.fieldingPositionId) &&
          FieldingPositions[selectingPositionParams.fieldingPositionId] === 'WICKET_KEEPER'
        ) {
          isWicketkeeper = true
          placementViablePositions = WicketKeeperPositionOptions.map((option: string, idx: number) => {
            return {
              id: idx,
              name: option,
              short: false,
              mid: false,
              deep: false,
            }
          })
        }
        setViablePositions(placementViablePositions)

        // show absolute-positioned modal on screen
        const minModalHeight: number = 110 + (placementViablePositions.length || 1) * 48 + 60 // inner & outer modal padding, plus height of buttons + button padding
        const canShowBelow: boolean = window.innerHeight - event.evt.clientY >= minModalHeight
        const canShowAbove: boolean = event.evt.clientY - minModalHeight - 50 >= 0
        setSelectingPositionParams({
          x: canShowBelow || canShowAbove ? event.evt.clientX : event.evt.clientX + 176,
          y: canShowBelow
            ? event.evt.clientY
            : canShowAbove
            ? event.evt.clientY - minModalHeight - 50 // extra padding for the height/padding of the icon
            : event.evt.clientY - minModalHeight * 0.333, // show to the right hand side if cannot show above or below
          playerMpId: selectingPositionParams?.playerMpId,
          playerMpName: selectingPositionParams?.playerMpName,
          fieldingPositionId: isWicketkeeper ? selectingPositionParams?.fieldingPositionId : undefined,
        })
      }
    }
    const handleUpdatePlacements = ({
      createFavourite,
      updateFavourite,
      label,
      subtitle,
    }: HandleUpdatePlacementsArgs) => {
      const stage = stageRef.current
      if (!stage || !isCurrentInnings) return

      const collection = stage.find('.person')
      const coords = collection.map(item => {
        return {
          id: uuidV4(),
          playerMp: item.attrs.playerMpId,
          fieldingPositionId: item.attrs.fieldingPositionId,
          placement: {
            id: uuidV4(),
            x:
              !matchSettings.activeFieldRotation || matchSettings.activeFieldRotation === 0
                ? round((item.attrs.x / stageDimensions.width) * 100, 2)
                : round(100 - (item.attrs.x / stageDimensions.width) * 100, 2),
            y:
              !matchSettings.activeFieldRotation || matchSettings.activeFieldRotation === 0
                ? round((item.attrs.y / stageDimensions.height) * 100, 2)
                : round(100 - (item.attrs.y / stageDimensions.height) * 100, 2),
          },
        }
      })
      ball.fieldingAnalysis?.updatePlacements(coords)
      if (createFavourite) {
        const id = fieldingPlacements.createField({
          battingHandedId: indexOf(HandedTypeOptions, matchSettings.activeFieldHanded),
          label: label || '',
          subtitle: subtitle || '',
          fielders: coords,
          matchId: urlParams.id || '',
        })
        matchSettings.setActiveFieldID(id)
        if (setFieldHasChanged) setFieldHasChanged(false)
      } else if (updateFavourite) {
        fieldingPlacements.updateField(matchSettings.activeFieldId, matchSettings.activeFieldHanded, coords)
        if (setFieldHasChanged) setFieldHasChanged(false)
      } else {
        if (setFieldHasChanged) setFieldHasChanged(true)
      }
    }
    const closeSelectPosition = () => {
      setIsDraggingPlayer(null)
      if (!editBall && setFieldHasChanged) setFieldHasChanged(true)
      setSelectingPositionParams(null)
      setSelectingPlayerParams(null)
      setViablePositions(null)
    }
    const handleSelectPosition = ({ playerMpId, fieldingPositionId, isWicketkeeper }: HandleSelectPositionArgs) => {
      if (!playerMpId) return
      if (isWicketkeeper) {
        if (!isNil(fieldingPositionId)) {
          ball.setWicketKeeperPosition(WicketKeeperPositionOptions[fieldingPositionId])
        }
      } else {
        const playerToUpdate: IFieldingPositionModel | undefined = find(
          ball.fieldingAnalysis?.fieldingPositions,
          (fp: IFieldingPositionModel) => {
            return fp.playerMp?.id === playerMpId
          }
        )
        if (!playerToUpdate) return
        playerToUpdate?.updatePosition(fieldingPositionId)
        if (stageRef.current) {
          const collection = stageRef.current.find('.person')
          each(collection, item => {
            if (item.attrs.playerMpId === playerMpId) {
              item.attrs.fieldingPositionId = fieldingPositionId
            }
          })
        }
      }
      handleUpdatePlacements({})
      if (!editBall && setFieldHasChanged) setFieldHasChanged(true)
      closeSelectPosition()
    }

    useEffect(() => {
      if (!fieldHasChanged && isCurrentInnings) {
        handleUpdatePlacements({ updateFavourite: true })
      }
      if (
        inBall &&
        (!editBall ||
          (editBall && !ball.battingAnalysis?.shots.wagonWheel?.x && !ball.fieldingAnalysis?.fieldedWicketKeeper)) &&
        setWagonWheeling
      ) {
        setWagonWheeling(true)
        if (editBall) setIsEditingWithoutWagonWheel(true)
      }
    }, []) // eslint-disable-line

    return (
      <>
        <Flex flex={1} direction="row">
          <Flex
            flex={3}
            alignItems="flex-start"
            justifyContent="center"
            marginX={['7px', '7px', '7px', '7px']}
            backgroundColor="#FFF"
            border={`solid 1px ${Theme.colors.gray['300']}`}
          >
            {!inBall && isCurrentInnings && !actionsDisabled && (
              <FieldingPlacementsOverlay ball={ball} setFieldHasChanged={setFieldHasChanged} />
            )}
            <Box position="absolute" float="left" marginTop="3.5em">
              <Stage
                width={stageDimensions.width}
                height={stageDimensions.height}
                ref={stageRef}
                listening={isCurrentInnings && !actionsDisabled}
              >
                <Layer onClick={handleStageClick} onTap={handleStageClick} ref={layerRef}>
                  <Group>
                    <Image
                      image={field}
                      x={stageDimensions.width / 2}
                      y={stageDimensions.height / 2}
                      offsetX={stageDimensions.width / 2}
                      offsetY={stageDimensions.height / 2}
                      width={stageDimensions.width}
                      height={stageDimensions.height}
                      rotation={matchSettings.activeFieldRotation || 0}
                    />
                    <KText
                      text="OFF"
                      x={
                        (matchSettings.activeFieldHanded === 'LEFT' && matchSettings.activeFieldRotation === 180) ||
                        (matchSettings.activeFieldHanded === 'RIGHT' && matchSettings.activeFieldRotation === 0)
                          ? stageDimensions.width / 7
                          : stageDimensions.width * 0.857 - 80
                      }
                      y={stageDimensions.height / 2 - 20}
                      fill={Theme.colors.cls.black}
                      fontFamily="Arial"
                      fontStyle="bold"
                      fontSize={30}
                      width={80}
                      height={40}
                      align={
                        (matchSettings.activeFieldHanded === 'LEFT' && matchSettings.activeFieldRotation === 180) ||
                        (matchSettings.activeFieldHanded === 'RIGHT' && matchSettings.activeFieldRotation === 0)
                          ? 'left'
                          : 'right'
                      }
                      opacity={0.2}
                      listening={false}
                    />
                    <KText
                      text="LEG"
                      x={
                        (matchSettings.activeFieldHanded === 'LEFT' && matchSettings.activeFieldRotation === 0) ||
                        (matchSettings.activeFieldHanded === 'RIGHT' && matchSettings.activeFieldRotation === 180)
                          ? stageDimensions.width / 7
                          : stageDimensions.width * 0.857 - 80
                      }
                      y={stageDimensions.height / 2 - 20}
                      fill={Theme.colors.cls.black}
                      fontFamily="Arial"
                      fontStyle="bold"
                      fontSize={30}
                      width={80}
                      height={40}
                      align={
                        (matchSettings.activeFieldHanded === 'LEFT' && matchSettings.activeFieldRotation === 0) ||
                        (matchSettings.activeFieldHanded === 'RIGHT' && matchSettings.activeFieldRotation === 180)
                          ? 'left'
                          : 'right'
                      }
                      opacity={0.2}
                      listening={false}
                    />
                    <Circle
                      id="umpireControl"
                      x={stageDimensions.width / 2}
                      y={
                        matchSettings.activeFieldRotation === 180
                          ? stageDimensions.height * 0.4
                          : stageDimensions.height * 0.6
                      }
                      width={12}
                      height={12}
                      radius={6}
                      fill="#FFF"
                      strokeWidth={2}
                      stroke="#000"
                      shadowEnabled={false}
                      listening={false}
                    />
                    <Circle
                      id="umpireNonControl"
                      x={
                        matchSettings.activeFieldHanded === 'RIGHT'
                          ? matchSettings.activeFieldRotation === 180
                            ? stageDimensions.width * 0.295
                            : stageDimensions.width * 0.705
                          : matchSettings.activeFieldRotation === 180
                          ? stageDimensions.width * 0.705
                          : stageDimensions.width * 0.295
                      }
                      y={
                        matchSettings.activeFieldRotation === 180
                          ? stageDimensions.height * 0.555
                          : stageDimensions.height * 0.445
                      }
                      width={12}
                      height={12}
                      radius={6}
                      fill="#FFF"
                      strokeWidth={2}
                      stroke="#000"
                      shadowEnabled={false}
                      listening={false}
                    />
                  </Group>
                  {(isCurrentInnings || inBall) &&
                    ball &&
                    ball.fieldingAnalysis &&
                    ball.fieldingAnalysis.fieldingPositions?.map((coords: IFieldingPositionModel, idx: number) => (
                      <Group key={`person${idx}`}>
                        {!inBall &&
                          lastBall &&
                          coords.playerMp &&
                          fielderHasMoved(coords, ball, lastBall) &&
                          (!isDraggingPlayer || isDraggingPlayer !== coords.playerMp.id) && (
                            <Line
                              points={
                                !matchSettings.activeFieldRotation || matchSettings.activeFieldRotation === 0
                                  ? [
                                      stageDimensions.width *
                                        ((lastBall.fieldingAnalysis?.fieldingPlacementForPlayer(coords.playerMp.id)
                                          ?.placement.x || 0) /
                                          100),
                                      stageDimensions.height *
                                        ((lastBall.fieldingAnalysis?.fieldingPlacementForPlayer(coords.playerMp.id)
                                          ?.placement.y || 0) /
                                          100),
                                      stageDimensions.width * (coords.placement.x / 100),
                                      stageDimensions.height * (coords.placement.y / 100),
                                    ]
                                  : [
                                      ((100 -
                                        (lastBall.fieldingAnalysis?.fieldingPlacementForPlayer(coords.playerMp.id)
                                          ?.placement.x || 0)) /
                                        100) *
                                        stageDimensions.width,
                                      ((100 -
                                        (lastBall.fieldingAnalysis?.fieldingPlacementForPlayer(coords.playerMp.id)
                                          ?.placement.y || 0)) /
                                        100) *
                                        stageDimensions.height,
                                      stageDimensions.width * ((100 - coords.placement.x) / 100),
                                      stageDimensions.height * ((100 - coords.placement.y) / 100),
                                    ]
                              }
                              strokeWidth={4}
                              stroke={Theme.colors.cls.yellow['400']}
                              lineCap="round"
                              lineJoin="round"
                              dash={[5, 7]}
                            />
                          )}
                        <Group
                          name="person"
                          x={
                            !matchSettings.activeFieldRotation || matchSettings.activeFieldRotation === 0
                              ? (coords.placement.x / 100) * stageDimensions.width
                              : ((100 - coords.placement.x) / 100) * stageDimensions.width
                          }
                          y={
                            !matchSettings.activeFieldRotation || matchSettings.activeFieldRotation === 0
                              ? (coords.placement.y / 100) * stageDimensions.height
                              : ((100 - coords.placement.y) / 100) * stageDimensions.height
                          }
                          width={36}
                          height={36}
                          playerMpId={coords.playerMp?.id}
                          playerMpName={coords.playerMp?.getDisplayName()}
                          fieldingPositionId={coords.fieldingPositionId}
                          shirtNumber={coords.playerMp?.shirtNumber}
                          onDragStart={handleDragStart}
                          onDragEnd={handleDragEnd}
                          draggable={
                            (!inBall || (inBall && editBall)) &&
                            !selectingPlayerParams &&
                            coords.fieldingPositionId !== undefined &&
                            (coords.fieldingPositionId === null ||
                              FieldingPositions[coords.fieldingPositionId] !== 'BOWLER')
                          }
                        >
                          <Circle
                            placement={true}
                            width={36}
                            height={36}
                            radius={18}
                            stroke={
                              selectingPlayerParams && selectingPlayerParams.playerMpId === coords.playerMp?.id
                                ? Theme.colors.cls.blue[200]
                                : (inBall &&
                                    ball.fieldingAnalysis?.fieldingPlayers?.find(
                                      (p: IFieldingPlayerModel) => p.playerMp.id === coords.playerMp?.id
                                    )) ||
                                  (!inBall && lastBall && coords.playerMp && fielderHasMoved(coords, ball, lastBall))
                                ? Theme.colors.cls.yellow[800]
                                : '#3BA25D'
                            }
                            strokeWidth={2}
                            fill={
                              selectingPlayerParams && selectingPlayerParams.playerMpId === coords.playerMp?.id
                                ? Theme.colors.cls.blue['400']
                                : (inBall &&
                                    ball.fieldingAnalysis?.fieldingPlayers?.find(
                                      (p: IFieldingPlayerModel) => p.playerMp.id === coords.playerMp?.id
                                    )) ||
                                  (!inBall && lastBall && coords.playerMp && fielderHasMoved(coords, ball, lastBall))
                                ? Theme.colors.cls.yellow['400']
                                : Theme.colors.cls.black
                            }
                            scaleX={
                              selectingPlayerParams && selectingPlayerParams.playerMpId === coords.playerMp?.id
                                ? 1.5
                                : 1.0
                            }
                            scaleY={
                              selectingPlayerParams && selectingPlayerParams.playerMpId === coords.playerMp?.id
                                ? 1.5
                                : 1.0
                            }
                            shadowEnabled={false}
                            onClick={handlePlacementClick} // desktop
                            onTap={handlePlacementClick} // mobile
                            listening={!wagonWheeling}
                          />
                          <KText
                            text={
                              matchSettings.fielderShirtNumbers
                                ? coords.playerMp?.shirtNumber?.toString() || coords.playerMp?.getInitials || '?'
                                : coords.playerMp?.getInitials || '?'
                            }
                            fill={
                              (inBall &&
                                ball.fieldingAnalysis?.fieldingPlayers?.find(
                                  (p: IFieldingPlayerModel) =>
                                    p.playerMp.id === coords.playerMp?.id &&
                                    p.playerMp.id !== selectingPlayerParams?.playerMpId
                                )) ||
                              (!inBall &&
                                lastBall &&
                                coords.playerMp &&
                                fielderHasMoved(coords, ball, lastBall) &&
                                (!selectingPlayerParams || selectingPlayerParams.playerMpId !== coords.playerMp?.id))
                                ? Theme.colors.cls.black
                                : '#FFFFFF'
                            }
                            fontFamily="Arial"
                            fontStyle="bold"
                            fontSize={
                              selectingPlayerParams && selectingPlayerParams.playerMpId === coords.playerMp?.id
                                ? 16
                                : 14
                            }
                            width={36}
                            height={36}
                            offsetX={18}
                            offsetY={
                              selectingPlayerParams && selectingPlayerParams.playerMpId === coords.playerMp?.id ? 8 : 6
                            }
                            align="center"
                            listening={false}
                          />
                        </Group>
                      </Group>
                    ))}
                  {ball && ball.battingAnalysis && ball.battingAnalysis.shots.wagonWheel && (
                    <Group name="wagonWheel">
                      <Line
                        name="wagonWheelLine"
                        stroke="black"
                        strokeWidth={2}
                        lineCap="round"
                        lineJoin="round"
                        points={[
                          stageOrigin.x,
                          stageOrigin.y,
                          matchSettings.activeFieldRotation === 180
                            ? ((100 - ball.battingAnalysis.shots.wagonWheel.x) / 100) * stageDimensions.width
                            : (ball.battingAnalysis.shots.wagonWheel.x / 100) * stageDimensions.width,
                          matchSettings.activeFieldRotation === 180
                            ? ((100 - ball.battingAnalysis.shots.wagonWheel.y) / 100) * stageDimensions.height
                            : (ball.battingAnalysis.shots.wagonWheel.y / 100) * stageDimensions.height,
                        ]}
                      />
                      <Circle
                        name="wagonWheelCircle"
                        radius={6}
                        fill={Theme.colors.cls.yellow['400']}
                        stroke={Theme.colors.cls.yellow[800]}
                        strokeWidth={2}
                        x={
                          matchSettings.activeFieldRotation === 180
                            ? ((100 - ball.battingAnalysis.shots.wagonWheel.x) / 100) * stageDimensions.width
                            : (ball.battingAnalysis.shots.wagonWheel.x / 100) * stageDimensions.width
                        }
                        y={
                          matchSettings.activeFieldRotation === 180
                            ? ((100 - ball.battingAnalysis.shots.wagonWheel.y) / 100) * stageDimensions.height
                            : (ball.battingAnalysis.shots.wagonWheel.y / 100) * stageDimensions.height
                        }
                      />
                    </Group>
                  )}
                </Layer>
              </Stage>
            </Box>
          </Flex>
          <Flex
            flex={1}
            minW="218px"
            h="100%"
            direction="column"
            border={`solid 1px ${Theme.colors.gray['300']}`}
            padding="7px"
            overflowX="hidden"
            overflowY="auto"
          >
            {selectingPlayerParams && (
              <>
                <FieldingPlacementsFielder
                  ball={ball}
                  editBall={editBall}
                  inBall={inBall}
                  viablePositions={viablePositions}
                  selectingPlayerParams={selectingPlayerParams}
                  handleSelectPosition={handleSelectPosition}
                  handleRunOutRoles={handleRunOutRoles}
                  triggerNewBowler={triggerNewBowler}
                  triggerNewFielder={triggerNewFielder}
                  handleCreateFielder={handleCreateFielder}
                  setFieldHasChanged={setFieldHasChanged}
                />
                <Box w="100%" h="80px" position="sticky" bottom="0%" left="0%" padding="14px 0 0">
                  <Flex flex={1} w="100%" h="100%" direction="row">
                    {inBall && (
                      <Flex h="100%" flex={1}>
                        <Button
                          w="100%"
                          h="100%"
                          colorScheme="red"
                          onClick={clearSelectPlayer}
                          isDisabled={
                            selectingPlayerParams?.playerMpId &&
                            ball.fieldingAnalysis?.fielderInvolvedOnBall(selectingPlayerParams.playerMpId)
                              ? false
                              : true
                          }
                          data-testid="clearSelectPlayerButton"
                        >
                          Clear
                        </Button>
                      </Flex>
                    )}
                    <Flex h="100%" flex={1} paddingLeft="7px">
                      <Button
                        w="100%"
                        h="100%"
                        colorScheme="cls.gray"
                        onClick={closeSelectPlayer}
                        data-testid="closeSelectPlayerButton"
                      >
                        Close
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </>
            )}
            {!selectingPlayerParams && !inBall && isCurrentInnings && !actionsDisabled && (
              <FieldingPlacementsFields
                ball={ball}
                fieldHasChanged={fieldHasChanged}
                setFieldHasChanged={setFieldHasChanged}
                handleUpdatePlacements={handleUpdatePlacements}
                completeChangeBowler={completeChangeBowler}
              />
            )}
            {!selectingPlayerParams && inBall && (
              <>
                <SubHeading text="Tasks" secondary />
                <Box marginTop="7px">
                  <ProgressSteps
                    arrange="column"
                    itemWidth="230px"
                    items={[
                      {
                        text: 'Plotted shot location',
                        isComplete: !isNil(inBallShotLocationDone) ? inBallShotLocationDone() : false,
                        isClearable: !inBallNonFieldedWicket,
                        labelClear: editBall && !isEditingWithoutWagonWheel ? 'Re-plot' : undefined,
                        onClear: () => clearComponent(undefined, true),
                      },
                      {
                        text: 'Basics and Events',
                        isComplete: !isNil(inBallBasicsEventsDone) ? inBallBasicsEventsDone() : false,
                      },
                      {
                        text: 'Fielder Information',
                        isComplete: !isNil(inBallFielderInfoDone) ? inBallFielderInfoDone() : false,
                      },
                    ]}
                  />
                </Box>
              </>
            )}
          </Flex>
        </Flex>
        {(!inBall || (inBall && editBall)) && (
          <FieldingPlacementsPositions
            selectingPositionParams={selectingPositionParams}
            viablePositions={viablePositions}
            handleSelectPosition={handleSelectPosition}
            closeSelectPosition={closeSelectPosition}
          />
        )}
      </>
    )
  }
)
