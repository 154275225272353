import type { MutableRefObject } from 'react'
import { useEffect, useRef, useState } from 'react'

import { NotificationBox } from '../NotificationBox/NotificationBox'

const useIsVisible = (ref: MutableRefObject<Element | null>) => {
  const [isIntersecting, setIntersecting] = useState(!ref || !ref.current ? true : false)

  useEffect(() => {
    if (!ref || !ref.current) return
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))
    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}

export const AdExtensionSniffer = () => {
  const adWrapperRef = useRef(null)
  const isVisible = useIsVisible(adWrapperRef)

  const handleRefresh = () => {
    if (window) window.location?.reload()
  }

  return (
    <>
      <div ref={adWrapperRef} className="ad-wrapper" style={{ position: 'fixed', top: '0', left: '0' }} />
      {!isVisible && (
        <NotificationBox
          onClick={handleRefresh}
          id="adBlock"
          text="Please turn off your Ad blocker"
          buttonText="Re-check"
          buttonIcon={['far', 'redo']}
        />
      )}
    </>
  )
}
