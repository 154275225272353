import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import type { IBallModel } from '@clsplus/cls-plus-data-models'
import type { GetDismissalMethodsType } from '@ias-shared/cricket-logic'
import { getDismissalMethods } from '@ias-shared/cricket-logic'
import { endsWith, startsWith } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { db } from '../../data/dexie/Database'
import { DismissalMethods, ExtrasTypeOptions, ScoringButtons } from '../../data/reference'
import BallHelpers from '../../helpers/ballHelpers'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { DropdownCallbackArgs } from '../../types'
import type { BasicScoringProps } from '../../types/props'
import Predictive from '../AdvancedScoring/Predictive'
import WicketSection from '../BallDescription/WicketSection'
import { CustomButton } from '../Buttons/CustomButton'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import Dropdown from '../Dropdown/Dropdown'
import Stepper from '../Stepper/Stepper'

const isActive = (ball: IBallModel, value: number, noBallValue: number | null = 1): boolean => {
  if (value === 0) return ball.runsBat === 0
  if (value === 1 && ball.getExtraType !== 'WIDE' && !endsWith(ball.getExtraType, 'BYE')) return ball.runsBat === 1
  if ((ball.runsBat || 0) + (ball.runsExtra || 0) > 0) {
    return (
      (ball.runsBat && ball.runsBat === value) ||
      (ball.getExtraType && startsWith(ball.getExtraType, 'NO_BALL')
        ? ball.runsExtra === value + (noBallValue || 1)
        : ball.getExtraType === 'WIDE'
        ? ball.runsExtra === value + 1
        : ball.runsExtra === value)
    )
  }
  return false
}

const isDisabledAllRun = (ball: IBallModel): boolean => {
  if (ball.runsBat !== null) {
    // if not 4 or 6 runs, disable All Run button
    return ball.runsBat !== 4 && ball.runsBat !== 6
  } else if (ball.getExtraType) {
    if (!startsWith(ball.getExtraType, 'NO_BALL')) {
      // if not 4 or 6 leg byes/byes, or 5 or 7 wides
      return ball.getExtraType === 'WIDE'
        ? ball.runsExtra !== 5 && ball.runsExtra !== 7
        : ball.runsExtra !== 4 && ball.runsExtra !== 6
    } else {
      // if not 4 or 6 leg byes/byes (coupled with the 1 no ball => 5 || 7)
      return ball.getExtraType === 'NO_BALL' ? true : ball.runsExtra !== 5 && ball.runsExtra !== 7
    }
  }
  return true
}

const BasicScoring = observer(
  ({
    ball,
    game,
    mode = 'main',
    editBall = false,
    insertBall = false,
    ballRunsVal,
    setBallRunsVal,
    ballExtrasVal,
    setBallExtrasVal,
    isPrimaryEditCheck,
  }: BasicScoringProps) => {
    const [isShortRunOpen, setIsShortRunOpen] = useState(false)
    const isNoBallExtra = ball.getExtraType && startsWith(ball.getExtraType, 'NO_BALL')
    const isFivePlusActive =
      (ball.runsBat && ball.runsBat >= 5 && ball.runsBat !== 6) ||
      (ball.runsExtra &&
        ball.getExtraType &&
        ((!isNoBallExtra && ball.getExtraType !== 'WIDE' && ball.runsExtra >= 5 && ball.runsExtra !== 6) ||
          (ball.getExtraType === 'WIDE' && ball.runsExtra >= 6 && ball.runsExtra !== 7) ||
          (isNoBallExtra &&
            ball.runsExtra >= 5 + (game.matchConfigs.noBallRuns || 1) &&
            ball.runsExtra !== 6 + (game.matchConfigs.noBallRuns || 1))))
        ? true
        : false
    const runsAndExtrasRuns =
      ball.getExtraType && ball.runsExtra
        ? isNoBallExtra
          ? (ball.runsBat || 0) + ball.runsExtra - (game.matchConfigs.noBallRuns || 1)
          : ball.runsExtra
        : ball.runsBat || 0
    const dismissalAllowed = getDismissalMethods({}).find(
      (dismissal: GetDismissalMethodsType) =>
        ball.dismissal && dismissal.id === DismissalMethods[ball.dismissal.dismissalTypeId]
    ) || {
      allowedWithRuns: true,
      allowedWithWides: true,
      allowedWithNoBalls: true,
    }

    const changeRuns = ({ value }: { value: number }) => {
      if (value < 4 && ball.allRun) changeAllRun()
      setBallRunsVal(
        value === ball.runsBat ||
          (ball.runsExtra && value === ball.runsExtra && !isNoBallExtra) ||
          (ball.runsExtra && value === ball.runsExtra - (game.matchConfigs.noBallRuns || 1) && isNoBallExtra)
          ? null
          : ballExtrasVal === 'WIDE'
          ? value - 1
          : value
      )
      ball.setRunsAndExtras(
        ballExtrasVal === 'NO_BALL'
          ? value + (game.matchConfigs.noBallRuns || 1)
          : ballExtrasVal === 'WIDE'
          ? value + 1
          : value,
        ballExtrasVal,
        'runs',
        game.matchConfigs.noBallRuns
      )
      if (
        (ball.runsBat === 4 || ball.runsBat === 6 || ball.runsExtra === 4 || ball.runsExtra === 6) &&
        !ball.allRun &&
        !ball.predictives?.likelyBoundary
      ) {
        updatePredictiveMessaging('boundary')
        setTimeout(() => ball.setLikelyBoundary(true), 50) // timeout needed to avoid duplicate ball S3P payload detection in rootStore.tsx
      }
      if (isPrimaryEditCheck) isPrimaryEditCheck()
    }
    const changeRunsCustom = ({ value }: DropdownCallbackArgs) => {
      changeRuns({ value: Number(value) })
    }
    const changeExtraType = ({ value }: { value: string }) => {
      let extrasType: string | undefined | null = ball.getExtraType
      if (extrasType && extrasType === value) {
        // turning off the only active extra type
        if (extrasType !== 'NO_BALL' && ballRunsVal === 1) setBallRunsVal(null)
        extrasType = null
      } else if (extrasType && startsWith(extrasType, 'NO_BALL_') && value === 'NO_BALL') {
        // extra type is a no ball + leg bye/bye, and we are turning off the no ball
        extrasType = extrasType?.replace('NO_BALL_', '')
      } else if (extrasType && startsWith(extrasType, 'NO_BALL_') && (value === 'LEG_BYE' || value === 'BYE')) {
        // extra type is a no ball + leg bye/bye, and we are turning off or changing the leg bye/bye
        extrasType = extrasType.replace('NO_BALL_', '') !== value ? `NO_BALL_${value}` : 'NO_BALL'
      } else if (extrasType && startsWith(extrasType, 'NO_BALL') && (value === 'LEG_BYE' || value === 'BYE')) {
        // extra type is a no ball, and we are adding a leg bye or bye
        extrasType = `NO_BALL_${value}`
      } else if ((extrasType === 'LEG_BYE' || extrasType === 'BYE') && value === 'NO_BALL') {
        // extra type is a leg bye or bye, and we are adding a no ball
        extrasType = `NO_BALL_${extrasType}`
      } else {
        // turning on an extra type, with no existing extra type
        extrasType = value
      }
      setBallExtrasVal(extrasType)
      ball.setRunsAndExtras(ballRunsVal, extrasType, 'extras', game.matchConfigs.noBallRuns)
      if (isPrimaryEditCheck) isPrimaryEditCheck()
    }
    const changeAllRun = () => {
      ball.setAllRun()
    }
    const changeShortRuns = (value: number, operation: 'add' | 'subtract') => {
      ball.setShortRun(value)
      // Subtracting short runs means adding runs back onto the total & vice versa
      changeRuns({ value: operation === 'subtract' ? runsAndExtrasRuns + 1 : runsAndExtrasRuns - 1 })
    }
    const updatePredictiveMessaging = (type: string) => {
      if (mode === 'betting') {
        if (type === 'boundary') {
          db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.possibleBoundary(game))
        } else if (type === 'wicket') {
          db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.possibleWicket(game))
        }
      }
    }

    useEffect(() => {
      if (editBall) {
        setBallRunsVal(ball.runsBat || ball.runsExtra)
        if (ball.getExtraType) {
          setBallExtrasVal(ball.getExtraType)
        }
      }
    }, [ball, setBallExtrasVal, editBall, setBallRunsVal])

    return (
      <Flex flex={2} direction={mode === 'main' ? 'row' : 'column'} w={mode !== 'main' ? '100%' : undefined}>
        <Flex w="100%" flex={1} justify="center" direction="row">
          {mode === 'main' && (
            <Flex flex={1} align="center" padding="0 7px 0 0">
              <Predictive
                mode={mode}
                ball={ball}
                editBall={editBall}
                updatePredictiveMessaging={updatePredictiveMessaging}
              />
            </Flex>
          )}
          <Flex
            flex={1}
            direction={mode !== 'main' ? 'column' : 'row'}
            marginLeft={mode === 'main' ? '21px' : 0}
            width={mode !== 'main' ? '100%' : undefined}
          >
            <Flex
              flex={mode !== 'main' ? undefined : 1}
              justify={mode !== 'main' ? 'flex-start' : 'center'}
              direction={mode !== 'main' ? ['column', 'column', 'row', 'row'] : 'row'}
              width={mode !== 'main' ? '100%' : undefined}
            >
              {mode !== 'main' && (
                <Flex flex={1} margin={['4px 0 14px 0', '4px 0 14px 0', '0 21px 0 0', '0 21px 0 0']}>
                  <Predictive
                    mode="betting"
                    ball={ball}
                    editBall={editBall ? true : false}
                    updatePredictiveMessaging={updatePredictiveMessaging}
                  />
                </Flex>
              )}
              <Flex
                flex={mode !== 'main' ? [1, 1, 4, 4] : 1}
                justify={mode !== 'main' ? 'flex-start' : 'center'}
                direction="column"
                width={mode !== 'main' ? '100%' : undefined}
              >
                <Flex paddingBottom="7px" justify={mode !== 'main' ? 'flex-start' : 'center'}>
                  <>
                    {ScoringButtons.map(button => (
                      <SimpleStateButton
                        key={button.value}
                        onClick={changeRuns}
                        value={button.value}
                        isActive={isActive(ball, button.value, game.matchConfigs.noBallRuns || 1)}
                        isDisabled={
                          (button.optionsName === 'dot' && ball.extrasTypeId !== null) ||
                          !dismissalAllowed.allowedWithRuns
                        }
                        height="44px"
                        width={mode !== 'main' ? undefined : '52px'}
                        isFlex={mode !== 'main'}
                        isFirst={button.first}
                        isMiddle={button.middle}
                        isLast={button.last}
                        hideCheck={mode !== 'main'}
                        paddingXOuter={mode !== 'main' ? '0px' : undefined}
                        data-testid={`scoringButton_${button.label}`}
                      >
                        <Text fontSize="2xl">{button.label}</Text>
                      </SimpleStateButton>
                    ))}
                    <Flex marginLeft="3px">
                      <Dropdown
                        target="runs_bat"
                        origValue="5+"
                        forceChanged={isFivePlusActive}
                        width={mode !== 'main' ? undefined : '60px'}
                        height="44px"
                        fontSize="2xl"
                        options={[
                          { value: '5', label: '5' },
                          { value: '7', label: '7' },
                          { value: '8', label: '8' },
                          { value: '9', label: '9' },
                          { value: '10', label: '10' },
                          { value: '11', label: '11' },
                          { value: '12', label: '12' },
                        ]}
                        value={
                          isFivePlusActive
                            ? ball.getExtraType === 'WIDE'
                              ? `${runsAndExtrasRuns - 1}`
                              : `${runsAndExtrasRuns}`
                            : '5+'
                        }
                        onChange={changeRunsCustom}
                        placement={mode !== 'main' ? 'bottom-end' : undefined}
                        hideCheck={mode !== 'main'}
                        isClearable
                        preserveCase
                        buttonDisable={!dismissalAllowed.allowedWithRuns}
                        data-testid="basicScoringRunsDropdown"
                      />
                    </Flex>
                  </>
                </Flex>
                <Flex justify={mode !== 'main' ? 'flex-start' : 'center'}>
                  <SimpleStateButton
                    onClick={changeExtraType}
                    value={'NO_BALL'}
                    isActive={
                      ball.getExtraType === 'NO_BALL' ||
                      ball.getExtraType === 'NO_BALL_LEG_BYE' ||
                      ball.getExtraType === 'NO_BALL_BYE'
                    }
                    isDisabled={!dismissalAllowed.allowedWithNoBalls}
                    isFirst
                    height="44px"
                    width={mode !== 'main' ? undefined : '60px'}
                    isFlex={mode !== 'main'}
                    paddingXOuter={mode !== 'main' ? '0px' : undefined}
                    hideCheck={mode !== 'main'}
                    data-testid="noBallBasicScoring"
                  >
                    <Text fontSize={mode !== 'main' ? 'lg' : '2xl'}>NB</Text>
                  </SimpleStateButton>
                  <SimpleStateButton
                    onClick={changeExtraType}
                    value={'WIDE'}
                    isActive={ball.getExtraType === 'WIDE'}
                    isDisabled={!dismissalAllowed.allowedWithWides}
                    isMiddle
                    height="44px"
                    width={mode !== 'main' ? undefined : '63px'}
                    isFlex={mode !== 'main'}
                    paddingXOuter={mode !== 'main' ? '0px' : undefined}
                    hideCheck={mode !== 'main'}
                    data-testid="wideBasicScoring"
                  >
                    <Text fontSize={mode !== 'main' ? 'lg' : '2xl'}>WD</Text>
                  </SimpleStateButton>
                  <SimpleStateButton
                    onClick={changeExtraType}
                    value={'LEG_BYE'}
                    isActive={ball.getExtraType === 'LEG_BYE' || ball.getExtraType === 'NO_BALL_LEG_BYE'}
                    isDisabled={
                      ball.getExtraType === 'NO_BALL_LEG_BYE'
                        ? !dismissalAllowed.allowedWithNoBalls
                        : !dismissalAllowed.allowedWithRuns
                    }
                    isMiddle
                    height="44px"
                    width={mode !== 'main' ? undefined : '63px'}
                    isFlex={mode !== 'main'}
                    hideCheck={mode !== 'main'}
                    paddingXOuter={mode !== 'main' ? '0px' : undefined}
                    data-testid="legByeBasicScoring"
                  >
                    <Text fontSize={mode !== 'main' ? 'lg' : '2xl'}>LB</Text>
                  </SimpleStateButton>
                  <SimpleStateButton
                    onClick={changeExtraType}
                    value={'BYE'}
                    isActive={ball.getExtraType === 'BYE' || ball.getExtraType === 'NO_BALL_BYE'}
                    isDisabled={
                      ball.getExtraType === 'NO_BALL_BYE'
                        ? !dismissalAllowed.allowedWithNoBalls
                        : !dismissalAllowed.allowedWithRuns
                    }
                    isMiddle
                    height="44px"
                    width={mode !== 'main' ? undefined : '63px'}
                    isFlex={mode !== 'main'}
                    hideCheck={mode !== 'main'}
                    paddingXOuter={mode !== 'main' ? '0px' : undefined}
                    data-testid="byeBasicScoing"
                  >
                    <Text fontSize={mode !== 'main' ? 'lg' : '2xl'}>B</Text>
                  </SimpleStateButton>
                  <CustomButton
                    onClick={changeAllRun}
                    isActive={ball.allRun}
                    isDisabled={isDisabledAllRun(ball)}
                    height="44px"
                    width={mode !== 'main' ? undefined : '63px'}
                    isFlex={mode !== 'main'}
                    isMiddle
                    hideCheck={mode !== 'main'}
                    paddingXOuter={mode !== 'main' ? '0px' : undefined}
                    data-testid="allRunBasicScoring"
                  >
                    <Text fontSize={14} textTransform="uppercase">
                      All
                      <br />
                      Run
                    </Text>
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      setIsShortRunOpen(true)
                    }}
                    isActive={ball.shortRun > 0}
                    isDisabled={
                      BallHelpers.runsAndExtrasTotal(ball, false, true) -
                        (!ball.runsExtra ||
                        (ball.runsExtra &&
                          ball.extrasTypeId !== null &&
                          ExtrasTypeOptions[ball.extrasTypeId] === 'NO_BALL')
                          ? 1
                          : 0) +
                        ball.shortRun <=
                      0
                    }
                    height="44px"
                    width={mode !== 'main' ? undefined : '66px'}
                    isFlex={mode !== 'main'}
                    isLast
                    hideCheck={true}
                    paddingXOuter={mode !== 'main' ? '0px' : undefined}
                    data-testid="shortRun(s)BasicScoring"
                  >
                    <Text fontSize={14} textTransform="uppercase">
                      Short
                      <br />
                      Run(s)
                    </Text>
                  </CustomButton>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              flex={1.5}
              justify="center"
              align="center"
              marginY={mode !== 'main' ? '11px' : undefined}
              marginLeft={mode === 'main' ? '28px' : undefined}
              borderTop={mode !== 'main' ? 'solid 2px' : undefined}
              borderTopColor={mode !== 'main' ? 'cls.backgroundGray' : undefined}
              borderBottom={mode !== 'main' ? 'solid 2px' : undefined}
              borderBottomColor={mode !== 'main' ? 'cls.backgroundGray' : undefined}
            >
              <WicketSection
                ball={ball}
                game={game}
                mode={mode}
                editBall={editBall}
                insertBall={insertBall}
                isPrimaryEditCheck={isPrimaryEditCheck}
                setBallRunsVal={setBallRunsVal}
                setBallExtrasVal={setBallExtrasVal}
              />
            </Flex>
          </Flex>
        </Flex>
        <Modal
          isOpen={isShortRunOpen}
          onClose={() => {
            setIsShortRunOpen(false)
          }}
          size="md"
        >
          <ModalOverlay />
          <ModalContent
            style={
              mode === 'main'
                ? { width: '224px', height: '119px', top: '16rem', left: '364px', position: 'absolute' }
                : { width: '94%', height: '119px', top: '14rem', left: '3%', position: 'absolute' }
            }
          >
            <ModalHeader>Short Run(s)</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex w="100%" justifyContent={mode !== 'main' ? 'center' : 'flex-start'}>
                <Box w="154px">
                  <Stepper
                    id="short_run"
                    value={ball.shortRun}
                    onChange={changeShortRuns}
                    min={0}
                    max={
                      ball.shortRun
                        ? BallHelpers.runsAndExtrasTotal(ball, false, true) + ball.shortRun
                        : BallHelpers.runsAndExtrasTotal(ball, false, true)
                    }
                    isEditing={editBall}
                    isRatio
                  />
                </Box>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    )
  }
)

export default BasicScoring
