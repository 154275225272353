import { types } from 'mobx-state-tree'

import MatchMetaDataModel from './MatchMetaDataModel'
import MatchSettingsModel from './MatchSettingsModel'

const MatchMap = types.model('MatchMap', {
  id: types.identifier, // matchID
  meta: MatchMetaDataModel,
  settings: MatchSettingsModel,
})

export default MatchMap
