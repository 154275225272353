import { Flex, Text } from '@chakra-ui/react'

import type { SocketLightProps } from '../../types/props'

const SocketLights = ({ connected, primary, assignedRole, setPromptUser }: SocketLightProps) => {
  const message = connected ? 'Connected' : 'Offline'
  const primaryMessage = primary ? 'Primary' : 'Secondary'

  const handleClick = () => {
    setPromptUser(true)
  }
  return (
    <Flex
      direction="column"
      align="center"
      justify="space-evenly"
      h="100%"
      w="100%"
      onClick={handleClick}
      _hover={{ cursor: 'pointer' }}
    >
      <Flex justify="center" align="center" flex={1} w="100%">
        <Text fontSize={12} color="white">
          {message}
        </Text>
      </Flex>
      {connected && assignedRole && (
        <Flex justify="center" align="center" flex={1} w="100%">
          <Text fontSize={12} color="white">
            {primaryMessage}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}
export default SocketLights
