import { theme } from '@chakra-ui/react'

const ourTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    cls: {
      blue: {
        50: '#d9f7ff',
        100: '#ace3ff',
        200: '#7bcfff',
        300: '#49bcff',
        400: '#0087D8', // main
        500: '#008fe6',
        600: '#0070b4',
        700: '#005082',
        800: '#003051',
        900: '#001121',
      },
      yellow: {
        50: '#fffadb',
        100: '#ffefaf',
        200: '#ffe47e',
        300: '#FFD846',
        400: '#FFD846',
        500: '#FFD846',
        600: '#ffcf1e',
        700: '#e6b507',
        800: '#b38d00',
        900: '#806500',
      },
      black: '#434A54',
      textGray: '#9B9B9B',
      backgroundGray: '#EFEFEF',
      gray: {
        100: '#dcd8d9',
        200: '#bfbfbf', // dark
        300: '#a6a6a6',
        400: '#8c8c8c',
        500: '#737373',
        600: '#595959',
        700: '#404040',
        800: '#282626',
        900: '#150a0d',
      },
      white: {
        100: '#FFFFFF',
        200: '#FFFFFF',
        300: '#FFFFFF',
        400: '#FFFFFF',
        500: '#FFFFFF',
        600: '#E2E8F0', // dark
        700: '#E2E8F0',
        800: '#E2E8F0',
        900: '#E2E8F0',
      },
      transparentHighlightDark: 'rgba(0, 0, 0, 0.2)',
      transparentHighlightLight: 'rgba(255, 255, 255, 0.3)',
      fieldGreen: '#ADC5B5',
      negativeRed: '#fc6969',
      tiers: {
        1: '#4EAA38',
        2: '#a6e511',
        3: '#FEE468',
        4: '#ffb543',
        5: '#f98226',
      },
    },
  },
  shadows: {
    ...theme.shadows,
    cls: {
      light: '0 1px 1px 0 rgba(0, 0, 0, 0.3)',
      dark: '0 1px 1px 0 rgba(67, 74, 84, 0.3)',
    },
  },
  fonts: {
    body: 'Source Sans Pro, sans-serif',
    heading: 'Source Sans Pro, sans-serif',
    mono: theme.fonts.mono,
  },
  fontSizes: {
    '2xs': '10px',
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    '2xl': '20px',
    '3xl': '24px',
    '4xl': '32px',
    '5xl': '44px',
    '6xl': '60px',
  },
  toast: {
    short: 1500,
    normal: 3000,
    long: 5000,
    extraLong: 10000,
  },
  styles: {
    global: {
      '.react-datepicker__navigation-icon::before': {
        top: '12px',
      },
    },
  },
}

export default ourTheme
