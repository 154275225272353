import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import type {
  BallModel,
  CurrentInning,
  IBallModel,
  IBattingPerformanceModel,
  IBowlingPerformanceModel,
  IFieldingPlacementStoreModel,
} from '@clsplus/cls-plus-data-models'
import { observer } from 'mobx-react-lite'
import type { SnapshotOrInstance } from 'mobx-state-tree'
import { useEffect, useState } from 'react'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import useInterval from 'use-interval'

import { MatchStartingModal } from '../components/MatchStartingModal/MatchStartingModal'
import { SocketModal } from '../components/SocketModal/SocketModal'
import { db } from '../data/dexie/Database'
import type BallStore from '../data/stores/balls/BallStore/BallStore'
import { useMst } from '../data/stores/rootStore'
import Auth from '../helpers/auth'
import S3PHelpers from '../helpers/s3pHelpers'
import type { CascadeEditProps, EditBallProps, TimeMachineLocalStorage, UmpireEnds } from '../types'
import type {
  IBallStore,
  IDetailedMatchStore,
  ILocalMatchStore,
  ISettingsModel,
  ISocketStore,
  ITimelineStore,
} from '../types/models'
import BattingSetup from './BattingSetup'
import BowlingSetup from './BowlingSetup'
import Metrics from './Metrics'
import { PostMatch } from './PostMatch'
import Scorebook from './Scorebook'
import { Scoring } from './Scoring'
import Settings from './Settings'
import Setup from './Setup'

const Game = observer(() => {
  const store = useMst()
  const {
    detailedMatches,
    balls,
    appSettings,
    timelineEvents,
    localMatches,
    socketStore,
    fieldingPlacements,
  }: {
    detailedMatches: IDetailedMatchStore
    balls: IBallStore
    appSettings: ISettingsModel
    timelineEvents: ITimelineStore
    localMatches: ILocalMatchStore
    socketStore: ISocketStore
    fieldingPlacements: IFieldingPlacementStoreModel
  } = useMst()
  const { id, type } = useParams()
  const [inBall, setInBall] = useState(false)
  const [endOver, setEndOver] = useState(false)
  const [endInning, setEndInning] = useState(false)
  const [closedInning, setClosedInning] = useState(false)
  const [betweenOvers, setBetweenOvers] = useState(false)
  const [awaitingFirstBallOfOver, setAwaitingFirstBallOfOver] = useState(true)
  const [dismissal, setDismissal] = useState(false)
  const [retired, setRetired] = useState<string | undefined>()
  const [nonBallDismissal, setNonBallDismissal] = useState<string | undefined>()
  const [mistake, setMistake] = useState(false)
  const [changeBowler, setChangeBowler] = useState(false)
  const [mistakeBowler, setMistakeBowler] = useState(false)
  const [changeBowlerBallOver, setChangeBowlerBallOver] = useState<IBallModel | null>(null)
  const [changeFielder, setChangeFielder] = useState<string | null>(null)
  const [fieldHasChanged, setFieldHasChanged] = useState(false)
  const [scorePassedIsOpen, setScorePassedIsOpen] = useState(false)
  const [oversPassedIsOpen, setOversPassedIsOpen] = useState(false)
  const [wicketsPassedIsOpen, setWicketsPassedIsOpen] = useState(false)
  const [powerPlayStartIsOpen, setPowerPlayStartIsOpen] = useState(false)
  const [venueEndsIsOpen, setVenueEndsIsOpen] = useState(false)
  const [umpireEndsIsOpen, setUmpireEndsIsOpen] = useState(false)
  const [umpireEnds, setUmpireEnds] = useState<UmpireEnds | undefined>()
  const [cascadeEditIsOpen, setCascadeEditIsOpen] = useState(false)
  const [cascadeEditProps, setCascadeEditProps] = useState<CascadeEditProps | undefined>()
  const [cascadeFromEditBallDismissed, setCascadeFromEditBallDismissed] = useState(false)
  const [editBall, setEditBall] = useState<SnapshotOrInstance<typeof BallModel> | undefined>()
  const [editBallProps, setEditBallProps] = useState<EditBallProps | undefined>()
  const [editBallPrimary, setEditBallPrimary] = useState(false)
  const [batterPerformance, setBatterPerformanceToChange] = useState<IBattingPerformanceModel | undefined>()
  const [bowlerPerformance, setBowlerPerformanceToChange] = useState<IBowlingPerformanceModel | undefined>()
  const [currentBall, setCurrentBall] = useState<IBallModel | undefined>()
  const [ballsSnapshot, setBallsSnapshot] = useState<SnapshotOrInstance<typeof BallStore> | undefined>()
  const [ballRunsVal, setBallRunsVal] = useState<number | null>(null)
  const [ballExtrasVal, setBallExtrasVal] = useState<string | null>(null)
  const [currentInning, setCurrentInning] = useState<CurrentInning | undefined>()
  const [commentaryChanged, setCommentaryChanged] = useState(false)
  const [cancelledDismissal, setCancelledDismissal] = useState(false)
  const [insertingBall, setInsertingBall] = useState(false)
  const [isMatchStartingModalOpen, setIsMatchStartingModalOpen] = useState(false)
  const [isMatchStartingModalManual, setIsMatchStartingModalManual] = useState(false)
  const [isMatchStartingModalConfirmed, setIsMatchStartingModalConfirmed] = useState(0)

  useInterval(() => {
    store.s3pSyncCheck(id)
  }, 300)

  useInterval(() => {
    // send ping every 1 min to ensure socket stays active and is not auto-disconnected
    if (id) store.sendPing(id, appSettings.appMode)
  }, 60000)

  useEffect(() => {
    // connect if we aren't already connected to this game
    if (id && ((socketStore.matchId && socketStore.matchId !== id) || !socketStore.matchId)) {
      const profile = Auth.getUserProfile()
      const tokens = Auth.getTokens()
      if (id && appSettings.appMode && profile?.email && tokens?.accessToken) {
        // eslint-disable-next-line max-len
        const queryString = `auth=${tokens.accessToken}&matchId=${id}&mode=${
          appSettings.manualScoring.active ? 'postMatch' : appSettings.appMode
        }&email=${profile.email}`
        store.connectSocket(queryString, id)
      }
    }
    return function cleanup() {
      store.closeSocket()
    }
  }, [appSettings.appMode, appSettings.manualScoring.active, id, store, socketStore.matchId])

  if (!id) return null
  if (type) {
    appSettings.setAppMode(type)
  }
  const appMode = appSettings.manualScoring.active ? 'postMatch' : appSettings.appMode
  detailedMatches.getMatch(id, appMode, socketStore.forceDataReloadMatch)
  timelineEvents.getEvents(id, appSettings.appMode, localMatches.getIsLocalMatch(id), socketStore.forceDataReloadEvents)

  const game = detailedMatches.results.get(id)?.match
  const gameTimelineEvents = timelineEvents.results.get(id)

  if (game) {
    balls.getBalls(game, appSettings.appMode, localMatches.getIsLocalMatch(id), socketStore.forceDataReloadBalls)
  }

  useInterval(() => {
    if (game && type && type !== 'fielding' && import.meta.env.VITE_ENV !== 'DEV') {
      db.createS3PMessage(
        S3PHelpers.metadata(appSettings.manualScoring.active ? 'postMatch' : type, game),
        S3PHelpers.heartbeat()
      )
    }
  }, 3000)

  useEffect(() => {
    // check if we are between/at the end of an innings/end of match
    // (i.e. no active innings, but we do have some innings, and we do have a recently-ended innings)
    if (
      !game?.getActiveInning(undefined, true) &&
      game?.getAllInningInOrder.length &&
      game?.getActiveInning(true) &&
      setClosedInning
    ) {
      setClosedInning(true)
    }
  }, [game, setClosedInning])

  useEffect(() => {
    // check if we need to ensure Time Machine is enabled
    const timeMachineString: string | null = window.localStorage.getItem('cls_plus_timeMachine')
    if (timeMachineString) {
      const timeMachineParsed: TimeMachineLocalStorage = JSON.parse(timeMachineString)
      if (timeMachineParsed.matchId === game?.id && timeMachineParsed.activated) {
        store.appSettings.timeMachine.setBaseline(timeMachineParsed.baseline)
        store.appSettings.timeMachine.setActivated(timeMachineParsed.activated, timeMachineParsed.matchId)
      }
    }
  }, [store.appSettings.timeMachine, game?.id])

  // TODO: Refactor this so it doesn't break resuming matches with existing balls
  // useEffect(() => {
  //   const addToLocalMatches = async () => {
  //     if (game && !localMatches.getIsLocalMatch(game.id)) {
  //       // add entry to localMatches
  //       const matchDb = await db.matches.get(game.id)
  //       if (matchDb) {
  //         const match = detailedMatches.createMatch(matchDb.matchSN)
  //         if (match) {
  //           localMatches.addLocalMatch(match)
  //         }
  //       }
  //     }
  //   }
  //   addToLocalMatches()
  // }, [game, detailedMatches, localMatches])

  const matchStateReset = () => {
    setInBall(false)
    setCancelledDismissal(false)
    setEndOver(false)
    setEndInning(false)
    setBetweenOvers(false)
    setClosedInning(false)
    setAwaitingFirstBallOfOver(false)
    setDismissal(false)
    setRetired(undefined)
    setNonBallDismissal(undefined)
    setMistake(false)
    setChangeBowler(false)
    setChangeFielder(null)
    setFieldHasChanged(false)
    setMistakeBowler(false)
    setChangeBowlerBallOver(null)
    setScorePassedIsOpen(false)
    setOversPassedIsOpen(false)
    setWicketsPassedIsOpen(false)
    setPowerPlayStartIsOpen(false)
    setVenueEndsIsOpen(false)
    setUmpireEndsIsOpen(false)
    setUmpireEnds(undefined)
    setCascadeEditIsOpen(false)
    setCascadeEditProps(undefined)
    setCascadeFromEditBallDismissed(false)
    setEditBall(undefined)
    setEditBallProps(undefined)
    setEditBallPrimary(false)
    setBatterPerformanceToChange(undefined)
    setBowlerPerformanceToChange(undefined)
    setCurrentBall(undefined)
    setBallsSnapshot(undefined)
    setBallRunsVal(null)
    setBallExtrasVal(null)
    setCurrentInning(undefined)
    setCommentaryChanged(false)
    setInsertingBall(false)
    setIsMatchStartingModalOpen(false)
    setIsMatchStartingModalManual(false)
    setIsMatchStartingModalConfirmed(0)
  }

  if (
    detailedMatches.state === 'pending' ||
    timelineEvents.state === 'pending' ||
    (balls.activeRequests && balls.activeRequests.length > 0)
  ) {
    return (
      <Flex w="100%" h="calc(100vh - 42px)" alignItems="center" justifyContent="center" direction="column">
        <Spinner thickness="4px" speed="0.75s" emptyColor="cls.gray.200" color="cls.blue.400" size="xl" />
        <Box marginTop="14px">
          <Text fontWeight="bold" fontSize="2xl" textAlign="center">
            Loading Match
          </Text>
          <Text fontSize="lg" textAlign="center" marginTop="20px">
            Loading Match data
            <br />
            <br />
            Checking system for Ball Data
            <br />
            <br />
            Checking system for match Event data
            <br />
            <br />
            Setting up socket connection
          </Text>
        </Box>
      </Flex>
    )
  }

  if (game) {
    return (
      <>
        <Routes>
          <Route
            path="setup/*"
            element={
              <>
                <Setup
                  mode={appSettings.appMode}
                  timelineEvents={gameTimelineEvents}
                  closedInning={closedInning}
                  isMatchStartingModalConfirmed={isMatchStartingModalConfirmed}
                  setIsMatchStartingModalConfirmed={setIsMatchStartingModalConfirmed}
                  setIsMatchStartingModalOpen={setIsMatchStartingModalOpen}
                  setIsMatchStartingModalManual={setIsMatchStartingModalManual}
                />
                {appSettings.appMode === 'betting' && (
                  <MatchStartingModal
                    game={game}
                    mode={appSettings.appMode}
                    isOpen={isMatchStartingModalOpen}
                    setIsOpen={setIsMatchStartingModalOpen}
                    isManual={isMatchStartingModalManual}
                    isConfirmed={isMatchStartingModalConfirmed}
                    setIsConfirmed={setIsMatchStartingModalConfirmed}
                  />
                )}
              </>
            }
          />
          <Route path="settings/*" element={<Settings mode={appSettings.appMode} />} />
          <Route path="inning-batting-setup" element={<BattingSetup mode={appSettings.appMode} />} />
          <Route
            path="inning-bowling-setup"
            element={<BowlingSetup mode={appSettings.appMode} currentBall={currentBall} />}
          />
          <Route
            path="scoring"
            element={
              <Scoring
                game={game}
                balls={balls}
                appSettings={appSettings}
                timelineEvents={gameTimelineEvents}
                relevantTimelineEvents={gameTimelineEvents?.getRelevantEvents(currentInning, game)}
                fieldingPlacements={fieldingPlacements}
                inBall={inBall}
                endOver={endOver}
                endInning={endInning}
                closedInning={closedInning}
                betweenOvers={betweenOvers}
                awaitingFirstBallOfOver={awaitingFirstBallOfOver}
                dismissal={dismissal}
                retired={retired}
                nonBallDismissal={nonBallDismissal}
                mistake={mistake}
                changeBowler={changeBowler}
                mistakeBowler={mistakeBowler}
                changeBowlerBallOver={changeBowlerBallOver}
                changeFielder={changeFielder}
                fieldHasChanged={fieldHasChanged}
                scorePassedIsOpen={scorePassedIsOpen}
                oversPassedIsOpen={oversPassedIsOpen}
                wicketsPassedIsOpen={wicketsPassedIsOpen}
                powerPlayStartIsOpen={powerPlayStartIsOpen}
                venueEndsIsOpen={venueEndsIsOpen}
                umpireEndsIsOpen={umpireEndsIsOpen}
                umpireEnds={umpireEnds}
                cascadeEditIsOpen={cascadeEditIsOpen}
                cascadeEditProps={cascadeEditProps}
                cascadeFromEditBallDismissed={cascadeFromEditBallDismissed}
                editBall={editBall}
                editBallProps={editBallProps}
                editBallPrimary={editBallPrimary}
                batterPerformance={batterPerformance}
                bowlerPerformance={bowlerPerformance}
                currentBall={currentBall}
                ballsSnapshot={ballsSnapshot}
                ballRunsVal={ballRunsVal}
                ballExtrasVal={ballExtrasVal}
                currentInning={currentInning}
                commentaryChanged={commentaryChanged}
                cancelledDismissal={cancelledDismissal}
                insertingBall={insertingBall}
                setInBall={setInBall}
                setEndOver={setEndOver}
                setEndInning={setEndInning}
                setClosedInning={setClosedInning}
                setBetweenOvers={setBetweenOvers}
                setAwaitingFirstBallOfOver={setAwaitingFirstBallOfOver}
                setDismissal={setDismissal}
                setRetired={setRetired}
                setNonBallDismissal={setNonBallDismissal}
                setMistake={setMistake}
                setChangeBowler={setChangeBowler}
                setMistakeBowler={setMistakeBowler}
                setChangeBowlerBallOver={setChangeBowlerBallOver}
                setChangeFielder={setChangeFielder}
                setFieldHasChanged={setFieldHasChanged}
                setScorePassedIsOpen={setScorePassedIsOpen}
                setOversPassedIsOpen={setOversPassedIsOpen}
                setWicketsPassedIsOpen={setWicketsPassedIsOpen}
                setPowerPlayStartIsOpen={setPowerPlayStartIsOpen}
                setVenueEndsIsOpen={setVenueEndsIsOpen}
                setUmpireEndsIsOpen={setUmpireEndsIsOpen}
                setUmpireEnds={setUmpireEnds}
                setCascadeEditIsOpen={setCascadeEditIsOpen}
                setCascadeEditProps={setCascadeEditProps}
                setCascadeFromEditBallDismissed={setCascadeFromEditBallDismissed}
                setEditBall={setEditBall}
                setEditBallProps={setEditBallProps}
                setEditBallPrimary={setEditBallPrimary}
                setBatterPerformanceToChange={setBatterPerformanceToChange}
                setBowlerPerformanceToChange={setBowlerPerformanceToChange}
                setCurrentBall={setCurrentBall}
                setBallsSnapshot={setBallsSnapshot}
                setBallRunsVal={setBallRunsVal}
                setBallExtrasVal={setBallExtrasVal}
                setCurrentInning={setCurrentInning}
                setCommentaryChanged={setCommentaryChanged}
                setCancelledDismissal={setCancelledDismissal}
                setInsertingBall={setInsertingBall}
              />
            }
          />
          <Route path="scorebook/*" element={<Scorebook game={game} appSettings={appSettings} />} />
          <Route path="metrics" element={<Metrics game={game} />} />
          <Route path="post-match/*" element={<PostMatch mode={appSettings.appMode} />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <SocketModal
          socketStore={socketStore}
          matchId={id}
          appMode={appSettings.appMode}
          matchStateReset={matchStateReset}
        />
      </>
    )
  }
  return null
})

export default Game
