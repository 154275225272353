import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/react'
import Dexie from 'dexie'
import { exportDB, importDB } from 'dexie-export-import'
import fileDownload from 'js-file-download'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import LogoSVG from '../assets/logo_white_bg.svg'
import { Button } from '../components/Buttons/Button'
import { db } from '../data/dexie/Database'
import { useMst } from '../data/stores/rootStore'
import type { IBallStore, ITimelineStore } from '../types/models'

const clearDemoData = (ballStore: IBallStore, eventStore: ITimelineStore, matchID: string) => {
  ballStore.deleteMatchData(matchID)
  eventStore.deleteMatchData(matchID)

  return Promise.all([
    db.matches.delete(matchID),
    db.balls.where({ matchId: matchID }).delete(),
    db.events.delete(matchID),
    db.timeline.where({ matchId: matchID }).delete(),
  ])
}

const Authenticated = () => {
  const navigate = useNavigate()
  const store = useMst()
  const toast = useToast()
  const { appSettings, balls, timelineEvents } = store
  const [showImport, setShowImport] = useState(false)

  const handleClick = (mode: string, matchID?: string, manualScoring?: boolean) => {
    if (mode === 'games') {
      navigate(`/games`)
    }
    if (matchID && mode) {
      appSettings.setAppMode(mode)
      const clearPromiseArray = clearDemoData(balls, timelineEvents, matchID)
      // load data
      store.loadMockData()

      appSettings.manualScoring.setActive(manualScoring ? true : false)

      clearPromiseArray.then(() => {
        setTimeout(() => navigate(`/game/${matchID}/${mode}/setup${manualScoring ? '/manual' : ''}`), 1000)
      })
    }
  }

  const deleteData = async () => {
    await db.delete()
    await Dexie.delete('Database')
    toast({
      title: 'Database Deleted',
      description: 'To create a new database, please refresh',
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }

  const downloadData = async () => {
    //@ts-ignore
    const blob = await exportDB(db, { prettyJson: true })
    fileDownload(blob, 'database.json')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const importData = async (event: any) => {
    const file = event.target.files[0]
    if (!file) throw new Error(`Only files can be uploaded here`)
    console.log('Importing ' + file.name) // eslint-disable-line no-console
    await db.delete()
    await importDB(file)
    console.log('Import complete') // eslint-disable-line no-console
  }

  return (
    <Flex align="center" justify="center" h="100vh" direction="column" backgroundColor="#FFF">
      <Box position="absolute" top="20px" left="20px">
        <Menu>
          <MenuButton data-testid="menuButton2" as={Button}>
            Actions
          </MenuButton>
          <MenuList minWidth="500px" borderColor="gray.200">
            <MenuItem
              data-testid="menuList2MenuItem1"
              onClick={() => handleClick('main', '126571a4-2e62-469b-a361-53038f57a8fd')}
            >
              Main Scoring Mode (Sample match)
            </MenuItem>
            <MenuItem
              data-testid="menuList2MenuItem2"
              onClick={() => handleClick('betting', '126571a4-2e62-469b-a361-53038f57a8fd')}
            >
              Betting Scoring Mode (Sample match)
            </MenuItem>
            <MenuItem
              data-testid="menuList2MenuItem3"
              onClick={() => handleClick('fielding', '126571a4-2e62-469b-a361-53038f57a8fd')}
            >
              Fielding Scoring Mode (Sample match)
            </MenuItem>
            <MenuItem
              data-testid="menuList2MenuItem4"
              onClick={() => handleClick('main', '126571a4-2e62-469b-a361-53038f57a8fd', true)}
            >
              Post-match Scoring Mode (Sample match)
            </MenuItem>
            <MenuItem data-testid="menuList2MenuItem5" onClick={() => downloadData()}>
              Download Database
            </MenuItem>
            <MenuItem data-testid="menuList2MenuItem6" onClick={() => setShowImport(true)}>
              Import Database
            </MenuItem>
            <MenuItem data-testid="menuList2MenuItem7" onClick={() => deleteData()}>
              Delete Database
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <img src={LogoSVG} style={{ height: '120px' }} alt="CLS+ Logo" />
      <Button as={Link} to="/games" colorScheme="cls.blue" size="lg" marginY="70px" data-testid="gamesButton">
        Games
      </Button>
      {showImport && <input type="file" onChange={importData} />}
    </Flex>
  )
}

export default Authenticated
