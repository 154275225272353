import { Box, Grid, Text } from '@chakra-ui/react'

import type { PartnershipsProps } from '../../types/props'
import { PartnershipCard } from '../PartnershipCard/PartnershipCard'

export const Partnerships = ({ inning }: PartnershipsProps) => {
  return (
    <Box>
      <Text fontSize="xl" fontStyle="italic" padding="10px">
        Partnerships
      </Text>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {inning.getPartnershipsInOrder?.map(p => (
          <PartnershipCard
            key={`partnership_${p.wicketNumber}`}
            partnership={p}
            inningsMatchOrder={inning.inningsMatchOrder}
          />
        ))}
      </Grid>
    </Box>
  )
}
