import { KinesisClient, PutRecordsCommand } from '@aws-sdk/client-kinesis'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers'

export type KinesisRecord = {
  Data: Uint8Array
  PartitionKey: string
}

const userPoolId = import.meta.env.VITE_AWS_USER_POOL_ID as string
const identityPoolId = import.meta.env.VITE_AWS_IDENTITY_POOL_ID as string
const region = 'eu-west-1'

let client: KinesisClient

export async function createKinesisClient(idToken: string | null): Promise<void> {
  if (idToken) {
    client = new KinesisClient({
      region: region,
      credentials: fromCognitoIdentityPool({
        clientConfig: { region },
        identityPoolId: identityPoolId,
        logins: {
          [`cognito-idp.${region}.amazonaws.com/${userPoolId}`]: idToken,
        },
      }),
    })
    // Trigger a fetch straight away
    await refreshCredentials()
  } else {
    console.warn('Tried to create a kinesis client with no idToken')
  }
}

async function getKinesisClient(): Promise<KinesisClient> {
  if (client) {
    const credentials = await client.config.credentials()
    // If we already have a client then just make sure the credentials have not expired
    if (credentials && credentials.expiration && new Date().getTime() > credentials.expiration.getTime()) {
      console.log('AWS Credentials have expired... requesting new ones')
      await refreshCredentials()
    }
    return client
  } else {
    throw new Error('No Kinesis Client configured')
  }
}

async function refreshCredentials() {
  if (client) {
    await client.config.credentials({ forceRefresh: true })
  }
}

export async function sendDataToKinesis(records: KinesisRecord[]) {
  try {
    const kinesisClient = await getKinesisClient()
    const command = new PutRecordsCommand({
      Records: records,
      StreamName: 's3p-kinesis-stream',
    })
    await kinesisClient.send(command)

    // Check to see if AWS Credentials will expire soon
    const credentials = await client.config.credentials()
    if (credentials && credentials.expiration && new Date().getTime() > credentials.expiration.getTime() - 600000) {
      console.log('AWS Credentials are going to expire soon... requesting new ones')
      await refreshCredentials()
    }

    return 'ok'
  } catch (e) {
    console.warn('Push to kinesis failed', e)
    return 'error'
  }
}
