import { Flex } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import type { TrajectoryProps } from '../../types/props'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'

const Trajectory = observer(({ ball, mode, isPrimaryEditCheck, noShot }: TrajectoryProps) => {
  const changeInTheAir = () => {
    if (!ball) return
    ball.setInTheAir()
    if (isPrimaryEditCheck) isPrimaryEditCheck()
  }
  const changeThroughField = () => {
    if (!ball) return
    ball.setThroughField()
    if (isPrimaryEditCheck) isPrimaryEditCheck()
  }

  return (
    <Flex flex={1} w="100%" direction={mode === 'fielding' ? 'row' : 'column'}>
      <Flex flex={1} w={mode !== 'fielding' ? '100%' : undefined}>
        <SimpleStateButton
          target="through_field"
          value={ball.battingAnalysis?.shots.throughField || false}
          onClick={changeThroughField}
          isActive={!!ball.battingAnalysis?.shots.throughField}
          isDisabled={mode === 'fielding' && noShot}
          isSwitch
          isFlex
          hideCheck
          paddingXOuter="2px"
          isWhite={mode === 'fielding'}
          data-testid="trajectoryThroughField"
        >
          Through Field
        </SimpleStateButton>
      </Flex>
      <Flex flex={1} w={mode !== 'fielding' ? '100%' : undefined} margin={mode === 'fielding' ? '0 0 0 7px' : '0px'}>
        <SimpleStateButton
          target="in_the_air"
          value={ball.battingAnalysis?.shots.inTheAir || false}
          onClick={changeInTheAir}
          isActive={ball.battingAnalysis?.shots.inTheAir}
          isDisabled={mode === 'fielding' && noShot}
          isSwitch
          isFlex
          hideCheck
          paddingXOuter="2px"
          isWhite={mode === 'fielding'}
          data-testid="trajectoryInTheAir"
        >
          In The Air
        </SimpleStateButton>
      </Flex>
    </Flex>
  )
})

export default Trajectory
