import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import type { IFieldingPlayerModel, IFieldingPositionModel } from '@clsplus/cls-plus-data-models'
import { indexOf, isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import type { SnapshotIn } from 'mobx-state-tree'
import { getSnapshot } from 'mobx-state-tree'
import { useEffect, useState } from 'react'

import { FieldingPositions, WicketKeeperPositionCoords, WicketKeeperPositionOptions } from '../../data/reference'
import type { FieldingPositionPositionsInterface } from '../../types'
import type { FieldingPlacementsFielderProps, PositionParamsProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import { CustomButton } from '../Buttons/CustomButton'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import SubHeading from '../Headings/SubHeading'
import Stepper from '../Stepper/Stepper'

export const FieldingPlacementsFielder = observer(
  ({
    ball,
    editBall,
    inBall,
    viablePositions,
    selectingPlayerParams,
    handleSelectPosition,
    triggerNewBowler,
    triggerNewFielder,
    handleCreateFielder,
    handleRunOutRoles,
    setFieldHasChanged,
  }: FieldingPlacementsFielderProps) => {
    const [runsCostSavedModalIsOpen, setRunsCostSavedModalIsOpen] = useState(false)
    const [editBallFielderSnapshot, setEditBallFielderSnapshot] = useState<SnapshotIn<IFieldingPlayerModel> | null>(
      null
    )

    let fielderObject = ball.fieldingAnalysis?.fieldingPlayers?.find(
      (p: IFieldingPlayerModel) => p.playerMp.id === selectingPlayerParams?.playerMpId
    )

    const fielderNumberPossible: number[] =
      ball.fieldingAnalysis?.fieldingPlayers?.map((fp: IFieldingPlayerModel, idx) => {
        return idx + 1
      }) || []
    if (!fielderObject) fielderNumberPossible.push(fielderNumberPossible.length + 1)

    const checkFielderOptionExists = (target: string) => {
      return ball.fieldingAnalysis?.fieldingPlayers?.find((p: IFieldingPlayerModel) => {
        // @ts-ignore
        return !isNil(p[target])
      })
        ? true
        : false
    }

    const handleOverthrows = (was: number, is: number) => {
      if (!ball.fieldingAnalysis || (is >= 0 && was >= 0)) return
      const increment: number = (is - was) * -1
      const newOverthrows: number = (ball.fieldingAnalysis.overThrows || 0) + increment
      if (newOverthrows < 0) return
      ball.fieldingAnalysis.setOverthrows(newOverthrows)
    }

    const createFielderIfNotExists = (selectingPlayerParams: PositionParamsProps) => {
      if (!fielderObject && handleCreateFielder) {
        fielderObject = handleCreateFielder(selectingPlayerParams, true)
      }
    }

    const handleDifficultyRatingChange = ({ value }: { value: number }) => {
      if (!selectingPlayerParams) return
      createFielderIfNotExists(selectingPlayerParams)
      fielderObject?.setDifficultyRating(fielderObject?.difficultyRating === value ? null : value)
    }

    const handleFieldingOrderChange = ({ value }: { value: number }) => {
      if (!selectingPlayerParams) return
      createFielderIfNotExists(selectingPlayerParams)

      const existingFielderAtOrder = ball.fieldingAnalysis?.fieldingPlayers?.find((p: IFieldingPlayerModel) => {
        return p.order === value
      })
      if (existingFielderAtOrder && fielderObject) existingFielderAtOrder.setOrder(fielderObject.order)
      fielderObject?.setOrder(value)
    }

    const handleRunsSavedChange = ({ value, target }: { value: number; target?: string | null }) => {
      if (!selectingPlayerParams) return
      createFielderIfNotExists(selectingPlayerParams)

      switch (target) {
        case 'misfielded-runsSaved':
          fielderObject?.setMisfieldRunsSaved(value)
          break
        case 'dive-runsSaved':
          fielderObject?.setDiveGoodRunsSaved(value)
          break
        case 'slide-runsSaved':
          fielderObject?.setSlideGoodRunsSaved(value)
          break
        case 'throw-runsSaved':
          handleOverthrows(fielderObject?.throwGood?.runsSaved || 0, value)
          fielderObject?.setThrowGoodRunsSaved(value)
          break
        case 'stumps-runsSaved':
          handleOverthrows(fielderObject?.throwHitStumps?.runsSaved || 0, value)
          fielderObject?.setThrowHitStumpsRunsSaved(value)
          break
        case 'backedUp-runsSaved':
          handleOverthrows(fielderObject?.throwBackedUp?.runsSaved || 0, value)
          fielderObject?.setThrowBackedUpRunsSaved(value)
          break
      }
    }

    const handleFielderOptionChange = ({ value, target }: { value: boolean; target?: string | null }) => {
      if (!selectingPlayerParams) return
      createFielderIfNotExists(selectingPlayerParams)

      switch (target) {
        case 'misfielded':
          if (
            (!ball.fieldingAnalysis?.misfielded && value) ||
            (checkFielderOptionExists(target) && ball.fieldingAnalysis?.misfielded)
          ) {
            ball.fieldingAnalysis?.setMisfield()
          }
          fielderObject?.setMisfield(
            value,
            editBallFielderSnapshot && editBallFielderSnapshot.misfielded
              ? editBallFielderSnapshot.misfielded.id
              : undefined
          )
          break
        case 'stumpingMissed':
          if (
            (!ball.fieldingAnalysis?.stumpingMissed && value) ||
            (checkFielderOptionExists(target) && ball.fieldingAnalysis?.stumpingMissed)
          ) {
            ball.fieldingAnalysis?.setMissedStumping()
          }
          if (!ball.fieldingAnalysis?.wicketOpportunity && value) {
            ball.fieldingAnalysis?.setWicketOpportunity()
          }
          fielderObject?.setMissedStumping(fielderObject?.stumpingMissed === value ? null : value)
          break
        case 'droppedCatch':
          if (
            (!ball.fieldingAnalysis?.droppedCatch && value) ||
            (checkFielderOptionExists(target) && ball.fieldingAnalysis?.droppedCatch)
          ) {
            ball.fieldingAnalysis?.setDroppedCatch()
          }
          if (!ball.fieldingAnalysis?.wicketOpportunity && value) {
            ball.fieldingAnalysis?.setWicketOpportunity()
          }
          fielderObject?.setDroppedCatch(fielderObject?.droppedCatch === value ? null : value)
          break
        case 'runOutMissed':
          if (
            (!ball.fieldingAnalysis?.runOutMissed && value) ||
            (checkFielderOptionExists(target) && ball.fieldingAnalysis?.runOutMissed)
          ) {
            ball.fieldingAnalysis?.setMissedRunOut()
          }
          if (!ball.fieldingAnalysis?.wicketOpportunity && value) {
            ball.fieldingAnalysis?.setWicketOpportunity()
          }
          fielderObject?.setMissedRunOut(fielderObject?.runOutMissed === value ? null : value)
          break
        case 'pressure':
          fielderObject?.setPressure(fielderObject?.pressure === value ? null : value)
          break
        case 'dive':
          fielderObject?.setDiveGood(
            fielderObject?.diveGood?.value === value ? null : value,
            editBallFielderSnapshot && editBallFielderSnapshot.diveGood
              ? editBallFielderSnapshot.diveGood.id
              : undefined
          )
          break
        case 'slide':
          fielderObject?.setSlideGood(
            fielderObject?.slideGood?.value === value ? null : value,
            editBallFielderSnapshot && editBallFielderSnapshot.slideGood
              ? editBallFielderSnapshot.slideGood.id
              : undefined
          )
          break
        case 'throw':
          fielderObject?.setThrowGood(
            fielderObject?.throwGood?.value === value ? null : value,
            editBallFielderSnapshot && editBallFielderSnapshot.throwGood
              ? editBallFielderSnapshot.throwGood.id
              : undefined
          )
          if (fielderObject?.throwGood === null) {
            fielderObject?.setThrowHitStumps(null)
            fielderObject?.setThrowBackedUp(null)
          }
          break
        case 'throwWithIntent':
          fielderObject?.setThrowWithIntent(fielderObject?.throwWithIntent === value ? null : value)
          break
        case 'stumps':
          fielderObject?.setThrowHitStumps(
            fielderObject?.throwHitStumps?.value === value ? null : value,
            editBallFielderSnapshot && editBallFielderSnapshot.throwHitStumps
              ? editBallFielderSnapshot.throwHitStumps.id
              : undefined
          )
          if (handleRunOutRoles && ball.fieldingAnalysis?.runOut) handleRunOutRoles()
          break
        case 'backedUp':
          fielderObject?.setThrowBackedUp(
            fielderObject?.throwBackedUp?.value === value ? null : value,
            editBallFielderSnapshot && editBallFielderSnapshot.throwBackedUp
              ? editBallFielderSnapshot.throwBackedUp.id
              : undefined
          )
          break
      }
    }

    const handleWicketKeeperPosition = (position: string) => {
      if (!position) return
      ball.setWicketKeeperPosition(position)
      // set wk circle position on field & update placement
      const wkPosCoords = WicketKeeperPositionCoords.find(coords => {
        return coords.id === position
      })
      if (wkPosCoords) {
        const wkPlacement = ball.fieldingAnalysis?.fieldingPositions?.find((p: IFieldingPositionModel) => {
          return p.fieldingPositionId === indexOf(FieldingPositions, 'WICKET_KEEPER')
        })
        if (wkPlacement) {
          wkPlacement.updatePlacement(wkPosCoords.x, wkPosCoords.y)
          if (setFieldHasChanged) setFieldHasChanged(true)
        }
      }
    }

    useEffect(() => {
      if (editBall && fielderObject && !editBallFielderSnapshot) {
        // if we are editing a ball, we need a snapshot for any existing UUID references
        setEditBallFielderSnapshot(getSnapshot(fielderObject))
      }
    }, [ball, editBall, fielderObject, editBallFielderSnapshot])

    if (!selectingPlayerParams) return null

    return (
      <>
        <Box w="100%" h="calc(100% - 14px)" position="relative">
          <Flex flex={1} direction="row">
            <Flex flex={inBall && editBall && !isNil(selectingPlayerParams.playerMpId) ? 0.75 : 1}>
              <SubHeading
                text={
                  selectingPlayerParams.playerMpName
                    ? `${selectingPlayerParams.playerMpName} ${
                        !isNil(selectingPlayerParams.shirtNumber) ? `(#${selectingPlayerParams.shirtNumber})` : ''
                      }`
                    : !isNil(selectingPlayerParams.shirtNumber)
                    ? `#${selectingPlayerParams.shirtNumber}`
                    : 'Unknown Player'
                }
                secondary
                bold
              />
            </Flex>
            {inBall && editBall && !isNil(selectingPlayerParams.playerMpId) && (
              <Flex flex={0.25} paddingLeft="7px">
                <Button
                  w="100%"
                  h="28px"
                  colorScheme="green"
                  onClick={() => {
                    if (triggerNewFielder) triggerNewFielder(selectingPlayerParams.playerMpId)
                  }}
                  data-testid="swapFielderEditButton"
                >
                  Swap
                </Button>
              </Flex>
            )}
          </Flex>
          {(inBall ||
            (!inBall &&
              !isNil(selectingPlayerParams.fieldingPositionId) &&
              FieldingPositions[selectingPlayerParams.fieldingPositionId] === 'WICKET_KEEPER')) && (
            <SubHeading
              text={
                !isNil(selectingPlayerParams.fieldingPositionId)
                  ? FieldingPositions[selectingPlayerParams.fieldingPositionId].replace(/_/g, ' ')
                  : 'Custom Position'
              }
              secondary
            />
          )}
          <Flex h="100%" direction="column" marginTop="14px">
            <Box w="100%">
              {!inBall && (
                <>
                  {viablePositions && viablePositions.length > 0 && (
                    <Box w="100%">
                      {!isNil(selectingPlayerParams.playerMpId) && (
                        <Box w="100%" paddingBottom="14px">
                          <Button
                            w="100%"
                            colorScheme="green"
                            onClick={() => {
                              if (triggerNewFielder) triggerNewFielder(selectingPlayerParams.playerMpId)
                            }}
                            data-testid="swapFielderButton"
                          >
                            Swap Fielder
                          </Button>
                        </Box>
                      )}
                      <Box w="100%" paddingY="14px">
                        <SubHeading text="Change Position" tertiary />
                        {viablePositions.map((position: FieldingPositionPositionsInterface) => {
                          return (
                            <Box key={`positionC_${position.id}`} paddingY="4px">
                              <CustomButton
                                isActive={selectingPlayerParams.fieldingPositionId === position.id}
                                onClick={() =>
                                  handleSelectPosition({
                                    playerMpId: selectingPlayerParams.playerMpId,
                                    fieldingPositionId: position.id,
                                  })
                                }
                                width="100%"
                                hideCheck
                                isWhite
                                data-testid={`changePosition${position.name.replace(/_/g, ' ')}`}
                              >
                                {position.name.replace(/_/g, ' ')}
                              </CustomButton>
                            </Box>
                          )
                        })}
                        <Box w="100%" key="positionC_Custom" paddingY="4px">
                          <CustomButton
                            isActive={isNil(selectingPlayerParams.fieldingPositionId)}
                            onClick={() =>
                              handleSelectPosition({
                                playerMpId: selectingPlayerParams.playerMpId,
                                fieldingPositionId: null,
                              })
                            }
                            width="100%"
                            hideCheck
                            isWhite
                            data-testid="changePositionCustom"
                          >
                            CUSTOM
                          </CustomButton>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {triggerNewBowler &&
                    selectingPlayerParams &&
                    selectingPlayerParams.fieldingPositionId &&
                    FieldingPositions[selectingPlayerParams.fieldingPositionId] === 'BOWLER' && (
                      <Box w="100%" paddingBottom="14px">
                        <Button
                          w="100%"
                          colorScheme="green"
                          onClick={() => triggerNewBowler('CHANGE')}
                          data-testid="changeBowlerButton"
                        >
                          Change Bowler
                        </Button>
                      </Box>
                    )}
                  {selectingPlayerParams &&
                    selectingPlayerParams.fieldingPositionId &&
                    FieldingPositions[selectingPlayerParams.fieldingPositionId] === 'WICKET_KEEPER' && (
                      <Box w="100%">
                        {triggerNewFielder && (
                          <Box w="100%" paddingBottom="28px">
                            <Button
                              w="100%"
                              colorScheme="green"
                              onClick={() => {
                                triggerNewFielder(selectingPlayerParams.playerMpId)
                              }}
                              data-testid="swapWicketKeeperButton"
                            >
                              Swap Wicketkeeper
                            </Button>
                          </Box>
                        )}
                        <SubHeading text="Change Position" tertiary />
                        <Box paddingY="4px">
                          <CustomButton
                            isActive={
                              ball.fieldingAnalysis &&
                              !isNil(ball.fieldingAnalysis?.wicketKeeperPositionId) &&
                              WicketKeeperPositionOptions[ball.fieldingAnalysis.wicketKeeperPositionId] === 'UP'
                                ? true
                                : false
                            }
                            onClick={() => handleWicketKeeperPosition('UP')}
                            width="100%"
                            hideCheck
                            isWhite
                            data-testid="wicketKeeperPositionUp"
                          >
                            Up
                          </CustomButton>
                        </Box>
                        <Box paddingY="4px">
                          <CustomButton
                            isActive={
                              ball.fieldingAnalysis &&
                              !isNil(ball.fieldingAnalysis?.wicketKeeperPositionId) &&
                              WicketKeeperPositionOptions[ball.fieldingAnalysis.wicketKeeperPositionId] === 'BACK'
                                ? true
                                : false
                            }
                            onClick={() => handleWicketKeeperPosition('BACK')}
                            width="100%"
                            hideCheck
                            isWhite
                            data-testid="wicketKeeperPositionBack"
                          >
                            Back
                          </CustomButton>
                        </Box>
                      </Box>
                    )}
                </>
              )}
              {inBall && (
                <Box w="100%">
                  {fielderNumberPossible && fielderNumberPossible.length > 0 && (
                    <Flex direction="row" w="100%">
                      <Flex flex={1}>
                        <SubHeading text="Fielding Order #" tertiary />
                      </Flex>
                      <Flex flex={3} direction="row" marginLeft="14px">
                        {fielderNumberPossible.map((num: number, idx: number) => (
                          <Flex
                            flex={1}
                            key={`fielderNumber${idx}`}
                            marginRight={idx + 1 < fielderNumberPossible.length ? '7px' : '0px'}
                          >
                            <SimpleStateButton
                              target="order"
                              value={num}
                              onClick={handleFieldingOrderChange}
                              isActive={fielderObject?.order === num}
                              hideCheck
                              paddingX="4px"
                              paddingXOuter="7px"
                              isFlex
                              isWhite
                              data-testid={`fieldingOrder${num}Button`}
                            >
                              {num}
                            </SimpleStateButton>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  )}
                  <Box w="100%" paddingY="7px">
                    <SubHeading text="Difficulty" tertiary />
                    <Flex flexDirection="row">
                      <SimpleStateButton
                        value={1}
                        onClick={handleDifficultyRatingChange}
                        isActive={fielderObject?.difficultyRating === 1}
                        hideCheck
                        paddingX="4px"
                        paddingXOuter="7px"
                        isFlex
                        isFirst
                        isWhite
                        data-testid="fieldingDifficultyOne"
                      >
                        1
                      </SimpleStateButton>
                      <SimpleStateButton
                        value={2}
                        onClick={handleDifficultyRatingChange}
                        isActive={fielderObject?.difficultyRating === 2}
                        hideCheck
                        paddingX="4px"
                        paddingXOuter="7px"
                        isFlex
                        isMiddle
                        isWhite
                        data-testid="fieldingDifficultyTwo"
                      >
                        2
                      </SimpleStateButton>
                      <SimpleStateButton
                        value={3}
                        onClick={handleDifficultyRatingChange}
                        isActive={fielderObject?.difficultyRating === 3}
                        hideCheck
                        paddingX="4px"
                        paddingXOuter="7px"
                        isFlex
                        isMiddle
                        isWhite
                        data-testid="fieldingDifficultyThree"
                      >
                        3
                      </SimpleStateButton>
                      <SimpleStateButton
                        value={4}
                        onClick={handleDifficultyRatingChange}
                        isActive={fielderObject?.difficultyRating === 4}
                        hideCheck
                        paddingX="4px"
                        paddingXOuter="7px"
                        isFlex
                        isMiddle
                        isWhite
                        data-testid="fieldingDifficultyFour"
                      >
                        4
                      </SimpleStateButton>
                      <SimpleStateButton
                        value={5}
                        onClick={handleDifficultyRatingChange}
                        isActive={fielderObject?.difficultyRating === 5}
                        hideCheck
                        paddingX="4px"
                        paddingXOuter="7px"
                        isFlex
                        isLast
                        isWhite
                        data-testid="fieldingDifficultyFive"
                      >
                        5
                      </SimpleStateButton>
                    </Flex>
                  </Box>
                  <Box w="100%" paddingY="7px">
                    <SubHeading text="Events" tertiary />
                    <Flex direction="column">
                      <Flex flex={1} direction="row" paddingBottom="4px">
                        <Flex flex={1}>
                          <SimpleStateButton
                            target="pressure"
                            onClick={handleFielderOptionChange}
                            value={fielderObject?.pressure ? false : true}
                            isActive={fielderObject?.pressure ? true : false}
                            paddingX="2px"
                            paddingXOuter="2px"
                            hideCheck
                            isFlex
                            isWhite
                            width="100%"
                            data-testid="fielderPressure"
                          >
                            Pressure
                          </SimpleStateButton>
                        </Flex>
                        <Flex flex={1} paddingLeft="4px">
                          <SimpleStateButton
                            target="misfielded"
                            onClick={handleFielderOptionChange}
                            value={fielderObject?.misfielded?.value ? false : true}
                            isActive={fielderObject?.misfielded?.value ? true : false}
                            paddingX="2px"
                            paddingXOuter="2px"
                            hideCheck
                            isFlex
                            isWhite
                            width="100%"
                            data-testid="fielderMisfield"
                          >
                            Misfield
                          </SimpleStateButton>
                        </Flex>
                        <Flex flex={1} paddingLeft="4px">
                          <SimpleStateButton
                            target="runOutMissed"
                            onClick={handleFielderOptionChange}
                            value={fielderObject?.runOutMissed ? false : true}
                            isActive={fielderObject?.runOutMissed ? true : false}
                            paddingX="2px"
                            paddingXOuter="2px"
                            hideCheck
                            isFlex
                            isWhite
                            width="100%"
                            data-testid="fielderMissedRunOut"
                          >
                            Missed R.O.
                          </SimpleStateButton>
                        </Flex>
                      </Flex>
                      <Flex flex={1} direction="row" paddingY="4px">
                        <Flex flex={1}>
                          <SimpleStateButton
                            target="droppedCatch"
                            onClick={handleFielderOptionChange}
                            value={fielderObject?.droppedCatch ? false : true}
                            isActive={fielderObject?.droppedCatch ? true : false}
                            paddingX="2px"
                            paddingXOuter="2px"
                            hideCheck
                            isFlex
                            isWhite
                            width="100%"
                            data-testid="fielderDroppedCatch"
                          >
                            Dropped Catch
                          </SimpleStateButton>
                        </Flex>
                        <Flex flex={1} paddingLeft="4px">
                          <SimpleStateButton
                            target="stumpingMissed"
                            onClick={handleFielderOptionChange}
                            value={fielderObject?.stumpingMissed ? false : true}
                            isActive={fielderObject?.stumpingMissed ? true : false}
                            paddingX="2px"
                            paddingXOuter="2px"
                            hideCheck
                            isFlex
                            isWhite
                            width="100%"
                            data-testid="fielderMissedStumping"
                          >
                            Missed Stump.
                          </SimpleStateButton>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Box>
                  <Box w="100%" paddingY="7px">
                    <Flex direction="row" paddingBottom="4px">
                      <Flex flex={1}>
                        <SubHeading text="Dive / Jump" tertiary />
                      </Flex>
                      <Flex flex={0.75} paddingX="2px">
                        <SimpleStateButton
                          target="dive"
                          value={true}
                          onClick={handleFielderOptionChange}
                          isActive={fielderObject?.diveGood?.value ? true : false}
                          hideCheck
                          paddingX="4px"
                          paddingXOuter="7px"
                          isFlex
                          isWhite
                          data-testid="fielderDiveJumpGood"
                        >
                          Good
                        </SimpleStateButton>
                      </Flex>
                      <Flex flex={0.75} paddingX="2px">
                        <SimpleStateButton
                          target="dive"
                          value={false}
                          onClick={handleFielderOptionChange}
                          isActive={fielderObject?.diveGood?.value === false}
                          hideCheck
                          paddingX="4px"
                          paddingXOuter="7px"
                          isFlex
                          isWhite
                          data-testid="fielderJumpDiveBad"
                        >
                          Bad
                        </SimpleStateButton>
                      </Flex>
                    </Flex>
                    <Flex direction="row" paddingY="4px">
                      <Flex flex={1}>
                        <SubHeading text="Slide" tertiary />
                      </Flex>
                      <Flex flex={0.75} paddingX="2px">
                        <SimpleStateButton
                          target="slide"
                          value={true}
                          onClick={handleFielderOptionChange}
                          isActive={fielderObject?.slideGood?.value ? true : false}
                          hideCheck
                          paddingX="4px"
                          paddingXOuter="7px"
                          isFlex
                          isWhite
                          data-testid="fielderSlideGood"
                        >
                          Good
                        </SimpleStateButton>
                      </Flex>
                      <Flex flex={0.75} paddingX="2px">
                        <SimpleStateButton
                          target="slide"
                          value={false}
                          onClick={handleFielderOptionChange}
                          isActive={fielderObject?.slideGood?.value === false}
                          hideCheck
                          paddingX="4px"
                          paddingXOuter="7px"
                          isFlex
                          isWhite
                          data-testid="fielderSlideBad"
                        >
                          Bad
                        </SimpleStateButton>
                      </Flex>
                    </Flex>
                    <Flex direction="row" paddingY="4px">
                      <Flex flex={1}>
                        <SubHeading text="Throw" tertiary />
                      </Flex>
                      <Flex flex={0.75} paddingX="2px">
                        <SimpleStateButton
                          target="throw"
                          value={true}
                          onClick={handleFielderOptionChange}
                          isActive={fielderObject?.throwGood?.value ? true : false}
                          hideCheck
                          paddingX="4px"
                          paddingXOuter="7px"
                          isFlex
                          isWhite
                          data-testid="fielderThrowGood"
                        >
                          Good
                        </SimpleStateButton>
                      </Flex>
                      <Flex flex={0.75} paddingX="2px">
                        <SimpleStateButton
                          target="throw"
                          value={false}
                          onClick={handleFielderOptionChange}
                          isActive={fielderObject?.throwGood?.value === false}
                          hideCheck
                          paddingX="4px"
                          paddingXOuter="7px"
                          isFlex
                          isWhite
                          data-testid="fielderThrowBad"
                        >
                          Bad
                        </SimpleStateButton>
                      </Flex>
                    </Flex>
                    {!isNil(fielderObject?.throwGood) && (
                      <Flex direction="row">
                        <Flex flex={1} />
                        <Flex flex={1.5} padding="4px 2px">
                          <SimpleStateButton
                            target="throwWithIntent"
                            value={fielderObject?.throwWithIntent ? false : true}
                            onClick={handleFielderOptionChange}
                            isActive={fielderObject?.throwWithIntent ? true : false}
                            hideCheck
                            paddingX="4px"
                            paddingXOuter="7px"
                            isFlex
                            isWhite
                            data-testid="fielderThowWithIntent"
                          >
                            Throw with intent
                          </SimpleStateButton>
                        </Flex>
                      </Flex>
                    )}
                    <Flex direction="row" paddingY="4px">
                      <Flex flex={1}>
                        <SubHeading text="Stumps" tertiary />
                      </Flex>
                      <Flex flex={0.75} paddingX="2px">
                        <SimpleStateButton
                          target="stumps"
                          value={true}
                          onClick={handleFielderOptionChange}
                          isActive={fielderObject?.throwHitStumps?.value ? true : false}
                          isDisabled={isNil(fielderObject?.throwGood)}
                          hideCheck
                          paddingX="4px"
                          paddingXOuter="7px"
                          isFlex
                          isWhite
                          data-testid="fielderStumpsHit"
                        >
                          Hit
                        </SimpleStateButton>
                      </Flex>
                      <Flex flex={0.75} paddingX="2px">
                        <SimpleStateButton
                          target="stumps"
                          value={false}
                          onClick={handleFielderOptionChange}
                          isActive={fielderObject?.throwHitStumps?.value === false}
                          isDisabled={isNil(fielderObject?.throwGood)}
                          hideCheck
                          paddingX="4px"
                          paddingXOuter="7px"
                          isFlex
                          isWhite
                          data-testid="fielderStumpsMiss"
                        >
                          Miss
                        </SimpleStateButton>
                      </Flex>
                    </Flex>
                    <Flex direction="row" paddingY="4px">
                      <Flex flex={1}>
                        <SubHeading text="Backed up?" tertiary />
                      </Flex>
                      <Flex flex={0.75} paddingX="2px">
                        <SimpleStateButton
                          target="backedUp"
                          value={true}
                          onClick={handleFielderOptionChange}
                          isActive={fielderObject?.throwBackedUp?.value ? true : false}
                          hideCheck
                          paddingX="4px"
                          paddingXOuter="7px"
                          isFlex
                          isWhite
                          data-testid="fielderThrowBackedUpYes"
                        >
                          Yes
                        </SimpleStateButton>
                      </Flex>
                      <Flex flex={0.75} paddingX="2px">
                        <SimpleStateButton
                          target="backedUp"
                          value={false}
                          onClick={handleFielderOptionChange}
                          isActive={fielderObject?.throwBackedUp?.value === false}
                          hideCheck
                          paddingX="4px"
                          paddingXOuter="7px"
                          isFlex
                          isWhite
                          data-testid="fielderBackedUpNo"
                        >
                          No
                        </SimpleStateButton>
                      </Flex>
                    </Flex>
                    <Flex w="100%" padding="14px 0 21px">
                      <CustomButton
                        onClick={() => setRunsCostSavedModalIsOpen(true)}
                        isActive={!isNil(fielderObject?.runsSaved)}
                        width="100%"
                        paddingX="8px"
                        paddingXOuter="4px"
                        isFlex
                        isWhite
                        hideCheck
                        data-testid="openRunsCostSavedModal"
                      >
                        Runs Cost / Saved
                      </CustomButton>
                    </Flex>
                  </Box>
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
        <Modal
          isOpen={runsCostSavedModalIsOpen}
          onClose={() => setRunsCostSavedModalIsOpen(false)}
          size="md"
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Runs Cost / Saved</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column">
                <Flex flex={1} direction="row" paddingY="7px">
                  <Flex flex={1} direction="column" justifyContent="center">
                    <SubHeading text="Misfield" secondary />
                  </Flex>
                  <Flex flex={1.5} direction="row">
                    <Stepper
                      id="misfielded-runsSaved"
                      labels={['COST', 'SAVED']}
                      value={fielderObject?.misfielded?.runsSaved || 0}
                      onChange={value => handleRunsSavedChange({ value: value, target: 'misfielded-runsSaved' })}
                      isEditing={editBall}
                    />
                  </Flex>
                </Flex>
                <Flex flex={1} direction="row" paddingY="7px">
                  <Flex flex={1} direction="column" justifyContent="center">
                    <SubHeading text="Dive / Jump" secondary />
                  </Flex>
                  <Flex flex={1.5} direction="row">
                    <Stepper
                      id="dive-runsSaved"
                      labels={['COST', 'SAVED']}
                      value={fielderObject?.diveGood?.runsSaved || 0}
                      onChange={value => handleRunsSavedChange({ value: value, target: 'dive-runsSaved' })}
                      isEditing={editBall}
                    />
                  </Flex>
                </Flex>
                <Flex flex={1} direction="row" paddingY="7px">
                  <Flex flex={1} direction="column" justifyContent="center">
                    <SubHeading text="Slide" secondary />
                  </Flex>
                  <Flex flex={1.5} direction="row">
                    <Stepper
                      id="slide-runsSaved"
                      labels={['COST', 'SAVED']}
                      value={fielderObject?.slideGood?.runsSaved || 0}
                      onChange={value => handleRunsSavedChange({ value: value, target: 'slide-runsSaved' })}
                      isEditing={editBall}
                    />
                  </Flex>
                </Flex>
                <Flex flex={1} direction="row" paddingY="7px">
                  <Flex flex={1} direction="column" justifyContent="center">
                    <SubHeading text="Throw" secondary />
                  </Flex>
                  <Flex flex={1.5} direction="row">
                    <Stepper
                      id="throw-runsSaved"
                      labels={['COST', 'SAVED']}
                      value={fielderObject?.throwGood?.runsSaved || 0}
                      onChange={value => handleRunsSavedChange({ value: value, target: 'throw-runsSaved' })}
                      isEditing={editBall}
                    />
                  </Flex>
                </Flex>
                <Flex flex={1} direction="row" paddingY="7px">
                  <Flex flex={1} direction="column" justifyContent="center">
                    <SubHeading text="Stumps" secondary />
                  </Flex>
                  <Flex flex={1.5} direction="row">
                    <Stepper
                      id="stumps-runsSaved"
                      labels={['COST', 'SAVED']}
                      value={fielderObject?.throwHitStumps?.runsSaved || 0}
                      onChange={value => handleRunsSavedChange({ value: value, target: 'stumps-runsSaved' })}
                      isEditing={editBall}
                    />
                  </Flex>
                </Flex>
                <Flex flex={1} direction="row" paddingY="7px">
                  <Flex flex={1} direction="column" justifyContent="center">
                    <SubHeading text="Backed up?" secondary />
                  </Flex>
                  <Flex flex={1.5} direction="row">
                    <Stepper
                      id="backedUp-runsSaved"
                      labels={['COST', 'SAVED']}
                      value={fielderObject?.throwBackedUp?.runsSaved || 0}
                      onChange={value => handleRunsSavedChange({ value: value, target: 'backedUp-runsSaved' })}
                      isEditing={editBall}
                    />
                  </Flex>
                </Flex>
              </Flex>
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => setRunsCostSavedModalIsOpen(false)}
                size="md"
                colorScheme="cls.gray"
                ml={3}
                data-testid="runsCostSavedModalDoneButton"
              >
                Done
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
)
