import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { capitalize, includes } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import type { BatterChangeType, DropdownCallbackArgs } from '../../types'
import type { CurrentBattersProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import Dropdown from '../Dropdown/Dropdown'

type StrikeBatterChangeOption = {
  value: Exclude<BatterChangeType, 'RUN_OUT'>
  label: string
}

type NonStrikeBatterChangeOption = {
  value: BatterChangeType
  label: string
}

const strikeDropdownOptions: StrikeBatterChangeOption[] = [
  { value: 'RETIRED_HURT', label: 'Retire Hurt' },
  { value: 'RETIRED_NO', label: 'Retire Not Out' },
  { value: 'RETIRED', label: 'Retire Out' },
  { value: 'ABSENT', label: 'Absent' },
  { value: 'MISTAKE', label: 'Mistake' },
]

const nonStrikeDropdownOptions: NonStrikeBatterChangeOption[] = [
  { value: 'RETIRED_HURT', label: 'Retire Hurt' },
  { value: 'RETIRED_NO', label: 'Retire Not Out' },
  { value: 'RETIRED', label: 'Retire Out' },
  { value: 'RUN_OUT', label: 'Mankad' },
  { value: 'ABSENT', label: 'Absent' },
  { value: 'MISTAKE', label: 'Mistake' },
]

export const CurrentBatters = observer(
  ({ inning, triggerNewBatter, performance, setPerformance, isDisabled, editBall }: CurrentBattersProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [batterChangeType, setBatterChangeType] = useState<BatterChangeType | undefined>()

    const playerPerfs = inning.getCurrentBatterPerformances

    const handleBatterClick = ({ value }: DropdownCallbackArgs) => {
      if (includes(['RETIRED', 'RUN_OUT', 'ABSENT'], value)) {
        setIsOpen(true)
        setBatterChangeType(value as BatterChangeType)
      } else {
        if (triggerNewBatter) triggerNewBatter(value as BatterChangeType)
      }
      return
    }

    const handleDoubleConfirmation = () => {
      if (batterChangeType) {
        if (triggerNewBatter) triggerNewBatter(batterChangeType)
      }
      setIsOpen(false)
    }

    const cancelConfirmation = () => {
      setIsOpen(false)
      setBatterChangeType(undefined)
    }

    return (
      <>
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => setIsOpen(false)} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Wicket Confirmation</ModalHeader>
            <ModalBody>
              <strong>{performance?.getPlayerProfile?.getDisplayName()}</strong>:{' '}
              {batterChangeType && capitalize(batterChangeType.replace('_', ' ').toLowerCase())}
              <Text marginTop="10px">
                This choice means the batter is OUT.
                <br />
                NB: You can undo this wicket from the Activity Log.
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="green"
                mr={3}
                onClick={handleDoubleConfirmation}
                data-testid="wicketConfirmationButton"
              >
                Confirm Wicket
              </Button>
              <Button colorScheme="gray" onClick={cancelConfirmation} data-testid="cancelWicketConfirmationButton">
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Box flex={1.25}>
          <Box height="14px" />
          {playerPerfs.map((player, idx) => {
            const teamMember = player.getPlayerProfile
            return (
              <Box marginBottom="4px" paddingRight="7px" key={player.playerMp.id}>
                <Dropdown
                  options={player.onStrike ? strikeDropdownOptions : nonStrikeDropdownOptions}
                  value={
                    <Flex color="cls.black" fontSize="lg" key={teamMember?.id} align="center">
                      {`${teamMember?.getDisplayName()}${teamMember?.getShirtNumber(true)}`}
                      {player.onStrike && (
                        <FontAwesomeIcon icon={['fal', 'cricket']} size="sm" style={{ marginLeft: '7px' }} />
                      )}
                    </Flex>
                  }
                  onChange={handleBatterClick}
                  onOpen={setPerformance ? () => setPerformance(player) : undefined}
                  placement="right"
                  justifyContent="flex-start"
                  isWhite
                  ignoreState
                  lastOptionSeparated
                  globalDisable={isDisabled || editBall}
                  data-testid={`setBatterDropdown${idx + 1}`}
                />
              </Box>
            )
          })}
        </Box>
      </>
    )
  }
)
