import { types } from 'mobx-state-tree'

import { ClspMatchModel } from './MatchModel'

const MatchMapModel = types.model('DetailedMatchMap', {
  id: types.identifier, // NOTE(jcook): match unique_id
  match: ClspMatchModel,
})

export default MatchMapModel
