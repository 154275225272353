import { Flex } from '@chakra-ui/react'
import { capitalize, indexOf, isNil } from 'lodash'
import { observer } from 'mobx-react-lite'

import * as Reference from '../../data/reference'
import BallHelpers from '../../helpers/ballHelpers'
import type { ShotProps } from '../../types/props'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import SubHeading from '../Headings/SubHeading'
import Trajectory from './Trajectory'

const Shot = observer(({ ball, incompleteData, isPrimaryEditCheck }: ShotProps) => {
  const shotType =
    ball.battingAnalysis && !isNil(ball.battingAnalysis?.shots.shotTypeId)
      ? Reference.ShotTypeOptions[ball.battingAnalysis.shots.shotTypeId]
      : null
  const shotContact =
    ball.battingAnalysis && !isNil(ball.battingAnalysis?.shots.shotContactId)
      ? Reference.ShotContactOptions[ball.battingAnalysis.shots.shotContactId]
      : null
  const bodyContact =
    ball.battingAnalysis && !isNil(ball.battingAnalysis?.shots.bodyContactId)
      ? Reference.BodyContactOptions[ball.battingAnalysis.shots.bodyContactId]
      : null
  const shotContactNotRequired: boolean =
    ball.battingAnalysis &&
    !isNil(ball.battingAnalysis.shots.shotTypeId) &&
    (Reference.ShotTypeOptions[ball.battingAnalysis.shots.shotTypeId] === 'PADDED' ||
      Reference.ShotTypeOptions[ball.battingAnalysis.shots.shotTypeId] === 'LEAVE')
      ? true
      : false
  const changeShotType = ({ value }: { value: string }) => {
    if (!ball) return
    if (ball.battingAnalysis?.shots.shotTypeId !== indexOf(Reference.ShotTypeOptions, value)) {
      const wasAlreadyAttacking = ball.battingAnalysis?.shots.attacking ? true : false
      ball.setShotType(value)
      if (!bodyContact && value === 'PADDED') {
        ball.setBodyContact('HIT_PAD') // default to HIT PAD body contact for a padded shot type
        ball.setShotContact(null) // remove contact as well
      } else if (!shotContact && value !== 'LEAVE') {
        ball.setShotContact('GOOD') // default to GOOD contact for a shot
      } else if (shotContact && (value === 'LEAVE' || value === 'PADDED')) {
        ball.setShotContact(null) // remove contact if LEAVE or PADDED is selected as shot
      }
      if (!wasAlreadyAttacking && value !== 'LEAVE' && value !== 'PADDED' && value !== 'DEFENSIVE') {
        // if we have selected an attacking shot type, prefill the ATTACKING button value
        ball.setAttacking(true)
      }
      if (isPrimaryEditCheck) isPrimaryEditCheck()
    }
  }
  const changeShotContact = ({ value }: { value: string }) => {
    if (!ball) return
    ball.setShotContact(value)
    if (isPrimaryEditCheck) isPrimaryEditCheck()
  }
  const changeBodyContact = ({ value }: { value: string }) => {
    if (!ball) return
    ball.setBodyContact(value)
  }

  return (
    <Flex flex={2} flexDirection="column" marginTop="7px">
      <SubHeading text="Shot Type" warning={incompleteData.type} tertiary />
      {Reference.ShotTypeOptionsBracketed.map((bracket: string[], idx: number) => (
        <Flex key={`shotTypeB${idx}`} flexDirection="row" flexWrap="wrap" paddingBottom="7px">
          {bracket.map((option: string, index: number) => (
            <Flex key={`shotType${index}`} flexGrow={1} marginRight="7px">
              <SimpleStateButton
                target="type"
                value={option}
                onClick={changeShotType}
                isActive={shotType === option}
                paddingX="7px"
                paddingXOuter="4px"
                hideCheck
                isFlex
                data-testid={BallHelpers.formatShotTypeOption(option)}
              >
                {BallHelpers.formatShotTypeOption(option)}
              </SimpleStateButton>
            </Flex>
          ))}
        </Flex>
      ))}
      <Flex flexDirection="row" marginTop="7px">
        <Flex flex={3} flexDirection="column" flexWrap="wrap">
          <SubHeading text="Shot Contact" warning={incompleteData.contact} tertiary />
          {Reference.ShotContactOptionsBracketed.map((bracket: string[], idx: number) => (
            <Flex key={`shotContactB${idx}`} flexDirection="row">
              {bracket.map((option: string, index: number) => (
                <Flex key={`shotContact${index}`} flexGrow={1} paddingBottom="7px" marginRight="7px">
                  <SimpleStateButton
                    target="contact"
                    value={option}
                    onClick={changeShotContact}
                    isActive={shotContact === option}
                    isDisabled={shotContactNotRequired}
                    paddingX="4px"
                    paddingXOuter="2px"
                    hideCheck
                    isFlex
                    data-testid={capitalize(option.replace('_', ' ').toLowerCase())}
                  >
                    {capitalize(option.replace('_', ' ').toLowerCase())}
                  </SimpleStateButton>
                </Flex>
              ))}
            </Flex>
          ))}
        </Flex>
        <Flex flex={1.5} flexDirection="column" flexWrap="wrap" marginLeft="7px">
          <SubHeading text="Body Contact" tertiary />
          {Reference.BodyContactOptionsBracketed.map((bracket: string[], idx: number) => (
            <Flex key={`bodyContactB${idx}`} flexDirection="row">
              {bracket.map((option: string, index: number) => (
                <Flex key={`bodyContact${index}`} flexGrow={1} paddingBottom="7px" marginRight="7px">
                  <SimpleStateButton
                    target="body_contact"
                    value={option}
                    onClick={changeBodyContact}
                    isActive={
                      ball.battingAnalysis?.shots.bodyContactId !== null &&
                      ball.battingAnalysis?.shots.bodyContactId !== undefined &&
                      Reference.BodyContactOptions[ball.battingAnalysis.shots.bodyContactId] === option
                        ? true
                        : false
                    }
                    paddingX="4px"
                    paddingXOuter="2px"
                    hideCheck
                    isFlex
                    data-testid={capitalize(option.replace('_', ' ').toLowerCase())}
                  >
                    {capitalize(option.replace('_', ' ').toLowerCase())}
                  </SimpleStateButton>
                </Flex>
              ))}
            </Flex>
          ))}
        </Flex>
        <Flex flex={0.5} flexDirection="column" marginLeft="7px" paddingRight="7px">
          <SubHeading text="Trajectory" tertiary />
          <Trajectory ball={ball} isPrimaryEditCheck={isPrimaryEditCheck} />
        </Flex>
      </Flex>
    </Flex>
  )
})

export default Shot
