import { Button as ChakraButton, forwardRef } from '@chakra-ui/react'

import type { ButtonProps } from '../../types/props'

export const Button = forwardRef(({ onClick, ...props }: ButtonProps, ref) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.currentTarget.blur()
    if (onClick) {
      onClick(e)
    }
  }

  return <ChakraButton {...props} ref={ref} onClick={handleClick} shadow="cls.light" />
})
