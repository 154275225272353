import 'react-datepicker/dist/react-datepicker-cssmodules.css'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Checkbox,
  Flex,
  Heading,
  Spinner,
  Text,
} from '@chakra-ui/react'
import type { IMatchOfficialModel, ISummaryMatchModel, ISummaryMatchTeamModel } from '@clsplus/cls-plus-data-models'
import { keys } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useNavigate } from 'react-router-dom'

import { Button } from '../components/Buttons/Button'
import GameDetail from '../components/GameDetail/GameDetail'
import { GameTabs } from '../components/GameTabs/GameTabs'
import SubHeading from '../components/Headings/SubHeading'
import MatchValidation from '../components/MatchValidation/MatchValidation'
import { db } from '../data/dexie/Database'
import { useMst } from '../data/stores/rootStore'
import { getFormattedDate } from '../helpers/generalHelpers'
import MatchHelpers from '../helpers/matchHelpers'
import type {
  IClspMatchModel,
  IDetailedMatchStore,
  ILocalMatchStore,
  IMatchesSearchStore,
  ISettingsModel,
} from '../types/models'
import type { MatchValidationResponse } from '../types/props'

const Games = observer(() => {
  const {
    detailedMatches,
    localMatches,
    summaryMatches,
    appSettings,
  }: {
    detailedMatches: IDetailedMatchStore
    localMatches: ILocalMatchStore
    summaryMatches: IMatchesSearchStore
    appSettings: ISettingsModel
  } = useMst()

  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState(1)
  const [selectedGame, setSelectedGame] = useState<IClspMatchModel | ISummaryMatchModel | null>(null)
  const [selectedGameStatus, setSelectedGameStatus] = useState<string | null>(null)
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(startDate)
  const [gameModeAlertOpen, setGameModeAlertOpen] = useState(false)
  const [gameDeviceAlertOpen, setGameDeviceAlertOpen] = useState(false)
  const [updatePendingAlertOpen, setUpdatePendingAlertOpen] = useState(false)
  const [doubleConfirmationDelete, setDoubleConfirmationDelete] = useState(false)
  const [isCheckingSdsValidation, setIsCheckingSdsValidation] = useState(false)
  const [sdsValidationIssues, setSdsValidationIssues] = useState<MatchValidationResponse | null>(null)
  const [sdsValidationIssueOpen, setSdsValidationIssueOpen] = useState(false)
  const [resetLocalDatabase, setResetLocalDatabase] = useState<boolean | null>(null)
  const cancelGameModeRef = useRef(null)
  const cancelGameDeviceRef = useRef(null)
  const cancelUpdatePendingRef = useRef(null)
  const cancelDoubleConfirmationRef = useRef(null)
  const closeGameModeAlert = () => {
    setGameModeAlertOpen(false)
    setSelectedGame(null)
    setSelectedGameStatus(null)
  }
  const closeGameDeviceAlert = () => {
    setGameDeviceAlertOpen(false)
    setSelectedGame(null)
    setSelectedGameStatus(null)
  }
  const closeDoubleConfirmationAlert = () => {
    setDoubleConfirmationDelete(false)
    setSelectedGame(null)
    setSelectedGameStatus(null)
  }
  const closeSdsValidationIssue = () => {
    setSdsValidationIssueOpen(false)
    setSdsValidationIssues(null)
    setSelectedGame(null)
    setSelectedGameStatus(null)
  }
  const handleGameClick = async (game: ISummaryMatchModel) => {
    const isLocalMatch = localMatches.getIsLocalMatch(game.id)
    if (!isLocalMatch && document.getElementById('updateAlert')) {
      // Disallow if we are trying to load a new match while an app update is pending
      setUpdatePendingAlertOpen(true)
      return
    }

    // First, check if game exists in local db. If it doesn't, ensure reset db checkbox appears on mode selector
    setResetLocalDatabase(!isLocalMatch)

    // Then, check if we need to do SDS validation on the game.
    const gameHasStarted = game.matchResultTypeId !== null && game.matchResultTypeId > 0
    if (game.dataProvider === 'sds' && !gameHasStarted) {
      // Do an SDS validation check on the game
      setIsCheckingSdsValidation(true)
      const sdsValidation: MatchValidationResponse = await summaryMatches.validateSdsMatch(game)
      let isSdsValidated = true
      keys(sdsValidation).forEach(key => {
        if (key in sdsValidation && sdsValidation[key].length > 0) {
          isSdsValidated = false
          sdsValidation[key] = sdsValidation[key].map(id => {
            if (typeof id === 'string') {
              switch (key) {
                // TODO: This case never worked because there is no `matchPlayers` property in the `SummaryMatchTeam` model.
                // To fix it we could either add `matchPlayers` to that model, or (preferably) simply send a match ID to the
                // validation endpoint rather than serialising the entire match object. However, this might not be necessary
                // as players are already SDS validated when they are added to a match team prior to the match starting.
                //
                // case 'players': {
                //   const player = flatten<IMatchPlayerModel>(
                //     game.matchTeams.map(team => {
                //       return team.matchPlayers
                //     })
                //   ).find(p => {
                //     return p.player.id === id
                //   })
                //   return { id: id, name: player?.cardNameF }
                // }
                case 'officials': {
                  const official = game.matchOfficials?.find((o: IMatchOfficialModel) => {
                    return o.official.id === id
                  })
                  return {
                    id: id,
                    name: official?.official.person?.cardNameF,
                  }
                }
                case 'venue':
                  return { id: id, name: game.venue?.fullName }
                case 'team':
                  return {
                    id: id,
                    name: game.matchTeams.find((team: ISummaryMatchTeamModel) => {
                      return team.teamId === id
                    })?.name,
                  }
                case 'match':
                case 'competition':
                default:
                  return { id: id }
              }
            }
          })
        }
      })
      if (isSdsValidated) {
        handleGameClickSuccess(game)
      } else {
        setSdsValidationIssues(sdsValidation)
        setSdsValidationIssueOpen(true)
      }
      setIsCheckingSdsValidation(false)
    } else {
      handleGameClickSuccess(game)
    }
  }
  const handleGameClickSuccess = (game: ISummaryMatchModel) => {
    setGameModeAlertOpen(true)
    setSelectedGame(game)
  }
  const handleGameDeviceClick = (game: IClspMatchModel, status?: string) => {
    setGameDeviceAlertOpen(true)
    setSelectedGame(game)
    if (status) setSelectedGameStatus(status)
  }
  const handleGameModeClick = (type: string, manualScoring: boolean = false) => {
    if (!type || !selectedGame) return
    appSettings.setAppMode(type)
    if (resetLocalDatabase) {
      Promise.all([db.matches.clear(), db.balls.clear(), db.s3p.clear(), db.events.clear(), db.timeline.clear()])
    } else {
      setResetLocalDatabase(null)
    }

    appSettings.manualScoring.setActive(manualScoring ? true : false)

    if (selectedGame.matchResultTypeId !== null && selectedGame.matchResultTypeId > 0) {
      if (manualScoring) {
        navigate(`/game/${selectedGame.id}/${type}/scorebook/manual`)
      } else {
        navigate(`/game/${selectedGame.id}/${type}/scoring`)
      }
    } else {
      navigate(`/game/${selectedGame.id}/${type}/setup${manualScoring ? '/manual' : ''}`)
    }
  }
  const handleGameDeviceOptionClick = (remove?: boolean) => {
    setGameDeviceAlertOpen(false)
    if (remove) {
      removeGameDevice()
    } else {
      setGameModeAlertOpen(true)
    }
  }
  const handleGameResetOption = () => {
    if (selectedGame) {
      removeGameDevice()
      detailedMatches.resetMatch(selectedGame.id)
      setGameDeviceAlertOpen(false)
      setDoubleConfirmationDelete(false)
    }
  }
  const removeGameDevice = () => {
    if (!selectedGame) return
    const gameId = selectedGame.id

    localMatches.removeLocalMatch(gameId)
    detailedMatches.deleteMatch(gameId)
    return Promise.all([
      db.matches.delete(gameId),
      db.balls.where({ matchId: gameId }).delete(),
      db.s3p.where({ matchId: gameId }).delete(),
      db.events.delete(gameId),
      db.timeline.where({ matchId: gameId }).delete(),
    ])
  }

  const handleStartDateChange = (date: Date) => {
    setStartDate(date)

    if (endDate && endDate < date) {
      setEndDate(date)
    }
  }

  const handleEndDateChange = (date: Date) => {
    setEndDate(date)
  }

  const startDateShort = getFormattedDate(startDate)
  const endDateShort = endDate !== null ? getFormattedDate(endDate) : getFormattedDate(startDate)
  const startDateLocal = new Date(`${startDateShort} 00:00:00`).toISOString()
  const endDateLocal = new Date(`${endDateShort} 23:59:59`).toISOString()

  summaryMatches.getMatches(startDateLocal, endDateLocal)
  const games: ISummaryMatchModel[] | undefined = summaryMatches.results.get(`${startDateLocal}_${endDateLocal}`)?.data

  const localMatchesNotStarted = MatchHelpers.matchesInDateOrder(localMatches.getNotYetStarted())
  const localMatchesInProgress = MatchHelpers.matchesInDateOrder(localMatches.getInProgress())
  const localMatchesComplete = MatchHelpers.matchesInDateOrder(localMatches.getCompleted())

  return (
    <>
      <Flex direction="column" paddingX="14px">
        <Flex
          justify="flex-start"
          flexDirection={['column', 'column', 'column', 'row']}
          align="center"
          h={['140px', '140px', '140px', '88px']}
          w="100%"
        >
          <Flex flex={1} alignItems="center" paddingY={['7px', '7px', '7px', '0px']}>
            <Heading fontStyle="italic" textTransform="uppercase">
              Matches
            </Heading>
          </Flex>
          <Flex flex={2} flexDirection={['column', 'column', 'column', 'row']}>
            <Flex
              flex={1}
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              paddingY={['7px', '7px', '7px', '0px']}
            >
              <GameTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            </Flex>
            <Flex
              flex={1}
              flexDirection={['row', 'row', 'row', 'column']}
              justifyContent="center"
              alignItems="center"
              paddingY={['7px', '7px', '7px', '0px']}
            >
              {selectedTab === 2 && (
                <>
                  <Flex direction="row" alignItems="center">
                    <Box marginRight="7px">
                      <Text w="50px">From: </Text>
                    </Box>
                    <DatePicker
                      selected={startDate}
                      onChange={handleStartDateChange}
                      onChangeRaw={e => e.preventDefault()}
                      selectsStart
                      startDate={startDate}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Flex>
                  <Flex
                    direction="row"
                    margin={['0 0 0 14px', '0 0 0 14px', '0 0 0 14px', '7px 0 0 0']}
                    alignItems="center"
                  >
                    <Box marginRight="7px">
                      <Text w="50px">To: </Text>
                    </Box>
                    <DatePicker
                      selected={endDate}
                      onChange={handleEndDateChange}
                      onChangeRaw={e => e.preventDefault()}
                      selectsEnd
                      startDate={startDate}
                      minDate={startDate}
                      dateFormat="dd/MM/yyyy"
                    />
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
        {selectedTab === 1 && (
          <Flex
            h={['auto', 'auto', 'calc(100vh - 130px)']}
            w="100%"
            direction="column"
            overflowY="scroll"
            backgroundColor="white"
            borderTopLeftRadius="7px"
            borderTopRightRadius="7px"
            padding="14px 21px"
          >
            <Box marginBottom="40px">
              <SubHeading text={`Not Yet Started (${localMatchesNotStarted.length})`} />
              {localMatchesNotStarted.map((game, idx) => {
                if (!game) return null
                return <GameDetail key={game.id} type="local" game={game} idx={idx} onClick={handleGameDeviceClick} />
              })}
            </Box>
            <Box>
              <SubHeading text={`In Progress (${localMatchesInProgress.length})`} />
              {localMatchesInProgress.map((game, idx) => {
                if (!game) return null
                return <GameDetail key={game.id} type="local" game={game} idx={idx} onClick={handleGameDeviceClick} />
              })}
            </Box>
            <Box marginTop="40px">
              <SubHeading text={`Completed (${localMatchesComplete.length})`} />
              {localMatchesComplete.map((game, idx) => {
                if (!game) return null
                return <GameDetail key={game.id} type="local" game={game} idx={idx} onClick={handleGameDeviceClick} />
              })}
            </Box>
          </Flex>
        )}
        {selectedTab === 2 && (
          <Flex direction={['column', 'column', 'column', 'row']}>
            <Flex
              flex={1}
              h={['auto', 'auto', 'auto', 'calc(100vh - 130px)']}
              backgroundColor="cls.white.400"
              borderTopLeftRadius="7px"
              borderTopRightRadius="7px"
              borderBottomLeftRadius={['7px', '7px', '7px', 0]}
              borderBottomRightRadius={['7px', '7px', '7px', 0]}
              marginTop={['14px', '14px', '14px', 0]}
              padding="14px 21px"
            >
              {games ? (
                <Flex w="100%" direction="column" overflowY="scroll">
                  {games.map((game, idx) => (
                    <GameDetail key={game.id} type="system" game={game} idx={idx} onClick={handleGameClick} />
                  ))}
                </Flex>
              ) : (
                <Flex w="100%" alignItems="center" justifyContent="center" direction="row">
                  <Spinner thickness="4px" speed="0.75s" emptyColor="cls.gray.200" color="cls.blue.400" size="xl" />
                  <Box ml="14px">
                    <Text fontWeight="bold" fontSize="2xl" textAlign="center">
                      Loading
                    </Text>
                  </Box>
                </Flex>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
      {isCheckingSdsValidation && (
        <Flex
          position="absolute"
          top="0"
          left="0"
          w="100vw"
          h="100vh"
          alignItems="center"
          justifyContent="center"
          backgroundColor="blackAlpha.600"
          zIndex="1000"
        >
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="center"
            backgroundColor="white"
            borderRadius="7px"
            padding="21px 35px"
          >
            <Spinner thickness="4px" speed="0.75s" emptyColor="cls.gray.200" color="cls.blue.400" size="xl" />
            <Text ml="14px" fontWeight="bold" fontSize="2xl" textAlign="center">
              Validating
            </Text>
          </Flex>
        </Flex>
      )}
      <AlertDialog
        isOpen={gameModeAlertOpen}
        leastDestructiveRef={cancelGameModeRef}
        onClose={closeGameModeAlert}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size="xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Start/Resume Scoring
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text>Select the scoring mode you wish to use for this game:</Text>
            <Flex direction="column" mt="14px">
              <Flex direction="row" py="4px">
                <Button
                  flex={1}
                  colorScheme="green"
                  onClick={() => handleGameModeClick('main')}
                  textTransform="capitalize"
                  data-testid="mainScoringModeButton"
                  isDisabled={[0, 1].includes(selectedGame?.matchConfigs?.coverageLevelId ?? 3)}
                >
                  Main mode
                </Button>
                <Button
                  flex={1}
                  colorScheme="green"
                  onClick={() => handleGameModeClick('main', true)}
                  ml={3}
                  textTransform="capitalize"
                  data-testid="mainScoringModeManualButton"
                  isDisabled={[1, 2, 3].includes(selectedGame?.matchConfigs?.coverageLevelId ?? 0)}
                >
                  Main mode (Post-match scoring)
                </Button>
              </Flex>
              <Flex direction="row" py="4px">
                <Button
                  flex={1}
                  colorScheme="green"
                  onClick={() => handleGameModeClick('betting')}
                  textTransform="capitalize"
                  data-testid="bettingScoringModeButton"
                  isDisabled={[0].includes(selectedGame?.matchConfigs?.coverageLevelId ?? 3)}
                >
                  Betting mode
                </Button>
                <Button
                  flex={1}
                  colorScheme="green"
                  onClick={() => handleGameModeClick('fielding')}
                  ml={3}
                  textTransform="capitalize"
                  data-testid="fieldingScoringModeButton"
                  isDisabled={[0, 1, 2].includes(selectedGame?.matchConfigs?.coverageLevelId ?? 3)}
                >
                  Fielding mode
                </Button>
              </Flex>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Flex direction="row" w="100%" alignItems="center" justifyContent="space-between">
              {resetLocalDatabase !== null && (
                <Flex flex={2} h="100%" justifyContent="flex-start">
                  <Checkbox
                    colorScheme="cls.yellow"
                    borderColor="cls.gray.200"
                    isChecked={resetLocalDatabase}
                    onChange={() => setResetLocalDatabase(!resetLocalDatabase)}
                    size="md"
                    data-testid="resetLocalDatabase"
                  >
                    Reset local database (recommended)
                  </Checkbox>
                </Flex>
              )}
              <Flex flex={1} justifyContent="flex-end">
                <Button onClick={closeGameModeAlert} data-testid="cancelScoringModeAlertButton">
                  Cancel
                </Button>
              </Flex>
            </Flex>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={gameDeviceAlertOpen}
        leastDestructiveRef={cancelGameDeviceRef}
        onClose={closeGameDeviceAlert}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        size="xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Actions
          </AlertDialogHeader>
          <AlertDialogBody>
            <Flex flex={1}>
              <Flex flex={1} direction="column" marginRight="10px">
                <Heading size="md" textAlign="center" marginBottom="5px">
                  Scoring
                </Heading>
                {selectedGameStatus !== 'COMPLETE' && (
                  <Button
                    colorScheme="green"
                    onClick={() => handleGameDeviceOptionClick()}
                    mb={3}
                    textTransform="capitalize"
                    data-testid="continueScoringGameButton"
                  >
                    Continue Scoring
                  </Button>
                )}
              </Flex>
              <Flex flex={1} direction="column" marginLeft="10px">
                <Heading size="md" textAlign="center" marginBottom="5px" data-testid="deleteGameButton">
                  Delete
                </Heading>
                <Button
                  colorScheme="red"
                  onClick={() => handleGameDeviceOptionClick(true)}
                  mb={3}
                  textTransform="capitalize"
                  data-testid="clearMatchDataButton"
                >
                  Clear match data from this device
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => setDoubleConfirmationDelete(true)}
                  mb={3}
                  textTransform="capitalize"
                  data-testid="systemMatchResetButton"
                >
                  System Match Reset
                </Button>
              </Flex>
            </Flex>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={closeGameDeviceAlert} data-testid="cancelGameDeviceAlertButton">
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={updatePendingAlertOpen}
        leastDestructiveRef={cancelUpdatePendingRef}
        onClose={() => setUpdatePendingAlertOpen(false)}
        closeOnOverlayClick={true}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Information
          </AlertDialogHeader>

          <AlertDialogBody>
            There is a required app update pending. You must update before you start scoring a new game.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={() => setUpdatePendingAlertOpen(false)} data-testid="cancelUpdatePendingButton">
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={doubleConfirmationDelete}
        leastDestructiveRef={cancelDoubleConfirmationRef}
        onClose={closeGameModeAlert}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Are you sure?
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to delete all the data collected for this game? This will clear data across the whole
            system, and return the game to a state where it can be scored from the start again.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={closeDoubleConfirmationAlert} data-testid="cancelDoubleConfirmationButton">
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={() => handleGameResetOption()}
              ml={3}
              textTransform="capitalize"
              data-testid="resetGameDataButton"
            >
              Yes, Delete it all
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <MatchValidation
        title="SDS validation"
        message="Please contact the operations team for assistance, ensuring to include the following output in your message:" // eslint-disable-line max-len
        issues={sdsValidationIssues}
        isOpen={sdsValidationIssueOpen}
        onClose={closeSdsValidationIssue}
      />
    </>
  )
})

export default Games
