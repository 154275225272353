import { Box, Flex } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import { BowlerApproach } from '../../components/BallDescription/BowlerApproach'
import { FreeHit } from '../../components/BallDescription/FreeHit'
import { Button } from '../../components/Buttons/Button'
import ControlsPanel from '../../components/ControlsPanel/ControlsPanel'
import { ShortScorecard } from '../../components/Scorecard/Short'
import type { PreBallProps } from '../../types/props'

const PreBallBetting = observer(
  ({
    ball,
    game,
    activeInning,
    setCurrentBall,
    timelineEvents,
    inningsInOrder,
    setEndOver,
    setClosedInning,
    currentBall,
    triggerNewBatter,
    triggerNewBowler,
    batterPerformance,
    setBatterPerformanceToChange,
    setBowlerPerformanceToChange,
    handlePenaltyRuns,
    isDisabled,
    editBall,
    closedInning,
    bowlerRunningIn,
  }: Omit<PreBallProps, 'triggerEndOver'>) => {
    return (
      <Flex flex={1} w="100%" h="100%" direction={['column', 'column', 'row', 'row']}>
        <Flex flex={[0, 0, 2, 2]} direction="column" w="100%" marginBottom={['-10px', '-10px', '0', '0']}>
          <Box paddingX="7px">
            <ShortScorecard
              inning={activeInning}
              game={game}
              mode="betting"
              ball={currentBall}
              triggerNewBatter={triggerNewBatter}
              triggerNewBowler={triggerNewBowler}
              batterPerformance={batterPerformance}
              setBatterPerformance={setBatterPerformanceToChange}
              setBowlerPerformance={setBowlerPerformanceToChange}
              handlePenaltyRuns={handlePenaltyRuns}
              isDisabled={isDisabled}
              editBall={editBall ? true : false}
              closedInning={closedInning ? true : false}
            />
          </Box>
          <Box
            padding={['14px 0px', '14px 0px', '14px 7px', '14px 7px']}
            w="100%"
            marginTop={['0', '0', '14px', '14px']}
          >
            <Flex
              backgroundColor="cls.white.400"
              direction="column"
              h="100%"
              paddingY="7px"
              marginTop="7px"
              borderRadius="7px"
            >
              <Flex
                direction="column"
                padding={!closedInning ? '7px 0px 14px' : '7px 0px'}
                w="100%"
                borderBottom={!closedInning ? 'solid 4px' : 'none'}
                borderBottomColor="cls.backgroundGray"
              >
                <Flex paddingX={['4px', '14px', '14px', '14px']}>
                  <ControlsPanel
                    game={game}
                    inning={activeInning}
                    currentBall={currentBall}
                    setCurrentBall={setCurrentBall}
                    timelineEvents={timelineEvents}
                    inningsInOrder={inningsInOrder}
                    mode="betting"
                    isDisabled={isDisabled}
                    setEndOver={setEndOver}
                    closedInning={closedInning ? true : false}
                    setClosedInning={setClosedInning}
                  />
                </Flex>
              </Flex>
              {!closedInning && (
                <Flex
                  direction="row"
                  padding={['7px 7px 4px', '7px 7px 4px', '14px 7px 4px', '14px 7px 4px']}
                  marginX="7px"
                >
                  {game.matchConfigs.maxOvers !== null && (
                    <Box marginRight="14px">
                      <FreeHit mode="betting" game={game} ball={ball} isDisabled={isDisabled} />
                    </Box>
                  )}
                  <Box>
                    <BowlerApproach mode="betting" ball={ball} isDisabled={isDisabled} />
                  </Box>
                </Flex>
              )}
              {!closedInning && (
                <Flex
                  padding="4px 7px"
                  marginX="7px"
                  marginTop={game.matchConfigs.maxOvers === null ? ['7px', '7px', '14px', '14px'] : '0px'}
                >
                  <Button
                    h="66px"
                    fontSize="lg"
                    colorScheme="cls.blue"
                    onClick={bowlerRunningIn}
                    isDisabled={isDisabled}
                    w="100%"
                    data-testid="bowlerRunningInButton"
                  >
                    Bowler Running In
                  </Button>
                </Flex>
              )}
            </Flex>
          </Box>
        </Flex>
      </Flex>
    )
  }
)

export default PreBallBetting
