import { Box, Flex, Text } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import Bowling from '../../assets/bowling.svg'
import type { BowlerChangeType, DropdownCallbackArgs } from '../../types'
import type { CurrentBowlersProps } from '../../types/props'
import Dropdown from '../Dropdown/Dropdown'

type BowlerChangeOption = {
  value: BowlerChangeType
  label: string
}

const dropDownOptions: BowlerChangeOption[] = [
  { value: 'CHANGE', label: 'Change Bowler' },
  { value: 'MISTAKE', label: 'Mistake' },
]

export const CurrentBowlers = observer(
  ({ inning, triggerNewBowler, setPerformance, isDisabled, editBall }: CurrentBowlersProps) => {
    const playerPerfs = inning.getCurrentBowlerPerformances

    const handleBowlerClick = ({ value }: DropdownCallbackArgs) => {
      if (value && triggerNewBowler) {
        triggerNewBowler(value as BowlerChangeType)
      }
    }

    return (
      <Box flex={1.25}>
        <Box height="14px" />
        {playerPerfs.map((player, idx) => {
          const teamMember = player.getPlayerProfile
          return (
            <Box marginBottom="4px" paddingRight="7px" key={`${player.playerMp.id}`}>
              {idx === 0 && (
                <Dropdown
                  options={dropDownOptions}
                  value={
                    <Flex color="cls.black" fontSize="lg" key={`${teamMember?.id}`} align="center">
                      {`${teamMember?.getDisplayName()}${teamMember?.getShirtNumber(true)}`}
                      {player.onStrike && (
                        <Box width="17px" marginLeft="7px">
                          <img src={Bowling} style={{ height: 14, width: 14 }} alt="Bowling" />
                        </Box>
                      )}
                    </Flex>
                  }
                  onChange={handleBowlerClick}
                  onOpen={setPerformance ? () => setPerformance(player) : undefined}
                  placement="right"
                  justifyContent="flex-start"
                  isWhite
                  ignoreState
                  globalDisable={isDisabled || editBall}
                  data-testid="currentBowlerDropdown"
                />
              )}
              {idx === 1 && (
                <Flex color="cls.black" fontSize="lg" key={`${teamMember?.id}`} align="center">
                  {`${teamMember?.getDisplayName()}${teamMember?.getShirtNumber(true)}`}
                  {player.onStrike && (
                    <Box width="17px" marginLeft="7px">
                      <img src={Bowling} style={{ height: 14, width: 14 }} alt="Bowling" />
                    </Box>
                  )}
                </Flex>
              )}
            </Box>
          )
        })}
        {playerPerfs.length === 1 && (
          <Box height="28px">
            <Text color="cls.black" fontSize="lg">
              -
            </Text>
          </Box>
        )}
      </Box>
    )
  }
)
