import { Box, Flex } from '@chakra-ui/react'
import type { IMatchTeamModel } from '@clsplus/cls-plus-data-models'
import { capitalize, indexOf, isNil } from 'lodash'
import { observer } from 'mobx-react-lite'

import { db } from '../../data/dexie/Database'
import { TossDecisionOptions } from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { TossProps } from '../../types/props'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import SubHeading from '../Headings/SubHeading'

export const Toss = observer(({ game, isDisabled = false, timelineEvents }: TossProps) => {
  const { appSettings } = useMst()

  const tossEventCheck = () => {
    if (game.wonTossMatchTeamId && !isNil(game.tossDecisionId)) {
      const teamWonToss = game.getTeamById(game.wonTossMatchTeamId)
      const teamLostToss = game.getTeamByNotId(game.wonTossMatchTeamId)
      if (teamWonToss && teamLostToss) {
        timelineEvents?.addToss(
          `Toss (${teamWonToss.name}, ${TossDecisionOptions[game.tossDecisionId]})`,
          null,
          appSettings.timeMachine.baseline,
          appSettings.timeMachine.activated
        )
        if (appSettings.appMode === 'betting' || (appSettings.appMode === 'main' && appSettings.manualScoring.active)) {
          db.createS3PMessage(
            S3PHelpers.metadata(appSettings.manualScoring.active ? 'postMatch' : appSettings.appMode, game),
            S3PHelpers.toss(TossDecisionOptions[game.tossDecisionId], game),
            true
          )
        }
        game.setDescription(
          `${teamWonToss.name} won the toss and elected to ${TossDecisionOptions[game.tossDecisionId].toLowerCase()}`
        )
        game.setBattedFirstMatchTeam(game.tossDecisionId === 0 ? teamWonToss.id : teamLostToss.id)
      }
    }
  }

  const changeTossProp = ({ value, target }: { value: string; target?: string | null }) => {
    if (!value || !target) return
    if (target === 'toss_won_by') {
      game.setTossWonBy(value)
      tossEventCheck()
    }
    if (target === 'toss_decision') {
      game.setTossDecision(value)
      tossEventCheck()
    }
  }

  return (
    <Box paddingTop="14px">
      <SubHeading text="Toss" secondary />
      <SubHeading text="Won By" icon={['far', 'coin']} tertiary />
      <Box paddingBottom="14px">
        <Flex direction="row" padding="4px 0">
          {game.matchTeams.map((team: IMatchTeamModel, index: number) => (
            <SimpleStateButton
              key={`toss_team_${team.id}`}
              onClick={changeTossProp}
              value={team.id.toString()}
              target="toss_won_by"
              isActive={game.wonTossMatchTeamId === team.id}
              isFlex
              isFirst={index === 0}
              isLast={index !== 0}
              isDisabled={isDisabled}
              data-testid={`${team.name}WonToss`}
            >
              {team.name}
            </SimpleStateButton>
          ))}
        </Flex>
      </Box>
      <SubHeading text="Decision" icon={['far', 'comment-check']} tertiary />
      <Box paddingBottom="14px">
        <Flex direction="row" padding="4px 0">
          {TossDecisionOptions.map((option: string, index: number) => (
            <SimpleStateButton
              key={`toss_decision_${option}`}
              onClick={changeTossProp}
              value={option}
              target="toss_decision"
              isActive={game.tossDecisionId === indexOf(TossDecisionOptions, option)}
              isFlex
              isFirst={index === 0}
              isLast={index !== 0}
              isDisabled={isDisabled}
              data-testid={`${capitalize(option.toLowerCase())}TossDecision`}
            >
              {capitalize(option.toLowerCase())}
            </SimpleStateButton>
          ))}
        </Flex>
      </Box>
    </Box>
  )
})
