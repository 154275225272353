import { Flex, Heading } from '@chakra-ui/react'

import type { TeamNamesProps } from '../../types/props'

export const TeamNames = ({ game, mode = 'main' }: TeamNamesProps) => {
  const homeTeam = game.getHomeTeam()
  const awayTeam = game.getAwayTeam

  return (
    <Flex
      padding={[
        mode === 'main' ? '14px 21px 14px 14px' : '14px 14px 14px 14px',
        mode === 'main' ? '14px 21px 14px 14px' : '14px 14px 14px 14px',
        mode === 'main' ? '14px 21px 14px 14px' : '0px 14px 14px 7px',
        mode === 'main' ? '14px 21px 14px 14px' : '0px 14px 14px 7px',
      ]}
      align="center"
    >
      {homeTeam && (
        <Heading fontStyle="italic" textTransform="uppercase">
          {homeTeam.name}
        </Heading>
      )}
      {homeTeam && awayTeam && (
        <Heading fontStyle="italic" fontSize="26px" marginX="14px">
          vs
        </Heading>
      )}
      {awayTeam && (
        <Heading fontStyle="italic" textTransform="uppercase">
          {awayTeam.name}
        </Heading>
      )}
    </Flex>
  )
}
