import 'react-datepicker/dist/react-datepicker.css'

import { Box, Flex, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { indexOf, isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import DatePicker from 'react-datepicker'

import { MatchStatus, ScoreFormats } from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import type { ISettingsModel } from '../../types/models'
import type { AppSettingsProps } from '../../types/props'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import Dropdown from '../Dropdown/Dropdown'
import SubHeading from '../Headings/SubHeading'

const AppSettings = observer(({ game, mode = 'main', isSetup = false }: AppSettingsProps) => {
  const { appSettings }: { appSettings: ISettingsModel } = useMst()
  const matchSettings = appSettings.getMatchSettings(game.id)

  if (!matchSettings) return null

  const calculateBaseline = () => {
    if (appSettings.timeMachine.baseline) {
      // reset baseline
      return null
    }
    if (game.scoresUpdated && game.matchStatusId !== indexOf(MatchStatus, 'NOT_YET_STARTED')) {
      // in progress match
      return new Date(game.scoresUpdated).toISOString()
    }
    if (game.matchDates && game.matchDates.length > 0 && game.matchDates[0].startDateTime) {
      // upcoming match
      return new Date(game.matchDates[0].startDateTime).toISOString()
    }
    // default
    return new Date().toISOString()
  }

  const calculateMinDate = () => {
    if (appSettings.timeMachine.baseline && !isSetup) {
      return zonedTimeToUtc(
        new Date(appSettings.timeMachine.baseline),
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    }
    if (game.matchDates && game.matchDates.length > 0 && game.matchDates[0].startDateTime) {
      return zonedTimeToUtc(
        new Date(game.matchDates[0].startDateTime),
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
    }
    return null
  }

  return (
    <Box paddingTop="16px">
      <SubHeading text="Preferences" secondary />
      <Flex
        flex={3}
        flexDirection="row"
        alignItems="center"
        paddingY="7px"
        borderBottom="1px solid"
        borderBottomColor="cls.backgroundGray"
      >
        <Flex flex={1}>
          <Text textTransform="capitalize">Score Format</Text>
        </Flex>
        <Flex flex={1} justifyContent="flex-end">
          <Dropdown
            options={ScoreFormats.map(option => {
              return { value: option }
            })}
            value={matchSettings.scoreFormat}
            onChange={({ value }) => {
              if (value) matchSettings.setScoreFormat(value)
            }}
            ignoreState
            data-testid="scoreFormatsDropdown"
          />
        </Flex>
      </Flex>
      {mode === 'main' && (
        <Flex
          flex={3}
          flexDirection="row"
          alignItems="center"
          paddingY="7px"
          borderBottom="1px solid"
          borderBottomColor="cls.backgroundGray"
        >
          <Flex flex={1}>
            <Text textTransform="capitalize">Pre Ball Screen</Text>
          </Flex>
          <Flex flex={1} justifyContent="flex-end">
            <SimpleStateButton
              onClick={({ value }: { value: boolean }) => matchSettings.setPreBallScreen(value)}
              value={matchSettings.preBallScreen}
              isActive={!!matchSettings.preBallScreen}
              isSwitch
              data-testid="preBallScreenSwitch"
            >
              {matchSettings.preBallScreen ? 'Enabled' : 'Disabled'}
            </SimpleStateButton>
          </Flex>
        </Flex>
      )}
      {(mode === 'main' || mode === 'fielding') && (
        <Flex
          flex={3}
          flexDirection="row"
          alignItems="center"
          paddingY="7px"
          borderBottom="1px solid"
          borderBottomColor="cls.backgroundGray"
        >
          <Flex flex={1}>
            <Text textTransform="capitalize">Fielder Placement</Text>
          </Flex>
          <Flex flex={1} justifyContent="flex-end">
            <SimpleStateButton
              onClick={({ value }: { value: boolean }) => matchSettings.setFielderPlacement(value)}
              value={matchSettings.fielderPlacement || false}
              isActive={!!matchSettings.fielderPlacement}
              isDisabled={mode === 'fielding'}
              isSwitch
              data-testid="fielderPlacementSwitch"
            >
              {matchSettings.fielderPlacement ? 'Enabled' : 'Disabled'}
            </SimpleStateButton>
          </Flex>
        </Flex>
      )}
      {mode === 'fielding' && (
        <>
          <Flex
            flex={3}
            flexDirection="row"
            alignItems="center"
            paddingY="7px"
            borderBottom="1px solid"
            borderBottomColor="cls.backgroundGray"
          >
            <Flex flex={1}>
              <Text textTransform="capitalize">Fielder Events</Text>
            </Flex>
            <Flex flex={1} justifyContent="flex-end">
              <SimpleStateButton
                onClick={({ value }: { value: boolean }) => matchSettings.setFielderEvents(value)}
                value={matchSettings.fielderEvents || false}
                isActive={!!matchSettings.fielderEvents}
                isDisabled={true}
                isSwitch
                data-testid="fielderEventsSwitch"
              >
                {matchSettings.fielderEvents ? 'Enabled' : 'Disabled'}
              </SimpleStateButton>
            </Flex>
          </Flex>
          <Flex
            flex={3}
            flexDirection="row"
            alignItems="center"
            paddingY="7px"
            borderBottom="1px solid"
            borderBottomColor="cls.backgroundGray"
          >
            <Flex flex={1}>
              <Text textTransform="capitalize">Use Shirt Numbers (if available)</Text>
            </Flex>
            <Flex flex={1} justifyContent="flex-end">
              <SimpleStateButton
                onClick={({ value }: { value: boolean }) => matchSettings.setFielderShirtNumbers(value)}
                value={matchSettings.fielderShirtNumbers || false}
                isActive={!!matchSettings.fielderShirtNumbers}
                isSwitch
                data-testid="useShirtNumsSwitch"
              >
                {matchSettings.fielderShirtNumbers ? 'Enabled' : 'Disabled'}
              </SimpleStateButton>
            </Flex>
          </Flex>
        </>
      )}
      {mode !== 'fielding' && (
        <Flex
          flex={3}
          flexDirection="row"
          alignItems="center"
          paddingY="7px"
          borderBottom="1px solid"
          borderBottomColor="cls.backgroundGray"
        >
          <Flex flex={1}>
            <Text textTransform="capitalize">Ball Preview</Text>
          </Flex>
          <Flex flex={1} justifyContent="flex-end">
            <SimpleStateButton
              onClick={({ value }: { value: boolean }) => matchSettings.setBallPreview(value)}
              value={matchSettings.ballPreview || false}
              isActive={!!matchSettings.ballPreview}
              isSwitch
              data-testid="ballPreviewSwitch"
            >
              {matchSettings.ballPreview ? 'Enabled' : 'Disabled'}
            </SimpleStateButton>
          </Flex>
        </Flex>
      )}
      {/* TIME MACHINE */}
      <Flex
        flex={3}
        flexDirection="row"
        alignItems="center"
        paddingY={appSettings.timeMachine.activated ? '3px' : '12px'}
        borderBottom="1px solid"
        borderBottomColor="cls.backgroundGray"
      >
        <Flex flex={1} flexDirection="column">
          <Flex alignItems="center" direction="row">
            <Flex mr="14px">
              <FontAwesomeIcon icon={['far', 'flux-capacitor']} size="lg" />
            </Flex>
            <Flex flex={1} flexDirection="column">
              <Text textTransform="capitalize">Time Machine</Text>
              {appSettings.timeMachine.activated && (
                <Flex flex={1} justifyContent="flex-start">
                  <Text fontSize="sm" lineHeight="shorter" mt="-4px">{`Last Activated: ${format(
                    new Date(appSettings.timeMachine.activated),
                    'dd/MM/yyyy hh:mm a'
                  )} (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}</Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex flex={1} flexDirection="row" justifyContent="flex-end" alignItems="center">
          {!isNil(appSettings.timeMachine.baseline) && (
            <Box mr="14px" padding="4px" backgroundColor="cls.backgroundGray">
              <DatePicker
                selected={
                  appSettings.timeMachine.baseline
                    ? utcToZonedTime(
                        new Date(appSettings.timeMachine.baseline),
                        Intl.DateTimeFormat().resolvedOptions().timeZone
                      )
                    : null
                }
                minDate={calculateMinDate()}
                maxDate={new Date()}
                onChange={date => {
                  if (date instanceof Date) {
                    const dateModified = zonedTimeToUtc(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
                    const nowModified = zonedTimeToUtc(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone)
                    appSettings.timeMachine.setBaseline(dateModified.toISOString())
                    appSettings.timeMachine.setActivated(nowModified.toISOString(), game.id)
                  }
                }}
                onChangeRaw={e => e.preventDefault()}
                showTimeInput
                dateFormat="dd/MM/yyyy hh:mm a"
              />
              <Text fontSize="sm">{`(${Intl.DateTimeFormat().resolvedOptions().timeZone})`}</Text>
            </Box>
          )}
          <SimpleStateButton
            onClick={() => {
              const nowModified = zonedTimeToUtc(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone)
              appSettings.timeMachine.setBaseline(calculateBaseline())
              appSettings.timeMachine.setActivated(
                appSettings.timeMachine.activated ? null : nowModified.toISOString(),
                game.id
              )
            }}
            value={!isNil(appSettings.timeMachine.baseline)}
            isActive={!isNil(appSettings.timeMachine.baseline)}
            isDisabled={!isSetup}
            isSwitch
            data-testid="timeMachineSwitch"
          >
            {appSettings.timeMachine.baseline ? 'Enabled' : 'Disabled'}
          </SimpleStateButton>
        </Flex>
      </Flex>
    </Box>
  )
})

export default AppSettings
