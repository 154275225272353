import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { capitalize, indexOf, isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { BallDescription } from '../../components/BallDescription/BallDescription'
import { BallSummary } from '../../components/BallDescription/BallSummary'
import BasicScoring from '../../components/BasicScoring/BasicScoring'
import { Button } from '../../components/Buttons/Button'
import { CustomButton } from '../../components/Buttons/CustomButton'
import { SimpleStateButton } from '../../components/Buttons/SimpleStateButton'
import { Checkbox } from '../../components/Checkbox/Checkbox'
import SubHeading from '../../components/Headings/SubHeading'
import { ShortScorecard } from '../../components/Scorecard/Short'
import * as Reference from '../../data/reference'
import BallHelpers from '../../helpers/ballHelpers'
import type { InBallProps } from '../../types/props'

const InBallBetting = observer(
  ({
    game,
    ball,
    lastBall,
    ballStore,
    timelineEvents,
    completeBall,
    resyncBall,
    cancelEditBall,
    editBall,
    editBallProps,
    deadBall,
    ballRunsVal,
    setBallRunsVal,
    ballExtrasVal,
    setBallExtrasVal,
    commentaryChanged,
    setCommentaryChanged,
    activeInning,
    handlePenaltyRuns,
    insertingBall,
    isPrimaryEditCheck,
    isDisabled,
  }: Omit<InBallProps, 'triggerEndOver'>) => {
    const allowedToBeEndOfOver: boolean =
      !ball.getExtraType || ball.getExtraType === 'LEG_BYE' || ball.getExtraType === 'BYE'
    const [endOver, setEndOver] = useState(
      ball.ballDisplayNumber
        ? ball.ballDisplayNumber > (game.matchConfigs.ballsPerOver || 6) - 1 && allowedToBeEndOfOver
        : false
    )
    const shotType =
      ball.battingAnalysis && !isNil(ball.battingAnalysis?.shots.shotTypeId)
        ? Reference.ShotTypeOptions[ball.battingAnalysis?.shots.shotTypeId]
        : null
    const shotContact =
      ball.battingAnalysis && !isNil(ball.battingAnalysis?.shots.shotContactId)
        ? Reference.ShotContactOptions[ball.battingAnalysis?.shots.shotContactId]
        : null
    const shotContactNotRequired: boolean =
      ball.battingAnalysis &&
      !isNil(ball.battingAnalysis.shots.shotTypeId) &&
      (Reference.ShotTypeOptions[ball.battingAnalysis.shots.shotTypeId] === 'PADDED' ||
        Reference.ShotTypeOptions[ball.battingAnalysis.shots.shotTypeId] === 'LEAVE')
        ? true
        : false

    const changeShotContact = (value: string) => {
      if (!ball) return
      ball.setShotContact(value)
      if (isPrimaryEditCheck) isPrimaryEditCheck()
    }
    const changeShotType = (value: string) => {
      if (!ball) return
      if (ball.battingAnalysis?.shots.shotTypeId !== indexOf(Reference.ShotTypeOptions, value)) {
        const wasAlreadyAttacking = ball.battingAnalysis?.shots.attacking ? true : false
        ball.setShotType(value)
        if (!shotContact && value === 'PADDED') {
          ball.setShotContact(null) // remove contact as well
        } else if (!shotContact && value !== 'LEAVE') {
          ball.setShotContact('GOOD') // default to GOOD contact for a shot
        } else if (shotContact && (value === 'LEAVE' || value === 'PADDED')) {
          ball.setShotContact(null) // remove contact if LEAVE or PADDED is selected as shot
        }
        if (!wasAlreadyAttacking && value !== 'LEAVE' && value !== 'PADDED' && value !== 'DEFENSIVE') {
          // if we have selected an attacking shot type, prefill the ATTACKING button value
          ball.setAttacking(true)
        }
        if (isPrimaryEditCheck) isPrimaryEditCheck()
      }
    }

    const wicketOrReviewAreIncomplete: boolean =
      (ball.pendingDismissal &&
        (!ball.dismissal || ball.dismissal.dismissalTypeId === null || isNil(ball.dismissal?.batterMp))) ||
      (ball.pendingReview &&
        (!ball.review ||
          ball.review.dismissalTypeId === null ||
          (ball.review.originalDecision === null &&
            indexOf(
              ball.review.reviewSourceTypeId === 0
                ? Reference.DismissalMethodsNoOrigDecisionUmpire
                : Reference.DismissalMethodsNoOrigDecisionPlayer,
              Reference.DismissalMethods[ball.review.dismissalTypeId]
            ) === -1) ||
          (ball.review.finalDecision === null && ball.review.drsUmpiresCall === null)))

    useEffect(() => {
      setEndOver(
        ball.ballDisplayNumber
          ? ball.ballDisplayNumber > (game.matchConfigs.ballsPerOver || 6) - 1 && allowedToBeEndOfOver
          : false
      )
    }, [ball.ballDisplayNumber, allowedToBeEndOfOver, game.matchConfigs.ballsPerOver])

    return (
      <Flex flex={1} w="100%" direction={['column', 'column', 'row', 'row']}>
        <Flex flex={2} direction="column" w="100%">
          <Box paddingX="7px" marginRight={['7px', '7px', '0', '0']}>
            <ShortScorecard
              inning={activeInning}
              game={game}
              mode="betting"
              ball={ball}
              handlePenaltyRuns={handlePenaltyRuns}
              isDisabled={isDisabled}
              editBall={editBall ? true : false}
              scoresOnly
            />
          </Box>
          <Box
            position="relative"
            backgroundColor="white"
            borderTopLeftRadius="7px"
            borderTopRightRadius="7px"
            margin={['7px 0 0', '7px 0 0', '7px 7px 0', '14px 7px 0']}
          >
            <Flex
              flex={1}
              direction="column"
              justifyContent={['center', 'center', 'center', 'space-between']}
              padding={['7px 7px', '7px 14px', '7px 21px 4px', '7px 21px 4px']}
              borderBottomColor="cls.backgroundGray"
              borderBottomWidth="4px"
            >
              <Flex flex={1} direction={['column', 'column', 'column', 'row']}>
                <Flex
                  flex={0.75}
                  display={['none', 'block', 'block', 'block']}
                  justifyContent="center"
                  alignItems="center"
                  paddingTop={['7px', '7px', '7px', '10px']}
                >
                  <SubHeading
                    text={`${editBall ? 'Edit Ball' : insertingBall ? 'Insert Ball' : 'Current Ball'} (over ${
                      editBall ? editBall.overNumber : ball.overNumber
                    }.${editBall ? editBall.ballDisplayNumber : ball.ballDisplayNumber})`}
                    secondary
                    bold
                  />
                </Flex>
                <Flex
                  flex={1.25}
                  justifyContent={['center', 'flex-start', 'flex-start', 'flex-end']}
                  alignItems="center"
                  padding="4px 0 7px"
                >
                  <BallDescription
                    mode="betting"
                    ball={ball}
                    ballStore={ballStore}
                    game={game}
                    editBall={editBall ? true : false}
                    isNewestBall={editBallProps?.isNewestBall}
                    preBall={false}
                    timelineEvents={timelineEvents}
                    isDisabled={isDisabled}
                  />
                </Flex>
              </Flex>
              {!isNil(commentaryChanged) && setCommentaryChanged && (
                <Flex flex={1} padding="4px 0 10px">
                  <BallSummary
                    mode="betting"
                    game={game}
                    ball={ball}
                    lastBall={editBall ? ball.getBallBefore(ball) : lastBall}
                    editBall={editBall ? true : false}
                    commentaryChanged={commentaryChanged}
                    setCommentaryChanged={setCommentaryChanged}
                    isDisabled={isDisabled}
                    enabled
                    detailed
                  />
                </Flex>
              )}
            </Flex>
            <Flex
              direction="column"
              alignItems="center"
              margin="7px 7px 0"
              padding={['4px 7px 7px', '4px 7px 7px', '4px 14px 7px', '4px 14px 7px']}
            >
              <BasicScoring
                ball={ball}
                game={game}
                mode="betting"
                editBall={editBall ? true : false}
                insertBall={insertingBall}
                ballRunsVal={ballRunsVal}
                setBallRunsVal={setBallRunsVal}
                ballExtrasVal={ballExtrasVal}
                setBallExtrasVal={setBallExtrasVal}
                isPrimaryEditCheck={isPrimaryEditCheck}
              />
              <Flex flex={1} w="100%" direction={['column', 'column', 'row', 'row']}>
                <Flex flex={[1, 1, 1.6, 1.6]} direction="column">
                  <SubHeading text="Shot Type" tertiary />
                  {Reference.ShotTypeOptionsBracketed.map((bracket: string[], idx: number) => (
                    <Stack direction="row" key={`shotTypeB${idx}`} spacing="7px" flexWrap="wrap">
                      {bracket.map((option: string, index: number) => (
                        <Flex key={`shotType${index}`} flexGrow={1} paddingBottom="7px">
                          <SimpleStateButton
                            target="type"
                            value={option}
                            onClick={({ value }) => changeShotType(value)}
                            isActive={shotType === option}
                            paddingX="7px"
                            paddingXOuter="4px"
                            hideCheck
                            isFlex
                            data-testid={`changeShotType${BallHelpers.formatShotTypeOption(option)}`}
                          >
                            {BallHelpers.formatShotTypeOption(option)}
                          </SimpleStateButton>
                        </Flex>
                      ))}
                    </Stack>
                  ))}
                </Flex>
                <Flex
                  flex={1}
                  direction={['row', 'row', 'column', 'column']}
                  margin={['14px 0 0', '14px 0 0', '0 0 0 21px', '0 0 0 21px']}
                >
                  <Flex flex={[2, 2, 1, 1]} direction="column">
                    <SubHeading text="Shot Contact" tertiary />
                    {Reference.ShotContactOptionsBracketed.map((bracket: string[], idx: number) => (
                      <Stack direction="row" w="100%" key={`shotContactB${idx}`} spacing="7px" flexWrap="wrap">
                        {bracket.map((option: string, index: number) => (
                          <Flex flex={1} key={`shotContact${index}`} paddingBottom="7px">
                            <SimpleStateButton
                              target="contact"
                              value={option}
                              onClick={({ value }) => changeShotContact(value)}
                              isActive={shotContact === option}
                              isDisabled={shotContactNotRequired}
                              isFlex
                              hideCheck
                              paddingXOuter="0px"
                              paddingX={option.length <= 5 ? '2px' : '4px'}
                              data-testid={capitalize(option.replace('_', ' ').toLowerCase())}
                            >
                              {capitalize(option.replace('_', ' ').toLowerCase())}
                            </SimpleStateButton>
                          </Flex>
                        ))}
                      </Stack>
                    ))}
                  </Flex>
                  <Flex flex={1} direction="column" margin={['0 0 0 14px', '0 0 0 14px', '4px 0 0 0', '4px 0 0 0']}>
                    <SubHeading text="Trajectory" tertiary />
                    <Flex w="100%" direction="row">
                      <Flex flex={1}>
                        <CustomButton
                          height="44px"
                          onClick={() => {
                            ball.setThroughField()
                            if (isPrimaryEditCheck) isPrimaryEditCheck()
                          }}
                          isActive={!!ball.battingAnalysis?.shots.throughField}
                          isFlex
                          hideCheck
                          paddingXOuter="0px"
                          paddingX="7px"
                          data-testid="ballTrajectoryThroughField"
                        >
                          <Text fontSize={14}>
                            Through
                            <br />
                            Field
                          </Text>
                        </CustomButton>
                      </Flex>
                      <Flex flex={1} marginLeft="7px">
                        <CustomButton
                          height="44px"
                          onClick={() => {
                            ball.setInTheAir()
                            if (isPrimaryEditCheck) isPrimaryEditCheck()
                          }}
                          isActive={!!ball.battingAnalysis?.shots.inTheAir}
                          isFlex
                          hideCheck
                          paddingXOuter="0px"
                          paddingX="7px"
                          data-testid="ballTrajectoryInTheAir"
                        >
                          <Text fontSize={14}>
                            In The
                            <br />
                            Air
                          </Text>
                        </CustomButton>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <Flex
              position="sticky"
              bottom="0%"
              left="0%"
              backgroundColor="white"
              borderBottomLeftRadius="7px"
              borderBottomRightRadius="7px"
              marginX="7px"
              padding="7px"
            >
              <Flex flex={1.5}>
                {editBall || insertingBall ? (
                  <Button
                    w="100%"
                    h="55px"
                    colorScheme="gray"
                    onClick={() => {
                      if (cancelEditBall) cancelEditBall(endOver)
                    }}
                    data-testid="cancelEditBallButton"
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button w="100%" h="55px" colorScheme="gray" onClick={deadBall} data-testid="deadBallButton">
                    Dead
                    <br />
                    Ball
                  </Button>
                )}
              </Flex>
              <Flex flex={3} flexDirection="row" paddingLeft="7px">
                {editBall && (
                  <Flex flex={0.75}>
                    <Button
                      w="100%"
                      h="55px"
                      colorScheme="teal"
                      onClick={() => resyncBall()}
                      data-testid="resyncBallDataButton"
                    >
                      <FontAwesomeIcon icon={['far', 'cloud-upload']} size="sm" style={{ fontSize: '16px' }} />
                    </Button>
                  </Flex>
                )}
                <Flex flex={2} marginLeft={editBall ? '7px' : '0px'}>
                  <Button
                    h="55px"
                    w="100%"
                    fontSize="lg"
                    colorScheme="cls.blue"
                    onClick={() => completeBall(editBall ? ball.endOfOver : endOver)}
                    isDisabled={wicketOrReviewAreIncomplete}
                    data-testid="doneConfirmBallButton"
                  >
                    {editBall ? 'Done' : 'Confirm'}
                  </Button>
                </Flex>
                {ball &&
                  ball.ballDisplayNumber &&
                  ball.ballDisplayNumber > (game.matchConfigs.ballsPerOver || 6) - 1 &&
                  !editBall && (
                    <Flex flex={1} justify="flex-end" marginLeft="7px">
                      <Checkbox
                        colorScheme="cls.yellow"
                        isChecked={endOver ? true : false}
                        isDisabled={!allowedToBeEndOfOver}
                        onChange={() => setEndOver(!endOver)}
                        data-testid="endOverCheckbox"
                      >
                        End Over
                      </Checkbox>
                    </Flex>
                  )}
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    )
  }
)

export default InBallBetting
