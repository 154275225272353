const white = 'rgba(255,255,255,.5)',
  rlg = 'repeating-linear-gradient',
  t = 'transparent'

export const gradientStyle = `${rlg}(-45deg, ${t}, ${t} 35px, ${white} 35px, ${white} 70px)`
export const borderStyle = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'gray.200',
  padding: '4px 7px',
}
