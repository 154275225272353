import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { sortBy } from 'lodash'
import { observer } from 'mobx-react-lite'

import Theme from '../../theme/theme'
import type { PersonListProps } from '../../types/props'
import { Button } from '../Buttons/Button'

export const PersonList = observer(
  ({
    data = [],
    existingData = [],
    props,
    personListOpen,
    setPersonListOpen,
    personListAdding,
    setPersonListAdding,
    onAddPerson,
  }: PersonListProps) => {
    const handleAddPerson = (id: string) => {
      setPersonListAdding(true)
      onAddPerson(id)
    }

    if (existingData.length) {
      const existingPersonIds = existingData
        .map(existingPerson => {
          if ('player' in existingPerson) return existingPerson.player.id
          if ('official' in existingPerson) return existingPerson.official.id
        })
        .filter((id): id is string => !!id)

      data.forEach(person => person.setSelected(existingPersonIds.includes(person.id)))
    }

    const people = sortBy(data, [p => p.person?.alphaName || p.person?.cardNameS || p.person?.cardNameF || ''])

    return (
      <>
        <Modal isOpen={personListOpen} onClose={() => setPersonListOpen(false)} size="lg" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{props?.type === 'team' ? 'Manage Team' : 'Manage Umpires'}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box overflowY="scroll" maxH="calc(100vh / 2)">
                {people.length > 0 ? (
                  people.map(person => (
                    <Flex
                      key={person.id}
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      paddingY="4px"
                      borderBottom={`solid 1px ${Theme.colors.cls.backgroundGray}`}
                    >
                      <Text>{person.person?.fullName}</Text>
                      <Button
                        colorScheme="green"
                        onClick={() => handleAddPerson(person.id)}
                        isDisabled={personListAdding || person.selected}
                        data-testid={`add${person.person?.fullName}Button`}
                      >
                        Add
                      </Button>
                    </Flex>
                  ))
                ) : (
                  <Flex alignItems="center" justifyContent="center">
                    <Spinner thickness="4px" speed="0.75s" emptyColor="cls.gray.200" color="cls.blue.400" size="xl" />
                  </Flex>
                )}
              </Box>
            </ModalBody>
            <ModalFooter />
          </ModalContent>
        </Modal>
      </>
    )
  }
)
