import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import { useLiveQuery } from 'dexie-react-hooks'
import { isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { db } from '../../data/dexie/Database'
import type { S3PBrowserFilter, S3PBrowserProps } from '../../types/props'

export const S3PBrowser = observer(({ matchId, isOpen, setIsOpen }: S3PBrowserProps) => {
  const [filter, setFilter] = useState<S3PBrowserFilter>('')
  const [filterValue, setFilterValue] = useState('')

  const data = useLiveQuery(
    () =>
      db.s3p
        .where({ matchId: matchId })
        .filter(item => {
          const pd = JSON.parse(item.data)
          const filterSplit = filterValue.split('.')
          return (
            pd.payload.type !== 'Event.Sport.Cricket.Heartbeat' &&
            (filterValue === '' ||
              (filter == 'id' && pd.payload.id.indexOf(filterValue) > -1) ||
              (filter == 'overBall' &&
                !isNil(pd.payload.over_number) &&
                (pd.payload.over_number - 1).toString().indexOf(filterSplit[0]) > -1 &&
                !isNil(pd.payload.ball_number) &&
                (filterSplit.length === 1 || pd.payload.ball_number.toString().indexOf(filterSplit[1]) > -1)))
          )
        })
        .reverse()
        .limit(filterValue === '' ? 50 : 1000)
        .toArray(),
    [filter, filterValue]
  )

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} scrollBehavior="inside" size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          S3P Payloads
          <HStack spacing="14px" pt="7px">
            <Input
              placeholder="Filter by over.ball"
              value={filter === 'overBall' ? filterValue : ''}
              onChange={evt => {
                setFilter('overBall')
                setFilterValue(evt.target.value)
              }}
            />
            <Text fontSize="md">or</Text>
            <Input
              placeholder="Filter by payload.id"
              value={filter === 'id' ? filterValue : ''}
              onChange={evt => {
                setFilter('id')
                setFilterValue(evt.target.value)
              }}
            />
          </HStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {filterValue === '' && (
            <Alert status="info">
              <AlertIcon />
              Showing 50 most recent messages. Use filters to find specific messages.
            </Alert>
          )}
          {data && data.length > 0 ? (
            data.map(item => {
              const parsedItem = JSON.parse(item.data)
              return (
                <Flex
                  key={`s3pPayload${item.id}`}
                  direction="column"
                  mb="14px"
                  mt={filterValue === '' ? '14px' : '0px'}
                >
                  <Flex direction="row" justifyContent="space-between" backgroundColor="black" px="7px">
                    <Text flex={1} color="white">
                      {item.timestamp}
                    </Text>
                    <Text flex={0.5} color="cls.textGray" textAlign="center">
                      {!isNil(parsedItem.payload.over_number) && !isNil(parsedItem.payload.ball_number)
                        ? `(Over ${Number(parsedItem.payload.over_number) - 1}.${parsedItem.payload.ball_number})`
                        : ''}
                    </Text>
                    <Text flex={1} color="white" textAlign="right">
                      {parsedItem.payload.type}
                    </Text>
                  </Flex>
                  <Box background="gray.200" padding="7px">
                    <Text as="pre" fontSize="12px" whiteSpace="pre-wrap" overflowX="scroll">
                      {JSON.stringify(parsedItem, null, 4)}
                    </Text>
                  </Box>
                </Flex>
              )
            })
          ) : (
            <Text py="14px">No S3P Payloads</Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
})
