import { Box, Flex } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'

import BallHelpers from '../../../helpers/ballHelpers'
import type { FieldingMiscProps } from '../../../types/props'
import { SimpleStateButton } from '../../Buttons/SimpleStateButton'
import { Checkbox } from '../../Checkbox/Checkbox'
import SubHeading from '../../Headings/SubHeading'
import Slider from '../../Slider/Slider'
import Stepper from '../../Stepper/Stepper'
import FieldingMiscOverthrows from './FieldingMiscOverthrows'

const FieldingMisc = observer(({ ball, editBall, incompleteData }: FieldingMiscProps) => {
  const changeRunsSaved = (value: number) => {
    if (!ball || !ball.fieldingAnalysis) return
    ball.fieldingAnalysis.setRunsSaved(value)
  }
  const changeDifficultyRatingPrimary = useCallback(
    (value: number) => {
      if (!ball || !ball.fieldingAnalysis) return
      ball.fieldingAnalysis.setDifficultyRating('primary', value)
    },
    [ball]
  )
  const changeDifficultyRatingSecondary = useCallback(
    (value: number) => {
      if (!ball || !ball.fieldingAnalysis) return
      ball.fieldingAnalysis.setDifficultyRating('secondary', value)
    },
    [ball]
  )
  const changeByeBlame = ({ value }: { value: string }) => {
    if (!ball) return
    ball.setByeBlame(value)
  }
  const isByes: boolean = ball.getExtraType === 'BYE' || ball.getExtraType === 'NO_BALL_BYE'

  return (
    <Flex flexDirection="row" align="flex-start">
      <Flex
        direction="column"
        align="center"
        mt="7px"
        p="0 7px 0 14px"
        borderLeft="solid 1px"
        borderLeftColor="cls.backgroundGray"
      >
        <SubHeading text="Difficulty" centered quaternary noPadding />
        <Flex w="100%" minW="80px" flexDirection="column">
          <Slider
            mt="4px"
            value={ball.getPrimaryFielder?.difficultyRating || 1}
            min={1}
            max={5}
            increment={1}
            labels={['1', '2', '3', '4', '5']}
            isDisabled={!ball.getPrimaryFielder}
            onChange={changeDifficultyRatingPrimary}
            data-testid="primaryFielderDifficulty"
          />
          <Slider
            mt="7px"
            value={ball.getSecondaryFielder?.difficultyRating || 1}
            min={1}
            max={5}
            increment={1}
            labels={['1', '2', '3', '4', '5']}
            isDisabled={!ball.getSecondaryFielder}
            onChange={changeDifficultyRatingSecondary}
            data-testid="secondaryFielderDifficulty"
          />
        </Flex>
      </Flex>
      <Flex
        direction="column"
        align="center"
        margin="7px 0 0 7px"
        pl="7px"
        borderLeft="solid 1px"
        borderLeftColor="cls.backgroundGray"
      >
        <SubHeading text="Pressure" warning={incompleteData} centered quaternary noPadding />
        <Flex flexDirection="column">
          <Flex flex={1} minH="36px" alignItems="center" justifyContent="center">
            <Checkbox
              isDisabled={!ball.getPrimaryFielder}
              colorScheme="cls.yellow"
              isChecked={ball.getPrimaryFielder?.pressure ? true : false}
              onChange={() => {
                ball.getPrimaryFielder?.setPressure(!ball.getPrimaryFielder?.pressure)
              }}
              size="lg"
              data-testid="primaryFielderPressure"
            />
          </Flex>
          <Flex flex={1} minH="37px" alignItems="center" justifyContent="center">
            <Checkbox
              isDisabled={!ball.getSecondaryFielder}
              colorScheme="cls.yellow"
              isChecked={ball.getSecondaryFielder?.pressure ? true : false}
              onChange={() => {
                ball.getSecondaryFielder?.setPressure(!ball.getSecondaryFielder?.pressure)
              }}
              size="lg"
              data-testid="secondaryFielderPressure"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        align="center"
        margin="7px 0 0 7px"
        pl="7px"
        borderLeft="solid 1px"
        borderLeftColor="cls.backgroundGray"
      >
        <SubHeading text="Good Throw" warning={incompleteData} centered quaternary noPadding />
        <Flex flexDirection="column">
          <Flex flex={1} minH="36px" alignItems="center" justifyContent="center">
            <Checkbox
              isDisabled={!ball.getPrimaryFielder}
              colorScheme="cls.yellow"
              isChecked={ball.getPrimaryFielder?.throwGood?.value ? true : false}
              onChange={() => {
                if (ball.getPrimaryFielder) {
                  ball.getPrimaryFielder.setThrowGood(
                    !editBall && ball.getPrimaryFielder.throwGood?.value
                      ? null
                      : !ball.getPrimaryFielder.throwGood?.value,
                    ball.getPrimaryFielder.throwGood?.id ?? undefined
                  )
                }
              }}
              size="lg"
              data-testid="primaryGoodThrow"
            />
          </Flex>
          <Flex flex={1} minH="37px" alignItems="center" justifyContent="center">
            <Checkbox
              isDisabled={!ball.getSecondaryFielder}
              colorScheme="cls.yellow"
              isChecked={ball.getSecondaryFielder?.throwGood?.value ? true : false}
              onChange={() => {
                if (ball.getSecondaryFielder) {
                  ball.getSecondaryFielder.setThrowGood(
                    !editBall && ball.getSecondaryFielder.throwGood?.value
                      ? null
                      : !ball.getSecondaryFielder.throwGood?.value,
                    ball.getSecondaryFielder.throwGood?.id ?? undefined
                  )
                }
              }}
              size="lg"
              data-testid="secondaryGoodThrow"
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        margin="7px 0 0 7px"
        px="7px"
        minWidth="35px"
        borderLeft="solid 1px"
        borderLeftColor="cls.backgroundGray"
        borderRight="solid 1px"
        borderRightColor="cls.backgroundGray"
      >
        <SubHeading text="Bye Blame" warning={incompleteData} quaternary centered noPadding />
        <Flex flexDirection="column" alignItems="center" mt="2px">
          <Box ml="2px">
            <SimpleStateButton
              target="bye_blame"
              value="BOWLER"
              onClick={changeByeBlame}
              isActive={ball.getByeBlame === 'BOWLER'}
              isDisabled={!isByes}
              paddingX="4px"
              paddingXOuter="4px"
              hideCheck
              isFirst
              data-testid="byeBlameBowler"
            >
              Bowler
            </SimpleStateButton>
          </Box>
          <Box ml="2px" mt="7px">
            <SimpleStateButton
              target="bye_blame"
              value="WICKET_KEEPER"
              onClick={changeByeBlame}
              isActive={ball.getByeBlame === 'WICKET_KEEPER'}
              isDisabled={!isByes}
              paddingX="4px"
              paddingXOuter="4px"
              hideCheck
              isFirst
              data-testid="byeBlameKeeper"
            >
              Keeper
            </SimpleStateButton>
          </Box>
        </Flex>
      </Flex>
      <Flex direction="column" flex={0.75} margin="7px 0 0 7px" maxW="230px">
        <Flex flex={1} direction="row">
          <Flex flex={1} alignItems="flex-end">
            <SubHeading text="Runs Saved:" centered quaternary noPadding />
          </Flex>
          <Flex flex={2} pl="7px" mt="0px">
            <Stepper
              id="runsSaved"
              labels={['Cost', 'Saved']}
              value={ball.fieldingAnalysis?.runsSaved || 0}
              onChange={changeRunsSaved}
              min={BallHelpers.runsAndExtrasTotal(ball, true)}
              isEditing={editBall}
            />
          </Flex>
        </Flex>
        <Flex flex={1} direction="row" mt="10px">
          <Flex flex={1} alignItems="flex-end">
            <SubHeading text="Over Throws:" centered quaternary noPadding />
          </Flex>
          <FieldingMiscOverthrows ball={ball} editBall={editBall} />
        </Flex>
      </Flex>
    </Flex>
  )
})

export default FieldingMisc
