import { types } from 'mobx-state-tree'

import { ScoreFormats } from '../../reference'

const MatchSettingsModel = types
  .model('MatchSettingsModel', {
    scoreFormat: types.enumeration(ScoreFormats),
    preBallScreen: false,
    fielderPlacement: types.maybeNull(types.boolean),
    fielderEvents: types.maybeNull(types.boolean),
    fielderShirtNumbers: types.maybeNull(types.boolean),
    ballPreview: types.maybeNull(types.boolean),
    activeFieldId: '',
    activeFieldHanded: 'RIGHT',
    activeFieldRotation: 0,
  })
  .actions(self => {
    const setScoreFormat = (format: string) => {
      self.scoreFormat = format
    }
    const setFielderPlacement = (choice: boolean) => {
      self.fielderPlacement = choice
      self.preBallScreen = choice
    }
    const setFielderEvents = (choice: boolean) => {
      self.fielderEvents = choice
      self.preBallScreen = choice
    }
    const setFielderShirtNumbers = (choice: boolean) => {
      self.fielderShirtNumbers = choice
    }
    const setBallPreview = (choice: boolean) => {
      self.ballPreview = choice
    }
    const setActiveFieldID = (id: string) => {
      self.activeFieldId = id
    }
    const setActiveFieldHanded = (id: string) => {
      self.activeFieldHanded = id
    }
    const setActiveFieldRotation = (value: number) => {
      self.activeFieldRotation = value
    }
    const setPreBallScreen = (choice: boolean) => {
      self.preBallScreen = choice
      self.fielderPlacement = choice
    }
    return {
      setScoreFormat,
      setFielderPlacement,
      setFielderEvents,
      setFielderShirtNumbers,
      setBallPreview,
      setActiveFieldID,
      setActiveFieldHanded,
      setActiveFieldRotation,
      setPreBallScreen,
    }
  })

export default MatchSettingsModel
