import { Box, Flex, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { useMst } from '../../data/stores/rootStore'
import type { FieldingPlacementsOverlayProps } from '../../types/props'
import { CustomButton } from '../Buttons/CustomButton'

export const FieldingPlacementsOverlay = observer(({ ball, setFieldHasChanged }: FieldingPlacementsOverlayProps) => {
  const { appSettings } = useMst()
  const matchSettings = appSettings.getMatchSettings(ball.getMatchId)

  const handleFieldRotation = () => {
    matchSettings.setActiveFieldRotation(matchSettings.activeFieldRotation === 0 ? 180 : 0)
  }
  const handleFieldHanded = (battingHanded: string) => {
    if (!battingHanded) return
    if (matchSettings.activeFieldHanded !== battingHanded) {
      // if we have changed from R-L or L-R
      matchSettings.setActiveFieldHanded(battingHanded)
      // flip positions x values
      if (ball.fieldingAnalysis?.fieldingPositions) {
        const placements = toJS(ball.fieldingAnalysis.fieldingPositions)
        for (const p of placements) {
          ball.fieldingAnalysis.updateFieldingPlacementPosition(p.id, 100 - p.placement.x, p.placement.y)
        }
        if (setFieldHasChanged) setFieldHasChanged(true)
      }
    }
  }

  return (
    <Box
      w="100%"
      position="sticky"
      float="left"
      top="0"
      zIndex="100"
      paddingBottom="0.5em"
      bgGradient="repeating-linear-gradient(
          -45deg,
          rgb(154, 154, 154, 0.1),
          rgb(97, 95, 95, 0.1) 2.5em,
          rgb(97, 95, 95, 0.3) 2.5em,
          rgb(97, 95, 95, 0.3) 7em /* determines size */
        )"
    >
      <Flex flex={1} direction="row" justifyContent="space-between" marginTop="7px">
        <Flex flex={1} marginLeft="0.5em">
          <CustomButton
            onClick={handleFieldRotation}
            width="100%"
            paddingX="8px"
            paddingXOuter="4px"
            isFlex
            hideCheck
            data-testid="oneEightyFieldRotation"
          >
            <Flex direction="row" alignItems="center">
              <FontAwesomeIcon icon={['fas', 'sync-alt']} size="sm" style={{ marginRight: '7px', fontSize: '12px' }} />
              <Text>180°</Text>
            </Flex>
          </CustomButton>
        </Flex>
        <Flex flex={6} />
        <Flex flex={1} direction="row" marginRight="0.5em">
          <Flex>
            <CustomButton
              isActive={matchSettings.activeFieldHanded === 'LEFT'}
              onClick={() => handleFieldHanded('LEFT')}
              width="100%"
              paddingX="8px"
              paddingXOuter="4px"
              isFlex
              hideCheck
              data-testid="leftHandedBatter"
            >
              {matchSettings.activeFieldHanded === 'LEFT' && <Text>LH Batter</Text>}
              {matchSettings.activeFieldHanded !== 'LEFT' && (
                <Flex direction="row" alignItems="center">
                  <FontAwesomeIcon
                    icon={['fas', 'exchange']}
                    size="sm"
                    style={{ marginRight: '7px', fontSize: '12px' }}
                  />
                  <Text>LH Batter</Text>
                </Flex>
              )}
            </CustomButton>
          </Flex>
          <Flex marginLeft="0.5em">
            <CustomButton
              isActive={!matchSettings.activeFieldHanded || matchSettings.activeFieldHanded === 'RIGHT'}
              onClick={() => handleFieldHanded('RIGHT')}
              width="100%"
              paddingX="8px"
              paddingXOuter="4px"
              isFlex
              hideCheck
              data-testid="rightHandedBatter"
            >
              {matchSettings.activeFieldHanded === 'RIGHT' && <Text>RH Batter</Text>}
              {matchSettings.activeFieldHanded !== 'RIGHT' && (
                <Flex direction="row" alignItems="center">
                  <FontAwesomeIcon
                    icon={['fas', 'exchange']}
                    size="sm"
                    style={{ marginRight: '7px', fontSize: '12px' }}
                  />
                  <Text>RH Batter</Text>
                </Flex>
              )}
            </CustomButton>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  )
})
