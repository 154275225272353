import { Box, Flex, Heading } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from '../../theme/theme'
import type { SubHeadingProps } from '../../types/props'
import { Button } from '../Buttons/Button'

const SubHeading = ({
  text,
  icon,
  buttonProps,
  centered = false,
  secondary,
  tertiary,
  quaternary,
  bold,
  noPadding,
  warning,
}: SubHeadingProps) => {
  const fontWeight = () => {
    if (warning || (secondary && bold)) return 600
    if ((tertiary || quaternary) && bold) return 600
    if (secondary || tertiary || quaternary) return 400
    return 'bold'
  }
  return (
    <Flex
      flexDirection="row"
      alignItems={buttonProps ? 'flex-start' : 'center'}
      justifyContent={centered ? 'center' : undefined}
      paddingBottom={!noPadding ? '7px' : '0px'}
    >
      {icon && (
        <Box flex="none" minWidth="24px" lineHeight="normal">
          <FontAwesomeIcon icon={icon} size="1x" />
        </Box>
      )}
      <Flex flexDirection="row">
        <Heading
          as={tertiary || quaternary ? 'h4' : secondary ? 'h3' : 'h2'}
          size={quaternary ? 'xs' : tertiary ? 'sm' : 'md'}
          textTransform="uppercase"
          fontStyle={tertiary || quaternary ? 'none' : 'italic'}
          fontWeight={fontWeight()}
          letterSpacing="-0.5px"
          color={warning ? theme.colors.cls.negativeRed : undefined}
        >
          {text}
        </Heading>
        {warning && (
          <Flex paddingLeft="4px" alignItems="center">
            <FontAwesomeIcon
              icon={['fas', 'exclamation-circle']}
              color={theme.colors.cls.negativeRed}
              size="sm"
              style={{ fontSize: '10px' }}
            />
          </Flex>
        )}
      </Flex>
      {buttonProps && (
        <Flex alignItems="flex-start" margin="2px 0 0 14px">
          <Button
            height={buttonProps.height || '14px'}
            fontSize="xs"
            onClick={buttonProps.onClick}
            data-testid={`${buttonProps.text}Button`}
          >
            {buttonProps.text}
          </Button>
        </Flex>
      )}
    </Flex>
  )
}

export default SubHeading
