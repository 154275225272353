import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react'
import type { IFieldingPlacementModel, IFieldingPositionModel, IMatchPlayerModel } from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { each, isNil } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FieldingPositions, HandedTypeOptions } from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import type { IMatchSettingsModel } from '../../types/models'
import type { FieldingPlacementsFieldsProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import { CustomButton } from '../Buttons/CustomButton'
import Dropdown from '../Dropdown/Dropdown'
import SubHeading from '../Headings/SubHeading'

export const FieldingPlacementsFields = observer(
  ({
    ball,
    fieldHasChanged,
    setFieldHasChanged,
    handleUpdatePlacements,
    completeChangeBowler,
  }: FieldingPlacementsFieldsProps) => {
    const activeFieldRef = useRef<HTMLDivElement>(null)
    const { appSettings, fieldingPlacements } = useMst()
    const matchSettings: IMatchSettingsModel = appSettings.getMatchSettings(ball.getMatchId)
    const urlParams = useParams()

    const [newLabel, setNewLabel] = useState('')
    const [newSubtitle, setNewSubtitle] = useState('')
    const [bowlerSelectOpen, setBowlerSelectOpen] = useState(false)
    const [bowlerSelectOptions, setBowlerSelectOptions] = useState<IMatchPlayerModel[]>([])
    const cancelBowlerSelectRef = useRef(null)

    const createNewFavourite = () => {
      handleUpdatePlacements({ createFavourite: true, label: newLabel, subtitle: newSubtitle })
      setNewLabel('')
      setNewSubtitle('')
    }
    const updateFavourite = (bowler: IMatchPlayerModel, isNewBowler?: boolean) => {
      const field: IFieldingPlacementModel | undefined = fieldingPlacements.getFieldById(matchSettings.activeFieldId)
      if (!field) return
      if (isNewBowler) {
        // assigning a new bowler to the field preset
        const bowlerPositionIndex = FieldingPositions.findIndex((fp: string) => fp === 'BOWLER')
        const oldBowler = field.fielders.find((f: IFieldingPositionModel) => {
          return f.fieldingPositionId === bowlerPositionIndex
        })
        const newBowler = field.fielders.find((f: IFieldingPositionModel) => {
          return f.playerMp?.id === bowler.id
        })
        if (oldBowler && newBowler) {
          const oldBowlerCoords = { x: oldBowler.placement.x, y: oldBowler.placement.y }
          const newBowlerPosition = newBowler.fieldingPositionId
          const newBowlerCoords = { x: newBowler.placement.x, y: newBowler.placement.y }
          field.updateLabel(bowler, ball.overNumber)
          oldBowler?.updatePosition(newBowlerPosition)
          oldBowler?.updatePlacement(newBowlerCoords.x, newBowlerCoords.y)
          newBowler?.updatePosition(bowlerPositionIndex)
          newBowler?.updatePlacement(oldBowlerCoords.x, oldBowlerCoords.y)
        }
      } else {
        // using existing field preset bowler
        if (completeChangeBowler) completeChangeBowler(bowler)
      }
      ball.fieldingAnalysis?.updatePlacements(toJS(field.fielders))
      updateFavouriteComplete()
    }
    const updateFavouriteComplete = () => {
      if (setFieldHasChanged) setFieldHasChanged(false)
      setBowlerSelectOptions([])
      setNewLabel('')
      setNewSubtitle('')
    }
    const favouriteSelected = (fav: IFieldingPlacementModel) => {
      matchSettings.setActiveFieldID(fav.id)
      if (!isNil(fav.battingHandedId)) matchSettings.setActiveFieldHanded(HandedTypeOptions[fav.battingHandedId])

      // check if a fielder needs changing for the preset
      if (ball.fieldingAnalysis?.fieldingPositions) {
        each(fav.fielders, (ff: IFieldingPositionModel) => {
          const favFielderExists = ball.fieldingAnalysis?.fieldingPositions?.find((p: IFieldingPositionModel) => {
            return p.playerMp?.id === ff.playerMp?.id
          })
          if (!favFielderExists) {
            each(ball.fieldingAnalysis?.fieldingPositions, (bf: IFieldingPositionModel) => {
              const ballFielderExists = fav.fielders.find((q: IFieldingPositionModel) => {
                return q.playerMp?.id === bf.playerMp?.id
              })
              if (!ballFielderExists && bf.playerMp) {
                ff.updatePlayer(bf.playerMp)
              }
            })
          }
        })
      }

      // check if bowler needs changing for the preset
      const existingPresetBowler = fav.fielders.find((f: IFieldingPositionModel) => {
        return f.fieldingPositionId === FieldingPositions.findIndex((fp: string) => fp === 'BOWLER')
      })
      if (ball.bowlerMp && existingPresetBowler?.playerMp) {
        if (ball.ballNumber === 1 && ball.bowlerMp.id !== existingPresetBowler.playerMp.id) {
          // at start of over, prompt user about if they want the current bowler -OR- the bowler in the preset to be the bowler now.
          setBowlerSelectOptions([ball.bowlerMp, existingPresetBowler.playerMp])
          setBowlerSelectOpen(true)
        } else {
          // assume current bowler as we are mid-over
          updateFavourite(ball.bowlerMp, true)
        }
      } else {
        ball.fieldingAnalysis?.updatePlacements(toJS(fav.fielders))
        updateFavouriteComplete()
      }
    }

    const autocompleteOptions = [
      {
        value: `${ball.bowlerMp?.getShortName} to ${matchSettings.activeFieldHanded === 'RIGHT' ? 'RHB' : 'LHB'}`,
      },
      {
        value: `${ball.bowlerMp?.getDisplayName()} to ${matchSettings.activeFieldHanded === 'RIGHT' ? 'RHB' : 'LHB'}`,
      },
      {
        value: `${ball.bowlerMp?.getShortName} to ${
          matchSettings.activeFieldHanded === 'RIGHT' ? 'RHB' : 'LHB'
        } (Over ${ball.overNumber + 1})`,
      },
      {
        value: `${ball.bowlerMp?.getDisplayName()} to ${
          matchSettings.activeFieldHanded === 'RIGHT' ? 'RHB' : 'LHB'
        } (Over ${ball.overNumber + 1})`,
      },
    ]
    if (ball.bowlerMp?.getShortName === ball.bowlerMp?.getDisplayName()) {
      // if bowler is just known by a single name, remove the now-duplicate options
      autocompleteOptions.splice(2, 1)
      autocompleteOptions.splice(0, 1)
    }

    useEffect(() => {
      if (activeFieldRef && activeFieldRef.current) activeFieldRef.current.scrollIntoView({ behavior: 'smooth' })
    }, [])

    return (
      <>
        <Box w="100%" h="calc(100% - 21px)">
          <SubHeading text="My Fields" secondary bold />
          <Box w="100%" h="100%">
            {fieldingPlacements &&
              fieldingPlacements.inOrder
                .filter((val: IFieldingPlacementModel) => {
                  return val.matchId === urlParams.id || val.matchId === ''
                })
                .map((fav: IFieldingPlacementModel) => (
                  <CustomButton
                    key={fav.id}
                    width={'100%'}
                    height={fieldHasChanged && fav.id === matchSettings.activeFieldId ? '98px' : '60px'}
                    marginBottom={'14px'}
                    onClick={() => favouriteSelected(fav)}
                    isActive={fav.id === matchSettings.activeFieldId}
                    paddingX="4px"
                    paddingXOuter="7px"
                    textNotCentred
                    isWhite
                    hideCheck
                    data-testid={
                      !isNil(fav.battingHandedId)
                        ? `${fav.label} ${HandedTypeOptions[fav.battingHandedId].substr(0, 1)}`
                        : fav.label
                    }
                  >
                    <Flex
                      ref={fav.id === matchSettings.activeFieldId ? activeFieldRef : undefined}
                      w="100%"
                      direction="row"
                    >
                      <Flex flex={4}>
                        <Text w="100%" textAlign="left" marginBottom="2px">
                          {fav.label}
                        </Text>
                      </Flex>
                      {!isNil(fav.battingHandedId) && (
                        <Flex
                          flex={1}
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          backgroundColor="cls.transparentHighlightDark"
                          borderRadius="4px"
                        >
                          <FontAwesomeIcon
                            icon={['fal', 'hand-paper']}
                            size="sm"
                            style={{ marginRight: '4px', fontSize: '12px' }}
                          />
                          <Text marginBottom="2px" fontSize={14}>
                            {HandedTypeOptions[fav.battingHandedId].substr(0, 1)}
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                    <Text width={'100%'} textAlign="left" fontSize={12} color="cls.gray.400">
                      {fav.subtitle}
                    </Text>
                    {fieldHasChanged && fav.id === matchSettings.activeFieldId && (
                      <Text
                        h="30px"
                        lineHeight="30px"
                        borderRadius="7px"
                        margin="8px 0 4px"
                        backgroundColor="cls.blue.400"
                        textColor="#FFF"
                        onClick={() => {
                          if (ball.bowlerMp) {
                            fieldingPlacements
                              .getFieldById(matchSettings.activeFieldId)
                              ?.updateLabel(ball.bowlerMp, ball.overNumber)
                            handleUpdatePlacements({ updateFavourite: true })
                          }
                        }}
                      >
                        Update
                      </Text>
                    )}
                  </CustomButton>
                ))}
            {fieldHasChanged && (
              <Flex flex={1} backgroundColor="#FFF" marginY="7px" padding="7px" borderRadius={7} boxShadow="cls.light">
                <Flex flex={1} flexDirection="column" paddingRight="7px">
                  <Flex flex={1} flexDirection="row">
                    <InputGroup>
                      <Input
                        value={newLabel}
                        maxLength={50}
                        onChange={e => setNewLabel(e.target.value)}
                        h="42px"
                        marginBottom="1px"
                        placeholder="Name"
                        data-testid="fieldingPlacementLabelChangeInput"
                      />
                      <InputRightElement>
                        <Box marginRight="4px">
                          <Dropdown
                            options={autocompleteOptions}
                            value={<FontAwesomeIcon icon={['far', 'magic']} size="sm" />}
                            onChange={({ value }) => {
                              if (value) setNewLabel(value)
                            }}
                            placement="bottom-end"
                            listWidth="190px"
                            ignoreState
                            preserveCase
                            data-testid="bowlerOptionsDropdown"
                          />
                        </Box>
                      </InputRightElement>
                    </InputGroup>
                  </Flex>
                  <Flex flex={1} flexDirection="row" marginTop="2px">
                    <Flex flex={1.5}>
                      <Input
                        value={newSubtitle}
                        maxLength={50}
                        onChange={e => setNewSubtitle(e.target.value)}
                        h="34px"
                        placeholder="Subtitle"
                        data-testid="subtitleInput"
                      />
                    </Flex>
                    <Flex flex={1} justifyContent="flex-end">
                      <Button
                        h="auto"
                        colorScheme="cls.blue"
                        onClick={() => createNewFavourite()}
                        isDisabled={newLabel === ''}
                        data-testid="createNewFavouriteButton"
                      >
                        Create
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            )}
            <Box w="100%" h="1px" />
          </Box>
        </Box>
        <AlertDialog
          isOpen={bowlerSelectOpen}
          leastDestructiveRef={cancelBowlerSelectRef}
          onClose={() => setBowlerSelectOpen(false)}
          closeOnOverlayClick={false}
          closeOnEsc={false}
          isCentered
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Bowler Select
            </AlertDialogHeader>

            <AlertDialogBody>Select the bowler to use for this field</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="green"
                onClick={() => {
                  updateFavourite(bowlerSelectOptions[0], true)
                  setBowlerSelectOpen(false)
                }}
                ml={3}
                textTransform="capitalize"
                data-testid="fieldBowlerSelectCurrent"
              >
                {bowlerSelectOptions && bowlerSelectOptions.length === 2 ? bowlerSelectOptions[0].getDisplayName() : ''}
              </Button>
              <Button
                colorScheme="green"
                onClick={() => {
                  updateFavourite(bowlerSelectOptions[1], false)
                  setBowlerSelectOpen(false)
                }}
                ml={3}
                textTransform="capitalize"
                data-testid="fieldBowlerSelectPreset"
              >
                {bowlerSelectOptions && bowlerSelectOptions.length === 2 ? bowlerSelectOptions[1].getDisplayName() : ''}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    )
  }
)
