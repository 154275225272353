import type { IBallModel, ITimelineEventModel } from '@clsplus/cls-plus-data-models'
import { zonedTimeToUtc } from 'date-fns-tz'
import Dexie from 'dexie'
import { isNil } from 'lodash'
import type { SnapshotOrInstance } from 'mobx-state-tree'
import { v4 as uuidV4, v5 as uuidV5 } from 'uuid'

import Auth from '../../helpers/auth'
import type { IClspMatchModel } from '../../types/models'

export const dataUUIDNamespace = '66c8caaf-4fd3-46fd-a9e2-75b747d1d75b'

type S3PMetadata = {
  scoringMode: string
  matchId: string
  competitionId?: string
  inningsId?: string
  inningsMatchOrder?: number
  overNumber?: number
  ballNumber?: number
}

type DBS3PMessage = S3PMetadata & {
  id?: string
  user?: string
  timestamp: string
  messageId: string
  syncRequired: number
  data: string
}

export type DBMatch = {
  matchId: string
  syncRequired: number
  matchSN: SnapshotOrInstance<IClspMatchModel>
  message: string
}

export type DBInningsBalls = {
  inningsId: string
  matchId: string
  balls: SnapshotOrInstance<IBallModel>[]
}

export type DBEvent = {
  key: string
  events: SnapshotOrInstance<ITimelineEventModel>[]
}

export type DBTimelineMessage = {
  timestamp: string
  messageId: string
  matchId: string
  syncRequired: number
  message: string
  type: string
}

class Database extends Dexie {
  matches: Dexie.Table<DBMatch, string>
  balls: Dexie.Table<DBInningsBalls, string>
  events: Dexie.Table<DBEvent, string>
  timeline: Dexie.Table<DBTimelineMessage, number>
  s3p: Dexie.Table<DBS3PMessage, number>

  constructor() {
    super('CLSP_Database')

    this.version(3).stores({
      matches: 'matchId, syncRequired',
      balls: 'inningsId, matchId',
      events: 'key',
      timeline: '++id, matchId, messageId, timestamp, syncRequired, type, [matchId+syncRequired]',
      s3p: '++id, matchId, inningsId, inningsMatchOrder, competitionId, messageId, user, scoringMode, timestamp, syncRequired, [matchId+syncRequired], [messageId+syncRequired]', // eslint-disable-line max-len
    })

    this.matches = this.table('matches')
    this.balls = this.table('balls')
    this.events = this.table('events')
    this.timeline = this.table('timeline')
    this.s3p = this.table('s3p')
  }

  createS3PMessage = async (metadata: S3PMetadata, payload?: Record<string, unknown>, forceUUIDv5?: boolean) => {
    if (payload) {
      const timestamp = zonedTimeToUtc(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone).toISOString()
      let dataUUID = ''
      if (forceUUIDv5) {
        // used for match-specific messages that need a consistent ID across all modes
        dataUUID = uuidV5(`${metadata.matchId}_${payload.type}`, dataUUIDNamespace)
      } else if (!isNil(payload.over_number) && !isNil(payload.ball_number)) {
        // used for ball-specific messages that need a consistent ID across all modes
        dataUUID = uuidV5(
          `${metadata.matchId}_${payload.innings_order ?? metadata.inningsMatchOrder}_${payload.over_number}_${
            payload.ball_number
          }_${payload.type}`,
          dataUUIDNamespace
        )
      } else if (payload.type === 'State.Sport.Cricket.Statistics.SportEvent.Period') {
        // used for innings state messages that need a consistent ID across all modes
        dataUUID = uuidV5(
          `${metadata.matchId}_${metadata.inningsMatchOrder}_${payload.current_over_number}_${payload.current_ball_number}_${payload.type}`, // eslint-disable-line max-len
          dataUUIDNamespace
        )
      } else {
        // use a standard v4 UUID for all other messages
        dataUUID = uuidV4()
      }
      const messageId = uuidV4()
      await this.s3p.put({
        user: Auth.getUserProfile()?.email,
        scoringMode: metadata.scoringMode,
        messageId: messageId,
        matchId: metadata.matchId,
        inningsId: metadata.inningsId,
        inningsMatchOrder: metadata.inningsMatchOrder,
        competitionId: metadata.competitionId,
        timestamp: timestamp,
        syncRequired: 1,
        data: JSON.stringify({
          meta: {
            sport_event_id: metadata.matchId,
          },
          payload: {
            event_time: timestamp,
            id: dataUUID,
            ...payload,
          },
        }),
      })
    }
  }

  getTimelineMessageToSync(matchId: string) {
    return this.timeline.where('[matchId+syncRequired]').equals([matchId, 1]).sortBy('timestamp')
  }

  syncMessage(type: string, messageId: string, matchId: string | undefined) {
    if (type === 'updateBall' || type === 'deleteBall' || type === 'updateEvent') {
      this.timeline.where({ messageId: messageId }).modify(changes => (changes.syncRequired = 0))
    }
    if (matchId && type === 'updateMatch') {
      this.matches.update(matchId, { syncRequired: 0 })
    }
  }
}

const db = new Database()

export { db }
