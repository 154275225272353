import { Editable, EditableInput, EditablePreview, Input, Tooltip, useColorModeValue } from '@chakra-ui/react'
import { useCallback, useState } from 'react'

import type { EditableFieldProps } from '../../types/props'

const EditableField = ({
  type,
  value,
  onChange,
  width = '20',
  height,
  marginLeft = undefined,
  pattern,
  errorMessage = 'Please check your input',
  background,
  label,
  textAlign = 'center',
  ...props
}: EditableFieldProps) => {
  const [isError, setErr] = useState(false)

  const handleSubmit = useCallback(
    (val: string) => {
      const isValid = pattern ? val.toString().match(pattern) : true

      if (!isValid) {
        setErr(true)
        return
      }

      if (type === 'number') {
        onChange(Number(val))
      } else {
        onChange(val)
      }
    },
    [onChange, pattern, type]
  )

  return (
    <Editable
      margin="0 auto"
      marginLeft={marginLeft}
      defaultValue={`${value}`}
      isPreviewFocusable={true}
      selectAllOnFocus={false}
      width={width}
      height={height ?? undefined}
      onChange={e => {
        if (pattern && !e.match(pattern)) {
          setErr(true)
        } else {
          setErr(false)
        }
      }}
      onSubmit={handleSubmit}
      outline={`${isError ? '1px solid red' : 'inherit'}`}
      borderRadius={`${isError ? '4px' : 'inherit'}`}
      background={background}
      textAlign={textAlign}
    >
      <Tooltip>
        <EditablePreview
          py={!height ? 2 : undefined}
          px={2}
          width={width}
          height={height ?? undefined}
          _hover={{
            background: useColorModeValue('gray.100', 'gray.700'),
          }}
          data-testid={`${props['data-testid']}-field`}
        />
      </Tooltip>
      {label && <label style={{ paddingRight: '16px' }}>{label}</label>}
      <Input
        data-testid={props['data-testid']}
        py={2}
        px={2}
        height={height ?? undefined}
        as={EditableInput}
        pattern={pattern}
        title={errorMessage}
      />
    </Editable>
  )
}

export default EditableField
