import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Flex,
  Text,
} from '@chakra-ui/react'
import type { IMatchOfficialModel } from '@clsplus/cls-plus-data-models'
import { find, indexOf } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'

import { OfficialTypeOptions } from '../../data/reference'
import { useMst } from '../../data/stores/rootStore'
import type { DropdownCallbackArgs } from '../../types'
import type { OfficialsProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import Dropdown from '../Dropdown/Dropdown'
import SubHeading from '../Headings/SubHeading'
import type { TextFieldCallbackArgs } from '../TextField/TextField'
import TextField from '../TextField/TextField'

export const Officials = observer(({ game, mode, currentBall, onManageClick }: OfficialsProps) => {
  const { balls } = useMst()
  const [umpireSwapIsOpen, setUmpireSwapIsOpen] = useState(false)
  const [umpireRoleIsOpen, setUmpireRoleIsOpen] = useState(false)
  const [umpireChanging, setUmpireChanging] = useState<IMatchOfficialModel | null>(null)
  const [umpireRoleChanging, setUmpireRoleChanging] = useState<string | null>(null)
  const cancelUmpireSwapRef = useRef(null)
  const cancelUmpireRoleRef = useRef(null)

  const onFieldUmpires = game.matchOfficials?.filter(official => official.getType === 'UMPIRE')
  const thirdUmpires = game.matchOfficials?.filter(official => official.getType === 'THIRD_UMPIRE')
  const otherEnd = find(game.venue?.venueEnds, end => {
    return end.id !== currentBall?.venueEnd?.id
  })
  const officialTypes = OfficialTypeOptions.map((officialType: string) => {
    return { value: officialType }
  })

  const handleChangeOfficialName = ({ id, value }: TextFieldCallbackArgs) => {
    if (!id || !game.matchOfficials) return
    const official = game.matchOfficials.find(official => official.id === id)
    game.updateOfficialName(value, official)
  }
  const swapUmpireEnds = (allBalls?: boolean) => {
    if (allBalls) {
      // swap for all balls in the match
      setUmpireSwapIsOpen(true)
    } else {
      // swap on the current ball
      const umpireControl = currentBall?.umpireControl
      const umpireNonControl = currentBall?.umpireNonControl
      if (umpireNonControl) {
        currentBall?.setUmpireControl(umpireNonControl)
        if (!umpireControl) currentBall?.setUmpireNonControl(null)
      }
      if (umpireControl) {
        currentBall?.setUmpireNonControl(umpireControl)
        if (!umpireNonControl) currentBall?.setUmpireControl(null)
      }
    }
  }
  const handleSwapUmpireAll = () => {
    const activeInning = game.getActiveInning()
    if (activeInning) {
      balls.swapUmpireEnds(activeInning.id)
    }
    setUmpireSwapIsOpen(false)
  }

  const handleChangeOfficialType = ({ id, value }: DropdownCallbackArgs) => {
    if (!id || !value) return
    const official: IMatchOfficialModel | undefined = game.matchOfficials?.find(o => o.id === id)
    if (!official) return

    if (
      OfficialTypeOptions[official.matchOfficialTypeId] === 'UMPIRE' &&
      value !== 'UMPIRE' &&
      (onFieldUmpires?.length || 0) <= 2
    ) {
      // if official was an on-field umpire, and changing their role will leave us with < 2 on field umpires
      setUmpireRoleIsOpen(true)
    } else if (value === 'UMPIRE' && (onFieldUmpires?.length || 0) >= 2) {
      // if we already have 2 on field umpires
      setUmpireRoleChanging('UMPIRE')
      setUmpireChanging(official)
      setUmpireRoleIsOpen(true)
    } else if (value === 'THIRD_UMPIRE' && (thirdUmpires?.length || 0) >= 1) {
      // if we already have 1 third umpire
      setUmpireRoleChanging('THIRD_UMPIRE')
      setUmpireChanging(official)
      setUmpireRoleIsOpen(true)
    } else {
      // otherwise, no issue
      confirmChangeOfficialType(official, value)
    }
  }
  const handleChangeOfficialRole = (type: string, id: string) => {
    setUmpireRoleIsOpen(false)
    setUmpireRoleChanging(null)
    const changingOfficial: IMatchOfficialModel | undefined = game.matchOfficials?.find(o => o.id === id)
    if (umpireChanging && type) {
      changingOfficial?.setType(umpireChanging.matchOfficialTypeId)
      umpireChanging?.setType(indexOf(OfficialTypeOptions, type))
    }
    if (type === 'UMPIRE' && currentBall) {
      // handle the correct "end" for the new umpire
      if (currentBall.umpireControl?.id === changingOfficial?.id) {
        // if umpire being changed was control, set our new umpire to control
        currentBall.setUmpireControl(umpireChanging)
      } else if (currentBall.umpireNonControl?.id === changingOfficial?.id) {
        // if umpire being changed was non-control, set our new umpire to non-control
        currentBall.setUmpireNonControl(umpireChanging)
      }
    }
  }
  const confirmChangeOfficialType = (official: IMatchOfficialModel, value: string) => {
    official.setType(indexOf(OfficialTypeOptions, value))
  }

  return (
    <>
      <Box paddingTop="14px">
        <SubHeading
          text="Umpires"
          secondary
          buttonProps={{
            text: 'Manage Umpires',
            height: '22px',
            onClick: onManageClick,
          }}
        />
        {game.matchOfficials &&
          game.matchOfficials.map(official => (
            <Box key={`official${official.id}`} paddingBottom="14px">
              <Flex key={`official${official.id}`} direction="row" alignItems="center" padding="4px 0">
                <Flex flex={1} flexDirection="row">
                  <Flex flex={1}>
                    <TextField
                      id={official.id}
                      value={official.getDisplayName}
                      target="display_name"
                      onChange={handleChangeOfficialName}
                      data-testid="officialDisplayNameTextField"
                    />
                  </Flex>
                  <Flex flex={1} paddingX="7px" alignItems="center">
                    <Dropdown
                      id={official.id}
                      options={officialTypes}
                      value={official.getType.replace('_', ' ').toLowerCase()}
                      onChange={handleChangeOfficialType}
                      ignoreState
                      data-testid="changeOfficialTypeDropdown"
                    />
                  </Flex>
                  {mode === 'main' && official.getType === 'UMPIRE' && currentBall && (
                    <Flex flex={1} alignItems="center">
                      <Text w="100%" fontSize="14px" marginLeft="14px">
                        {`(${
                          currentBall?.umpireControl?.id === official.id ? currentBall.venueEnd?.name : otherEnd?.name
                        } End${currentBall?.umpireControl?.id === official.id ? ' - Current' : ''})`}
                      </Text>
                    </Flex>
                  )}
                  {mode === 'main' && (official.getType !== 'UMPIRE' || !currentBall) && <Flex flex={1} />}
                </Flex>
              </Flex>
            </Box>
          ))}
        {onFieldUmpires && onFieldUmpires.length > 0 && currentBall && (
          <Flex direction="row">
            <Button
              marginRight="10px"
              colorScheme="green"
              onClick={() => swapUmpireEnds()}
              data-testid="swapUmpireEndsButton"
            >
              Swap Umpire Ends
            </Button>
            <Button
              colorScheme="green"
              onClick={() => swapUmpireEnds(true)}
              data-testid="swapUmpireEndsEntireInningsButton"
            >
              Swap Umpire Ends (Entire innings)
            </Button>
          </Flex>
        )}
      </Box>
      <AlertDialog
        isOpen={umpireSwapIsOpen}
        leastDestructiveRef={cancelUmpireSwapRef}
        onClose={() => setUmpireSwapIsOpen(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Umpires
          </AlertDialogHeader>
          <AlertDialogBody>
            Are you sure you wish to swap the ends allocated to each umpire, for every ball in this innings?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={() => setUmpireSwapIsOpen(false)} data-testid="cancelUpmireEndsSwapAlertButton">
              Cancel
            </Button>
            <Button
              colorScheme="green"
              onClick={() => handleSwapUmpireAll()}
              ml={3}
              data-testid="okUpmireEndsSwapAlertButton"
            >
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={umpireRoleIsOpen}
        leastDestructiveRef={cancelUmpireRoleRef}
        onClose={() => setUmpireRoleIsOpen(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Umpire Role
          </AlertDialogHeader>
          <AlertDialogBody>
            {umpireRoleChanging
              ? `The ${umpireRoleChanging
                  .replace('_', ' ')
                  .toLowerCase()} role is already filled. Which ${umpireRoleChanging
                  .replace('_', ' ')
                  .toLowerCase()} would you like to swap this role with?`
              : `Cannot remove the Umpire role from this official as it would result in less than 2 umpires on-field.` +
                ` Instead, select the Umpire role for another official to trigger a swap of roles.`}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={() => setUmpireRoleIsOpen(false)} data-testid="cancelUmpireRoleSwapAlertButton">
              {umpireRoleChanging ? 'Cancel' : 'Close'}
            </Button>
            {umpireRoleChanging === 'UMPIRE' && onFieldUmpires && (
              <>
                {onFieldUmpires.map((ump: IMatchOfficialModel) => (
                  <Button
                    key={ump.id}
                    colorScheme="green"
                    onClick={() => handleChangeOfficialRole(umpireRoleChanging, ump.id)}
                    ml={3}
                    data-testid="changeOfficialRoleButton"
                  >
                    {ump.getDisplayName}
                  </Button>
                ))}
              </>
            )}
            {umpireRoleChanging === 'THIRD_UMPIRE' && thirdUmpires && (
              <>
                {thirdUmpires.map((ump: IMatchOfficialModel) => (
                  <Button
                    key={ump.id}
                    colorScheme="green"
                    onClick={() => handleChangeOfficialRole(umpireRoleChanging, ump.id)}
                    ml={3}
                    data-testid="changeThirdUmpRoleButton"
                  >
                    {ump.getDisplayName}
                  </Button>
                ))}
              </>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
})
