import { Box, Flex, Text } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import type { MatchSettingsProps } from '../../types/props'
import { CustomButton } from '../Buttons/CustomButton'
import SubHeading from '../Headings/SubHeading'
import Stepper from '../Stepper/Stepper'

const MatchSettings = observer(({ game, mode = 'main', isSetup }: MatchSettingsProps) => {
  return (
    <Box paddingTop="14px">
      <SubHeading text="Match Settings" secondary />
      <Flex
        flex={3}
        flexDirection="row"
        alignItems="flex-start"
        paddingY="7px"
        borderBottom="1px solid"
        borderBottomColor="cls.backgroundGray"
      >
        <Flex flex={1.5} direction="column">
          <Text textTransform="capitalize">Overs per innings</Text>
          <Box padding="4px 50px 4px 0">
            <Text color="cls.negativeRed" fontSize="md" fontWeight="bold" lineHeight="16px">
              IMPORTANT: Overs adjustments due to delay should not be made here. Please use DLS before first ball.
            </Text>
          </Box>
        </Flex>
        <Flex flex={1} justifyContent="flex-end">
          <Stepper
            id="oversPerInnings"
            value={game.matchConfigs.maxOvers === null ? 0 : game.matchConfigs.maxOvers}
            onChange={(value: number) => game.matchConfigs.setMaxOvers(value)}
            isUnlocked={true}
            isDisabled={!isSetup}
            min={0}
            max={100}
          />
        </Flex>
      </Flex>
      <Flex
        flex={3}
        flexDirection="row"
        alignItems="center"
        paddingY="7px"
        borderBottom="1px solid"
        borderBottomColor="cls.backgroundGray"
      >
        <Flex flex={1.5}>
          <Text textTransform="capitalize">Balls per over</Text>
        </Flex>
        <Flex flex={1} justifyContent="flex-end">
          <Stepper
            id="ballsPerOver"
            value={game.matchConfigs.ballsPerOver || 6}
            onChange={(value: number) => game.matchConfigs.setBallsPerOver(value)}
            min={1}
            max={9}
            isUnlocked={true}
            isDisabled={!isSetup}
          />
        </Flex>
      </Flex>
      {mode !== 'fielding' && (
        <>
          <Flex
            flex={3}
            flexDirection="row"
            alignItems="center"
            paddingY="7px"
            borderBottom="1px solid"
            borderBottomColor="cls.backgroundGray"
          >
            <Flex flex={1.5}>
              <Text textTransform="capitalize">Bowler consecutive overs?</Text>
            </Flex>
            <Flex flex={1} justifyContent="flex-end">
              <CustomButton
                isActive={game.matchConfigs.bowlerConsecutiveOvers || false}
                isDisabled={!isSetup}
                onClick={() => game.matchConfigs.setBowlerConsecutiveOvers(!game.matchConfigs.bowlerConsecutiveOvers)}
                data-testid={
                  game.matchConfigs.bowlerConsecutiveOvers
                    ? 'bowlerConsecutiveOversAllowed'
                    : 'bowlerConsecutiveOversNotAllowed'
                }
              >
                {game.matchConfigs.bowlerConsecutiveOvers ? 'Allowed' : 'Not allowed'}
              </CustomButton>
            </Flex>
          </Flex>
          <Flex
            flex={3}
            flexDirection="row"
            alignItems="center"
            paddingY="7px"
            borderBottom="1px solid"
            borderBottomColor="cls.backgroundGray"
          >
            <Flex flex={1.5}>
              <Text textTransform="capitalize">No ball runs</Text>
            </Flex>
            <Flex flex={1} justifyContent="flex-end">
              <Stepper
                id="ballsPerOver"
                value={game.matchConfigs.noBallRuns || 1}
                onChange={(value: number) => game.matchConfigs.setNoBallRuns(value)}
                min={1}
                max={9}
                isUnlocked={true}
                isDisabled={!isSetup}
              />
            </Flex>
          </Flex>
          <Flex
            flex={3}
            flexDirection="row"
            alignItems="center"
            paddingY="7px"
            borderBottom="1px solid"
            borderBottomColor="cls.backgroundGray"
          >
            <Flex flex={1.5}>
              <Text textTransform="capitalize">Free Hit after No Balls?</Text>
            </Flex>
            <Flex flex={1} justifyContent="flex-end">
              <CustomButton
                isActive={game.matchConfigs.freeHitAfterNoBall || false}
                isDisabled={!isSetup}
                onClick={() => game.matchConfigs.setFreeHitAfterNoBall(!game.matchConfigs.freeHitAfterNoBall)}
                data-testid={
                  'freeHitAfterBalls' + (game.matchConfigs.freeHitAfterNoBall ? 'Automatic' : 'NotAutomatic')
                }
              >
                {game.matchConfigs.freeHitAfterNoBall ? 'Automatic' : 'Not automatic'}
              </CustomButton>
            </Flex>
          </Flex>
          <Flex
            flex={3}
            flexDirection="row"
            alignItems="center"
            paddingY="7px"
            borderBottom="1px solid"
            borderBottomColor="cls.backgroundGray"
          >
            <Flex flex={1.5}>
              <Text textTransform="capitalize">BATTING - Max Reviews Per Inning</Text>
            </Flex>
            <Flex flex={1} justifyContent="flex-end">
              <Stepper
                id="maxBattingReviewsPerInning"
                value={
                  game.matchConfigs.maxBattingReviewsPerInnings !== null
                    ? game.matchConfigs.maxBattingReviewsPerInnings
                    : 0
                }
                onChange={(value: number) => game.matchConfigs.setMaxBattingReviewsPerInning(value)}
                min={0}
                max={9}
                isUnlocked={true}
                isDisabled={!isSetup}
              />
            </Flex>
          </Flex>
          <Flex
            flex={3}
            flexDirection="row"
            alignItems="center"
            paddingY="7px"
            borderBottom="1px solid"
            borderBottomColor="cls.backgroundGray"
          >
            <Flex flex={1.5}>
              <Text textTransform="capitalize">BOWLING - Max Reviews Per Inning</Text>
            </Flex>
            <Flex flex={1} justifyContent="flex-end">
              <Stepper
                id="maxBowlingReviewsPerInning"
                value={
                  game.matchConfigs.maxBowlingReviewsPerInnings !== null
                    ? game.matchConfigs.maxBowlingReviewsPerInnings
                    : 0
                }
                onChange={(value: number) => game.matchConfigs.setMaxBowlingReviewsPerInning(value)}
                min={0}
                max={9}
                isUnlocked={true}
                isDisabled={!isSetup}
              />
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  )
})

export default MatchSettings
