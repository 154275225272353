import { Flex } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import BallHelpers from '../../../helpers/ballHelpers'
import type { FieldingMiscOverthrowsProps } from '../../../types/props'
import Stepper from '../../Stepper/Stepper'

const FieldingMiscOverthrows = observer(({ mode, ball, editBall }: FieldingMiscOverthrowsProps) => {
  const changeOverthrows = (value: number) => {
    if (!ball || !ball.fieldingAnalysis) return
    ball.fieldingAnalysis.setOverthrows(value)
  }

  const maxOverthrows: number =
    ball.getExtraType === 'WIDE' ? (ball.runsExtra || 1) - 1 : BallHelpers.runsAndExtrasTotal(ball)

  return (
    <Flex flex={mode === 'fielding' ? 1 : 2} paddingLeft={mode === 'fielding' ? '0px' : '7px'}>
      <Stepper
        id="overthrows"
        value={ball.fieldingAnalysis?.overThrows || 0}
        onChange={changeOverthrows}
        min={0}
        max={mode !== 'fielding' ? maxOverthrows : undefined}
        isEditing={editBall}
        isRatio={mode !== 'fielding'}
        ratioSeparator="/"
        isWhite={mode === 'fielding'}
      />
    </Flex>
  )
})

export default FieldingMiscOverthrows
