import { Box, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import type { NotificationBoxProps } from '../../types/props'
import { Button } from '../Buttons/Button'

export const NotificationBox = ({ id, text, buttonText, buttonIcon, onClick, onCancel }: NotificationBoxProps) => {
  return (
    <Box
      h="50px"
      bg="cls.black"
      color="white"
      position="fixed"
      right="14px"
      bottom="14px"
      padding="7px 14px"
      shadow="cls.light"
      display="flex"
      alignItems="center"
      {...(id && { id })}
    >
      <Text as="span">{text}</Text>
      <Button
        ml="14px"
        px="4px"
        size="sm"
        color="cls.black"
        display="inline-block"
        onClick={onClick}
        data-testid={`${id}Button`}
      >
        {buttonIcon && <FontAwesomeIcon icon={buttonIcon} style={{ marginRight: 4 }} />}
        {buttonText}
      </Button>
      {onCancel && (
        <Box as="div" ml="7px" onClick={onCancel} _hover={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={['fal', 'times-circle']} />
        </Box>
      )}
    </Box>
  )
}
