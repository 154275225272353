import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Text,
} from '@chakra-ui/react'
import { upperFirst } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'

import type { MatchValidationObject, MatchValidationResponse } from '../../types/props'

const MatchValidationAlertCategory = ({
  data,
  cat,
  separateById,
}: {
  data: MatchValidationResponse['match']
  cat: keyof Omit<MatchValidationResponse, symbol | number>
  separateById: boolean
}) => {
  return (
    <Flex direction="row" py="7px" px="7px">
      <Flex flex={1}>❌</Flex>
      <Flex flex={4}>
        <Text ml="7px" fontWeight="bold" color="red">
          {upperFirst(cat)}
        </Text>
      </Flex>
      <Flex flex={8}>
        {cat !== 'match' && cat !== 'competition' && (
          <>
            {separateById ? (
              <Flex ml="7px" direction="column">
                {data.map((d: string | MatchValidationObject) => (
                  <Box key={typeof d === 'object' ? `${d.id}-${d.name}` : d} mb="14px">
                    {typeof d === 'object' && (
                      <Text fontSize="md" fontWeight="bold">
                        {d.id}
                      </Text>
                    )}
                    <Text fontSize="md">{typeof d === 'object' ? d.name : d}</Text>
                  </Box>
                ))}
              </Flex>
            ) : (
              <Text ml="7px" fontSize="md">
                {data
                  .map((d: string | MatchValidationObject) => {
                    return typeof d === 'object' ? d.name : d
                  })
                  .join(', ')}
              </Text>
            )}
          </>
        )}
      </Flex>
    </Flex>
  )
}

type MatchValidationProps = {
  title?: string
  message?: string
  issues: MatchValidationResponse | null
  isOpen: boolean
  onClose: () => void
  separateById?: boolean
}

const MatchValidation = observer(
  ({ title, message, issues, isOpen, onClose, separateById = false }: MatchValidationProps) => {
    const cancelValidationIssue = useRef(null)

    return (
      <AlertDialog
        size="xl"
        isOpen={isOpen}
        leastDestructiveRef={cancelValidationIssue}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {`${title ?? 'Validation'} failed`}
          </AlertDialogHeader>
          <AlertDialogBody>
            <Text>{`${title ?? 'Validation'} has failed for this game. ${message ?? ''}`}</Text>
            {issues && (
              <Box mt="21px" backgroundColor="cls.backgroundGray" padding="7px">
                {issues['match']?.length > 0 && (
                  <MatchValidationAlertCategory cat="match" data={issues['match']} separateById={separateById} />
                )}
                {issues['competition']?.length > 0 && (
                  <MatchValidationAlertCategory
                    cat="competition"
                    data={issues['competition']}
                    separateById={separateById}
                  />
                )}
                {issues['venue']?.length > 0 && (
                  <MatchValidationAlertCategory cat="venue" data={issues['venue']} separateById={separateById} />
                )}
                {issues['team']?.length > 0 && (
                  <MatchValidationAlertCategory cat="team" data={issues['team']} separateById={separateById} />
                )}
                {issues['players']?.length > 0 && (
                  <MatchValidationAlertCategory cat="players" data={issues['players']} separateById={separateById} />
                )}
                {issues['officials']?.length > 0 && (
                  <MatchValidationAlertCategory
                    cat="officials"
                    data={issues['officials']}
                    separateById={separateById}
                  />
                )}
              </Box>
            )}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose} data-testid="cancelValidationButton">
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    )
  }
)

export default MatchValidation
