import { Box, Flex } from '@chakra-ui/react'
import type { Dispatch, ReactNode, SetStateAction } from 'react'

interface GameTabsProps {
  selectedTab: number
  setSelectedTab: Dispatch<SetStateAction<number>>
}

export const GameTabs = ({ selectedTab, setSelectedTab }: GameTabsProps) => {
  return (
    <Flex>
      <Tab selected={selectedTab === 1} tabValue={1} setSelectedTab={setSelectedTab}>
        On this device
      </Tab>
      <Tab selected={selectedTab === 2} tabValue={2} setSelectedTab={setSelectedTab}>
        On the system
      </Tab>
    </Flex>
  )
}

interface TabProps {
  children: ReactNode
  selected: boolean
  tabValue: number
  setSelectedTab: Dispatch<SetStateAction<number>>
}

const Tab = ({ selected, tabValue, setSelectedTab, children }: TabProps) => {
  return (
    <Box
      padding={['7px 7px', '7px 28px']}
      maxH="38px"
      backgroundColor={selected ? 'cls.yellow.400' : 'white'}
      shadow="cls.light"
      onClick={() => setSelectedTab(tabValue)}
      _hover={{ cursor: 'pointer' }}
      _first={{ borderTopLeftRadius: '7px', borderBottomLeftRadius: '7px' }}
      _last={{ borderTopRightRadius: '7px', borderBottomRightRadius: '7px' }}
    >
      {children}
    </Box>
  )
}
