import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

import type { RangeStepperProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import { CustomButton } from '../Buttons/CustomButton'
import type { TextFieldCallbackArgs } from '../TextField/TextField'
import TextField from '../TextField/TextField'

const RangeStepper = ({
  id,
  title,
  value,
  range,
  isUnlocked = false,
  isEditing = false,
  modalContentProps = {},
  onChange,
}: RangeStepperProps) => {
  const [stepperValue, setStepperValue] = useState(value)
  const [selectedRange, setSelectedRange] = useState('0')
  const { isOpen, onOpen, onClose } = useDisclosure()

  const changeRangeValue = (value: number) => {
    setSelectedRange(value.toString())
    if (!range.incrementValues) changeStepperValue(value)
  }

  const changeStepperValue = (value: number) => {
    const valueAsNumber = value === null ? 0 : Number(value)
    setStepperValue(valueAsNumber)
    if (onChange) onChange(valueAsNumber)
    closeStepper()
  }

  const changeStepperValueText = ({ value }: TextFieldCallbackArgs) => {
    if (value !== null && (!isNaN(Number(value)) || value === '')) {
      if (value === '') value = '0'
      changeStepperValue(Number(value))
    }
  }

  const closeStepper = () => {
    setSelectedRange('0')
    onClose()
  }

  const ranges: number[] = []
  const rangeGranular: number[] = []
  let currentRange = range.start

  while (currentRange <= range.end) {
    ranges.push(currentRange)
    currentRange += range.increment
  }
  if (range.incrementValues && selectedRange !== null) {
    for (let i = 0; i < range.increment / range.incrementValues; i++) {
      rangeGranular.push(Number(selectedRange) + i)
    }
  }

  useEffect(() => {
    setStepperValue(value)
  }, [value])

  return (
    <Flex h="60px" flexDirection="row">
      <Box w="60px" h="100%" paddingRight="7px">
        <TextField
          id={`stepperText${id}`}
          defaultValue="0"
          value={`${stepperValue}`}
          textAlign="center"
          isReadOnly={!isUnlocked}
          onChange={changeStepperValueText}
          isEditing={isEditing}
          isStepperLarge
          data-testid={id + 'StepperTextField'}
        />
      </Box>
      <Box h="100%">
        <CustomButton
          onClick={onOpen}
          paddingX="2px"
          paddingXOuter="2px"
          height="70px"
          data-testid={`gripHorizontalStepper`}
          hideCheck
        >
          <FontAwesomeIcon icon={['fas', 'grip-horizontal']} size="lg" />
        </CustomButton>
      </Box>
      <Modal isOpen={isOpen} onClose={closeStepper} size="md">
        <ModalOverlay />
        <ModalContent {...modalContentProps} position="absolute">
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          {selectedRange === '0' && (
            <ModalBody>
              <Flex w="100%" h="100%" wrap="wrap" paddingBottom="14px">
                {ranges.map((rng, idx) => (
                  <Button
                    key={`rng${idx}`}
                    margin="0 0 7px 7px"
                    onClick={() => changeRangeValue(rng)}
                    data-testid={`rangeGranular_${rng}`}
                  >
                    {range.incrementValues ? `${rng}s` : rng}
                  </Button>
                ))}
              </Flex>
            </ModalBody>
          )}
          {range.incrementValues && selectedRange !== '0' && (
            <ModalBody>
              <Flex w="100%" h="100%" wrap="wrap" paddingBottom="14px">
                {rangeGranular.map((rng, idx) => (
                  <Button
                    key={`rngg${idx}`}
                    margin="0 0 7px 7px"
                    onClick={() => changeStepperValue(rng)}
                    data-testid={`rangeGranular_${rng}`}
                  >
                    {rng}
                  </Button>
                ))}
              </Flex>
            </ModalBody>
          )}
          <ModalFooter padding="0 7px 7px 0">
            {value !== 0 && (
              <Button colorScheme="cls.gray" onClick={() => changeStepperValue(0)} data-testid="resetRangeStepper">
                Reset
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default RangeStepper
