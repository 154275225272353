import { Flex } from '@chakra-ui/react'

import LogoSVG from '../../assets/logo_white_bg.svg'
import Auth from '../../helpers/auth'
import { Button } from '../Buttons/Button'

const Login = () => {
  return (
    <Flex align="center" justify="center" h="100vh" direction="column" backgroundColor="#FFF">
      <img src={LogoSVG} style={{ height: '120px', marginBottom: '38px' }} alt="CLS+ Logo" />
      {/** @ts-ignore */}
      <Button
        as="a"
        href={Auth.loginUrl}
        colorScheme="cls.blue"
        size="lg"
        marginBottom="35px"
        data-testid="loginButton"
      >
        Login
      </Button>
    </Flex>
  )
}

export default Login
