import type {
  BallModel,
  IBallModel,
  IBattingPerformanceModel,
  IBowlingPerformanceModel,
  IInningModel,
  IMatchBreaksModel,
  IMatchDatesModel,
  IMatchModel,
  IMatchNotesModel,
  IMatchTeamModel,
  IPenaltyRunsModel,
  ITimelineEventModel,
} from '@clsplus/cls-plus-data-models'
import {
  checkStrikeShouldChange,
  convertBallsTotalToOvers,
  convertOversToBallsTotal,
  getRunsRequired as getRunsRequiredText,
} from '@ias-shared/cricket-logic'
import {
  each,
  filter,
  flatten,
  forEach,
  includes,
  isNil,
  keys,
  last,
  orderBy,
  round,
  startsWith,
  toNumber,
  trim,
} from 'lodash'
import { toJS } from 'mobx'
import type { SnapshotIn, SnapshotOrInstance } from 'mobx-state-tree'
import moment from 'moment'

import {
  BallLengthOptions,
  BowlerApproachOptions,
  DismissalMethods,
  DismissalMethodsForBowler,
  ExtrasTypeOptions,
  MatchResultType,
  PowerPlayTriggers10,
  PowerPlayTriggers20,
  PowerPlayTriggers20BigBash,
  PowerPlayTriggers50,
  PowerPlayTriggersHundred,
  ShotContactOptions,
} from '../data/reference'
import type {
  AggregatedBallDataForOverRange,
  AggregatedBallDataForValue,
  BallEditModel,
  MatchNotePreparationItem,
} from '../types'
import type { IBallStore, IClspMatchModel } from '../types/models'
import { suffixNumber } from './generalHelpers'

function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined
}

const MatchHelpers = (function () {
  const getPlayerPerfValFromCol = (col: string, perf: IBowlingPerformanceModel | IBattingPerformanceModel) => {
    // This property and `bowlingPerformanceSpells` are used as discriminants to check the type of `perf`
    if ('battingPerformanceInstances' in perf) {
      if (col === 'strikeRate') {
        if (perf.runs !== undefined && perf.balls !== undefined) {
          return round(((perf.runs || 0) / (perf.balls || 1)) * 100, 2)
        }
        return ''
      }
      if (col === 'boundaries') {
        if (perf.fours !== undefined || perf.sixes !== undefined) {
          return `${perf.fours || 0}/${perf.sixes || 0}`
        }
        return ''
      }
      if (col === 'allBalls') {
        return perf.allBalls
      }
      if (col === 'allFours') {
        return perf.allFours
      }
      if (col === 'allSixes') {
        return perf.allSixes
      }
      if (col === 'allStrikeRate') {
        if (perf.runs !== undefined && perf.balls !== undefined) {
          return round(((perf.allRuns || 0) / (perf.allBalls || 1)) * 100, 2)
        }
        return ''
      }
    }

    if ('bowlingPerformanceSpells' in perf) {
      if (col === 'economyRate') {
        if (perf.runs !== undefined && perf.overs !== undefined) {
          return round((perf.runs || 0) / (Number(perf.overs) || 1), 2)
        }
        return ''
      }
      if (col === 'allEconomyRate') {
        if (perf.runs !== undefined && perf.overs !== undefined) {
          const oversAsBalls: number = convertOversToBallsTotal({ overs: perf.allOvers })
          if (!oversAsBalls) return 0
          // economy rate = (runs / balls) * ballsPerOver
          return round(((perf.allRuns || 0) / oversAsBalls) * 6, 2)
        }
        return ''
      }
      if (col === 'allOvers') {
        return perf.allOvers
      }
      if (col === 'allMaidens') {
        return perf.allMaidens
      }
      if (col === 'allWickets') {
        return perf.allWickets
      }
      if (col === 'allRuns_allWickets') {
        return `${perf.allRuns}/${perf.allWickets}`
      }
    }

    if (col === 'highlight') {
      return ''
    }
    if (col === 'allRuns') {
      return perf.allRuns
    }
    if (col === 'allDotBalls') {
      return perf.allDotBalls
    }

    if (col in perf) {
      return perf[col as keyof (IBowlingPerformanceModel | IBattingPerformanceModel)]
    }
  }
  const listExtrasFromInning = (inning: IInningModel) => {
    const { byes, legByes, noBalls, wides, penaltyRuns } = inning.progressiveScores
    let result = ''
    result = `${result} nb ${noBalls},`
    result = `${result} wd ${wides},`
    result = `${result} lb ${legByes},`
    result = `${result} b ${byes},`
    result = `${result} pen ${penaltyRuns},`
    return result.substring(0, result.length - 1)
  }
  const differenceBetweenBallsTotals = (
    ball: IBallModel | SnapshotOrInstance<typeof BallModel>,
    noBallValue?: number | null
  ) => {
    const totals: BallEditModel = {
      batter:
        typeof ball.batterMp !== 'number' && typeof ball.batterMp !== 'string' ? ball.batterMp?.id : `${ball.batterMp}`,
      bowler:
        typeof ball.bowlerMp !== 'number' && typeof ball.bowlerMp !== 'string' ? ball.bowlerMp?.id : `${ball.bowlerMp}`,
      balls:
        ball.extrasTypeId === null ||
        ball.extrasTypeId === undefined ||
        ExtrasTypeOptions[ball.extrasTypeId] === 'BYE' ||
        ExtrasTypeOptions[ball.extrasTypeId] === 'LEG_BYE'
          ? 1
          : 0,
      ballsBatter:
        ball.extrasTypeId === null || ball.extrasTypeId === undefined || ExtrasTypeOptions[ball.extrasTypeId] !== 'WIDE'
          ? 1
          : 0,
      runs: (ball.runsBat || 0) + (ball.runsExtra || 0) || 0,
      runsBatter: ball.runsBat || 0,
      runsBowler:
        ball.extrasTypeId === null ||
        ball.extrasTypeId === undefined ||
        ExtrasTypeOptions[ball.extrasTypeId] === 'NO_BALL' ||
        ExtrasTypeOptions[ball.extrasTypeId] === 'WIDE'
          ? (ball.runsBat || 0) + (ball.runsExtra || 0)
          : ExtrasTypeOptions[ball.extrasTypeId] === 'NO_BALL_LEG_BYE' ||
            ExtrasTypeOptions[ball.extrasTypeId] === 'NO_BALL_BYE'
          ? noBallValue || 1
          : 0,
      attackingShots: ball.battingAnalysis?.shots.attacking ? 1 : 0,
      appeals: ball.appeal ? 1 : 0,
      aroundTheWicket:
        !isNil(ball.bowlingAnalysis?.bowlerBallApproachId) &&
        BowlerApproachOptions[ball.bowlingAnalysis.bowlerBallApproachId] === 'AROUND'
          ? 1
          : 0,
      overTheWicket:
        !isNil(ball.bowlingAnalysis?.bowlerBallApproachId) &&
        BowlerApproachOptions[ball.bowlingAnalysis.bowlerBallApproachId] === 'OVER'
          ? 1
          : 0,
      yorkers:
        !isNil(ball.bowlingAnalysis?.ballLengthTypeId) &&
        BallLengthOptions[ball.bowlingAnalysis.ballLengthTypeId] === 'YORKER'
          ? 1
          : 0,
      bouncers:
        !isNil(ball.bowlingAnalysis?.ballLengthTypeId) &&
        BallLengthOptions[ball.bowlingAnalysis.ballLengthTypeId] === 'BOUNCER'
          ? 1
          : 0,
      edges:
        !isNil(ball.battingAnalysis?.shots.shotContactId) &&
        ShotContactOptions[ball.battingAnalysis.shots.shotContactId].indexOf('_EDGE') > -1
          ? 1
          : 0,
      playAndMisses:
        !isNil(ball.battingAnalysis?.shots.shotContactId) &&
        ShotContactOptions[ball.battingAnalysis.shots.shotContactId] === 'PLAY_MISS'
          ? 1
          : 0,
      fours: ball.runsBat === 4 && !ball.allRun ? 1 : 0,
      sixes: ball.runsBat === 6 && !ball.allRun ? 1 : 0,
      noBalls:
        ball.extrasTypeId !== null &&
        ball.extrasTypeId !== undefined &&
        (ExtrasTypeOptions[ball.extrasTypeId] === 'NO_BALL' ||
          ExtrasTypeOptions[ball.extrasTypeId] === 'NO_BALL_BYE' ||
          ExtrasTypeOptions[ball.extrasTypeId] === 'NO_BALL_LEG_BYE')
          ? 1
          : 0,
      wides:
        ball.extrasTypeId !== null && ball.extrasTypeId !== undefined && ExtrasTypeOptions[ball.extrasTypeId] === 'WIDE'
          ? ball.runsExtra || 0
          : 0,
      byes:
        ball.extrasTypeId !== null && ball.extrasTypeId !== undefined && ExtrasTypeOptions[ball.extrasTypeId] === 'BYE'
          ? ball.runsExtra || 0
          : ball.extrasTypeId !== null &&
            ball.extrasTypeId !== undefined &&
            ExtrasTypeOptions[ball.extrasTypeId] === 'NO_BALL_BYE'
          ? (ball.runsExtra || 0) - (noBallValue || 1)
          : 0,
      legByes:
        ball.extrasTypeId !== null &&
        ball.extrasTypeId !== undefined &&
        ExtrasTypeOptions[ball.extrasTypeId] === 'LEG_BYE'
          ? ball.runsExtra || 0
          : ball.extrasTypeId !== null &&
            ball.extrasTypeId !== undefined &&
            ExtrasTypeOptions[ball.extrasTypeId] === 'NO_BALL_LEG_BYE'
          ? (ball.runsExtra || 0) - (noBallValue || 1)
          : 0,
      wickets: ball.dismissal ? 1 : 0,
      retirement: ball.dismissal && startsWith(DismissalMethods[ball.dismissal.dismissalTypeId], 'RETIRED_') ? 1 : 0,
      wicketsBowlerCredited:
        ball.dismissal && DismissalMethodsForBowler.indexOf(DismissalMethods[ball.dismissal.dismissalTypeId]) > -1
          ? 1
          : 0,
      dismissedBatterId: ball.dismissal
        ? typeof ball.dismissal.batterMp !== 'number' && typeof ball.dismissal.batterMp !== 'string'
          ? ball.dismissal.batterMp?.id
          : `${ball.dismissal.batterMp}`
        : undefined,
      wicketType: ball.dismissal ? DismissalMethods[ball.dismissal.dismissalTypeId] : undefined,
      nonStrikeBatter:
        typeof ball.batterNonStrikeMp !== 'number' && typeof ball.batterNonStrikeMp !== 'string'
          ? ball.batterNonStrikeMp.id
          : `${ball.batterNonStrikeMp}`,
      overNumber: ball.overNumber,
      ballDisplayNumber: ball.ballDisplayNumber,
      ballNumber: ball.ballNumber,
      endOfOver: ball.endOfOver,
      extrasTypeId: ball.extrasTypeId,
      shotSideId: ball.battingAnalysis?.shots.shotSideId,
      shotZoneId: ball.battingAnalysis?.shots.shotZoneId,
    }
    return totals
  }
  const differenceBetweenBalls = function ({
    ball,
    currentBall,
    overStatus,
    cascade = false,
    isNewestBallInInnings = false,
    wasInMaiden = false,
    noBallValue = 1,
  }: {
    ball: IBallModel | SnapshotOrInstance<typeof BallModel> | undefined
    currentBall?: IBallModel | SnapshotOrInstance<typeof BallModel> | undefined
    overStatus: { singleBowler: boolean; complete: boolean; ballCount: number }
    cascade?: boolean
    isNewestBallInInnings?: boolean
    wasInMaiden?: boolean
    noBallValue?: number | null
  }) {
    if (!ball) return
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const diffObj: any = {
      was: differenceBetweenBallsTotals(ball, noBallValue),
      is: currentBall ? differenceBetweenBallsTotals(currentBall, noBallValue) : undefined,
    }
    diffObj.was.inMaiden = wasInMaiden
    if (overStatus.singleBowler && overStatus.complete) {
      diffObj.was.ballCountForCompleteOver = overStatus.ballCount
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const difference: any = {}
    forEach(keys(diffObj.was), key => {
      if (
        key !== 'batter' &&
        key !== 'bowler' &&
        key !== 'nonStrikeBatter' &&
        key !== 'dismissedBatterId' &&
        key !== 'wicketType' &&
        key !== 'overNumber' &&
        key !== 'ballDisplayNumber' &&
        key !== 'inMaiden' &&
        key !== 'ballCountForCompleteOver'
      ) {
        if (currentBall) {
          difference[key] = diffObj.is[key] - diffObj.was[key]
        } else {
          difference[key] = diffObj.was[key] !== 0 ? diffObj.was[key] * -1 : 0
        }
      }
    })
    diffObj.difference = difference
    if (currentBall) {
      diffObj.changeStrike =
        cascade ||
        ((!isNewestBallInInnings && diffObj.is && diffObj.was.batter !== diffObj.is.batter ? 1 : 0) +
          (currentBall.shortRun || 0) +
          diffObj.difference.runsBatter +
          diffObj.difference.byes +
          diffObj.difference.legByes +
          (diffObj.difference.noBalls !== 0 &&
            (diffObj.was.noBalls === 0 ||
            diffObj.is.wides !== 0 ||
            (diffObj.was.noBalls !== 0 && diffObj.was.noBalls % (diffObj.is.runsBatter + 1) === 0)
              ? diffObj.difference.noBalls - 1
              : diffObj.difference.noBalls)) +
          (diffObj.difference.wides !== 0 &&
            (diffObj.was.wides === 0 ||
            diffObj.is.noBalls !== 0 ||
            (diffObj.was.wides !== 0 &&
              diffObj.is.wides === 0 &&
              (diffObj.is.runsBatter === 0 || diffObj.was.wides % (diffObj.is.runsBatter + 1) !== 0))
              ? diffObj.difference.wides - 1
              : diffObj.difference.wides))) %
          2 !==
          0
    }
    return diffObj
  }
  const gameDescriptionString = (game: IClspMatchModel, manualScoring?: boolean) => {
    if ((game.matchStatusId || 0) >= 3 && !isNil(game.matchResultTypeId)) {
      // if game is already completed, construct an end of match description
      return game.getWinningMarginAndResult(MatchResultType[game.matchResultTypeId]).description || game.description
    } else {
      // otherwise, an in progress description is required
      const inningsCount = game.getInningCount()
      if (inningsCount <= 1) return game.description
      const activeInning = manualScoring ? game.getLastNonSuperOverInning : game.getActiveInning()
      const battingTeam = game.getBattingTeam(manualScoring ? activeInning?.id : undefined)
      const bowlingTeam = game.getBowlingTeam(manualScoring ? activeInning?.id : undefined)
      const isDlsAffected = manualScoring ? game.matchDls?.active : game.matchIsDlsAffected
      let descText = getRunsRequiredText({
        battingTeam: battingTeam?.name || 'Batting team',
        battingTeamRuns: battingTeam?.getTotalRunsIncludingSuperOvers || 0,
        bowlingTeam: bowlingTeam?.name || 'Bowling team',
        bowlingTeamRuns:
          inningsCount === 2 && isDlsAffected
            ? (game.matchDls?.targetScore || 0) - 1
            : bowlingTeam?.getTotalRunsIncludingSuperOvers || 0,
        isTestMatch: game.getMatchFormat === 'MULTIDAY',
        inningsCount: game.getInningCount(),
      })
      if (isDlsAffected) descText += ' (DLS Method)'
      return descText
    }
  }
  const formatDismissalTypeToAbbrev = (value: string) => {
    let returner = ''
    switch (value) {
      case 'CAUGHT':
        returner = 'c'
        break
      case 'LBW':
        returner = 'lbw'
        break
      case 'BOWLED':
        returner = 'b'
        break
      case 'STUMPED':
        returner = 'st'
        break
      case 'RUN_OUT':
        returner = 'ro'
        break
      case 'HIT_WICKET':
        returner = 'hw'
        break
      case 'RETIRED':
        returner = 'rt'
        break
      case 'HIT_TWICE':
        returner = 'ht'
        break
      case 'TIMED_OUT':
        returner = 'to'
        break
      case 'HANDLED_BALL':
        returner = 'hb'
        break
      case 'OBSTRUCTING_FIELD':
        returner = 'of'
        break
    }
    return returner
  }
  const strikeShouldChange = (ball: IBallModel, noBallValue?: number | null) => {
    const isNoBall =
      ball.extrasTypeId !== null &&
      ball.extrasTypeId !== undefined &&
      ExtrasTypeOptions[ball.extrasTypeId].search('NO_BALL') > -1
    const isWide =
      ball.extrasTypeId !== null && ball.extrasTypeId !== undefined && ExtrasTypeOptions[ball.extrasTypeId] === 'WIDE'
    return checkStrikeShouldChange({
      runsBat: (ball.runsBat || 0) + (ball.shortRun || 0),
      runsExtra: ball.runsExtra || 0,
      isWicketAndCrossed: !!(ball.dismissal && ball.dismissal.battersCrossed),
      noBall: isNoBall
        ? {
            isNoBall: true,
            noBallValue: noBallValue || 1,
          }
        : undefined,
      wide: isWide
        ? {
            isWide: true,
          }
        : undefined,
    })
  }
  const sortBallsAndEvents = (
    balls: IBallModel[], // this looks wrong, but is needed for TS
    events: ITimelineEventModel[] | undefined
  ) => {
    if (!events) return balls
    const ballsAndEvents = [...balls, ...events]
    return orderBy(
      ballsAndEvents,
      ballOrEvent => {
        if ('timestamps' in ballOrEvent) {
          // ball
          return ballOrEvent.timestamps.bowlerRunningIn
        }
        // event
        return ballOrEvent.eventTime
      },
      ['desc']
    )
  }
  const getLatestBall = (
    item: IBallModel | ITimelineEventModel,
    idx: number,
    items: (IBallModel | ITimelineEventModel)[]
  ) => {
    const itemsToSearch = items.slice(idx)
    if ('ballNumber' in item) return item
    const found = itemsToSearch.find((item): item is IBallModel => 'ballNumber' in item)
    return found
  }
  const matchesInDateOrder = (matches: (IClspMatchModel | undefined)[]) => {
    return orderBy(matches, match => {
      const dates = match?.getDatesInOrder
      if (dates && dates.length) {
        return dates[0]
      }
      return undefined
    })
  }
  const getFormattedMatchDates = (dates: IMatchDatesModel[]) => {
    const noDate = '1900-01-01T00:00:00Z'

    // First, figure out how many dates we have
    const formattedDates: (string | null)[] = []
    each(dates, date => {
      if (date.startDateTime !== noDate) formattedDates.push(date.startDateTime)
    })

    if (formattedDates.length === 1) {
      // single day game
      return `${moment(formattedDates[0]).format('DD/MM/YYYY hh:mm a')}`
    }

    // multi day game
    let combinedDateString = ''
    let currentMonth = ''
    let currentYear = ''
    const stringBreakdown: {
      days: string[]
      month: string
    }[] = []

    each(formattedDates, date => {
      const day = moment(date)
      if (day) {
        const month = day.format('MMM')
        if (month !== currentMonth) {
          stringBreakdown.push({
            days: [day.format('D')],
            month,
          })
          currentMonth = month
        } else {
          stringBreakdown[stringBreakdown.length - 1].days.push(day.format('D'))
        }
        currentYear = day.format('YYYY')
      }
    })

    // put the breakdown together
    each(stringBreakdown, range => {
      const days = range.days.join(', ')
      combinedDateString = `${combinedDateString}${days} ${range.month} & `
    })
    combinedDateString = combinedDateString.slice(0, -2)
    combinedDateString = `${combinedDateString} ${currentYear}`

    return combinedDateString
  }
  const overDisplayFormatter = (
    ballsPerOver: number,
    overString?: string,
    overNumber?: number,
    ballNumber?: number
  ) => {
    if (overString) {
      const overStringSplit = overString.split('.')
      if (overStringSplit.length > 1) {
        const over = parseInt(overStringSplit[0])
        const ball = parseInt(overStringSplit[1])
        if (ball === 0) return `${over}.${ball + 1}`
        if (ball >= ballsPerOver) return `${over + 1}`
        return overString
      } else {
        return overString
      }
    }
    if (overNumber && ballNumber && ballNumber === 0) return `${overNumber}.1`
    if (overNumber && ballNumber && ballNumber >= ballsPerOver) return `${overNumber + 1}`
    return `${overNumber}.${ballNumber}`
  }
  const generateMatchNotes = (game: IClspMatchModel, balls: IBallStore, isManuallyScoring?: boolean) => {
    const ballsPerOver = game.matchConfigs.ballsPerOver || 6
    // Loop through the innings in the match, extract the items that match the rulesets, order them properly
    const inningsInOrder = game.getAllInningInOrder
    // Each note is returned as a MatchNotePreparationItem and later converted into a proper Note
    const matchNotes: SnapshotIn<IMatchNotesModel>[] = []

    inningsInOrder.forEach((innings: IInningModel) => {
      // do not do this for super overs
      if (innings.superOver) return

      const collectionOfNotes: (MatchNotePreparationItem[] | undefined)[] = []
      if (!isManuallyScoring) {
        // PowerPlays
        collectionOfNotes.push(
          extractPowerPlays(innings, balls.confirmedBallsInOrder(innings.id, 'asc'), ballsPerOver, game.penaltyRuns)
        )
        // Breaks
        collectionOfNotes.push(extractBreaks(innings, balls.confirmedBallsInOrder(innings.id, 'asc'), game.matchBreaks))
        // Team Milestones
        collectionOfNotes.push(
          extractTeamBattingMilestones(
            innings,
            balls.confirmedBallsInOrder(innings.id, 'asc'),
            ballsPerOver,
            game.penaltyRuns
          )
        )
        // Batting Personal Milestones
        collectionOfNotes.push(
          extractPersonalBattingMilestones(innings, balls.confirmedBallsInOrder(innings.id, 'asc'))
        )
        // Bowling Personal Milestones
        collectionOfNotes.push(
          extractPersonalBowlingMilestones(innings, balls.confirmedBallsInOrder(innings.id, 'asc'), ballsPerOver)
        )
        // Player Reviews
        collectionOfNotes.push(
          extractPlayerReviews(innings, balls.confirmedBallsInOrder(innings.id, 'asc'), ballsPerOver)
        )
      }
      // End Innings
      collectionOfNotes.push(endInningNote(innings))

      // Now we need to order, and clean up the notes, then push them into the matchNotes[]
      const flatFilteredNotes: MatchNotePreparationItem[] = flatten(collectionOfNotes).filter(isDefined)
      const orderedNotes = orderBy(flatFilteredNotes, ['overStarting'], ['asc'])
      matchNotes.push({
        name: `${innings.getBattingTeamName} innings`,
        inningsMatchOrder: innings.inningsMatchOrder,
        inningsId: innings.id,
        notes: orderedNotes.map((note, idx) => ({ order: idx, detail: note.detail })),
      })
    })
    // Other notes are match level notes, appended to the final inning

    // End Match
    last(matchNotes)?.notes?.push({
      order: last(matchNotes)?.notes?.length || Infinity,
      detail: `End Match: ${game.description}`,
    })

    return matchNotes
  }
  const extractPowerPlays = (
    innings: IInningModel,
    balls: IBallModel[],
    ballsPerOver: number,
    penaltyRuns: IPenaltyRunsModel[]
  ): MatchNotePreparationItem[] | undefined => {
    return innings.powerPlays?.map(pp => {
      let text = `${pp.getMatchNotesDescription}: Overs ${overDisplayFormatter(ballsPerOver, pp.start)} - ${
        pp.end && overDisplayFormatter(ballsPerOver, pp.end)
      }`
      const stats = getAllStatsForOverRange(
        toNumber(pp.start),
        toNumber(pp.end),
        balls,
        penaltyRuns ? toJS(filter(penaltyRuns, { inningsNum: innings.inningsMatchOrder })) : []
      )
      text = `${text} (${stats.runs} runs, ${stats.extras} extras, ${stats.wickets} wickets)`
      return {
        overStarting: toNumber(pp.start),
        detail: text,
      }
    })
  }
  const extractBreaks = (
    innings: IInningModel,
    balls: IBallModel[],
    matchBreaks: IMatchBreaksModel[] | null
  ): MatchNotePreparationItem[] | undefined => {
    if (matchBreaks === null) return undefined

    // get only the breaks that matter for this inning
    const breaks = matchBreaks.map(matchBreak => {
      if (
        innings.startTime &&
        innings.endTime &&
        matchBreak.endTime &&
        matchBreak.startTime >= innings.startTime &&
        matchBreak.endTime <= innings.endTime
      ) {
        // break is within the innings
        // figure out which was the last ball completed before this break started
        // get stats from start of inning until and including this ball
        const ballsBeforeBreak = balls.filter(
          ball => ball.timestamps.bowlerRunningIn && ball.timestamps.bowlerRunningIn <= matchBreak.startTime
        )
        const startOverNumber = toNumber(`${ballsBeforeBreak[0].overNumber}.${ballsBeforeBreak[0].ballDisplayNumber}`)
        const endOverNumber = toNumber(
          `${ballsBeforeBreak[ballsBeforeBreak.length - 1].overNumber}.${
            ballsBeforeBreak[ballsBeforeBreak.length - 1].ballDisplayNumber
          }`
        )

        const stats = getAllStatsForOverRange(startOverNumber, endOverNumber, ballsBeforeBreak)
        const strikeBatterStats = getBattingStatsForOverRange(
          startOverNumber,
          endOverNumber,
          ballsBeforeBreak,
          ballsBeforeBreak[ballsBeforeBreak.length - 1].batterMp?.id
        )
        const nonStrikeBatterStats = getBattingStatsForOverRange(
          startOverNumber,
          endOverNumber,
          ballsBeforeBreak,
          ballsBeforeBreak[ballsBeforeBreak.length - 1].batterNonStrikeMp?.id
        )
        return {
          overStarting: endOverNumber,
          detail: `${matchBreak.getMatchBreakNotesType}: ${innings.getBattingTeam?.name} - ${
            stats.runs + stats.extras
          }/${stats.wickets} in ${endOverNumber} overs (${ballsBeforeBreak[
            ballsBeforeBreak.length - 1
          ].batterMp?.getDisplayName()} ${strikeBatterStats.runs}, ${ballsBeforeBreak[
            ballsBeforeBreak.length - 1
          ].batterNonStrikeMp?.getDisplayName()} ${nonStrikeBatterStats.runs})`,
        }
      }
      return undefined
    })
    return breaks.filter(isDefined)
  }
  const extractTeamBattingMilestones = (
    innings: IInningModel,
    balls: IBallModel[],
    ballsPerOver: number,
    penaltyRuns: IPenaltyRunsModel[] | undefined
  ): MatchNotePreparationItem[] | undefined => {
    const results: MatchNotePreparationItem[] = []
    const milestoneCount = Math.floor((innings.progressiveScores.runs || 0) / 50)
    for (let i = 1; i <= milestoneCount; i++) {
      const stats = getAllStatsUpUntilValue(
        i * 50,
        balls,
        penaltyRuns ? toJS(filter(penaltyRuns, { inningsNum: innings.inningsMatchOrder })) : []
      )
      results.push({
        overStarting: stats.endOver,
        detail: `${innings.getBattingTeamName}: ${i * 50} runs in ${overDisplayFormatter(
          ballsPerOver,
          `${stats.endOver}`
        )} overs (${stats.balls} balls), Extras ${stats.extras}`,
      })
    }
    return results
  }
  const extractPersonalBattingMilestones = (
    innings: IInningModel,
    balls: IBallModel[]
  ): MatchNotePreparationItem[] | undefined => {
    const results: MatchNotePreparationItem[] = []
    // Get list of batsmen, and how many milestones they passed
    const battersAndMilestoneCount = innings.battingPerformances.map(bp => {
      return {
        name: bp.playerMp.getDisplayName(),
        playerMpId: bp.playerMp.id,
        milestoneCount: Math.floor(bp.runs / 50),
      }
    })
    battersAndMilestoneCount.forEach(record => {
      if (record.milestoneCount > 0) {
        for (let i = 1; i <= record.milestoneCount; i++) {
          const stats = getBattingStatsUpUntilValue(i * 50, balls, record.playerMpId)
          results.push({
            overStarting: stats.endOver,
            detail: `${record.name}: ${i * 50} runs off ${stats.balls} balls (${stats.fours} x 4s, ${
              stats.sixes
            } x 6s)`,
          })
        }
      }
    })
    return results
  }
  const extractPersonalBowlingMilestones = (
    innings: IInningModel,
    balls: IBallModel[],
    ballsPerOver: number
  ): MatchNotePreparationItem[] | undefined => {
    const results: MatchNotePreparationItem[] = []
    // Get list of bowlers, and how many milestones they passed
    const bowlersAndMilestoneCount = innings.bowlingPerformances.map(bp => {
      return {
        name: bp.playerMp.getDisplayName(),
        playerMpId: bp.playerMp.id,
        milestoneCount: Math.floor((bp.wickets || 0) / 5),
      }
    })
    bowlersAndMilestoneCount.forEach(record => {
      if (record.milestoneCount > 0) {
        for (let i = 1; i <= record.milestoneCount; i++) {
          const stats = getBowlingStatsUpUntilValue(i * 5, balls, record.playerMpId)
          results.push({
            overStarting: stats.endOver,
            detail: `${record.name}: ${i * 5} wickets off ${convertBallsTotalToOvers({
              ballsPerOver: ballsPerOver ? ballsPerOver : undefined,
              balls: stats.balls,
            })} overs (${stats.runs} runs, ${stats.extras} extras)`,
          })
        }
      }
    })
    return results
  }
  const extractPlayerReviews = (
    innings: IInningModel,
    balls: IBallModel[],
    ballsPerOver: number
  ): MatchNotePreparationItem[] | undefined => {
    // loop through all balls, if we find a player review add a note about it, pretty simple one
    const notes: MatchNotePreparationItem[] = []
    balls.forEach(ball => {
      if (ball.review && ball.review.getReviewSource === 'PLAYER') {
        const overNumber = toNumber(overDisplayFormatter(ballsPerOver, `${ball.overNumber}.${ball.ballDisplayNumber}`))
        let detail = ''
        if (ball.review.originalDecision) {
          // eslint-disable-next-line
          detail = `Over ${overNumber}: Review by ${innings.getBattingTeamName} (Batting), Umpire ${
            ball.umpireControl?.getDisplayName
          }, Batter - ${ball.batterMp?.getDisplayName()} (${ball.review.getMatchNotesOutcome})`
        } else {
          detail = `Over ${overNumber}: Review by ${
            innings.getBowlingTeam?.name || ''
            // eslint-disable-next-line
          } (Bowling), Umpire ${ball.umpireControl?.getDisplayName}, Batter - ${ball.batterMp?.getDisplayName()} (${
            ball.review.getMatchNotesOutcome
          })`
        }
        notes.push({
          overStarting: overNumber,
          detail: detail,
        })
      }
    })
    return notes
  }
  const endInningNote = (innings: IInningModel): MatchNotePreparationItem[] | undefined => {
    const notes: MatchNotePreparationItem[] = []
    const currentBatters = innings.getCurrentBatterPerformances
    const currentBatterNotes = currentBatters.map(
      batter => `${batter.getPlayerProfile?.getDisplayName()} ${batter.runs}`
    )
    if (innings.progressiveScores) {
      notes.push({
        overStarting: toNumber(innings.progressiveScores.oversBowled),
        // eslint-disable-next-line
        detail: `End of Innings: ${innings.getBattingTeamName} - ${innings.progressiveScores.runs}/${
          innings.progressiveScores.wickets
        } in ${innings.progressiveScores.oversBowled} (${currentBatterNotes.join(', ')})`,
      })
    }
    return notes
  }
  const getAllStatsForOverRange = (
    overStart: number,
    overEnd: number,
    balls: IBallModel[],
    teamPenaltyRuns?: IPenaltyRunsModel[]
  ): AggregatedBallDataForOverRange => {
    const stats = {
      balls: 0,
      runs: 0,
      extras: 0,
      wickets: 0,
      fours: 0,
      sixes: 0,
    }
    for (let i = 0; i < balls.length; i++) {
      const ballNumber = toNumber(`${balls[i].overNumber}.${balls[i].ballDisplayNumber}`)
      if (ballNumber >= overStart && ballNumber <= overEnd) {
        if (
          !balls[i].getExtraType ||
          !includes(['NO_BALL', 'WIDE', 'NO_BALL_LEG_BYE', 'NO_BALL_BYE'], balls[i].getExtraType)
        ) {
          stats.balls++
        }
        stats.runs = stats.runs + (balls[i].runsBat || 0)
        stats.extras = stats.extras + (balls[i].runsExtra || 0)
        stats.fours = stats.fours + (balls[i].isFour ? 1 : 0)
        stats.sixes = stats.sixes + (balls[i].isSix ? 1 : 0)
        stats.wickets = stats.wickets + (balls[i].dismissal ? 1 : 0)
        if (
          teamPenaltyRuns &&
          teamPenaltyRuns.length > 0 &&
          checkPenaltyRunsInsert(teamPenaltyRuns, balls[i], balls[i + 1] || undefined)
        ) {
          stats.extras += teamPenaltyRuns[0].runs
          teamPenaltyRuns.splice(0, 1)
        }
      }
    }
    return stats
  }
  const getBattingStatsForOverRange = (
    overStart: number,
    overEnd: number,
    balls: IBallModel[],
    playerMpId: string | null | undefined
  ): AggregatedBallDataForOverRange => {
    const stats = {
      balls: 0,
      runs: 0,
      extras: 0,
      wickets: 0,
      fours: 0,
      sixes: 0,
    }
    if (isNil(playerMpId)) return stats
    balls.forEach(ball => {
      const ballNumber = toNumber(`${ball.overNumber}.${ball.ballDisplayNumber}`)
      if (ball.batterMp?.id === playerMpId && ballNumber >= overStart && ballNumber <= overEnd) {
        if (!ball.getExtraType || !includes(['NO_BALL', 'NO_BALL_LEG_BYE', 'NO_BALL_BYE'], ball.getExtraType)) {
          stats.balls++
        }
        stats.runs = stats.runs + (ball.runsBat || 0)
        stats.extras = stats.extras + (ball.runsExtra || 0)
        stats.fours = stats.fours + (ball.isFour ? 1 : 0)
        stats.sixes = stats.sixes + (ball.isSix ? 1 : 0)
        stats.wickets = stats.wickets + (ball.dismissal ? 1 : 0)
      }
    })
    return stats
  }
  const getAllStatsUpUntilValue = (
    score: number,
    balls: IBallModel[],
    teamPenaltyRuns?: IPenaltyRunsModel[]
  ): AggregatedBallDataForValue => {
    // Iterate through balls, accumulating stats, until we reach target score
    const stats = {
      balls: 0,
      runs: 0,
      extras: 0,
      wickets: 0,
      fours: 0,
      sixes: 0,
      endOver: 0,
    }
    for (let i = 0; stats.runs + stats.extras < score; i++) {
      if (
        !balls[i].getExtraType ||
        !includes(['NO_BALL', 'WIDE', 'NO_BALL_LEG_BYE', 'NO_BALL_BYE'], balls[i].getExtraType)
      ) {
        stats.balls++
      }
      stats.runs = stats.runs + (balls[i].runsBat || 0)
      stats.extras = stats.extras + (balls[i].runsExtra || 0)
      stats.fours = stats.fours + (balls[i].isFour ? 1 : 0)
      stats.sixes = stats.sixes + (balls[i].isSix ? 1 : 0)
      stats.wickets = stats.wickets + (balls[i].dismissal ? 1 : 0)
      stats.endOver = toNumber(`${balls[i].overNumber}.${balls[i].ballDisplayNumber}`)
      if (
        teamPenaltyRuns &&
        teamPenaltyRuns.length > 0 &&
        checkPenaltyRunsInsert(teamPenaltyRuns, balls[i], balls[i + 1] || undefined)
      ) {
        stats.extras += teamPenaltyRuns[0].runs
        teamPenaltyRuns.splice(0, 1)
      }
    }
    return stats
  }
  const getBattingStatsUpUntilValue = (
    score: number,
    balls: IBallModel[],
    playerMpId: string
  ): AggregatedBallDataForValue => {
    // Iterate through balls, accumulating stats, until we reach target score
    const stats = {
      balls: 0,
      runs: 0,
      extras: 0,
      wickets: 0,
      fours: 0,
      sixes: 0,
      endOver: 0,
    }
    for (let i = 0; stats.runs + stats.extras < score; i++) {
      if (balls[i].batterMp?.id !== playerMpId) continue
      if (!balls[i].getExtraType || !includes(['NO_BALL', 'NO_BALL_LEG_BYE', 'NO_BALL_BYE'], balls[i].getExtraType)) {
        stats.balls++
      }
      stats.runs = stats.runs + (balls[i].runsBat || 0)
      stats.extras = stats.extras + (balls[i].runsExtra || 0)
      stats.fours = stats.fours + (balls[i].isFour ? 1 : 0)
      stats.sixes = stats.sixes + (balls[i].isSix ? 1 : 0)
      stats.wickets = stats.wickets + (balls[i].dismissal ? 1 : 0)
      stats.endOver = toNumber(`${balls[i].overNumber}.${balls[i].ballDisplayNumber}`)
    }
    return stats
  }
  const getBowlingStatsUpUntilValue = (
    wickets: number,
    balls: IBallModel[],
    playerMpId: string
  ): AggregatedBallDataForValue => {
    // Iterate through balls, accumulating stats, until we reach target score
    const stats = {
      balls: 0,
      runs: 0,
      extras: 0,
      wickets: 0,
      fours: 0,
      sixes: 0,
      endOver: 0,
    }
    for (let i = 0; stats.wickets < wickets; i++) {
      if (balls[i].bowlerMp?.id !== playerMpId) continue
      if (
        !balls[i].getExtraType ||
        !includes(['NO_BALL', 'WIDE', 'NO_BALL_LEG_BYE', 'NO_BALL_BYE'], balls[i].getExtraType)
      ) {
        stats.balls++
      }
      stats.runs = stats.runs + (balls[i].runsBat || 0)
      stats.extras = stats.extras + (balls[i].runsExtra || 0)
      stats.fours = stats.fours + (balls[i].isFour ? 1 : 0)
      stats.sixes = stats.sixes + (balls[i].isSix ? 1 : 0)
      stats.wickets = stats.wickets + (balls[i].dismissal ? 1 : 0)
      stats.endOver = toNumber(`${balls[i].overNumber}.${balls[i].ballDisplayNumber}`)
    }
    return stats
  }
  const checkPenaltyRunsInsert = (
    teamPenaltyRuns: IPenaltyRunsModel[],
    currentBall: IBallModel,
    nextBall?: IBallModel
  ) => {
    if (
      !nextBall ||
      (nextBall &&
        teamPenaltyRuns[0].timestamp &&
        teamPenaltyRuns[0].timestamp > (currentBall.timestamps.bowlerRunningIn || '') &&
        teamPenaltyRuns[0].timestamp < (nextBall.timestamps.bowlerRunningIn || ''))
    ) {
      return true
    }
  }
  const checkIfInningsBreakStatusRequired = (game: IMatchModel, activeInning: IInningModel) => {
    // Duplicated snippet from data models repo (/src/Match/actions.ts)
    if (
      (!game.isLimitedOversMatch &&
        (game.getInningCount() <= 2 ||
          (game.getInningCount() === 3 &&
            ((!game.matchHasFollowOn &&
              (activeInning.getBowlingTeam?.getTotalRuns || 0) <= (activeInning.getBattingTeam?.getTotalRuns || 0)) ||
              (game.matchHasFollowOn && game.followOnTeamAheadOrTied))))) ||
      (game.isLimitedOversMatch &&
        (game.getInningCount() % 2 !== 0 || (game.getInningCount() % 2 === 0 && game.getMatchIsTied(true))))
    ) {
      return true
    }
    return false
  }
  const getPowerPlayTriggers = (game: IMatchModel) => {
    if (!game.matchConfigs.maxOvers) return null
    switch (game.matchConfigs.maxOvers) {
      case 10:
        // T10
        return PowerPlayTriggers10
      case 20:
        // T20, Hundred, Big Bash
        if (game.matchConfigs.ballsPerOver === 5) return PowerPlayTriggersHundred
        if ((game.competitionStage?.competition.name || '').search('Big Bash') > -1) return PowerPlayTriggers20BigBash
        return PowerPlayTriggers20
      case 50:
        // ODI
        return PowerPlayTriggers50
      default:
        return null
    }
  }
  const getInningsLabel = (innings: IInningModel, teamName?: string | null) => {
    const inningsNum = innings.superOver ? `Sup ${innings.inningsNumber - 1}` : suffixNumber(innings.inningsNumber)
    return trim(`${teamName} ${inningsNum}`)
  }
  const getOversPerInnings = (innings: IInningModel, maxOvers: number, dlsTargetOvers?: number | null) => {
    return innings.superOver
      ? 1
      : dlsTargetOvers && dlsTargetOvers < Number(innings.progressiveScores.oversBowled)
      ? Number(innings.progressiveScores.oversBowled)
      : dlsTargetOvers || maxOvers || 0
  }
  const getRunsRequired = (
    battingTeam: IMatchTeamModel | undefined,
    bowlingTeam: IMatchTeamModel | undefined,
    innings: IInningModel,
    dlsTargetScore?: number | null
  ) => {
    const dlsTarget = !innings.superOver && dlsTargetScore ? dlsTargetScore - 1 : null
    const bowlingTeamRuns = innings.superOver
      ? bowlingTeam?.getMostRecentInnings()?.progressiveScores.runs || 0
      : dlsTarget || bowlingTeam?.getTotalRuns || 0
    const battingTeamRuns = innings.superOver
      ? battingTeam?.getMostRecentInnings()?.progressiveScores.runs || 0
      : battingTeam?.getTotalRuns || 0
    return bowlingTeamRuns - battingTeamRuns >= 0 ? bowlingTeamRuns - battingTeamRuns + 1 : 0
  }
  return {
    getPlayerPerfValFromCol,
    listExtrasFromInning,
    differenceBetweenBallsTotals,
    differenceBetweenBalls,
    gameDescriptionString,
    formatDismissalTypeToAbbrev,
    strikeShouldChange,
    sortBallsAndEvents,
    getLatestBall,
    getFormattedMatchDates,
    matchesInDateOrder,
    generateMatchNotes,
    checkIfInningsBreakStatusRequired,
    getPowerPlayTriggers,
    getInningsLabel,
    getOversPerInnings,
    getRunsRequired,
  }
})()

export default MatchHelpers
