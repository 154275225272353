import { Box, Flex, Slider as ChakraSlider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/react'
import { useCallback, useEffect, useState } from 'react'

import type { SliderProps } from '../../types/props'

const Slider = ({ value, min, max, increment, labels, isDisabled = false, onChange, ...props }: SliderProps) => {
  const [tempValue, setTempValue] = useState(value)

  const handleSliderHeadChange = useCallback(
    (value: number) => {
      if (tempValue === value) return tempValue
      setTempValue(value)
    },
    [tempValue]
  )

  useEffect(() => {
    // reset slider value if value prop from parent is changed
    setTempValue(value)
  }, [value])

  return (
    <Flex flex={1} w="100%" flexDirection="column" opacity={isDisabled ? 0.3 : 1} {...props}>
      <Flex mx="3px">
        <ChakraSlider
          value={tempValue}
          min={min}
          max={max}
          step={increment}
          isDisabled={isDisabled}
          onChange={handleSliderHeadChange}
          onChangeEnd={onChange}
        >
          <SliderTrack bg="cls.backgroundGray">
            <Box position="relative" right={10} />
            <SliderFilledTrack bg="cls.yellow.400" />
          </SliderTrack>
          <SliderThumb boxSize={4} bg="cls.yellow.400" />
        </ChakraSlider>
      </Flex>
      {labels && (
        <Flex w="100%" direction="row" justifyContent="space-between">
          {labels.map((label: string) => (
            <Text key={`sliderLabel_${label}`} fontSize="xs" fontWeight="bold" color="cls.black">
              {label}
            </Text>
          ))}
        </Flex>
      )}
    </Flex>
  )
}

export default Slider
