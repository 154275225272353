import { Box, Flex, Text } from '@chakra-ui/react'
import type { IBallModel, IFieldingPlayerModel, IMatchPlayerModel } from '@clsplus/cls-plus-data-models'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { each, isNil, orderBy, round, some, values } from 'lodash'
import { useMemo } from 'react'
import type { Column, Row } from 'react-table'
import { useTable } from 'react-table'

import { useMst } from '../../data/stores/rootStore'
import { posNegNumber } from '../../helpers/generalHelpers'
import Theme from '../../theme/theme'
import type { IBallStore } from '../../types/models'
import type { FieldingMetricsProps } from '../../types/props'

interface FieldingRunsSavedProps {
  misfield: number
  dive: number
  slide: number
  throw: number
  stumps: number
  backedUp: number
  total: number
}

interface DifficultyRatingProps {
  l1: number
  l2: number
  l3: number
  l4: number
  l5: number
  total: number
}

interface GoodBadProps {
  good: number
  bad: number
  total: number
}

interface MadeMissedProps {
  made: number
  total: number
}

type PlayerDataProps = {
  involvements: DifficultyRatingProps
  catches: DifficultyRatingProps
  catchesDropped: DifficultyRatingProps
  runOuts: DifficultyRatingProps
  runOutAssists: DifficultyRatingProps
  runOutsMissed: DifficultyRatingProps
  stumpings: DifficultyRatingProps
  stumpingsMissed: DifficultyRatingProps
  throws: GoodBadProps
  runsSaved: FieldingRunsSavedProps
  misfields: DifficultyRatingProps
} & (
  | {
      player: 'Team'
      overthrows: number
      wicketOpportunities: MadeMissedProps
    }
  | {
      player: IMatchPlayerModel
      balls: number
      wkFielded: number
    }
)

interface TierBarProps {
  tier1: number
  tier2: number
  tier3: number
  tier4: number
  tier5: number
  legend?: boolean
  icon?: IconProp
}

const TierBar = ({ tier1, tier2, tier3, tier4, tier5, legend, icon }: TierBarProps) => {
  return (
    <>
      {icon && (
        <Box marginRight="2px">
          <FontAwesomeIcon icon={icon} size="sm" style={{ fontSize: '12px' }} />
        </Box>
      )}
      <Flex flex={tier1 + tier2 + tier3 + tier4 + tier5} paddingY="2px">
        {tier1 > 0 && (
          <Flex
            flex={tier1}
            backgroundColor={Theme.colors.cls.tiers['1']}
            borderTopLeftRadius="4px"
            borderBottomLeftRadius="4px"
            borderTopRightRadius={tier2 === 0 && tier3 === 0 && tier4 === 0 && tier5 === 0 ? '4px' : '0px'}
            borderBottomRightRadius={tier2 === 0 && tier3 === 0 && tier4 === 0 && tier5 === 0 ? '4px' : '0px'}
          >
            <Text w="100%" textAlign="center" fontSize="sm">
              {!legend ? tier1 : 'Level 1'}
            </Text>
          </Flex>
        )}
        {tier2 > 0 && (
          <Flex
            flex={tier2}
            backgroundColor={Theme.colors.cls.tiers['2']}
            borderTopLeftRadius={tier1 === 0 ? '4px' : '0px'}
            borderBottomLeftRadius={tier1 === 0 ? '4px' : '0px'}
            borderTopRightRadius={tier3 === 0 && tier4 === 0 && tier5 === 0 ? '4px' : '0px'}
            borderBottomRightRadius={tier3 === 0 && tier4 === 0 && tier5 === 0 ? '4px' : '0px'}
          >
            <Text w="100%" textAlign="center" fontSize="sm">
              {!legend ? tier2 : 'Level 2'}
            </Text>
          </Flex>
        )}
        {tier3 > 0 && (
          <Flex
            flex={tier3}
            backgroundColor={Theme.colors.cls.tiers['3']}
            borderTopLeftRadius={tier1 === 0 && tier2 === 0 ? '4px' : '0px'}
            borderBottomLeftRadius={tier1 === 0 && tier2 === 0 ? '4px' : '0px'}
            borderTopRightRadius={tier4 === 0 && tier5 === 0 ? '4px' : '0px'}
            borderBottomRightRadius={tier4 === 0 && tier5 === 0 ? '4px' : '0px'}
          >
            <Text w="100%" textAlign="center" fontSize="sm">
              {!legend ? tier3 : 'Level 3'}
            </Text>
          </Flex>
        )}
        {tier4 > 0 && (
          <Flex
            flex={tier4}
            backgroundColor={Theme.colors.cls.tiers['4']}
            borderTopLeftRadius={tier1 === 0 && tier2 === 0 && tier3 === 0 ? '4px' : '0px'}
            borderBottomLeftRadius={tier1 === 0 && tier2 === 0 && tier3 === 0 ? '4px' : '0px'}
            borderTopRightRadius={tier5 === 0 ? '4px' : '0px'}
            borderBottomRightRadius={tier5 === 0 ? '4px' : '0px'}
          >
            <Text w="100%" textAlign="center" fontSize="sm">
              {!legend ? tier4 : 'Level 4'}
            </Text>
          </Flex>
        )}
        {tier5 > 0 && (
          <Flex
            flex={tier5}
            backgroundColor={Theme.colors.cls.tiers['5']}
            borderTopLeftRadius={tier1 === 0 && tier2 === 0 && tier3 === 0 && tier4 === 0 ? '4px' : '0px'}
            borderBottomLeftRadius={tier1 === 0 && tier2 === 0 && tier3 === 0 && tier4 === 0 ? '4px' : '0px'}
            borderTopRightRadius="4px"
            borderBottomRightRadius="4px"
          >
            <Text w="100%" textAlign="center" fontSize="sm">
              {!legend ? tier5 : 'Level 5'}
            </Text>
          </Flex>
        )}
      </Flex>
    </>
  )
}

export const Fielding = ({ inning }: FieldingMetricsProps) => {
  const { balls }: { balls: IBallStore } = useMst()
  const teamPlayers: IMatchPlayerModel[] | undefined = inning.getBowlingTeam?.getPlayersInBattingOrder()

  const runsSavedConstructor = (props: FieldingRunsSavedProps) => {
    return (
      <Flex w="100%" direction="row" alignItems="center">
        {props.misfield !== 0 && (
          <Flex flex={1} justifyContent="center">
            M {posNegNumber(props.misfield)}
          </Flex>
        )}
        {props.dive !== 0 && (
          <Flex flex={1} justifyContent="center">
            D {posNegNumber(props.dive)}
          </Flex>
        )}
        {props.slide !== 0 && (
          <Flex flex={1} justifyContent="center">
            S {posNegNumber(props.slide)}
          </Flex>
        )}
        {props.throw !== 0 && (
          <Flex flex={1} justifyContent="center">
            T {posNegNumber(props.throw)}
          </Flex>
        )}
        {!isNil(props.stumps) && props.stumps !== 0 && (
          <Flex flex={1} justifyContent="center">
            HS {posNegNumber(props.stumps || 0)}
          </Flex>
        )}
        {!isNil(props.backedUp) && props.backedUp !== 0 && (
          <Flex flex={1} justifyContent="center">
            BU {posNegNumber(props.backedUp || 0)}
          </Flex>
        )}
      </Flex>
    )
  }

  const difficultyRatingConstructor = (props: DifficultyRatingProps, icon?: IconProp) => {
    return (
      <Flex w="100%" direction="row" alignItems="center">
        <TierBar tier1={props.l1} tier2={props.l2} tier3={props.l3} tier4={props.l4} tier5={props.l5} icon={icon} />
      </Flex>
    )
  }

  const throwGoodBadConstructor = (props: GoodBadProps) => {
    return (
      <Flex w="100%" direction="column" alignItems="center">
        {props.good + props.bad > 0 && (
          <Flex fontSize="lg" flex={1} justifyContent="center">
            {props.good} of {props.good + props.bad}
          </Flex>
        )}
        <Flex fontSize="md" flex={1} justifyContent="center">
          {`${round((props.good / (props.good + props.bad)) * 100, 0)}% good`}
        </Flex>
      </Flex>
    )
  }

  // table columns
  const columns: Column<PlayerDataProps>[] = useMemo(
    () => [
      {
        Header: 'Fielder',
        accessor: (row: PlayerDataProps) => (row.player === 'Team' ? row.player : row.player?.getDisplayName()),
        Cell: ({ row }: { row: Row<PlayerDataProps> }) => {
          if (row.original.player === 'Team') {
            return (
              <Flex flexDirection="column">
                <Box flex={1}>
                  <Text fontSize="xl">Team</Text>
                </Box>
                <Box flex={1}>
                  <Text fontSize="md">{`(${row.original.wicketOpportunities?.made} of ${
                    row.original.wicketOpportunities.total
                  } wicket ${row.original.wicketOpportunities.total !== 1 ? 'opportunities' : 'opportunity'})`}</Text>
                  <Text fontSize="md">{`(${row.original.overthrows} ${
                    row.original.overthrows !== 1 ? 'overthrows' : 'overthrow'
                  })`}</Text>
                </Box>
              </Flex>
            )
          }

          return (
            <Flex flexDirection="column">
              <Box flex={1}>
                <Text fontSize="xl">{`${row.original.player.getDisplayName()}${
                  row.original.player.wicketKeeper ? ' (WK)' : ''
                }`}</Text>
              </Box>
              {row.original.player.wicketKeeper && row.original.wkFielded > 0 && (
                <Box flex={1}>
                  <Text fontSize="md">{`(${row.original.wkFielded} ${
                    row.original.wkFielded !== 1 ? 'balls' : 'ball'
                  } fielded)`}</Text>
                </Box>
              )}
            </Flex>
          )
        },
      },
      {
        accessor: (row: PlayerDataProps) => row.involvements.total,
        Header: 'Involvements',
        Cell: ({ row }: { row: Row<PlayerDataProps> }) => {
          return (
            <Flex flexDirection="column">
              <Box flex={1}>
                <Text fontSize="lg">{row.original.involvements.total}</Text>
              </Box>
              <Box flex={1}>
                <Box fontSize="md">{difficultyRatingConstructor(row.original.involvements)}</Box>
              </Box>
            </Flex>
          )
        },
      },
      {
        accessor: (row: PlayerDataProps) => row.misfields.total,
        Header: 'Misfields',
        Cell: ({ row }: { row: Row<PlayerDataProps> }) => {
          return (
            <Flex flexDirection="column">
              <Box flex={1}>
                <Text fontSize="lg">{row.original.misfields.total}</Text>
              </Box>
              <Box flex={1}>
                <Box fontSize="md">{difficultyRatingConstructor(row.original.misfields)}</Box>
              </Box>
            </Flex>
          )
        },
      },
      {
        accessor: (row: PlayerDataProps) => row.catches.total,
        Header: 'Catches',
        Cell: ({ row }: { row: Row<PlayerDataProps> }) => {
          return (
            <Flex flexDirection="column">
              <Box flex={1}>
                <Text fontSize="lg">
                  {row.original.catches.total > 0 || row.original.catchesDropped.total > 0
                    ? `${row.original.catches.total} of ${
                        row.original.catches.total + row.original.catchesDropped.total
                      }`
                    : 0}
                </Text>
              </Box>
              {row.original.catches.total > 0 && (
                <Box flex={1}>
                  <Box fontSize="md">{difficultyRatingConstructor(row.original.catches, ['fas', 'check-circle'])}</Box>
                </Box>
              )}
              {row.original.catchesDropped.total > 0 && (
                <Box flex={1}>
                  <Box fontSize="md">
                    {difficultyRatingConstructor(row.original.catchesDropped, ['fas', 'times-circle'])}
                  </Box>
                </Box>
              )}
            </Flex>
          )
        },
      },
      {
        accessor: (row: PlayerDataProps) => row.runOuts.total,
        Header: 'Run Outs',
        Cell: ({ row }: { row: Row<PlayerDataProps> }) => {
          return (
            <Flex flexDirection="column">
              <Box flex={1}>
                <Text fontSize="lg">
                  {row.original.runOuts.total > 0 || row.original.runOutsMissed.total > 0
                    ? `${row.original.runOuts.total} of ${
                        row.original.runOuts.total + row.original.runOutsMissed.total
                      }`
                    : 0}
                </Text>
              </Box>
              {row.original.runOuts.total > 0 && (
                <Box flex={1}>
                  <Box fontSize="md">{difficultyRatingConstructor(row.original.runOuts, ['fas', 'check-circle'])}</Box>
                </Box>
              )}
              {row.original.runOutsMissed.total > 0 && (
                <Box flex={1}>
                  <Box fontSize="md">
                    {difficultyRatingConstructor(row.original.runOutsMissed, ['fas', 'times-circle'])}
                  </Box>
                </Box>
              )}
            </Flex>
          )
        },
      },
      {
        accessor: (row: PlayerDataProps) => row.runOutAssists.total,
        Header: 'Run Out Assists',
        Cell: ({ row }: { row: Row<PlayerDataProps> }) => {
          return (
            <Flex flexDirection="column">
              <Box flex={1}>
                <Text fontSize="lg">{row.original.runOutAssists.total}</Text>
              </Box>
              <Box flex={1}>
                <Box fontSize="md">{difficultyRatingConstructor(row.original.runOutAssists)}</Box>
              </Box>
            </Flex>
          )
        },
      },
      {
        accessor: (row: PlayerDataProps) => row.stumpings.total,
        Header: 'Stumpings',
        Cell: ({ row }: { row: Row<PlayerDataProps> }) => {
          return (
            <Flex flexDirection="column">
              <Box flex={1}>
                <Text fontSize="lg">{row.original.stumpings.total}</Text>
              </Box>
              {row.original.stumpings.total > 0 && (
                <Box flex={1}>
                  <Box fontSize="md">
                    {difficultyRatingConstructor(row.original.stumpings, ['fas', 'check-circle'])}
                  </Box>
                </Box>
              )}
              {row.original.stumpingsMissed.total > 0 && (
                <Box flex={1}>
                  <Box fontSize="md">
                    {difficultyRatingConstructor(row.original.stumpingsMissed, ['fas', 'times-circle'])}
                  </Box>
                </Box>
              )}
            </Flex>
          )
        },
      },
      {
        accessor: (row: PlayerDataProps) => row.throws.total,
        Header: 'Throws with intent',
        Cell: ({ row }: { row: Row<PlayerDataProps> }) => {
          return (
            <Flex flexDirection="column">
              {row.original.throws.total === 0 && (
                <Box flex={1}>
                  <Text fontSize="lg">{row.original.throws.total}</Text>
                </Box>
              )}
              {row.original.throws.total > 0 && (
                <Box flex={1}>
                  <Box>{throwGoodBadConstructor(row.original.throws)}</Box>
                </Box>
              )}
            </Flex>
          )
        },
      },
      {
        accessor: (row: PlayerDataProps) => row.runsSaved,
        Header: 'Runs Cost / Saved (-/+)',
        Cell: ({ row }: { row: Row<PlayerDataProps> }) => {
          return (
            <Flex flexDirection="column" flexWrap="wrap">
              <Box flex={1}>
                <Text fontSize="lg">{posNegNumber(row.original.runsSaved.total)}</Text>
              </Box>
              {some(values(row.original.runsSaved), (val: number) => val !== 0) && (
                <Box flex={1}>
                  <Box fontSize="md">{runsSavedConstructor(row.original.runsSaved)}</Box>
                </Box>
              )}
            </Flex>
          )
        },
      },
    ],
    []
  )

  // player data
  const data = useMemo(() => {
    const totalInvolvements: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
    const totalWicketOpportunities: MadeMissedProps = { made: 0, total: 0 }
    let totalOverthrows = 0
    const overthrowsCounted: string[] = []
    const totalCatches: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
    const totalCatchesDropped: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
    const totalRunOuts: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
    const totalRunOutAssists: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
    const totalRunOutsMissed: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
    const totalStumpings: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
    const totalStumpingsMissed: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
    const totalRunsSaved: FieldingRunsSavedProps = {
      misfield: 0,
      dive: 0,
      slide: 0,
      throw: 0,
      stumps: 0,
      backedUp: 0,
      total: 0,
    }
    const totalThrows: GoodBadProps = { good: 0, bad: 0, total: 0 }
    const totalMisfields: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
    const playerData: PlayerDataProps[] = orderBy(
      teamPlayers?.map((p: IMatchPlayerModel) => {
        const fielderBalls = balls.getBallsForFielder(inning.id, p.id)
        const involvements: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
        let wkFielded = 0
        const catches: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
        const catchesDropped: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
        const runOuts: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
        const runOutAssists: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
        const runOutsMissed: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
        const stumpings: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
        const stumpingsMissed: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
        const runsSaved: FieldingRunsSavedProps = {
          misfield: 0,
          dive: 0,
          slide: 0,
          throw: 0,
          stumps: 0,
          backedUp: 0,
          total: 0,
        }
        const throws: GoodBadProps = { good: 0, bad: 0, total: 0 }
        const misfields: DifficultyRatingProps = { l1: 0, l2: 0, l3: 0, l4: 0, l5: 0, total: 0 }
        each(fielderBalls, (b: IBallModel) => {
          if (b.fieldingAnalysis) {
            if (
              b.fieldingAnalysis.fieldingPlayers &&
              b.fieldingAnalysis.fieldedWicketKeeper &&
              b.fieldingAnalysis.fieldingPlayers[0].playerMp.id === p.id &&
              b.fieldingAnalysis.fieldingPlayers[0].playerMp.wicketKeeper &&
              b.fieldingAnalysis.fieldingPlayers[0].order === 1
            ) {
              wkFielded++
            }
            let wicketOpportunityWasForThisPlayer = false
            each(b.fieldingAnalysis.fieldingPlayers, (q: IFieldingPlayerModel) => {
              if (q.playerMp.id === p.id) {
                if (q.catch) {
                  catches.total++
                  totalCatches.total++
                  totalWicketOpportunities.made++
                  wicketOpportunityWasForThisPlayer = true
                  if (!isNil(q.difficultyRating)) {
                    // @ts-ignore
                    catches[`l${q.difficultyRating}`] += 1
                    // @ts-ignore
                    totalCatches[`l${q.difficultyRating}`] += 1
                  }
                }
                if (q.runOut) {
                  runOuts.total++
                  totalRunOuts.total++
                  totalWicketOpportunities.made++
                  wicketOpportunityWasForThisPlayer = true
                  if (!isNil(q.difficultyRating)) {
                    // @ts-ignore
                    runOuts[`l${q.difficultyRating}`] += 1
                    // @ts-ignore
                    totalRunOuts[`l${q.difficultyRating}`] += 1
                  }
                }
                if (q.runOutAssist) {
                  runOutAssists.total++
                  totalRunOutAssists.total++
                  if (!isNil(q.difficultyRating)) {
                    // @ts-ignore
                    runOutAssists[`l${q.difficultyRating}`] += 1
                    // @ts-ignore
                    totalRunOutAssists[`l${q.difficultyRating}`] += 1
                  }
                }
                if (q.stumping) {
                  stumpings.total++
                  totalStumpings.total++
                  totalWicketOpportunities.made++
                  wicketOpportunityWasForThisPlayer = true
                  if (!isNil(q.difficultyRating)) {
                    // @ts-ignore
                    stumpings[`l${q.difficultyRating}`] += 1
                    // @ts-ignore
                    totalStumpings[`l${q.difficultyRating}`] += 1
                  }
                }
                if (q.runsSaved !== null) {
                  runsSaved.total += q.runsSaved
                  totalRunsSaved.total += q.runsSaved
                  if (q.misfielded?.runsSaved !== null) {
                    runsSaved.misfield += q.misfielded?.runsSaved || 0
                    totalRunsSaved.misfield += q.misfielded?.runsSaved || 0
                  }
                  if (q.diveGood?.runsSaved !== null) {
                    runsSaved.dive += q.diveGood?.runsSaved || 0
                    totalRunsSaved.dive += q.diveGood?.runsSaved || 0
                  }
                  if (q.slideGood?.runsSaved !== null) {
                    runsSaved.slide += q.slideGood?.runsSaved || 0
                    totalRunsSaved.slide += q.slideGood?.runsSaved || 0
                  }
                  if (q.throwGood?.runsSaved !== null) {
                    runsSaved.throw += q.throwGood?.runsSaved || 0
                    totalRunsSaved.throw += q.throwGood?.runsSaved || 0
                  }
                  if (q.throwHitStumps?.runsSaved !== null && !isNil(totalRunsSaved.stumps)) {
                    runsSaved.stumps += q.throwHitStumps?.runsSaved || 0
                    totalRunsSaved.stumps += q.throwHitStumps?.runsSaved || 0
                  }
                  if (q.throwBackedUp?.runsSaved !== null && !isNil(totalRunsSaved.backedUp)) {
                    runsSaved.backedUp += q.throwBackedUp?.runsSaved || 0
                    totalRunsSaved.backedUp += q.throwBackedUp?.runsSaved || 0
                  }
                }
                if (q.throwGood !== null && q.throwWithIntent) {
                  throws.total++
                  totalThrows.total++
                  if (q.throwGood.value) {
                    throws.good++
                    totalThrows.good++
                  } else {
                    throws.bad++
                    totalThrows.bad++
                  }
                }
                if (q.droppedCatch) {
                  catchesDropped.total++
                  totalCatchesDropped.total++
                  wicketOpportunityWasForThisPlayer = true
                  // @ts-ignore
                  catchesDropped[`l${q.difficultyRating}`]++
                  // @ts-ignore
                  totalCatchesDropped[`l${q.difficultyRating}`]++
                }
                if (q.runOutMissed) {
                  runOutsMissed.total++
                  totalRunOutsMissed.total++
                  wicketOpportunityWasForThisPlayer = true
                  // @ts-ignore
                  runOutsMissed[`l${q.difficultyRating}`]++
                  // @ts-ignore
                  totalRunOutsMissed[`l${q.difficultyRating}`]++
                }
                if (q.stumpingMissed) {
                  stumpingsMissed.total++
                  totalStumpingsMissed.total++
                  wicketOpportunityWasForThisPlayer = true
                  // @ts-ignore
                  stumpingsMissed[`l${q.difficultyRating}`]++
                  // @ts-ignore
                  totalStumpingsMissed[`l${q.difficultyRating}`]++
                }
                if (q.misfielded) {
                  // @ts-ignore
                  misfields[`l${q.difficultyRating}`]++
                  misfields.total++
                  // @ts-ignore
                  totalMisfields[`l${q.difficultyRating}`]++
                  totalMisfields.total++
                }
                // @ts-ignore
                involvements[`l${q.difficultyRating}`]++
                involvements.total++
                // @ts-ignore
                totalInvolvements[`l${q.difficultyRating}`]++
                totalInvolvements.total++
              }
            })
            if (b.fieldingAnalysis.wicketOpportunity && wicketOpportunityWasForThisPlayer) {
              totalWicketOpportunities.total++
            }
            if (
              !isNil(b.fieldingAnalysis.overThrows) &&
              b.fieldingAnalysis.overThrows > 0 &&
              overthrowsCounted.indexOf(b.id) === -1
            ) {
              totalOverthrows += b.fieldingAnalysis.overThrows
              overthrowsCounted.push(b.id)
            }
          }
        })
        return {
          player: p,
          balls: fielderBalls?.length || 0,
          involvements: involvements,
          wkFielded: wkFielded,
          catches: catches,
          catchesDropped: catchesDropped,
          runOuts: runOuts,
          runOutAssists: runOutAssists,
          runOutsMissed: runOutsMissed,
          stumpings: stumpings,
          stumpingsMissed: stumpingsMissed,
          throws: throws,
          runsSaved: runsSaved,
          misfields: misfields,
        }
      }),
      ['balls'],
      ['desc']
    )
    playerData.push({
      player: 'Team',
      involvements: totalInvolvements,
      wicketOpportunities: totalWicketOpportunities,
      overthrows: totalOverthrows,
      catches: totalCatches,
      catchesDropped: totalCatchesDropped,
      runOuts: totalRunOuts,
      runOutAssists: totalRunOutAssists,
      runOutsMissed: totalRunOutsMissed,
      stumpings: totalStumpings,
      stumpingsMissed: totalStumpingsMissed,
      throws: totalThrows,
      runsSaved: totalRunsSaved,
      misfields: totalMisfields,
    })
    return playerData
  }, [teamPlayers, balls, inning.id])

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

  return (
    <>
      <table {...getTableProps()} style={{ width: '100%', backgroundColor: 'white' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()} style={{ backgroundColor: Theme.colors.cls.backgroundGray }}>
              {headerGroup.headers.map((column, idx) => (
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps()}
                  style={{
                    padding: '10px',
                    fontWeight: 'normal',
                    fontSize: idx === 0 ? '20px' : '14px',
                    fontStyle: 'italic',
                    textAlign: idx === 0 ? 'left' : 'center',
                    width: idx === 0 ? '21%' : idx === 1 ? '15%' : '9%',
                    borderLeft: idx > 0 ? `solid 1px ${Theme.colors.cls.backgroundGray}` : undefined,
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <tr
                {...row.getRowProps()}
                style={{
                  borderTop: `solid ${row.original.player === 'Team' ? '2px' : '1px'} ${Theme.colors.cls.backgroundGray}
                  `,
                }}
              >
                {row.cells.map((cell, idx) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '10px',
                        textAlign: idx === 0 ? 'left' : 'center',
                        width: idx === 0 ? '21%' : idx === 1 ? '15%' : '9%',
                        // @ts-ignore
                        fontWeight: cell.row.original.player === 'Team' ? 'bold' : 'regular',
                        borderLeft: idx > 0 ? `solid 1px ${Theme.colors.cls.backgroundGray}` : undefined,
                        verticalAlign: 'top',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <Flex w="100%" backgroundColor={Theme.colors.cls.backgroundGray} direction="column" alignItems="center">
        <Text w="100%" fontSize="xl" fontStyle="italic" padding="14px 0 7px 0" textAlign="center">
          Key
        </Text>
        <Text w="100%" fontSize="md" textAlign="center">
          M = misfield, D = dive/jump, S = slide, T = throw, HS = hit stumps, BU = backing up
        </Text>
        <Flex w="33%" direction="row">
          <TierBar tier1={1} tier2={1} tier3={1} tier4={1} tier5={1} legend />
          <Text fontSize="md" textAlign="center" marginLeft="7px">
            = difficulty ratings
          </Text>
        </Flex>
      </Flex>
    </>
  )
}
