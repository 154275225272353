import type { IDLSSuspensionModel } from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import { useCallback, useMemo } from 'react'
import type { Column, Row } from 'react-table'
import { useTable } from 'react-table'

import Theme from '../../theme/theme'
import type { DlsSuspensionProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import type { TextFieldCallbackArgs } from '../TextField/TextField'
import TextField from '../TextField/TextField'

export const DlsSuspensions = observer(({ game, inningsId, data, inSuperOver }: DlsSuspensionProps) => {
  const changeSuspensionField = useCallback(
    ({ id, target, value }: TextFieldCallbackArgs) => {
      if (!id || !target) return
      if (isNaN(Number(value)) && value !== '') value = '0'
      game.updateDlsSuspension(Object.assign({}, { id, target, value }, { inningsId: inningsId }))
    },
    [game, inningsId]
  )

  const removeSuspension = (id: string) => {
    if (!id) return
    game.removeDlsSuspension(id)
  }

  // table columns
  const columns: Column<IDLSSuspensionModel>[] = useMemo(
    () => [
      {
        accessor: (_row: IDLSSuspensionModel, index: number) => index,
        Header: '#',
      },
      {
        accessor: (row: IDLSSuspensionModel) => row.oversAt,
        Header: 'At Over',
        Cell: ({ row }: { row: Row<IDLSSuspensionModel> }) => (
          <TextField
            id={row.original.id}
            target="oversAt"
            value={row.original.oversAt !== null ? `${row.original.oversAt}` : ''}
            onChange={changeSuspensionField}
            isReadOnly={inSuperOver}
            data-testid="atOverChangeSuspensionTextField"
          />
        ),
      },
      {
        accessor: (row: IDLSSuspensionModel) => row.runs,
        Header: 'Runs',
        Cell: ({ row }: { row: Row<IDLSSuspensionModel> }) => (
          <TextField
            id={row.original.id}
            target="runs"
            value={row.original.runs !== null ? `${row.original.runs}` : ''}
            onChange={changeSuspensionField}
            isReadOnly={inSuperOver}
            data-testid="runsChangeSuspensionTextField"
          />
        ),
      },
      {
        accessor: (row: IDLSSuspensionModel) => row.wickets,
        Header: 'Wkts',
        Cell: ({ row }: { row: Row<IDLSSuspensionModel> }) => (
          <TextField
            id={row.original.id}
            target="wickets"
            value={row.original.wickets !== null ? `${row.original.wickets}` : ''}
            onChange={changeSuspensionField}
            isReadOnly={inSuperOver}
            data-testid="wicketsChangeSuspensionTextField"
          />
        ),
      },
      {
        accessor: (row: IDLSSuspensionModel) => row.oversRemaining,
        Header: 'Overs Rem.',
        Cell: ({ row }: { row: Row<IDLSSuspensionModel> }) => (
          <TextField
            id={row.original.id}
            target="oversRemaining"
            value={row.original.oversRemaining !== null ? `${row.original.oversRemaining}` : ''}
            onChange={changeSuspensionField}
            isReadOnly={inSuperOver}
            data-testid="oversRemainingChangeSuspensionTextField"
          />
        ),
      },
      {
        accessor: (_row: IDLSSuspensionModel, index: number) => index,
        Header: ' ',
        Cell: ({ row }: { row: Row<IDLSSuspensionModel> }) => (
          <Button
            colorScheme="red"
            onClick={() => {
              removeSuspension(row.original.id)
            }}
            isDisabled={inSuperOver}
            data-testid="deleteSuspensionField"
          >
            <FontAwesomeIcon icon={['far', 'trash-alt']} size="sm" />
          </Button>
        ),
      },
    ],
    [changeSuspensionField] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

  return (
    <table {...getTableProps()} style={{ width: '100%', backgroundColor: 'white' }}>
      <thead>
        {headerGroups.map(headerGroup => (
          // eslint-disable-next-line react/jsx-key
          <tr {...headerGroup.getHeaderGroupProps()} style={{ backgroundColor: Theme.colors.cls.backgroundGray }}>
            {headerGroup.headers.map(column => (
              // eslint-disable-next-line react/jsx-key
              <th
                {...column.getHeaderProps()}
                style={{
                  padding: '10px',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  fontStyle: 'italic',
                  textAlign: 'center',
                }}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, rIdx) => {
          prepareRow(row)
          return (
            // eslint-disable-next-line react/jsx-key
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, idx) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <td
                    {...cell.getCellProps()}
                    style={{
                      fontWeight: idx === 0 ? 'bold' : 'normal',
                      padding: '10px',
                      textAlign: 'center',
                    }}
                  >
                    {idx === 0 ? rIdx + 1 : cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
})
