import { Box, Flex, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import ActivityLog from '../../components/ActivityLog/ActivityLog'
import { Button } from '../../components/Buttons/Button'
import ControlsPanel from '../../components/ControlsPanel/ControlsPanel'
import { FieldingPlacements } from '../../components/Field/FieldingPlacements'
import Theme from '../../theme/theme'
import type { PreBallProps } from '../../types/props'

const PreBallFielding = observer(
  ({
    ball,
    game,
    activeInning,
    setCurrentBall,
    timelineEvents,
    inningsInOrder,
    setClosedInning,
    currentBall,
    triggerNewBowler,
    isDisabled,
    editBall,
    closedInning,
    bowlerRunningIn,
    confirmedBalls,
    relevantTimelineEvents,
    triggerEndOver,
    betweenOvers,
    triggerEditBall,
    triggerUndo,
    currentInning,
    setCurrentInning,
    editingDisabled,
    activityLogActionsDisabled,
    cancelEditBall,
    triggerMissedBall,
    lastBall,
    triggerNewFielder,
    completeChangeBowler,
    fieldHasChanged,
    setFieldHasChanged,
  }: PreBallProps) => {
    return (
      <Flex flex={1} w="100%" h="100%" direction={['column', 'column', 'row', 'row']}>
        <Flex flex={1} direction="row" w="100%">
          <Flex flex={1} maxW="210px" direction="column" border={`solid 1px ${Theme.colors.gray['300']}`} padding="7px">
            <Box w="100%" h="100%" position="relative">
              <Box marginBottom="21px">
                <ControlsPanel
                  game={game}
                  inning={activeInning}
                  currentBall={currentBall}
                  setCurrentBall={setCurrentBall}
                  timelineEvents={timelineEvents}
                  inningsInOrder={inningsInOrder}
                  mode="fielding"
                  isDisabled={isDisabled}
                  currentInning={currentInning}
                  setCurrentInning={setCurrentInning}
                  closedInning={closedInning}
                  setClosedInning={setClosedInning}
                  editBall={editBall ? currentBall : undefined}
                  cancelEditBall={cancelEditBall}
                />
              </Box>
              <Box w="100%" h="calc(100% - 94px)" paddingTop="14px" borderTop="solid 1px" borderTopColor="gray.300">
                <ActivityLog
                  game={game}
                  balls={confirmedBalls}
                  relevantTimelineEvents={relevantTimelineEvents}
                  mode="fielding"
                  manualEndOver={triggerEndOver}
                  betweenOvers={betweenOvers}
                  triggerEditBall={triggerEditBall}
                  editBall={editBall ? currentBall : undefined}
                  triggerUndo={triggerUndo}
                  triggerMissedBall={triggerMissedBall}
                  inningsInOrder={inningsInOrder}
                  currentInning={currentInning}
                  setCurrentInning={setCurrentInning}
                  editingDisabled={editingDisabled}
                  actionsDisabled={activityLogActionsDisabled}
                />
              </Box>
            </Box>
            <Box w="100%" h="80px" position="sticky" bottom="0%" left="0%" padding="14px 0 0">
              {activeInning.id === currentInning?.id && !activityLogActionsDisabled && (
                <Flex flex={1} w="100%" h="100%" direction="row">
                  <Flex h="100%" flex={2}>
                    {ball.ballNumber <= 6 && (
                      <Button
                        onClick={bowlerRunningIn}
                        w="100%"
                        h="100%"
                        colorScheme="cls.blue"
                        data-testid="readyForNextBallButton"
                      >
                        Ready for
                        <br />
                        next ball
                      </Button>
                    )}
                    {ball.ballNumber > 6 && (
                      <Menu>
                        <MenuButton as={Button} w="100%" h="100%" colorScheme="cls.blue">
                          Ready for
                          <br />
                          next ball
                        </MenuButton>
                        <MenuList borderColor="gray.200">
                          <MenuItem onClick={triggerEndOver}>End Over</MenuItem>
                          <MenuItem onClick={bowlerRunningIn}>Continue Over</MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                  </Flex>
                  <Flex paddingLeft="7px" h="100%" flex={1}>
                    <Button
                      onClick={triggerEndOver}
                      w="100%"
                      h="100%"
                      colorScheme="cls.gray"
                      data-testid="endOverButton"
                    >
                      End Over
                    </Button>
                  </Flex>
                </Flex>
              )}
            </Box>
          </Flex>
          <Flex flex={4} minW="624px">
            <FieldingPlacements
              ball={ball}
              lastBall={lastBall}
              triggerNewBowler={triggerNewBowler}
              triggerNewFielder={triggerNewFielder}
              completeChangeBowler={completeChangeBowler}
              fieldHasChanged={fieldHasChanged}
              setFieldHasChanged={setFieldHasChanged}
              isCurrentInnings={activeInning.id === currentInning?.id}
              actionsDisabled={activityLogActionsDisabled}
            />
          </Flex>
        </Flex>
      </Flex>
    )
  }
)

export default PreBallFielding
