import { Box, Flex } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import { BallSummary } from '../../components/BallDescription/BallSummary'
import SubHeading from '../../components/Headings/SubHeading'
import { useMst } from '../../data/stores/rootStore'
import type { IBallStore, ISettingsModel } from '../../types/models'
import type { DescriptionSectionProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import { BallDescription } from './BallDescription'

export const DescriptionSection = observer(
  ({
    game,
    ball,
    lastBall,
    cancelEditBall,
    preBall,
    endOver,
    editBall,
    editBallProps,
    bowlerRunningIn,
    triggerEndOver,
    deadBall,
    commentaryChanged,
    setCommentaryChanged,
    timelineEvents,
    isDisabled,
    closedInning,
    cancelledDismissal,
    insertingBall,
  }: DescriptionSectionProps) => {
    const { appSettings, balls }: { appSettings: ISettingsModel; balls: IBallStore } = useMst()
    const matchSettings = appSettings.getMatchSettings(game.id)

    const getBallSubheading = () => {
      if (insertingBall) return 'Insert Ball'
      if (editBall) return 'Edit Ball'
      if (preBall && !isDisabled) return 'Pre-ball'
      return 'Current Ball'
    }

    return (
      <Flex paddingBottom="7px">
        <Flex flex={1.75} direction="column">
          <Flex marginBottom="7px">
            {(!closedInning || (closedInning && editBall)) && (
              <SubHeading
                text={`${getBallSubheading()} (over ${editBall ? editBall.overNumber : ball.overNumber}.${
                  editBall ? editBall.ballDisplayNumber : ball.ballDisplayNumber
                })`}
                secondary
                bold
              />
            )}
            {closedInning && !editBall && <SubHeading text="End of Innings" secondary bold />}
          </Flex>
          <Flex flex={2} direction="row">
            <Flex flex={1.5}>
              {preBall && !closedInning && (
                <Button
                  w="100%"
                  h="100%"
                  colorScheme="cls.blue"
                  onClick={bowlerRunningIn}
                  isDisabled={isDisabled}
                  data-testid="bowlerRunningInButton"
                >
                  Bowler Running In
                </Button>
              )}
              {!preBall && (editBall || insertingBall) && (
                <Button
                  w="100%"
                  h="100%"
                  colorScheme="gray"
                  onClick={() => {
                    if (cancelEditBall) cancelEditBall(endOver)
                  }}
                  data-testid="cancelEditBallButton"
                >
                  Cancel
                </Button>
              )}
              {!preBall && !editBall && !insertingBall && (
                <Button
                  w="100%"
                  h="100%"
                  colorScheme="gray"
                  onClick={deadBall}
                  isDisabled={cancelledDismissal}
                  data-testid="deadBallButton"
                >
                  Dead Ball
                </Button>
              )}
              {closedInning && !editBall && !insertingBall && <Box h="38px" />}
            </Flex>
            {preBall &&
              ball &&
              ball.ballDisplayNumber &&
              ball.ballDisplayNumber > (game.matchConfigs.ballsPerOver || 6) && (
                <Flex flex={0.5} paddingLeft="7px">
                  <Button
                    w="100%"
                    h="100%"
                    colorScheme="cls.gray"
                    onClick={triggerEndOver}
                    isDisabled={isDisabled}
                    data-testid="button-manual-end-over"
                  >
                    End Over
                  </Button>
                </Flex>
              )}
          </Flex>
        </Flex>
        <Box flex={3.5} paddingX="28px">
          {(!closedInning || (closedInning && editBall)) && (
            <BallDescription
              mode="main"
              ball={ball}
              game={game}
              lastBall={lastBall}
              editBall={editBall ? true : false}
              isNewestBall={editBallProps?.isNewestBall}
              isNewestOver={editBallProps?.isNewestOver}
              preBall={preBall}
              timelineEvents={timelineEvents}
              isDisabled={isDisabled}
              ballStore={balls}
            />
          )}
        </Box>
        <Box flex={4.75}>
          {(!closedInning || (closedInning && editBall)) && (
            <BallSummary
              mode="main"
              game={game}
              ball={ball}
              lastBall={editBall ? ball.getBallBefore(ball) : lastBall}
              enabled={!!matchSettings.ballPreview}
              editBall={editBall ? true : false}
              commentaryChanged={commentaryChanged}
              setCommentaryChanged={setCommentaryChanged}
              isDisabled={isDisabled}
              detailed
            />
          )}
        </Box>
      </Flex>
    )
  }
)
