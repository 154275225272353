import { Box, Flex } from '@chakra-ui/react'
import type { ICoordinateModel, IFieldingPlayerModel } from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { concat, each, find, last, orderBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import FieldingBasicsFielded from '../../components/AdvancedScoring/Fielding/FieldingBasicsFielded'
import FieldingMiscOverthrows from '../../components/AdvancedScoring/Fielding/FieldingMiscOverthrows'
import Trajectory from '../../components/AdvancedScoring/Trajectory'
import { Button } from '../../components/Buttons/Button'
import { SimpleStateButton } from '../../components/Buttons/SimpleStateButton'
import { FieldingPlacements } from '../../components/Field/FieldingPlacements'
import SubHeading from '../../components/Headings/SubHeading'
import { FieldingPositions } from '../../data/reference'
import Theme from '../../theme/theme'
import type { InBallProps, PositionParamsProps } from '../../types/props'

const InBallFielding = observer(
  ({
    ball,
    game,
    editBall,
    mode,
    deadBall,
    resyncBall,
    completeBall,
    cancelEditBall,
    fieldHasChanged,
    setFieldHasChanged,
    triggerNewFielder,
  }: InBallProps) => {
    const allFielders: IFieldingPlayerModel[] =
      orderBy(ball.fieldingAnalysis?.fieldingPlayers, ['order'], ['asc']) || []
    const [noShot, setNoShot] = useState(
      editBall && !ball.battingAnalysis?.shots.wagonWheel && ball.fieldingAnalysis?.fieldedWicketKeeper ? true : false
    )
    const [nonFieldedWicket, setNonFieldedWicket] = useState(
      (!editBall && ball.pendingDismissal && allFielders.length === 0) || (editBall && ball.pendingDismissal)
        ? true
        : false
    )
    const [noFielder, setNoFielder] = useState(
      editBall &&
        (!ball.fieldingAnalysis?.fieldingPlayers || (ball.fieldingAnalysis?.fieldingPlayers?.length || 0) === 0)
        ? true
        : false
    )
    const [wagonWheeling, setWagonWheeling] = useState(false)
    const [resetCoordinates, setResetCoordinates] = useState<ICoordinateModel | null>(null)

    const inBallBasicsEventsDone = () => {
      return nonFieldedWicket ||
        ball.fieldingAnalysis?.fielded ||
        ball.battingAnalysis?.shots.throughField ||
        ball.battingAnalysis?.shots.inTheAir
        ? true
        : false
    }
    const inBallShotLocationDone = () => {
      return noShot || nonFieldedWicket || ball.battingAnalysis?.shots.wagonWheel ? true : false
    }
    const inBallFielderInfoDone = () => {
      return nonFieldedWicket ||
        noFielder ||
        (ball.fieldingAnalysis?.fieldingPlayers && ball.fieldingAnalysis.fieldingPlayers.length > 0)
        ? true
        : false
    }

    const handleWicketOpportunity = () => {
      if (!ball) return
      ball.fieldingAnalysis?.setWicketOpportunity()
    }

    const handleNoShot = () => {
      if (!noShot) {
        // setting no shot ON
        ball.setFieldedWicketkeeper(true)
        ball.setFielded(true)
        handleFieldedWicketkeeper(game.getBowlingTeam()?.getWicketkeeper?.id)
        if (ball.battingAnalysis?.shots.wagonWheel) ball.battingAnalysis.shots.updateWagonWheel()
        if (ball.battingAnalysis?.shots.throughField) ball.setThroughField()
        if (ball.battingAnalysis?.shots.inTheAir) ball.setInTheAir()
        setNoShot(true)
        setWagonWheeling(false)
      } else {
        // setting no shot OFF
        setNoShot(false)
        if (!ball.battingAnalysis?.shots.wagonWheel) setWagonWheeling(true)
      }
    }

    const handleNonFieldedWicket = () => {
      if (!nonFieldedWicket) {
        // setting non fielded wicket ON
        ball.togglePendingDismissal()
        if (ball.battingAnalysis?.shots.wagonWheel) ball.battingAnalysis.shots.updateWagonWheel()
        if (ball.battingAnalysis?.shots.throughField) ball.setThroughField()
        if (ball.battingAnalysis?.shots.inTheAir) ball.setInTheAir()
        setNonFieldedWicket(true)
        setNoFielder(true)
        setWagonWheeling(false)
      } else {
        // setting non fielded wicket OFF
        ball.togglePendingDismissal()
        setNonFieldedWicket(false)
        setNoFielder(false)
        if (!ball.battingAnalysis?.shots.wagonWheel) setWagonWheeling(true)
      }
    }

    const handleNoFielder = () => {
      setNoFielder(!noFielder)
    }

    const handleCaught = ({ value }: { value?: boolean | null }) => {
      if (ball.fieldingAnalysis?.runOut) ball.fieldingAnalysis.setRunOut()
      if (ball.fieldingAnalysis?.runOutAssist) ball.fieldingAnalysis.setRunOutAssist()
      if (ball.fieldingAnalysis?.stumping) ball.fieldingAnalysis.setStumping()
      if (!ball.battingAnalysis?.shots.inTheAir) ball.setInTheAir()
      ball.fieldingAnalysis?.setCatch()
      if (
        !ball.fieldingAnalysis?.wicketOpportunity ||
        (!ball.fieldingAnalysis.catch && ball.fieldingAnalysis?.wicketOpportunity)
      ) {
        ball.fieldingAnalysis?.setWicketOpportunity()
      }
      if (allFielders && allFielders.length > 0) {
        const lastFielder = last(orderBy(allFielders, ['order'], ['asc']))
        lastFielder?.setCatch(!value ? true : false)
      }
    }

    const handleStumped = ({ value }: { value?: boolean | null }) => {
      if (ball.fieldingAnalysis?.runOut) ball.fieldingAnalysis.setRunOut()
      if (ball.fieldingAnalysis?.runOutAssist) ball.fieldingAnalysis.setRunOutAssist()
      if (ball.fieldingAnalysis?.catch) ball.fieldingAnalysis.setCatch()
      ball.fieldingAnalysis?.setStumping()
      if (
        !ball.fieldingAnalysis?.wicketOpportunity ||
        (!ball.fieldingAnalysis.stumping && ball.fieldingAnalysis?.wicketOpportunity)
      )
        ball.fieldingAnalysis?.setWicketOpportunity()
      if (allFielders && allFielders.length > 0) {
        each(allFielders, (f: IFieldingPlayerModel) => f.setStumping(!value ? true : false))
      }
    }

    const handleRunOut = () => {
      if (ball.fieldingAnalysis?.catch) ball.fieldingAnalysis.setCatch()
      if (ball.fieldingAnalysis?.stumping) ball.fieldingAnalysis.setStumping()
      ball.fieldingAnalysis?.setRunOut()
      if (
        !ball.fieldingAnalysis?.wicketOpportunity ||
        (!ball.fieldingAnalysis.runOut && ball.fieldingAnalysis?.wicketOpportunity)
      )
        ball.fieldingAnalysis?.setWicketOpportunity()
      if (allFielders && allFielders.length > 0) {
        handleRunOutRoles()
      }
    }

    const handleRunOutRoles = (fielderObject?: IFieldingPlayerModel) => {
      const allFieldersRoles = orderBy(
        fielderObject ? concat(allFielders, [fielderObject]) : allFielders,
        ['order'],
        ['asc']
      )
      const lastFielder = last(allFieldersRoles)
      let hasPrimary = -1
      each(allFieldersRoles, (f: IFieldingPlayerModel, idx: number) => {
        // fielder is primary on the run out if:
        //  a) they are the only fielder on the ball
        //  b) they are the fielder who hit the stumps, and were the last fielder (in order) on the ball
        //  c) they did not hit the stumps, another fielder was ordered after them and they too didn't hit the stumps
        const isPrimary =
          (f.order === 1 && allFieldersRoles.length === 1) ||
          (f.throwHitStumps?.value && f.order === allFieldersRoles.length) ||
          (!f.throwHitStumps?.value && f.order === allFieldersRoles.length - 1 && !lastFielder?.throwHitStumps?.value)
        if (isPrimary) {
          f.setRunOut(true)
          f.setRunOutAssist(false)
          hasPrimary = idx
        } else if (!isPrimary && hasPrimary > -1 && idx > hasPrimary) {
          f.setRunOut(false)
          f.setRunOutAssist(true)
        } else {
          f.setRunOut(false)
          f.setRunOutAssist(false)
        }
      })
    }

    const handleCreateFielder = (params: PositionParamsProps, firedByDifficultyRating: boolean) => {
      const fielderObject: IFieldingPlayerModel | undefined = ball.setFielder(
        params.playerMpId,
        false,
        params.fieldingPositionId && FieldingPositions[params.fieldingPositionId] === 'WICKET_KEEPER' ? true : false,
        false,
        (ball.fieldingAnalysis?.fieldingPlayers?.length || 0) + 1,
        true,
        firedByDifficultyRating ? undefined : params.difficultyRating || 1
      )
      if (fielderObject) {
        setNoFielder(false)
        if (nonFieldedWicket) handleNonFieldedWicket()
        if (
          ball.fieldingAnalysis &&
          ball.fieldingAnalysis.catch &&
          ball.fieldingAnalysis.fieldingPlayers &&
          fielderObject.order ===
            ball.fieldingAnalysis.fieldingPlayers[ball.fieldingAnalysis.fieldingPlayers.length - 1].order
        ) {
          fielderObject.setCatch(true)
          if (fielderObject.order > 1) {
            // if catch was previously assigned to a different fielder, de-assign it from them now
            find(ball.fieldingAnalysis.fieldingPlayers, (p: IFieldingPlayerModel) => {
              return p.order === fielderObject.order - 1
            })?.setCatch(false)
          }
        }
        if (ball.fieldingAnalysis?.stumping && fielderObject?.order === 1) fielderObject.setStumping(true)
        if (ball.fieldingAnalysis?.runOut) {
          handleRunOutRoles(fielderObject)
        }
      }
      return fielderObject
    }

    const handleFieldedWicketkeeper = (value: string | undefined) => {
      if (!value) return
      handleCreateFielder(
        {
          playerMpId: value,
          fieldingPositionId: FieldingPositions.findIndex((fp: string) => fp === 'WICKET_KEEPER'),
          difficultyRating: 1,
        },
        false
      )
    }

    const handleResetWagonWheelCoordinates = () => {
      if (!resetCoordinates) return
      ball.battingAnalysis?.shots.updateWagonWheel(resetCoordinates)
    }

    return (
      <Flex flex={1} w="100%" h="100%" direction={['column', 'column', 'row', 'row']}>
        <Flex flex={1} direction="row" w="100%">
          <Flex flex={1} maxW="210px" direction="column" border={`solid 1px ${Theme.colors.gray['300']}`} padding="7px">
            <Box w="100%" h="100%" position="relative">
              <SubHeading text={`Over ${ball.overNumber + 1 || 1} - Ball ${ball.ballNumber}`} />
              {/* BASICS */}
              <SubHeading text="Basics" tertiary />
              <Box w="100%" paddingY="4px">
                <SimpleStateButton
                  value={noShot}
                  onClick={handleNoShot}
                  isDisabled={ball.battingAnalysis?.shots.wagonWheel ? true : false}
                  isActive={noShot}
                  width="100%"
                  hideCheck
                  isWhite
                  data-testid="noShotOrMissed"
                >
                  No Shot / Missed
                </SimpleStateButton>
              </Box>
              <Box w="100%" paddingY="4px">
                <Trajectory mode={mode} ball={ball} noShot={noShot} />
              </Box>
              <Box w="100%" padding="14px 0 4px">
                <SimpleStateButton
                  value={noFielder}
                  onClick={handleNoFielder}
                  isDisabled={
                    ball.fieldingAnalysis?.fielded ||
                    ball.fieldingAnalysis?.fieldedWicketKeeper ||
                    (ball.fieldingAnalysis?.fieldingPlayers?.length || 0) > 0
                      ? true
                      : false
                  }
                  isActive={noFielder}
                  width="100%"
                  hideCheck
                  isWhite
                  data-testid="notFielded"
                >
                  Not Fielded
                </SimpleStateButton>
              </Box>
              <Box w="100%" paddingY="4px">
                <FieldingBasicsFielded
                  mode={mode}
                  ball={ball}
                  fielders={game.getBowlingTeam()?.matchPlayers}
                  changeFirstFielder={handleFieldedWicketkeeper}
                  disabled={noFielder}
                />
              </Box>
              {/* EVENTS */}
              <Box w="100%" paddingTop="4px">
                <SubHeading text="Events" tertiary />
              </Box>
              <Box w="100%" paddingY="4px">
                <SimpleStateButton
                  value={ball.fieldingAnalysis?.wicketOpportunity}
                  onClick={handleWicketOpportunity}
                  isActive={ball.fieldingAnalysis?.wicketOpportunity}
                  isDisabled={
                    ball.fieldingAnalysis?.catch || ball.fieldingAnalysis?.runOut || ball.fieldingAnalysis?.stumping
                      ? true
                      : false
                  }
                  width="100%"
                  hideCheck
                  isWhite
                  data-testid="wicketOpportunityEvent"
                >
                  Wicket Opportunity
                </SimpleStateButton>
              </Box>
              <Box w="100%" paddingY="4px">
                <SimpleStateButton
                  target="catch"
                  value={ball.fieldingAnalysis?.catch}
                  onClick={handleCaught}
                  isActive={ball.fieldingAnalysis?.catch ? true : false}
                  width="100%"
                  hideCheck
                  isWhite
                  data-testid="caughtEvent"
                >
                  Caught
                </SimpleStateButton>
              </Box>
              <Box w="100%" paddingY="4px">
                <SimpleStateButton
                  target="runOut"
                  value={ball.fieldingAnalysis?.runOut}
                  onClick={handleRunOut}
                  isActive={ball.fieldingAnalysis?.runOut ? true : false}
                  width="100%"
                  hideCheck
                  isWhite
                  data-testid="runOutEvent"
                >
                  Run Out
                </SimpleStateButton>
              </Box>
              <Box w="100%" paddingY="4px">
                <SimpleStateButton
                  target="stumping"
                  value={ball.fieldingAnalysis?.stumping}
                  onClick={handleStumped}
                  isActive={ball.fieldingAnalysis?.stumping ? true : false}
                  width="100%"
                  hideCheck
                  isWhite
                  data-testid="stumpedEvent"
                >
                  Stumped
                </SimpleStateButton>
              </Box>
              <Box w="100%" paddingY="4px">
                <SimpleStateButton
                  value={nonFieldedWicket}
                  onClick={handleNonFieldedWicket}
                  isDisabled={allFielders.length > 0 || ball.battingAnalysis?.shots.wagonWheel ? true : false}
                  isActive={nonFieldedWicket}
                  width="100%"
                  hideCheck
                  isWhite
                  data-testid="nonFieldedWicketEvent"
                >
                  Non-fielded wicket
                </SimpleStateButton>
              </Box>
              {/* OVERTHROWS */}
              <Box w="100%" paddingTop="10px">
                <SubHeading text="Overthrows" tertiary />
              </Box>
              <Box w="100%" paddingY="4px">
                <FieldingMiscOverthrows mode={mode} ball={ball} editBall={editBall ? true : false} />
              </Box>
            </Box>
            <Box w="100%" h="80px" position="sticky" bottom="0%" left="0%" padding="14px 0 0">
              <Flex flex={1} w="100%" h="100%" direction="row">
                <Flex h="100%" flex={1}>
                  <Button
                    w="100%"
                    h="100%"
                    colorScheme="cls.gray"
                    onClick={event => {
                      if (resetCoordinates) handleResetWagonWheelCoordinates()
                      if (deadBall) deadBall(event)
                    }}
                    data-testid="cancelBallButton"
                  >
                    Cancel
                  </Button>
                </Flex>
                {editBall && resyncBall && (
                  <Flex flex={1} paddingLeft="4px">
                    <Button
                      w="100%"
                      h="100%"
                      colorScheme="teal"
                      onClick={() => {
                        if (resyncBall) resyncBall()
                      }}
                      data-testid="resyncBallDataButton"
                    >
                      <FontAwesomeIcon icon={['far', 'cloud-upload']} size="sm" style={{ fontSize: '14px' }} />
                    </Button>
                  </Flex>
                )}
                <Flex paddingLeft="4px" h="100%" flex={2}>
                  <Button
                    onClick={() => {
                      if (resetCoordinates) handleResetWagonWheelCoordinates()
                      completeBall(
                        false,
                        inBallBasicsEventsDone() && inBallShotLocationDone() && inBallFielderInfoDone() ? false : true
                      )
                    }}
                    w="100%"
                    h="100%"
                    colorScheme="cls.blue"
                    data-testid="completeBallorEditBallButton"
                  >
                    {editBall ? 'Done' : 'Confirm All'}
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </Flex>
          <Flex flex={4} minW="624px">
            <FieldingPlacements
              ball={ball}
              deadBall={deadBall}
              editBall={editBall ? true : false}
              cancelEditBall={cancelEditBall}
              fieldHasChanged={fieldHasChanged}
              setFieldHasChanged={setFieldHasChanged}
              handleCreateFielder={handleCreateFielder}
              handleRunOutRoles={handleRunOutRoles}
              wagonWheeling={wagonWheeling}
              setWagonWheeling={setWagonWheeling}
              resetCoordinates={resetCoordinates}
              setResetCoordinates={setResetCoordinates}
              inBallBasicsEventsDone={inBallBasicsEventsDone}
              inBallShotLocationDone={inBallShotLocationDone}
              inBallFielderInfoDone={inBallFielderInfoDone}
              inBallNonFieldedWicket={nonFieldedWicket}
              isCurrentInnings={true}
              triggerNewFielder={triggerNewFielder}
              inBall
            />
          </Flex>
        </Flex>
      </Flex>
    )
  }
)

export default InBallFielding
