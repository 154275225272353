import { Box, Flex } from '@chakra-ui/react'
import type { IFieldingPlayerModel, IMatchPlayerModel } from '@clsplus/cls-plus-data-models'
import { orderBy } from 'lodash'
import { observer } from 'mobx-react-lite'

import { db } from '../../../data/dexie/Database'
import S3PHelpers from '../../../helpers/s3pHelpers'
import type { DropdownCallbackArgs } from '../../../types'
import type { FieldingBasicsProps } from '../../../types/props'
import Dropdown from '../../Dropdown/Dropdown'
import FieldingBasicsFielded from './FieldingBasicsFielded'

const FieldingBasics = observer(({ ball, game, editBall, mode = 'main', isDisabled = false }: FieldingBasicsProps) => {
  const fielders: IMatchPlayerModel[] | undefined = game
    .getBowlingTeam(ball.getInningsId)
    ?.matchPlayers.filter(player => {
      return !player.getActiveReason?.endsWith('_OFF')
    })
  let fieldersHaveShirtNumbers = 0
  let fieldersOptions = fielders?.map((fielder: IMatchPlayerModel) => {
    if (fielder.shirtNumber !== null) fieldersHaveShirtNumbers++
    return {
      value: fielder.id,
      sortShirt: fielder.shirtNumber,
      sortName: `${
        fielder.player.person?.lastName ||
        fielder.player.person?.cardNameS ||
        fielder.player.person?.cardNameF ||
        fielder.getDisplayName()
      }, ${fielder.player.person?.firstName || ''}`,
      label: `${fielder.getDisplayName()}${fielder.getShirtNumber(true)}${fielder.wicketKeeper ? ' (WK)' : ''}${
        fielder.substitute && !fielder.getActiveReason?.endsWith('_ON') ? ' (sub)' : ''
      }`,
    }
  })
  fieldersOptions = orderBy(
    fieldersOptions,
    [fielders && fieldersHaveShirtNumbers >= fielders.length ? 'sortShirt' : 'sortName'],
    ['asc']
  )
  const firstFielder: IFieldingPlayerModel | undefined = ball.fieldingAnalysis?.fieldingPlayers?.find(
    (player: IFieldingPlayerModel) => player.order === 1
  )
  const secondFielder: IFieldingPlayerModel | undefined = ball.fieldingAnalysis?.fieldingPlayers?.find(
    (player: IFieldingPlayerModel) => player.order === 2
  )

  const changeFirstFielder = (value: string | undefined, clearValue?: boolean) => {
    if (!ball) return
    if (clearValue) {
      ball.setFielder(undefined, true, false, true)
    } else {
      ball.setFielder(
        fielders?.find(fielder => fielder.id === value),
        true,
        fielders?.find(fielder => fielder.id === value)?.wicketKeeper ? true : false,
        clearValue
      )
    }
    if (mode === 'betting') changeFielderDismissal()
  }
  const changeSecondFielder = ({ value, clearValue }: DropdownCallbackArgs) => {
    if (!ball) return
    ball.setFielder(
      fielders?.find(fielder => fielder.id === value),
      false,
      fielders?.find(fielder => fielder.id === value)?.wicketKeeper ? true : false,
      clearValue
    )
    if (mode === 'betting') changeFielderDismissal()
  }
  const changeFielderDismissal = () => {
    const activeInning = game.getActiveInning()
    if (editBall && ball.dismissal && activeInning) {
      const dismissalType = ball.dismissal.getDismissalType
      db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.dismissal(game, dismissalType, undefined, ball))
    }
  }

  return (
    <Flex w="100%" flexDirection="row">
      {mode === 'main' && (
        <Flex flexDirection="column">
          <FieldingBasicsFielded ball={ball} fielders={fielders} changeFirstFielder={changeFirstFielder} />
        </Flex>
      )}
      <Flex w="100%" flexDirection="column" marginLeft={mode === 'main' ? '7px' : '0px'}>
        {fieldersOptions && (
          <>
            <Box margin={mode === 'main' ? '0px 0px 7px' : '7px 0px 14px'}>
              <Dropdown
                target="players"
                options={fieldersOptions}
                origValue="Fielder"
                value={firstFielder?.playerMp ? `${firstFielder.playerMp.getDisplayName()}` : 'Fielder'}
                onChange={({ value, clearValue }) => changeFirstFielder(value, clearValue)}
                buttonDisable={isDisabled}
                hideCheck
                isClearable
                preserveCase
                data-testid="firstFielderDropdown"
              />
            </Box>
            <Box marginBottom={mode === 'main' ? '7px' : '14px'}>
              <Dropdown
                target="players"
                options={fieldersOptions}
                origValue="2nd Fielder"
                value={secondFielder?.playerMp ? `${secondFielder.playerMp.getDisplayName()}` : '2nd Fielder'}
                buttonDisable={isDisabled}
                onChange={changeSecondFielder}
                hideCheck
                isClearable
                preserveCase
                data-testid="secondFielderDropdown"
              />
            </Box>
          </>
        )}
      </Flex>
    </Flex>
  )
})

export default FieldingBasics
