import { Box, Flex, Text } from '@chakra-ui/react'
import type {
  IBowlingPerformanceModel,
  IBowlingPerformanceSpellModel,
  IMatchPlayerModel,
} from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { filter, orderBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import type { Column, Row } from 'react-table'
import { useTable } from 'react-table'

import EditableField from '../../components/EditableField/EditableField'
import Theme from '../../theme/theme'
import type { BowlingProps } from '../../types/props'
import { Button } from '../Buttons/Button'

type BowlingRow = {
  player: IMatchPlayerModel
  performance: IBowlingPerformanceModel | undefined
  firstPerformanceOrder: number | null
}

type BowlingSpellRow = {
  reference: string
  spell: IBowlingPerformanceSpellModel
  order: number
}

const BowlingSpell = ({
  bowlingPerformance,
  ballsPerOver,
  triggerInningsState,
}: {
  bowlingPerformance: IBowlingPerformanceModel
  ballsPerOver: number
  triggerInningsState: () => void
}) => {
  const columns: Column<BowlingSpellRow>[] = useMemo(
    () => [
      {
        id: 'spell',
        accessor: (row: BowlingSpellRow) => row.reference,
        Header: 'Spells',
        Cell: ({ row }: { row: Row<BowlingSpellRow> }) => {
          return <Text fontSize="14px">{row.original.reference}</Text>
        },
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell.overs,
        id: 'O',
        Cell: ({ row }: { row: Row<BowlingSpellRow> }) => {
          return (
            <EditableField
              data-testid={`bowlerSpellOvers_${bowlingPerformance.order}_${row.original.spell.instanceNumber}`}
              value={row.original.spell.overs ?? 0}
              width={'14'}
              onChange={(value: string) => {
                row.original.spell.setOvers(value)
                triggerInningsState()
              }}
              pattern="^\d{0,4}(?:\.[0-5]{1})?$"
              errorMessage="Please enter numbers, or decimals up to 1 digit"
            />
          )
        },
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell.maidens,
        id: 'M',
        Cell: ({ row }: { row: Row<BowlingSpellRow> }) => {
          return (
            <EditableField
              data-testid={`bowlerSpellMaidens_${bowlingPerformance.order}_${row.original.spell.instanceNumber}`}
              value={row.original.spell.maidens}
              type="number"
              width={'14'}
              onChange={(value: number) => {
                row.original.spell.setMaidens(value)
                triggerInningsState()
              }}
              pattern="^\d+$"
              errorMessage="Please enter numbers only"
            />
          )
        },
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell.runs,
        id: 'R',
        Cell: ({ row }: { row: Row<BowlingSpellRow> }) => {
          return (
            <EditableField
              data-testid={`bowlerSpellRuns_${bowlingPerformance.order}_${row.original.spell.instanceNumber}`}
              value={row.original.spell.runs}
              type="number"
              width={'14'}
              onChange={(value: number) => {
                row.original.spell.setRuns(value)
                triggerInningsState()
              }}
              pattern="^\d+$"
              errorMessage="Please enter numbers only"
            />
          )
        },
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell.wickets,
        id: 'W',
        Cell: ({ row }: { row: Row<BowlingSpellRow> }) => {
          return (
            <EditableField
              data-testid={`bowlerSpellWickets_${bowlingPerformance.order}_${row.original.spell.instanceNumber}`}
              value={row.original.spell.wickets ?? 0}
              type="number"
              width={'14'}
              onChange={(value: number) => {
                row.original.spell.setWickets(value)
                triggerInningsState()
              }}
              pattern="^\d+$"
              errorMessage="Please enter numbers only"
            />
          )
        },
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell.dots,
        id: '\u2022',
        Cell: ({ row }: { row: Row<BowlingSpellRow> }) => {
          return (
            <EditableField
              data-testid={`bowlerSpellDotBalls_${bowlingPerformance.order}_${row.original.spell.instanceNumber}`}
              value={row.original.spell.dots}
              type="number"
              width={'14'}
              onChange={(value: number) => {
                row.original.spell.setDots(value)
                triggerInningsState()
              }}
              pattern="^\d+$"
              errorMessage="Please enter numbers only"
            />
          )
        },
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell.econ(ballsPerOver),
        id: 'Econ',
        Cell: ({ row }: { row: Row<BowlingSpellRow> }) => {
          return (
            <Text
              width={'14'}
              margin="0 auto"
              data-testid={`bowlerSpellEcon_${bowlingPerformance.order}_${row.original.spell.instanceNumber}`}
            >
              {row.original.spell.econ(ballsPerOver) || 0}
            </Text>
          )
        },
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell.extras.wides,
        id: 'Wd',
        Cell: ({ row }: { row: Row<BowlingSpellRow> }) => {
          return (
            <EditableField
              data-testid={`bowlerSpellWides_${bowlingPerformance.order}_${row.original.spell.instanceNumber}`}
              value={row.original.spell.extras.wides}
              type="number"
              width={'14'}
              onChange={(value: number) => {
                row.original.spell.extras?.setWides(value)
                triggerInningsState()
              }}
              pattern="^\d+$"
              errorMessage="Please enter numbers only"
            />
          )
        },
      },
      {
        accessor: (row: BowlingSpellRow) => row.spell.extras.noBalls,
        id: 'NB',
        Cell: ({ row }: { row: Row<BowlingSpellRow> }) => {
          return (
            <EditableField
              data-testid={`bowlerSpellNoBalls_${bowlingPerformance.order}_${row.original.spell.instanceNumber}`}
              value={row.original.spell.extras.noBalls}
              type="number"
              width={'14'}
              onChange={(value: number) => {
                row.original.spell.extras?.setNoBalls(value)
                triggerInningsState()
              }}
              pattern="^\d+$"
              errorMessage="Please enter numbers only"
            />
          )
        },
      },
    ],
    [ballsPerOver, bowlingPerformance.order, triggerInningsState]
  )

  // player data
  const data: BowlingSpellRow[] = useMemo(() => {
    return orderBy(
      bowlingPerformance.bowlingPerformanceSpells?.map(spell => {
        return {
          reference: `Spell ${spell.start} \u279E ${spell.end || ''}`,
          spell,
          order: Number(`${spell.startOver}.${spell.startBall}`),
        }
      }),
      ['order'],
      ['asc']
    )
  }, [bowlingPerformance])

  const { getTableProps, getTableBodyProps, rows, prepareRow } = useTable({ columns, data })

  return (
    <table {...getTableProps()} style={{ width: '100%', backgroundColor: 'white' }}>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            // eslint-disable-next-line react/jsx-key
            <tr
              {...row.getRowProps()}
              style={{ borderTop: `solid 1px ${Theme.colors.cls.backgroundGray}` }}
              data-testid={`${bowlingPerformance.playerMp.id}_spell`}
            >
              {row.cells.map((cell, idx) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <td
                    {...cell.getCellProps()}
                    data-testid={`${bowlingPerformance.playerMp.id}_spell_${cell.column.id}`}
                    style={{
                      fontSize: '12px',
                      padding: idx === 0 ? '10px 10px 10px 40px' : '10px',
                      textAlign: idx === 0 ? 'left' : 'center',
                      flexGrow: idx === 0 ? 10 : 1,
                      minWidth: idx === 0 ? '235px' : undefined,
                    }}
                  >
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export const Bowling = observer(
  ({
    inning,
    game,
    scoreManually,
    ballsPerOver,
    triggerInningsState,
    setDeletePerf,
    setDeletePerfOpen,
  }: BowlingProps) => {
    const teamPlayers: IMatchPlayerModel[] | undefined = inning.getBowlingTeam?.matchPlayers
    const bowlingPerformances: IBowlingPerformanceModel[] = inning.bowlingPerformances

    // table columns
    const columns: Column<BowlingRow>[] = useMemo(
      () => [
        {
          id: 'bowlers',
          accessor: (row: BowlingRow) => row.player.getDisplayName(),
          flexGrow: 10,
          Header: (
            <Box>
              Bowling
              {!scoreManually && (
                <Box as="span" paddingLeft="10px" fontSize="14px">
                  {inning.bowlingReviewsRemaining} of {game.matchConfigs.maxBowlingReviewsPerInnings} reviews remaining
                </Box>
              )}
            </Box>
          ),
          Cell: ({ row }: { row: Row<BowlingRow> }) => {
            return (
              <Flex direction="row">
                {scoreManually && (
                  <Box marginRight="14px">
                    {!row.original.performance ? (
                      <Button
                        colorScheme="gray"
                        fontSize="xl"
                        isDisabled={
                          bowlingPerformances.find(
                            (p: IBowlingPerformanceModel) => p.playerMp.id === row.original.player.id
                          )
                            ? true
                            : false
                        }
                        onClick={() => inning.createNewBowlerPerformance(row.original.player)}
                        data-testid={`addBowler_${inning.inningsMatchOrder}_${row.original.player.id}`}
                      >
                        +
                      </Button>
                    ) : (
                      <Button
                        colorScheme="red"
                        fontSize="xl"
                        onClick={() => {
                          if (!setDeletePerfOpen || !setDeletePerf || !row.original.performance) return
                          if (row.original.performance.isEmpty) {
                            inning.removePerformance(row.original.performance)
                          } else {
                            setDeletePerfOpen(true)
                            setDeletePerf(row.original.performance)
                          }
                        }}
                        width={30}
                      >
                        <FontAwesomeIcon icon={['far', 'trash-alt']} size="xs" />
                      </Button>
                    )}
                  </Box>
                )}
                <Flex flex={1} alignItems="center">
                  <Text fontSize="xl">{row.original.player.getDisplayName()}</Text>
                </Flex>
              </Flex>
            )
          },
        },
        {
          accessor: (row: BowlingRow) => row.performance?.allOvers,
          Header: 'O',
          flexGrow: 1,
          Cell: ({ row }: { row: Row<BowlingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`bowlOvers${row.original.performance.id}`}
                data-testid={`bowlerOvers_${inning.inningsMatchOrder}_${row.original.performance.order}`}
                value={row.original.performance.allOvers}
                width={'14'}
                onChange={(value: string) => {
                  row.original.performance?.setOvers(value)
                  triggerInningsState()
                }}
                pattern="^\d{0,4}(?:\.[0-5]{1})?$"
                errorMessage="Please enter numbers, or decimals up to 1 digit"
              />
            )
          },
        },
        {
          accessor: (row: BowlingRow) => row.performance?.allMaidens,
          Header: 'M',
          flexGrow: 1,
          Cell: ({ row }: { row: Row<BowlingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`bowlMaidens${row.original.performance.id}`}
                data-testid={`bowlerMaidens_${inning.inningsMatchOrder}_${row.original.performance.order}`}
                value={row.original.performance.allMaidens}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.setMaidens(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BowlingRow) => row.performance?.allRuns,
          Header: 'R',
          flexGrow: 1,
          Cell: ({ row }: { row: Row<BowlingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`bowlRuns${row.original.performance.id}`}
                data-testid={`bowlerRuns_${inning.inningsMatchOrder}_${row.original.performance.order}`}
                value={row.original.performance.allRuns}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.setRuns(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BowlingRow) => row.performance?.allWickets,
          Header: 'W',
          flexGrow: 1,
          Cell: ({ row }: { row: Row<BowlingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`bowlWickets${row.original.performance.id}`}
                data-testid={`bowlerWickets_${inning.inningsMatchOrder}_${row.original.performance.order}`}
                value={row.original.performance.allWickets}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.setWickets(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BowlingRow) => row.performance?.allDotBalls,
          Header: '\u2022',
          flexGrow: 1,
          Cell: ({ row }: { row: Row<BowlingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`bowlDotBalls${row.original.performance.id}`}
                data-testid={`bowlerDotBalls_${inning.inningsMatchOrder}_${row.original.performance.order}`}
                value={row.original.performance.allDotBalls}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.setDots(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BowlingRow) => row.performance?.allEcon(ballsPerOver),
          Header: 'Econ',
          flexGrow: 1,
          Cell: ({ row }: { row: Row<BowlingRow> }) => {
            if (!row.original.performance) return null
            return (
              <Text
                width={'14'}
                margin="0 auto"
                data-testid={`bowlerEcon${inning.inningsMatchOrder}_${row.original.performance.order}`}
              >
                {row.original.performance.allEcon(ballsPerOver) || 0}
              </Text>
            )
          },
        },
        {
          accessor: (row: BowlingRow) => row.performance?.allWides,
          Header: 'Wd',
          flexGrow: 1,
          Cell: ({ row }: { row: Row<BowlingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`bowlWides${row.original.performance.id}`}
                data-testid={`bowlerWides_${inning.inningsMatchOrder}_${row.original.performance.order}`}
                value={row.original.performance.allWides}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.setWides(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BowlingRow) => row.performance?.allNoBalls,
          Header: 'NB',
          flexGrow: 1,
          Cell: ({ row }: { row: Row<BowlingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`bowlNoBalls${row.original.performance.id}`}
                data-testid={`bowlerNoBalls_${inning.inningsMatchOrder}_${row.original.performance.order}`}
                value={row.original.performance.allNoBalls}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.setNoBalls(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
      ],
      [
        ballsPerOver,
        game.matchConfigs.maxBowlingReviewsPerInnings,
        scoreManually,
        bowlingPerformances,
        inning,
        triggerInningsState,
        setDeletePerf,
        setDeletePerfOpen,
      ]
    )
    // player data
    const data: BowlingRow[] = filter(
      orderBy(
        teamPlayers?.map(p => {
          const perf = bowlingPerformances.find(b => b.playerMp.id === p.id)
          return {
            player: p,
            performance: perf,
            firstPerformanceOrder: perf ? perf.order : null,
          }
        }),
        ['firstPerformanceOrder'],
        ['asc']
      ),
      p => (scoreManually ? true : p.firstPerformanceOrder !== null)
    )
    const performanceCount = data.filter(d => d.performance !== undefined)?.length || 0

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

    const moveRow = (index: number, dir: string) => {
      const perfUp = bowlingPerformances.find(b => (dir === 'up' ? b.order === index : b.order === index + 1))
      const perfDown = bowlingPerformances.find(b => (dir === 'down' ? b.order === index : b.order === index - 1))
      perfUp?.setOrder(perfUp.order - 1)
      perfDown?.setOrder(perfDown.order + 1)
    }

    return (
      <table {...getTableProps()} style={{ width: '100%', backgroundColor: 'white' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()} style={{ backgroundColor: Theme.colors.cls.backgroundGray }}>
              {scoreManually && <th style={{ flexGrow: 1, width: '120px' }}></th>}
              {headerGroup.headers.map((column, idx) => (
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps()}
                  style={{
                    // @ts-ignore
                    flexGrow: column.flexGrow || 1,
                    padding: scoreManually ? '10px 0' : '10px',
                    fontWeight: 'normal',
                    fontSize: idx === 0 ? '20px' : '16px',
                    fontStyle: 'italic',
                    textAlign: idx === 0 ? 'left' : 'center',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row)
            return (
              <React.Fragment key={row.id}>
                <tr
                  {...row.getRowProps()}
                  style={{ borderTop: `solid ${scoreManually ? '1px' : '3px'} ${Theme.colors.cls.backgroundGray}` }}
                >
                  {scoreManually && (
                    <td style={{ textAlign: 'center' }}>
                      {index < performanceCount && (
                        <>
                          {index > 0 && (
                            <Button
                              onClick={() => moveRow(index + 1, 'up')}
                              marginX="2px"
                              size="xs"
                              data-testid="moveRowUpButton"
                            >
                              ▲
                            </Button>
                          )}
                          {index + 1 < performanceCount && (
                            <Button
                              onClick={() => moveRow(index + 1, 'down')}
                              marginX="2px"
                              size="xs"
                              data-testid="moveRowDownButton"
                            >
                              ▼
                            </Button>
                          )}
                        </>
                      )}
                    </td>
                  )}
                  {row.cells.map((cell, idx) => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: scoreManually ? '10px 0' : '10px',
                          textAlign: idx === 0 ? 'left' : 'center',
                          fontSize: !scoreManually ? '20px' : undefined,
                          minWidth: idx === 0 ? '235px' : undefined,
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
                {!scoreManually && row.original.performance && (
                  <tr style={{ width: '100%' }}>
                    <td colSpan={9}>
                      <BowlingSpell
                        bowlingPerformance={row.original.performance}
                        ballsPerOver={ballsPerOver}
                        triggerInningsState={triggerInningsState}
                      />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    )
  }
)
