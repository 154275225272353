//  State Tree Models
import type {
  IBallInningModel,
  IBallModel,
  IBattingPerformanceModel,
  IBowlingPerformanceModel,
  IFieldingPlacementModel,
  IFieldingPlayerModel,
  IMatchOfficialModel,
  IMatchTeamModel,
  IVenueEndModel,
} from '@clsplus/cls-plus-data-models'
import { BallInningModel, BallModel } from '@clsplus/cls-plus-data-models'
import {
  each,
  filter,
  find,
  findIndex,
  findLastIndex,
  flatten,
  includes,
  indexOf,
  isNil,
  last,
  orderBy,
  sortBy,
  startsWith,
} from 'lodash'
import type { SnapshotOrInstance } from 'mobx-state-tree'
import { destroy, detach, flow, getRoot, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'

import Auth from '../../../../helpers/auth'
import BallHelpers from '../../../../helpers/ballHelpers'
import { ballDataCleaner, overIdPatcher } from '../../../../helpers/dataHelpers'
import type { IClspMatchModel, IMatchSettingsModel } from '../../../../types/models'
import { RequestHandler } from '../../../api/RequestHandler'
import { db } from '../../../dexie/Database'
import { DismissalMethods, HandedTypeOptions, NewBallTypes } from '../../../reference'
import type { IRootStore } from '../../rootStore'

interface InsertBallProps {
  overNumber: number
  ballNumber: number
}

const BallStore = types
  .model('BallStore', {
    results: types.map(BallInningModel),
    activeRequests: types.array(types.string),
  })
  .views(self => {
    const confirmedBallsInOrder = (key: string, order?: 'asc' | 'desc'): IBallModel[] => {
      const ballMap = self.results.get(key)
      if (ballMap) {
        return orderBy(
          ballMap.balls.filter((b: IBallModel) => b.confirmed),
          ['overNumber', 'ballNumber'],
          [order || 'desc', order || 'desc']
        )
      }
      return []
    }
    const lastBall = (key: string): IBallModel | undefined => {
      const balls: IBallModel[] | undefined = self.results.get(key)?.balls
      if (!balls) return
      return last(
        orderBy(
          balls.filter(ball => ball.confirmed),
          ['overNumber', 'ballNumber'],
          ['asc', 'asc']
        )
      )
    }
    const getBall = (
      key: string,
      overNumber: number,
      ballNumber: number,
      includeUnconfirmed?: boolean
    ): IBallModel | undefined => {
      const balls = self.results.get(key)?.balls
      if (!balls) return
      return find(
        balls,
        includeUnconfirmed
          ? {
              overNumber: overNumber,
              ballNumber: ballNumber,
            }
          : {
              overNumber: overNumber,
              ballNumber: ballNumber,
              confirmed: true,
            }
      )
    }
    const getBallsAfter = (
      key: string,
      overNumber: number,
      ballNumber: number,
      overOnly: boolean,
      inclusive?: boolean,
      includeUnconfirmedBalls = false
    ): IBallModel[] | undefined => {
      const balls = self.results.get(key)?.balls
      if (!balls) return
      return filter(balls, ball => {
        return (
          (includeUnconfirmedBalls || (!includeUnconfirmedBalls && ball.confirmed)) &&
          ((ball.overNumber === overNumber &&
            (ball.ballNumber > ballNumber || (ball.ballNumber === ballNumber && inclusive))) ||
            (!overOnly && ball.overNumber > overNumber))
        )
      })
    }
    // does NOT include the from ball or TO ball
    const getBallsBetween = (
      key: string,
      fromOverNumber: number,
      fromBallNumber: number,
      toOverNumber: number,
      toBallNumber: number
    ): IBallModel[] | undefined => {
      const balls = self.results.get(key)?.balls
      if (!balls) return
      return filter(balls, ball => {
        return (
          ball.confirmed &&
          ball.overNumber >= fromOverNumber &&
          ball.ballNumber > fromBallNumber &&
          ball.overNumber <= toOverNumber &&
          ball.ballNumber < toBallNumber
        )
      })
    }
    const getBallsFromPerf = (
      key: string,
      type: string,
      perf: IBattingPerformanceModel | IBowlingPerformanceModel | undefined,
      overNumber?: number | undefined
    ): IBallModel[] | undefined => {
      if (perf) {
        const balls = self.results.get(key)?.balls
        if (!balls) return
        return filter(balls, ball => {
          return (
            ball.confirmed &&
            (overNumber === undefined || overNumber === ball.overNumber) &&
            ((type === 'bat' &&
              ball.batterMp?.id === perf.playerMp.id &&
              (!ball.batterInstance || ball.batterInstance === perf.latestInstanceNumber)) ||
              (type === 'bat' &&
                ball.batterNonStrikeMp?.id === perf.playerMp.id &&
                (!ball.batterNonStrikeInstance || ball.batterNonStrikeInstance === perf.latestInstanceNumber)) ||
              (type === 'bowl' &&
                ball.bowlerMp?.id === perf.playerMp.id &&
                (!ball.bowlerInstance || ball.bowlerInstance === perf.latestInstanceNumber)))
          )
        })
      }
    }
    const getIndexOfWickets = (key: string, includeRetirements = false): IBallModel[] | undefined => {
      const balls = self.results.get(key)?.balls
      if (!balls) return
      return orderBy(
        balls.filter((b: IBallModel) => {
          if (!includeRetirements) {
            return (
              b.dismissal &&
              b.dismissal?.dismissalTypeId !== null &&
              b.dismissal?.dismissalTypeId !== undefined &&
              !startsWith(DismissalMethods[b.dismissal.dismissalTypeId], 'RETIRED_')
            )
          } else {
            return b.dismissal
          }
        }),
        ['overNumber', 'ballNumber'],
        ['asc', 'asc']
      )
    }
    const getWicketBallOfWicketNumber = (key: string, wicket: number): IBallModel | undefined => {
      const wickets = getIndexOfWickets(key)
      if (!wickets) return
      if (wickets[wicket - 1]) return wickets[wicket - 1]
      return
    }
    const getNewestBall = (key: string, allowUnconfirmed?: boolean): IBallModel | undefined => {
      const balls = self.results.get(key)?.balls
      if (!balls) return
      const ballsOrdered = sortBy(balls, ['overNumber', 'ballNumber'], ['asc', 'asc']) // added to ensure parity when using Insert Ball func.
      let lastIdx
      if (allowUnconfirmed) {
        lastIdx = findLastIndex(ballsOrdered)
      } else {
        lastIdx = findLastIndex(ballsOrdered, (ball: IBallModel) => ball.confirmed)
      }
      return balls[lastIdx]
    }
    const getFirstBall = (key: string): IBallModel | undefined => {
      const balls = self.results.get(key)?.balls
      if (!balls) return
      return find(balls, {
        overNumber: 0,
        ballNumber: 1,
      })
    }
    const getBallsForBowler = (key: string, playerId: string, includeUncomfirmed = false): IBallModel[] | undefined => {
      const balls = self.results.get(key)?.balls
      if (!balls) return
      return filter(balls, ball => {
        if (includeUncomfirmed) {
          return ball.bowlerMp?.id === playerId
        } else {
          return ball.bowlerMp?.id === playerId && ball.confirmed
        }
      })
    }
    const getBallsForBowlerSpell = (
      key: string,
      playerId: string,
      instanceNumber: number,
      includeUncomfirmed = false
    ): IBallModel[] | undefined => {
      const balls = self.results.get(key)?.balls
      if (!balls) return
      return filter(balls, ball => {
        if (includeUncomfirmed) {
          return ball.bowlerMp?.id === playerId && ball.bowlerInstance === instanceNumber
        } else {
          return ball.bowlerMp?.id === playerId && ball.bowlerInstance === instanceNumber && ball.confirmed
        }
      })
    }
    const getLastBallForBowler = (key: string, playerId: string) => {
      const balls = getBallsForBowler(key, playerId)
      if (!balls) return
      return last(orderBy(balls, ['overNumber', 'ballNumber'], ['asc', 'asc']))
    }
    const getBallsForFielder = (key: string, playerId: string): IBallModel[] | undefined => {
      const balls = self.results.get(key)?.balls
      if (!balls) return
      return filter(balls, ball => {
        return find(ball.fieldingAnalysis?.fieldingPlayers, (p: IFieldingPlayerModel) => {
          return p.playerMp.id === playerId
        })
          ? true
          : false
      })
    }
    const getLastNewBallTaken = (key: string) => {
      const balls = self.results.get(key)?.balls
      if (!balls) return 0
      const idx = findLastIndex(balls, {
        newBallTakenId: indexOf(NewBallTypes, 'NEW_BALL'),
      })
      if (idx === -1) return 0
      return Number(`${balls[idx].overNumber}.${balls[idx].ballDisplayNumber}`)
    }
    const getInningsByMatchId = (id: string) => {
      const innings: IBallInningModel[] = []
      self.results.forEach(result => {
        if (result.matchId === id) innings.push(result)
      })
      return innings
    }
    const getDismissalBall = (key: string, batterId: string): IBallModel | undefined => {
      const balls = self.results.get(key)?.balls
      if (!balls) return undefined
      return balls.find((ball: IBallModel) => ball.dismissal && ball.dismissal.batterMp?.id === batterId)
    }
    return {
      confirmedBallsInOrder,
      lastBall,
      getBall,
      getBallsAfter,
      getBallsBetween,
      getBallsFromPerf,
      getNewestBall,
      getFirstBall,
      getIndexOfWickets,
      getWicketBallOfWicketNumber,
      getBallsForBowler,
      getBallsForBowlerSpell,
      getLastBallForBowler,
      getBallsForFielder,
      getLastNewBallTaken,
      getInningsByMatchId,
      getDismissalBall,
    }
  })
  .actions(self => {
    const removeBall = ({
      /* eslint-disable @typescript-eslint/no-unused-vars */
      inningsId,
      ballId,
      matchId, // used in RootStore onAction
      deadBall, // used in RootStore onAction
      ballComplete, // used in RootStore onAction
      overId, // used in RootStore onAction
    }: /* eslint-enable @typescript-eslint/no-unused-vars */
    {
      inningsId?: string
      matchId: string
      ballId: string
      deadBall: boolean
      ballComplete: boolean
      overId: string
    }): IBallModel | undefined => {
      if (!inningsId) return
      const balls = self.results.get(inningsId)?.balls
      if (!balls) return
      const ballIndex = findIndex(balls, {
        id: ballId,
      })
      if (ballIndex > -1) {
        balls.splice(ballIndex, 1)
      }
    }
    const createBall = ({
      mode,
      inningId,
      matchId,
      onStrikeBatter,
      nonStrikeBatter,
      bowler,
      fieldingTeam,
      endOfOver,
      powerPlayId,
      venueEnds,
      matchSettings,
      umpireControl,
      umpireNonControl,
      currentBall,
      ballsPerOver,
      bowlerConsecutiveOvers,
      freeHitAfterNoBall,
      fieldingPositions,
      insertBall,
      bowlerRunningInTimestamp,
      awaitingFirstBallOfNewOver,
    }: {
      mode: string
      inningId?: string
      matchId: string
      onStrikeBatter: IBattingPerformanceModel | undefined
      nonStrikeBatter: IBattingPerformanceModel | undefined
      bowler: IBowlingPerformanceModel | undefined
      fieldingTeam?: IMatchTeamModel | undefined
      endOfOver: boolean
      powerPlayId: number | undefined
      venueEnds: IVenueEndModel[] | null | undefined
      matchSettings: IMatchSettingsModel
      umpireControl?: IMatchOfficialModel | null | undefined
      umpireNonControl?: IMatchOfficialModel | null | undefined
      currentBall?: IBallModel | null | undefined
      ballsPerOver: number
      bowlerConsecutiveOvers: boolean
      freeHitAfterNoBall: boolean
      fieldingPositions: SnapshotOrInstance<IFieldingPlacementModel>[] | undefined
      insertBall: InsertBallProps | undefined
      bowlerRunningInTimestamp: string | undefined
      awaitingFirstBallOfNewOver?: boolean | undefined
    }): IBallModel | undefined => {
      if (!inningId) return
      if (mode !== 'fielding' && (!onStrikeBatter || !nonStrikeBatter || !bowler)) return
      let inning = self.results.get(inningId)
      if (!inning) {
        inning = self.results.put({
          id: inningId,
          matchId: matchId,
          balls: [],
        })
      }

      let previousBall = self.lastBall(inningId)
      let lastBallForBowler
      if (bowler) {
        lastBallForBowler = self.getLastBallForBowler(inningId, bowler.playerMp.id)
      }
      let ballNumberValues
      if (
        awaitingFirstBallOfNewOver ||
        (currentBall &&
          previousBall &&
          currentBall.overNumber !== previousBall.overNumber &&
          currentBall.ballNumber === 1)
      ) {
        // from cascade edit: if current ball is first ball of a different over to the one we were editing...
        // ...then recreate it at that same position
        ballNumberValues = {
          overNumber: awaitingFirstBallOfNewOver ? (previousBall?.overNumber || 0) + 1 : currentBall?.overNumber || 0,
          ballNumber: 1,
          ballDisplayNumber: 1,
        }
      } else if (insertBall) {
        previousBall = self.getBall(inningId, insertBall.overNumber, insertBall.ballNumber)
        let prevBallDisplayNumber
        if (previousBall) {
          prevBallDisplayNumber = previousBall.ballDisplayNumber
        } else if (!previousBall && insertBall.ballNumber > 1) {
          const previousPreviousBall = self.getBall(inningId, insertBall.overNumber, insertBall.ballNumber - 1)
          prevBallDisplayNumber = (previousPreviousBall?.ballDisplayNumber || 0) + 1
        }
        ballNumberValues = {
          overNumber: insertBall.overNumber,
          ballNumber: insertBall.ballNumber,
          ballDisplayNumber: prevBallDisplayNumber || insertBall.ballNumber,
        }
      } else {
        ballNumberValues = BallHelpers.calculateBallNumberInnings(previousBall, endOfOver, ballsPerOver)
      }
      let venueEnd = null
      let umpireControlling = null
      let umpireNonControlling = null
      let overId = null
      let freeHit = false

      if (previousBall) {
        let changeOfEnd: boolean = previousBall.overNumber !== ballNumberValues.overNumber
        if (bowlerConsecutiveOvers && changeOfEnd && ballNumberValues.overNumber % 2 !== 0) {
          // for bowler consecutive overs games, only change ends every 2nd over
          changeOfEnd = !changeOfEnd
        }
        if (previousBall.venueEnd) {
          venueEnd = !changeOfEnd
            ? previousBall.venueEnd
            : find(venueEnds, end => end.id !== previousBall?.venueEnd?.id)
        }
        if (previousBall.umpireControl) {
          umpireControlling = !changeOfEnd ? previousBall.umpireControl : previousBall.umpireNonControl
        }
        if (previousBall.umpireNonControl) {
          umpireNonControlling = !changeOfEnd ? previousBall.umpireNonControl : previousBall.umpireControl
        }
        // see if we can get the overId from the previous ball
        if (previousBall.overNumber === ballNumberValues.overNumber) {
          overId = previousBall.overId
        }
        if (freeHitAfterNoBall && !isNil(previousBall.extrasTypeId) && includes([0, 4, 5], previousBall.extrasTypeId)) {
          freeHit = true
        }
        if (
          freeHitAfterNoBall &&
          previousBall.freeHit &&
          !isNil(previousBall.extrasTypeId) &&
          previousBall.extrasTypeId === 1
        ) {
          freeHit = true
        }
      }

      // if no previous ball, or overId has not been assigned, create it now
      if (!overId) {
        overId = uuid()
      }

      if (!insertBall) {
        // check if core ball object already exists for the ball we want to add ...then remove it
        const duplicateBall = self.getBall(inningId, ballNumberValues.overNumber, ballNumberValues.ballNumber, true)
        if (duplicateBall) {
          removeBall({
            inningsId: inningId,
            matchId: matchId,
            ballId: duplicateBall.id,
            deadBall: false,
            ballComplete: !!duplicateBall.timestamps.confirmed,
            overId: overId,
          })
        }
      }

      let batterHanded
      if (onStrikeBatter) {
        batterHanded = onStrikeBatter.getPlayerProfile?.player.battingHandedId || null
      }
      const ball = BallModel.create({
        id: uuid(),
        overId,
        ballNumber: ballNumberValues.ballNumber,
        ballDisplayNumber: ballNumberValues.ballDisplayNumber || null,
        overNumber: ballNumberValues.overNumber,
        runsBat: null,
        runsExtra: null,
        allRun: false,
        shortRun: 0,
        textDescription: '',
        powerPlayId,
        batterMp: onStrikeBatter ? onStrikeBatter.playerMp.id : null,
        batterInstance: onStrikeBatter ? onStrikeBatter.latestInstanceNumber : null,
        batterNonStrikeMp: nonStrikeBatter ? nonStrikeBatter.playerMp.id : null,
        batterNonStrikeInstance: nonStrikeBatter ? nonStrikeBatter.latestInstanceNumber : null,
        bowlerMp: bowler ? bowler.playerMp.id : null,
        bowlerInstance: bowler ? bowler.activeOrNextInstanceNumber : null,
        freeHit,
        appeal: false,
        newBallTakenId: null,
        confirmed: false,
        battingAnalysis: BallHelpers.defaults.battingDetails(),
        bowlingAnalysis:
          mode !== 'fielding' && bowler
            ? BallHelpers.defaults.bowlingDetails(bowler, previousBall, lastBallForBowler)
            : null,
        fieldingAnalysis: BallHelpers.defaults.fieldingDetails(
          bowler,
          previousBall,
          matchSettings,
          !isNil(batterHanded) ? HandedTypeOptions[batterHanded] : null,
          fieldingTeam,
          fieldingPositions,
          lastBallForBowler
        ),
        venueEnd: venueEnd?.id,
        predictives: BallHelpers.defaults.predictiveDetails(),
        pendingDismissal: false,
        pendingReview: false,
        umpireControl: umpireControlling?.id || umpireControl?.id,
        umpireNonControl: umpireNonControlling?.id || umpireNonControl?.id,
        timestamps: {
          id: uuid(),
          bowlerRunningIn: bowlerRunningInTimestamp || undefined,
        },
      })
      inning.addBall(ball)
      return ball
    }
    const swapUmpireEnds = (key: string) => {
      const balls = self.results.get(key)?.balls
      if (!balls) return
      each(balls, ball => {
        const umpireControl = ball.umpireControl
        const umpireNonControl = ball.umpireNonControl
        ball.umpireControl = umpireNonControl
        ball.umpireNonControl = umpireControl
      })
    }
    const swapEnds = (key: string, ends: IVenueEndModel[]) => {
      const balls = self.results.get(key)?.balls
      if (!balls) return
      each(balls, (ball: IBallModel) => {
        if (ball.venueEnd) {
          const changedEnd = ends.find(end => ball.venueEnd && end.id !== ball.venueEnd.id)
          if (changedEnd) ball.venueEnd = changedEnd
        }
      })
    }
    const getBalls = (match: IClspMatchModel, mode: string, matchIsLocal: boolean, force?: boolean) => {
      const inningsIds = flatten(match.matchTeams.map(team => team.innings.map(inn => inn.id)))
      if (inningsIds.length === 0 && !force) return
      if (force) {
        // set socket force back to false
        try {
          const root: IRootStore = getRoot(self)
          root.socketStore.setDataForceReloadBalls(false)
        } catch {
          // eslint-disable-next-line
          console.error('unable to get parent of BallStore')
        }
      }
      inningsIds.forEach((id: string) => {
        const inning = self.results.get(id)
        if (inning && !force) {
          return
        }
        if (matchIsLocal && !force) {
          fetchLocalBalls(id, match.id)
          return
        }
        fetchBalls(id, match.id, mode, force)
      })
      return
    }

    const fetchBalls = flow(function* fetchBalls(inningsId: string, matchId: string, mode: string, force = false) {
      if (includes(self.activeRequests, matchId)) {
        return
      }
      self.activeRequests.push(matchId)
      let dataAdded = false
      let inningDataFound = false
      const tokens = Auth.getTokens()
      const method = 'GET'
      const url = `${import.meta.env.VITE_API_URL}balls/${matchId}?mode=${mode}`
      // make request
      try {
        const response = yield RequestHandler({
          method,
          url,
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
        })
        if (response && response.ok) {
          const data = yield response.json()
          if (data && data.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data.forEach((innings: any) => {
              if (innings.inningsId === inningsId) inningDataFound = true
              if (self.results.get(innings.inningsId) && !force) return
              innings = overIdPatcher(innings)
              const objToSave = {
                id: innings.inningsId,
                matchId,
                balls:
                  innings.balls?.length && innings.balls.length > 0
                    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      innings.balls.map((b: any) => ballDataCleaner(b, matchId))
                    : [],
              }
              self.results.put(BallInningModel.create(objToSave))
              db.balls.put({ inningsId: objToSave.id, matchId: objToSave.matchId, balls: objToSave.balls })
            })
            if (!inningDataFound) {
              self.results.put(
                BallInningModel.create({
                  id: inningsId,
                  matchId,
                  balls: [],
                })
              )
            }
            dataAdded = true
          }
        } else {
          self.results.put(
            BallInningModel.create({
              id: inningsId,
              matchId,
              balls: [],
            })
          )
        }
      } catch (error) {
        console.warn('Error getting Balls', error) // eslint-disable-line no-console
      }

      if (!dataAdded) {
        self.results.put(
          BallInningModel.create({
            id: inningsId,
            matchId,
            balls: [],
          })
        )
      }

      const requestToRemove = self.activeRequests.find(req => req === matchId)
      if (requestToRemove) self.activeRequests.remove(requestToRemove)
    })

    const fetchLocalBalls = flow(function* fetchLocalBalls(inningsId: string, matchId: string) {
      if (includes(self.activeRequests, inningsId)) {
        return
      }
      self.activeRequests.push(inningsId)
      let innings = yield db.balls.get(inningsId)

      if (innings) {
        innings = overIdPatcher(innings)
        self.results.put(
          BallInningModel.create({
            id: innings.inningsId,
            matchId,
            balls: innings.balls,
          })
        )
      } else {
        self.results.put(
          BallInningModel.create({
            id: inningsId,
            matchId,
            balls: [],
          })
        )
      }

      const requestToRemove = self.activeRequests.find(req => req === inningsId)
      if (requestToRemove) self.activeRequests.remove(requestToRemove)
    })

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const loadMockData = (data: any, matchId: string) => {
      if (data.length && data.length > 0) {
        data.forEach((innings: any) => {
          innings = overIdPatcher(innings)
          const objToSave = {
            id: innings.inningsId,
            matchId,
            balls:
              innings.balls?.length && innings.balls.length > 0
                ? innings.balls.map((b: any) => ballDataCleaner(b, matchId))
                : [],
          }
          self.results.put(BallInningModel.create(objToSave))
          db.balls.put({ inningsId: objToSave.id, matchId: objToSave.matchId, balls: objToSave.balls })
        })
      }
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    const deleteMatchData = (matchID: string) => {
      const innings = self.getInningsByMatchId(matchID)

      innings.forEach(i => {
        detach(i)
        destroy(i)
      })
    }

    return {
      createBall,
      removeBall,
      swapUmpireEnds,
      swapEnds,
      getBalls,
      fetchLocalBalls,
      loadMockData,
      deleteMatchData,
    }
  })

export default BallStore
