import { Box } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import type { CustomButtonProps } from '../../types/props'
import { Button } from './Button'

const getButtonMargins = ({ isFirst = false, isMiddle = false, isLast = false }) => {
  if (isFirst) {
    return '0 3px 0 0'
  } else if (isMiddle) {
    return '0 3px'
  } else if (isLast) {
    return '0 0 0 3px'
  }
  return '0px'
}

export const CustomButton = ({
  children,
  onClick,
  isActive = false,
  isDisabled = false,
  isFlex = false,
  isFirst = false,
  isMiddle = false,
  isLast = false,
  isWhite = false,
  hideCheck = false,
  textNotCentred = false,
  paddingX,
  paddingXOuter = '16px',
  ...props
}: CustomButtonProps) => {
  return (
    <Button
      onClick={onClick}
      colorScheme={isActive ? 'cls.yellow' : isWhite ? 'cls.white' : 'gray'}
      color="cls.black"
      position="relative"
      flex={isFlex ? 1 : undefined}
      isDisabled={isDisabled}
      margin={isFirst || isMiddle || isLast ? getButtonMargins({ isFirst, isMiddle, isLast }) : undefined}
      paddingX={paddingXOuter}
      size="sm"
      aria-pressed={isActive}
      {...props}
    >
      {isActive && !hideCheck && (
        <Box position="absolute" left="4px">
          <FontAwesomeIcon icon={['fas', 'check-circle']} size="sm" />
        </Box>
      )}
      <Box paddingX={paddingX || '10px'} width={textNotCentred ? '100%' : undefined} fontSize="md">
        {children}
      </Box>
      {isActive && (
        <Box
          position="absolute"
          bottom="2px"
          margin="auto"
          left="3%"
          w="94%"
          h="3px"
          backgroundColor="cls.transparentHighlightDark"
          borderBottomRightRadius="2.5px"
          borderBottomLeftRadius="2.5px"
        />
      )}
    </Button>
  )
}
