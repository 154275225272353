import { Box, Flex, Tag, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { capitalize } from 'lodash'

import MatchHelpers from '../../helpers/matchHelpers'
import type { GameSummaryProps } from '../../types/props'

function getDataProviderLabel(provider: string | null) {
  switch (provider) {
    case 'sds':
      return 'sportradar'
    case 'clsp':
    default:
      return 'cls+'
  }
}

const GameSummary = ({ status, venueName, matchDates = [], dataProvider = 'clsp' }: GameSummaryProps) => {
  return (
    <Flex w="100%" h="100%" py="4px" justifyContent="space-around">
      <Flex w="100%" direction="column" align="center" justifyContent="center">
        {status !== 'COMPLETED' && (
          <Box maxH="24px" marginBottom={['0px', '0px', '2px', '2px']}>
            <Tag size="sm" variant="solid" colorScheme={dataProvider === 'clsp' ? 'cls.blue' : 'red'}>
              <FontAwesomeIcon icon={['fas', 'database']} size="sm" />
              <Text marginLeft="7px" fontWeight="bold" textTransform="uppercase">
                {getDataProviderLabel(dataProvider)}
              </Text>
            </Tag>
          </Box>
        )}
        {(status === 'IN_PROGRESS' || status === 'COMPLETED') && (
          <Flex maxH="24px" marginBottom={['0px', '0px', '4px', '4px']}>
            <Text fontWeight="bold" fontSize="lg">
              {capitalize(status.replace(/_/g, ' '))}
            </Text>
          </Flex>
        )}
        {matchDates && (
          <Flex
            maxH="24px"
            flex={1}
            direction="row"
            align="center"
            justify={['flex-start', 'flex-start', 'center', 'center']}
          >
            <FontAwesomeIcon icon={['far', 'clock']} size="sm" />
            <Text marginLeft="7px">{MatchHelpers.getFormattedMatchDates(matchDates)}</Text>
          </Flex>
        )}
        {venueName && (
          <Flex
            maxH="24px"
            flex={[1, 1.5, 1.5, 1]}
            direction="row"
            align="center"
            justify={['flex-start', 'flex-start', 'center', 'center']}
          >
            <FontAwesomeIcon icon={['far', 'map-marker-alt']} size="sm" />
            <Text marginLeft="7px">{venueName}</Text>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default GameSummary
