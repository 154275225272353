import { Button } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import type { UndoItemProps } from '../../types/props'

const Undo = ({ mode, editBall, onClick }: UndoItemProps) => {
  return (
    <Button
      h="100%"
      maxW="40px"
      ml="7px"
      colorScheme="red"
      boxShadow="cls.light"
      borderRadius="4px"
      alignItems="center"
      justifyContent="center"
      opacity={editBall ? 0.4 : 1}
      cursor={editBall ? 'auto' : 'pointer'}
      onClick={onClick ?? undefined}
      data-testid="activityLogUndo"
    >
      <FontAwesomeIcon icon={['far', 'undo']} size={mode === 'fielding' ? 'sm' : 'lg'} color="white" />
    </Button>
  )
}

export default Undo
