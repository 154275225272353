/* eslint-disable max-len */
import { Flex, Tag, Text } from '@chakra-ui/react'
import type { IDLSModel, IMatchTeamModel, ISummaryMatchTeamModel } from '@clsplus/cls-plus-data-models'
import { isNil } from 'lodash'

interface GameTeamProps {
  team?: IMatchTeamModel | ISummaryMatchTeamModel
  home?: boolean
  isBatting?: boolean
  maxOvers: number | null
  dls: IDLSModel | null
}

export const GameTeam = ({ team, isBatting, maxOvers, dls }: GameTeamProps) => {
  return (
    <Flex flex={1} justifyContent="center" alignItems="center" direction="column" py="2">
      <Flex direction="column" justifyContent="center" mx="15%" w="70%" alignItems="center">
        <Text
          textTransform="uppercase"
          fontWeight={isBatting ? 900 : 400}
          fontSize="xl"
          fontStyle="italic"
          letterSpacing="-0.5px"
          lineHeight="22px"
        >
          {team?.name}
        </Text>
        {team?.innings.map((inning, idx) => (
          <Text key={inning.inningsMatchOrder} as="span" fontSize="3xl" textAlign="center">
            {idx > 0 ? ' & ' : ''}
            {inning.progressiveScores.runs}/{inning.progressiveScores.wickets}
            {!isNil(maxOvers) &&
              maxOvers !== 0 &&
              parseInt(inning.progressiveScores.oversBowled || '0') !== maxOvers && (
                <Text marginLeft="7px" as="span" textStyle="value" textAlign="center">
                  {inning.getInningStatus === 'IN_PROGRESS' && `(${inning.progressiveScores.oversBowled})`}
                  {inning.getInningStatus === 'IN_PROGRESS' && inning.getActivePowerPlay && (
                    <Text marginLeft="7px" as="span" textStyle="value" textAlign="center">
                      {inning.getActivePowerPlay?.getShortName}
                    </Text>
                  )}
                  {inning.superOver && (
                    <Text marginLeft="7px" as="span" textStyle="value" textAlign="center">
                      s.o
                    </Text>
                  )}
                </Text>
              )}
            {(isNil(maxOvers) || maxOvers === 0) && (
              <Text marginLeft="7px" as="span" textStyle="value" textAlign="center">
                {inning.getInningStatus === 'IN_PROGRESS' && `(${inning.progressiveScores.oversBowled})`}
                {inning.closeReasonId === 0 && (
                  <Text marginLeft="7px" as="span" textStyle="value" textAlign="center">
                    dec
                  </Text>
                )}
              </Text>
            )}
          </Text>
        ))}
        {dls?.targetScore && team?.innings && team?.innings.length > 0 && team?.innings[0].inningsMatchOrder === 2 && (
          <Flex flex={1} textAlign="center" alignItems="center" direction="column">
            <Tag size="md" colorScheme="blue" variant="outline">
              Target {dls?.targetScore || '0'}
            </Tag>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}
