import { Flex } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import type { SpeedSpinProps } from '../../types/props'
import SubHeading from '../Headings/SubHeading'
import RangeStepper from '../Stepper/RangeStepper'

const SpeedSpin = observer(({ ball, editBall = false }: SpeedSpinProps) => {
  const changeBallSpeed = (value: number) => {
    if (!ball) return
    ball.setBallSpeed(value)
  }
  const changeSpinRate = (value: number) => {
    if (!ball) return
    ball.setSpinRate(value)
  }

  return (
    <Flex flex={1} flexDirection="row">
      <Flex flex={1} flexDirection="column">
        <SubHeading text="Ball Speed" tertiary />
        <RangeStepper
          id="ball_speed"
          title="Ball Speed"
          value={ball.bowlingAnalysis?.ballSpeed || 0}
          onChange={changeBallSpeed}
          range={{ start: 60, end: 160, increment: 10, incrementValues: 1 }}
          isEditing={editBall && ball.bowlingAnalysis?.ballSpeed !== 0}
          modalContentProps={{ h: '230px', top: '39rem', left: '21px' }}
          isUnlocked
        />
      </Flex>
      <Flex flex={1} flexDirection="column" marginLeft="7px">
        <SubHeading text="Spin Rate" tertiary />
        <RangeStepper
          id="spin_rate"
          title="Spin Rate"
          value={ball.bowlingAnalysis?.spinRate || 0}
          onChange={changeSpinRate}
          range={{ start: 1000, end: 3200, increment: 100 }}
          isEditing={editBall && ball.bowlingAnalysis?.spinRate !== 0}
          modalContentProps={{ h: '380px', top: '31rem', left: '21px' }}
          isUnlocked
        />
      </Flex>
    </Flex>
  )
})

export default SpeedSpin
