import { Box, Flex, Text } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import { battingHeaders, bettingBattingHeaders, bettingBowlingHeaders, bowlingHeaders } from '../../data/reference'
import MatchHelpers from '../../helpers/matchHelpers'
import type { ShortScorecardProps } from '../../types/props'
import Dropdown from '../Dropdown/Dropdown'
import { ActionButtons } from './ActionButtons'
import { CurrentBatters } from './CurrentBatters'
import { CurrentBowlers } from './CurrentBowlers'
import { DataRows } from './DataRows'
import { HeaderRow } from './HeaderRow'
import { TeamSummary } from './TeamSummary'

export const ShortScorecard = observer(
  ({
    inning,
    game,
    ball,
    scoresOnly,
    mode = 'main',
    batterPerformance,
    triggerNewBatter,
    triggerNewBowler,
    setBatterPerformance,
    setBowlerPerformance,
    handlePenaltyRuns,
    isDisabled,
    editBall,
    insertingBall,
    closedInning,
    endOver,
  }: ShortScorecardProps) => {
    return (
      <Flex maxHeight={mode === 'main' ? '200px' : undefined} direction={mode === 'main' ? 'row' : 'column'}>
        <Box
          flex={1}
          margin={mode === 'main' ? '7px 0px 14px 14px' : undefined}
          padding={mode === 'main' ? '0 21px 0 0' : ['0', '0 7px', '0 7px', '0 7px']}
          borderRight={mode === 'main' ? '2px solid' : undefined}
          borderRightColor={mode === 'main' ? 'gray.200' : undefined}
        >
          <Box>
            {inning && <TeamSummary game={game} team={game.getBattingTeam(inning.id)} isBatting={true} />}
            {!inning && <TeamSummary game={game} team={game.getHomeTeam()} isBatting={false} />}
          </Box>
          {!scoresOnly && inning && (
            <Flex marginTop={mode === 'main' ? '-7px' : ['-7px', '-7px', '0', '0']}>
              <CurrentBatters
                inning={inning}
                triggerNewBatter={triggerNewBatter}
                performance={batterPerformance}
                setPerformance={setBatterPerformance}
                isDisabled={isDisabled || closedInning || endOver || insertingBall}
                editBall={editBall ? true : false}
              />
              {!isDisabled && !closedInning && !insertingBall && (
                <ActionButtons
                  type="batting"
                  mode={mode}
                  inning={inning}
                  ball={ball}
                  game={game}
                  editBall={editBall ? true : false}
                />
              )}
              {(isDisabled || closedInning || insertingBall) && <Flex flex={1.5} />}
              <Flex flex={1} direction="column">
                <HeaderRow headings={mode === 'main' ? battingHeaders : bettingBattingHeaders} />
                {inning.battingPerformances && (
                  <DataRows
                    data={inning}
                    type="Batting"
                    cols={mode === 'main' ? battingHeaders : bettingBattingHeaders}
                    short
                  />
                )}
              </Flex>
            </Flex>
          )}
        </Box>
        <Box
          flex={1}
          margin={mode === 'main' ? '7px 0px 14px 21px' : undefined}
          padding={mode === 'main' ? '0 21px' : ['0', '0 7px', '0 7px', '0 7px']}
          borderRight={mode === 'main' ? '2px solid' : undefined}
          borderRightColor={mode === 'main' ? 'gray.200' : undefined}
        >
          {mode === 'main' && (
            <Box>
              {inning && <TeamSummary game={game} team={game.getBowlingTeam(inning.id)} isBatting={false} />}
              {!inning && <TeamSummary game={game} team={game.getAwayTeam} isBatting={false} />}
            </Box>
          )}
          {!scoresOnly && inning && (
            <>
              {inning.bowlingPerformances && (
                <>
                  <Flex marginTop={mode === 'main' ? '-7px' : ['0', '0', '7px', '7px']}>
                    <CurrentBowlers
                      inning={inning}
                      mode={mode}
                      triggerNewBowler={triggerNewBowler}
                      setPerformance={setBowlerPerformance}
                      isDisabled={isDisabled || closedInning || endOver || insertingBall}
                      editBall={editBall ? true : false}
                    />
                    {!isDisabled && !closedInning && !insertingBall && (
                      <ActionButtons type="bowling" mode={mode} inning={inning} ball={ball} game={game} />
                    )}
                    {(isDisabled || closedInning || insertingBall) && <Flex flex={1.5} />}
                    <Flex flex={1} direction="column">
                      <HeaderRow headings={mode === 'main' ? bowlingHeaders : bettingBowlingHeaders} />
                      {inning.bowlingPerformances && (
                        <DataRows
                          data={inning}
                          type="Bowling"
                          cols={mode === 'main' ? bowlingHeaders : bettingBowlingHeaders}
                          short
                        />
                      )}
                    </Flex>
                  </Flex>
                  <Flex marginTop={mode !== 'main' ? '14px' : '0px'}>
                    <Flex flex={1.25}>
                      <Text marginRight="7px" fontSize="md" as="span" display="inline">
                        Extras: {inning.getTotalExtras}
                      </Text>
                      <Text as="span" fontSize="md" display="inline">
                        ({MatchHelpers.listExtrasFromInning(inning)} )
                      </Text>
                    </Flex>
                    <Flex flex={mode === 'main' ? 0.5 : 0.25} paddingLeft="11px" marginTop="-5px">
                      <Dropdown
                        target="penalty_runs"
                        options={[
                          { value: 'bat', label: '5 runs (To Batting Team)' },
                          { value: 'field', label: '5 runs (To Fielding Team)' },
                        ]}
                        value={mode === 'main' ? 'Penalty Runs' : 'Pen. Runs'}
                        onChange={({ value }) => {
                          if (value && handlePenaltyRuns) handlePenaltyRuns({ value })
                        }}
                        placement={mode !== 'main' ? 'left' : undefined}
                        isWhite
                        preserveCase
                        data-testid="penaltyRunsDropdown"
                      />
                    </Flex>
                    {mode === 'main' && <Flex flex={1.5} />}
                  </Flex>
                </>
              )}
            </>
          )}
        </Box>
      </Flex>
    )
  }
)
