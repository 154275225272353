import { types } from 'mobx-state-tree'

import InningMetaModel from './InningMetaModel'

const MatchMetaDataModel = types
  .model('MatchMetaDataModel', {
    inning: types.map(InningMetaModel),
  })
  .actions(self => {
    const addInning = (id: string) => {
      self.inning.put({
        id: id,
      })
    }

    return {
      addInning,
    }
  })

export default MatchMetaDataModel
