import { Flex, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import type { IMatchDatesModel } from '@clsplus/cls-plus-data-models'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import useInterval from 'use-interval'

import { db } from '../../data/dexie/Database'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { MatchStartingModalProps } from '../../types/props'
import { Button } from '../Buttons/Button'

export const MatchStartingModal = observer(
  ({ game, mode, isOpen, setIsOpen, isManual, isConfirmed, setIsConfirmed }: MatchStartingModalProps) => {
    const dateStrings: number[] = []
    game.matchDates.forEach((d: IMatchDatesModel) => {
      if (d.startDateTime) dateStrings.push(new Date(d.startDateTime).getTime())
    })

    const modalClose = () => setIsOpen(false)

    const confirmOnTime = () => {
      db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.matchStatus('15MINS_BEFORE_START', game))
      setIsConfirmed(2)
      modalClose()
    }

    const confirmDelayed = () => {
      db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.matchStatus('DELAYED', game))
      setIsConfirmed(1)
      modalClose()
    }

    const alertCheck = useCallback(() => {
      if (!isManual && !isOpen && isConfirmed === 0) {
        // show alert after 30 sec check if not already visible, we are not in manual mode, and we haven't already responded
        const currentTimestamp = new Date().getTime()
        dateStrings.forEach((timestamp: number) => {
          if (currentTimestamp + 930000 > timestamp && currentTimestamp - timestamp < 930000) {
            // if current time is within 15.5 minutes of start time, and less than 15.5 minutes after start time
            setIsOpen(true)
          }
        })
      }
    }, [isConfirmed]) // eslint-disable-line react-hooks/exhaustive-deps

    useInterval(() => alertCheck(), 30000) // check every 30 seconds...
    useEffect(() => alertCheck(), [alertCheck]) // ... but also ensure a check is done immediately

    return (
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={modalClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Start Time</ModalHeader>
          <ModalBody>
            {isManual ? (
              <Text>Please confirm that the match is either "Starting in 15 mins" or "Delayed".</Text>
            ) : (
              <Text>
                Play is due to begin in the next 15 minutes. Please confirm that the match is either "On Time" or
                "Delayed".
                <br />
                <br />
                NB: Confirmation of "On Time" will occur automatically if no response is received within 25 seconds.
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            {!isManual && isOpen && (
              <Flex flex={1} justifyContent="flex-start">
                <Countdown
                  date={Date.now() + 25000}
                  renderer={props => {
                    return (
                      <Text fontWeight="bold" fontSize="xl" color="red">
                        00:{zeroPad(props.seconds, 2)}
                      </Text>
                    )
                  }}
                  onComplete={confirmOnTime}
                />
              </Flex>
            )}
            {isManual && (
              <Button onClick={modalClose} mr="10px" data-testid="cancelMatchStartingButton">
                Cancel
              </Button>
            )}
            <Button colorScheme="green" onClick={confirmOnTime} mr="10px" data-testid="confirmMatchStartingButton">
              {isManual ? 'Starting in 15 mins' : 'On time'}
            </Button>
            <Button colorScheme="red" onClick={confirmDelayed} data-testid="delayMatchStartingButton">
              Delayed Start
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }
)
