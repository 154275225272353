import { Box, Flex, Text } from '@chakra-ui/react'
import { indexOf, isNil } from 'lodash'
import { observer } from 'mobx-react-lite'

import {
  DismissalMethods,
  DismissalMethodsNoOrigDecisionPlayer,
  DismissalMethodsNoOrigDecisionUmpire,
} from '../../data/reference'
import type { ReviewSectionProps } from '../../types/props'
import { CustomButton } from '../Buttons/CustomButton'
import SubHeading from '../Headings/SubHeading'

const ReviewSection = observer(
  ({ activeInning, ball, mode, editBall = false, updateReviewMessaging }: ReviewSectionProps) => {
    return (
      <>
        <Box w={mode !== 'main' ? '100%' : undefined}>
          <SubHeading
            text="Orig. Decision"
            tertiary
            noPadding
            warning={
              ball.pendingReview &&
              ball.review &&
              !isNil(ball.review.dismissalTypeId) &&
              indexOf(
                ball.review.reviewSourceTypeId === 0
                  ? DismissalMethodsNoOrigDecisionUmpire
                  : DismissalMethodsNoOrigDecisionPlayer,
                DismissalMethods[ball.review.dismissalTypeId]
              ) === -1 &&
              isNil(ball.review.originalDecision)
                ? true
                : false
            }
          />
          <Flex w={mode !== 'main' ? '100%' : undefined}>
            <Box w={mode !== 'main' ? '50%' : undefined} marginRight="7px">
              <CustomButton
                height="28px"
                width={mode === 'main' ? '64px' : '100%'}
                isDisabled={isNil(ball.review?.dismissalTypeId) || (editBall && ball.review !== null)}
                isActive={!!ball.review && ball.review.originalDecision === false}
                onClick={() => {
                  ball.setOriginalDecision(false, activeInning?.progressiveScores)
                  updateReviewMessaging()
                }}
                isFlex={mode !== 'main'}
                hideCheck
                data-testid="reviewNotOut"
              >
                <Text fontSize={14}>Not Out</Text>
              </CustomButton>
            </Box>
            <Box w={mode !== 'main' ? '50%' : undefined}>
              <CustomButton
                height="28px"
                width={mode === 'main' ? '44px' : '100%'}
                isDisabled={isNil(ball.review?.dismissalTypeId) || (editBall && ball.review !== null)}
                isActive={!!ball.review && ball.review.originalDecision === true}
                onClick={() => {
                  ball.setOriginalDecision(true, activeInning?.progressiveScores)
                  updateReviewMessaging()
                }}
                isFlex={mode !== 'main'}
                hideCheck
                data-testid="reviewOut"
              >
                <Text fontSize={14}>Out</Text>
              </CustomButton>
            </Box>
          </Flex>
        </Box>
        <Box
          w={mode !== 'main' ? '100%' : undefined}
          marginLeft={mode === 'main' ? '21px' : undefined}
          marginTop={mode !== 'main' ? '14px' : undefined}
        >
          <SubHeading
            text="Final Decision"
            tertiary
            noPadding
            warning={
              ball.pendingReview &&
              ball.review &&
              !isNil(ball.review.dismissalTypeId) &&
              isNil(ball.review.finalDecision) &&
              isNil(ball.review.drsUmpiresCall)
                ? true
                : false
            }
          />
          <Flex w={mode !== 'main' ? '100%' : undefined}>
            <Box w={mode !== 'main' ? '33%' : undefined} marginRight="7px">
              <CustomButton
                height="28px"
                width={mode === 'main' ? '64px' : '100%'}
                isDisabled={isNil(ball.review?.dismissalTypeId) || (editBall && ball.review !== null)}
                isActive={!!ball.review && ball.review.finalDecision === false}
                onClick={() => {
                  ball.setFinalDecision(false, activeInning?.progressiveScores)
                  updateReviewMessaging()
                }}
                isFlex={mode !== 'main'}
                hideCheck
                data-testid="reviewNotOut"
              >
                <Text fontSize={14}>Not Out</Text>
              </CustomButton>
            </Box>
            <Box w={mode !== 'main' ? '33%' : undefined} marginRight="7px">
              <CustomButton
                height="28px"
                width={mode === 'main' ? '44px' : '100%'}
                isDisabled={isNil(ball.review?.dismissalTypeId) || (editBall && ball.review !== null)}
                isActive={!!ball.review && ball.review.finalDecision === true}
                onClick={() => {
                  ball.setFinalDecision(true, activeInning?.progressiveScores)
                  updateReviewMessaging()
                }}
                isFlex={mode !== 'main'}
                hideCheck
                data-testid="reviewOut"
              >
                <Text fontSize={14}>Out</Text>
              </CustomButton>
            </Box>
            <Box w={mode !== 'main' ? '33%' : undefined}>
              <CustomButton
                height="28px"
                width={mode === 'main' ? '74px' : '100%'}
                isDisabled={isNil(ball.review?.dismissalTypeId) || (editBall && ball.review !== null)}
                isActive={!!ball.review && !!ball.review.drsUmpiresCall}
                onClick={() => {
                  ball.toggleUmpiresCall(activeInning?.progressiveScores)
                  updateReviewMessaging()
                }}
                isFlex={mode !== 'main'}
                hideCheck
                data-testid="UmpiresCall"
              >
                {mode === 'main' && <Text fontSize={14}>Ump. Call</Text>}
                {mode !== 'main' && <Text fontSize={14}>Umpires Call</Text>}
              </CustomButton>
            </Box>
          </Flex>
        </Box>
      </>
    )
  }
)

export default ReviewSection
