import { getRoot, types } from 'mobx-state-tree'
import { v4 as uuid } from 'uuid'

import { formatSocketMessage } from '../../helpers/dataHelpers'
import { UserEventTypes } from '../reference'
import type { IRootStore } from './rootStore'

export const SocketStore = types
  .model('SocketStore', {
    status: types.maybe(types.number),
    queryString: types.maybe(types.string),
    matchId: types.maybe(types.string),
    primary: false,
    assignedRole: false,
    promptUser: false,
    socketRequestState: types.maybe(types.enumeration(UserEventTypes)),
    forceDataReloadMatch: false,
    forceDataReloadEvents: false,
    forceDataReloadBalls: false,
  })
  .views(self => {
    const connected = () => {
      return !!(self.status && self.status < 2)
    }
    const topLevelStatus = () => {
      if (!connected()) return 'OFFLINE'
      if (connected() && self.assignedRole && self.primary) return 'PRIMARY'
      if (connected() && self.assignedRole && !self.primary) return 'SECONDARY'
      return 'UNKNOWN'
    }
    return {
      connected,
      topLevelStatus,
    }
  })
  .actions(self => {
    const setStatus = (status: number | undefined) => {
      self.status = status
    }
    const setQueryString = (queryString: string | undefined) => {
      self.queryString = queryString
    }
    const setMatchId = (value: string | undefined) => {
      self.matchId = value
    }
    const setPrimary = (value: boolean) => {
      self.primary = value
    }
    const setAssignedRole = (value: boolean) => {
      self.assignedRole = value
    }
    const setPromptUser = (value: boolean) => {
      self.promptUser = value
    }
    const setSocketRequestState = (value: string | undefined) => {
      self.socketRequestState = value
    }
    const sendMessage = (matchId: string, appMode: string, ev: { socketRequestState: string }): void => {
      const root: IRootStore = getRoot(self)
      // prepare message
      const message = formatSocketMessage(
        'updateUserEvent',
        matchId,
        root.appSettings.manualScoring.active ? 'postMatch' : appMode,
        null,
        uuid(),
        ev
      )
      // send it
      root.sendMessage(message)
    }
    const setDataForceReloadMatch = (value: boolean) => {
      self.forceDataReloadMatch = value
    }
    const setDataForceReloadEvents = (value: boolean) => {
      self.forceDataReloadEvents = value
    }
    const setDataForceReloadBalls = (value: boolean) => {
      self.forceDataReloadBalls = value
    }
    const reset = () => {
      self.status = undefined
      self.queryString = undefined
      self.matchId = undefined
      self.primary = false
      self.assignedRole = false
      self.promptUser = false
      self.socketRequestState = undefined
      self.forceDataReloadBalls = false
      self.forceDataReloadEvents = false
      self.forceDataReloadMatch = false
    }
    return {
      setStatus,
      setQueryString,
      setPrimary,
      setMatchId,
      setAssignedRole,
      setPromptUser,
      setSocketRequestState,
      sendMessage,
      setDataForceReloadMatch,
      setDataForceReloadEvents,
      setDataForceReloadBalls,
      reset,
    }
  })

export default SocketStore
