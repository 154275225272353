import { flow, types } from 'mobx-state-tree'

import Auth from '../../../../helpers/auth'
import type { MatchValidationResponse } from '../../../../types/props'
import { RequestHandler } from '../../../api/RequestHandler'
import MatchesSearchMap from './MatchesSearchMap'

const MatchesSearchStore = types
  .model('MatchesSearchStore', {
    results: types.map(MatchesSearchMap),
    state: types.enumeration('State', ['pending', 'done', 'error']),
  })
  .actions(self => {
    const getMatches = async (startDate: string, endDate: string) => {
      const data = self.results.get(`${startDate}_${endDate}`)
      if (data) {
        return data
      }
      if (self.state === 'pending') {
        return null
      }
      return fetchMatches(startDate, endDate)
    }
    const fetchMatches = flow(function* fetchMatches(startDate: string, endDate: string) {
      self.state = 'pending'

      // construct props
      const tokens = Auth.getTokens()
      const method = 'GET'
      const url = `${import.meta.env.VITE_API_URL}matches`
      const params = {
        startdate: startDate,
        enddate: endDate,
      }

      // make request
      try {
        const response = yield RequestHandler({
          method,
          url,
          params,
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
        })
        if (response && response.ok) {
          const data = yield response.json()
          self.results.put(
            MatchesSearchMap.create({
              filter: `${startDate}_${endDate}`,
              data,
            })
          )
          self.state = 'done'
          return self.results.get(`${startDate}_${endDate}`)?.data
        } else {
          // we got a response but it was not ok
          // for now just mark this request as done
          // because we don't have scope to handle this better
          self.state = 'done'
        }
      } catch (error) {
        console.warn('Error getting Matches', error) // eslint-disable-line no-console
        self.state = 'error'
      }
    })
    const validateSdsMatch = flow(function* validateSdsMatch(match) {
      self.state = 'pending'
      const responseDefault: MatchValidationResponse = {
        match: [],
        competition: [],
        players: [],
        officials: [],
        team: [],
        venue: [],
      }

      // construct props
      const tokens = Auth.getTokens()
      const method = 'POST'
      const url = `${import.meta.env.VITE_API_URL}sds/validate`
      const params = {}
      const body = JSON.stringify(match)

      // make request
      try {
        const response = yield RequestHandler({
          method,
          url,
          params,
          body,
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
        })
        self.state = 'done'
        if (response && response.ok) {
          return yield response.json()
        }
        return responseDefault
      } catch (error) {
        console.warn('Error checking SDS Mappings', error) // eslint-disable-line no-console
        self.state = 'error'
        return responseDefault
      }
    })
    return {
      getMatches,
      fetchMatches,
      validateSdsMatch,
    }
  })

export default MatchesSearchStore
