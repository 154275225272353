import { Flex } from '@chakra-ui/react'

import { InGameHeader } from '../../components/Headers/InGameHeader'
import type { StandardProps } from '../../types/props'

const Standard = ({ children }: StandardProps) => {
  return (
    <Flex h="100vh" direction="column" backgroundColor="cls.backgroundGray">
      <Flex backgroundColor={'cls.blue.400'} h="42px" w="100%" justify="center" align="center">
        <InGameHeader preGame={true} />
      </Flex>
      {children}
    </Flex>
  )
}

export default Standard
