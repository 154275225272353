import type { IBallModel, IFieldingPositionModel } from '@clsplus/cls-plus-data-models'
import { filter, flatten, map, round } from 'lodash'

import { FieldingPositions, FieldingPositionsBrackets } from '../data/reference'
import type { FieldingPositionInterface, FieldingPositionPositionsInterface } from '../types'

export const getAngleAndLength = (
  x: number,
  y: number,
  originX: number,
  originY: number,
  stageW: number,
  stageH: number,
  rotated?: boolean,
  leftHanded?: boolean
) => {
  // calculate sA and sB to get hypotenuse (length)
  let sA: number = round(((y - originY) / stageH) * 100, 2)
  const sAi: boolean = sA >= 0
  if (sA < 0) sA *= -1
  let sB: number = round(((x - originX) / stageW) * 100, 2)
  const sBi: boolean = sB >= 0
  if (sB < 0) sB *= -1

  // length from origin
  const sLP: number = round(Math.sqrt(Math.pow(sA, 2) + Math.pow(sB, 2)), 2)

  // calculate the angle using inverse tangent (tan^-1)
  let sAngle: number = Math.atan(sB / sA) * (180 / Math.PI)
  if (isNaN(sAngle)) sAngle = 0

  // convert the angle to 0-360
  if (sAi && sBi) {
    // 90-180
    sAngle = 180 - sAngle
  } else if (sAi && !sBi) {
    // 180-270
    sAngle = 180 + sAngle
  } else if (!sAi && sBi) {
    // 0-90
    sAngle = 0 + sAngle
  } else if (!sAi && !sBi) {
    // 270-360
    sAngle = 360 - sAngle
  }
  if (rotated) sAngle = sAngle < 180 ? sAngle + 180 : sAngle - 180
  if (leftHanded) sAngle = 360 - sAngle

  return { angle: sAngle, length: sLP }
}

export const getXandY = (x: number, y: number, rotated?: boolean) => {
  let cX = x
  let cY = y
  if (rotated) {
    cX = 100 - x
    cY = 100 - y
  }
  return { x: cX, y: cY }
}

export const getViablePositions = (
  x: number,
  y: number,
  originX: number,
  originY: number,
  stageW: number,
  stageH: number,
  rotated?: boolean,
  leftHanded?: boolean
) => {
  const sP = getAngleAndLength(x, y, originX, originY, stageW, stageH, rotated, leftHanded)

  // get position possibilities
  const sD: FieldingPositionPositionsInterface[] = flatten(
    map(
      filter(FieldingPositionsBrackets, bracket => {
        // angle is in a bracket, or if very close to the wicket (0-10 depth), be more forgiving on angle
        return (
          (sP.angle >= bracket.angle.start || (sP.length <= 10 && sP.angle + 45 >= bracket.angle.start)) &&
          (sP.angle <= bracket.angle.end || (sP.length <= 10 && sP.angle - 45 <= bracket.angle.end))
        )
      }),
      (bracket: FieldingPositionInterface) => {
        return filter(bracket.positions, position => {
          // depth ranges: 0-17 short, 13-35 mid, 28+ long
          return (
            ((position.short && sP.length <= 17) ||
              (position.mid && sP.length >= 13 && sP.length <= 35) ||
              (position.deep && sP.length >= 28)) &&
            FieldingPositions[position.id] !== 'BOWLER' &&
            FieldingPositions[position.id] !== 'WICKET_KEEPER'
          )
        })
      }
    )
  )
  return sD
}

export const fielderHasMoved = (coords: IFieldingPositionModel, ball: IBallModel, lastBall?: IBallModel) => {
  return coords.playerMp &&
    lastBall &&
    lastBall.overNumber === ball.overNumber &&
    lastBall.fieldingAnalysis &&
    lastBall.fieldingAnalysis.fieldingPositions &&
    lastBall.fieldingAnalysis.fieldingPositions.length > 0 &&
    lastBall.fieldingAnalysis.fieldingPlacementForPlayer(coords.playerMp.id)?.placement.x !== coords.placement.x &&
    lastBall.fieldingAnalysis.fieldingPlacementForPlayer(coords.playerMp.id)?.placement.y !== coords.placement.y
    ? true
    : false
}
