import type { CheckboxProps as ChakraCheckboxProps } from '@chakra-ui/react'
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'

export const Checkbox = ({ children, ...props }: ChakraCheckboxProps) => {
  return (
    <ChakraCheckbox {...props} borderColor="gray.200">
      {children}
    </ChakraCheckbox>
  )
}
