import { Box, Flex, Text } from '@chakra-ui/react'
import type { IBattingPartnershipPlayerModel } from '@clsplus/cls-plus-data-models'
import { orderBy } from 'lodash'
import { useMemo } from 'react'
import type { Column, Row } from 'react-table'
import { useTable } from 'react-table'

import Theme from '../../theme/theme'
import type { PartnershipCardProps } from '../../types/props'

const statusMap = (value: number) => {
  switch (value) {
    case 0: {
      return (
        <Text as="span" paddingLeft="2px" fontSize="lg">
          *
        </Text>
      )
    }
    case 1: {
      return (
        <Text as="span" paddingLeft="5px" fontSize="md">
          out
        </Text>
      )
    }
    case 2:
    default: {
      return (
        <Text as="span" paddingLeft="5px" fontSize="md">
          retired
        </Text>
      )
    }
  }
}

export const PartnershipCard = ({ partnership, inningsMatchOrder }: PartnershipCardProps) => {
  // table columns
  const columns: Column<IBattingPartnershipPlayerModel>[] = useMemo(
    () => [
      {
        id: 'batterNames',
        accessor: (row: IBattingPartnershipPlayerModel) => row.getDisplayName,
        Header: '',
        Cell: ({ row }: { row: Row<IBattingPartnershipPlayerModel> }) => {
          return (
            <Box>
              <Text fontSize="xl">
                {row.original.getDisplayName}
                {statusMap(row.original.status)}
              </Text>
            </Box>
          )
        },
      },
      {
        accessor: 'runs',
        Header: 'R',
      },
      {
        accessor: 'balls',
        Header: 'B',
      },
      {
        accessor: 'strikeRate',
        Header: 'SR',
      },
      {
        accessor: 'dots',
        Header: '\u2022',
      },
      {
        accessor: 'ones',
        Header: '1',
      },
      {
        accessor: 'twos',
        Header: '2',
      },
      {
        accessor: 'threes',
        Header: '3',
      },
      {
        accessor: 'fours',
        Header: '4',
      },
      {
        accessor: 'sixes',
        Header: '6',
      },
    ],
    []
  )

  const data: IBattingPartnershipPlayerModel[] = orderBy(partnership.partnershipPlayers, 'battingOrder', 'asc')
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

  return (
    <Box w="100%" bg="white" padding="10px">
      <Flex borderBottom="1px solid" borderBottomColor="cls.backgroundGray">
        <Box flex={1}>
          <Text fontSize="xl" fontWeight="bold">
            Wicket #{partnership.wicketNumber}
          </Text>
        </Box>
        <Box flex={1}>
          <Text fontSize="xl" fontWeight="medium" textAlign="right">
            {partnership.totalRuns} runs - {partnership.balls} balls
          </Text>
        </Box>
      </Flex>
      <Flex justify="space-between">
        <Text fontSize="md" data-testid={`partnershipCardStart_${inningsMatchOrder}_${partnership.wicketNumber}`}>
          Start: {partnership.start}
        </Text>
        <Text fontSize="md" data-testid={`partnershipCardEnd_${inningsMatchOrder}_${partnership.wicketNumber}`}>
          End: {partnership.end || '-'}
        </Text>
        <Text fontSize="md">Minutes: {partnership.allMins()}</Text>
      </Flex>

      <table {...getTableProps()} style={{ width: '100%', backgroundColor: 'white' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()} style={{ backgroundColor: Theme.colors.cls.backgroundGray }}>
              {headerGroup.headers.map((column, idx) => (
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps()}
                  style={{
                    padding: '4px',
                    fontWeight: 'normal',
                    fontSize: idx === 0 ? '20px' : '16px',
                    fontStyle: 'italic',
                    textAlign: idx === 0 ? 'left' : 'center',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <tr
                {...row.getRowProps()}
                style={{
                  borderTop: `solid 1px ${Theme.colors.cls.backgroundGray}`,
                }}
              >
                {row.cells.map((cell, idx) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '4px',
                        textAlign: idx === 0 ? 'left' : 'center',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <Box>
        <Text fontSize="lg" fontWeight="medium" paddingLeft="4px">
          Extras: {partnership.extraRuns}
        </Text>
      </Box>
    </Box>
  )
}
