import { Box, Flex, Spinner, Text } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { IconLibrary } from './assets/icons'
import Login from './components/atoms/Login'
import { useMst } from './data/stores/rootStore'
import Auth from './helpers/auth'
import InGame from './layouts/InGame/InGame'
import InGameAlternate from './layouts/InGame/InGameAlternate'
import Standard from './layouts/InGame/Standard'
import Authenticated from './screens/Authenticated'
import Game from './screens/Game'
import Games from './screens/Games'

// Load Font Awesome icon library
IconLibrary()

const params = new URL(document.location.href).searchParams
const code = params.get('code')
const tokens = Auth.getTokens()

export const App = observer(() => {
  const { appSettings, initialised, socketStore } = useMst()
  const [authenticated, setAuthenticated] = useState(false)
  const [authenticating, setAuthenticating] = useState(code !== null)

  window.addEventListener('popstate', () => {
    // prevent any browser navigation (back/forward)
    history.go(1)
  })

  useEffect(() => {
    const check = async () => {
      if (!tokens) {
        setAuthenticated(false)
      } else {
        setAuthenticating(true)
        const result = await Auth.checkTokens(tokens.accessToken, tokens.idToken, tokens.refreshToken)
        setAuthenticated(result)
        setAuthenticating(false)
      }
    }
    check()
  }, [])

  useEffect(() => {
    const execute = async (c: string) => {
      try {
        await Auth.login(c)
        setAuthenticated(true)
      } catch (err) {
        // console.log(err)
      }
      setAuthenticating(false)
    }
    if (code !== null) {
      window.history.replaceState({}, document.title, '/')
      execute(code)
    }
  }, [])

  if (!initialised) {
    return (
      <Flex h="calc(100vh - 42px)" alignItems="center" justifyContent="center" direction="column">
        <Spinner thickness="4px" speed="0.75s" emptyColor="cls.gray.200" color="cls.blue.400" size="xl" />
        <Box marginTop="14px">
          <Text fontWeight="bold" fontSize="2xl" textAlign="center">
            Initialising
          </Text>
          <Text fontSize="lg" textAlign="center">
            Browser Database...
          </Text>
        </Box>
      </Flex>
    )
  }
  if (authenticating) {
    return (
      <Flex h="calc(100vh - 42px)" alignItems="center" justifyContent="center" direction="column">
        <Spinner thickness="4px" speed="0.75s" emptyColor="cls.gray.200" color="cls.blue.400" size="xl" />
        <Box marginTop="14px">
          <Text fontWeight="bold" fontSize="2xl" textAlign="center">
            Authenticating
          </Text>
          <Text fontSize="lg" textAlign="center">
            User Credentials...
          </Text>
        </Box>
      </Flex>
    )
  }
  if (!authenticated) {
    return <Login />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Authenticated />} />
        <Route
          path="/games"
          element={
            <Standard>
              <Games />
            </Standard>
          }
        />
        <Route
          path="/game/:id/:type/*"
          element={
            appSettings.appMode === 'main' ? (
              <InGame socketStatus={socketStore.topLevelStatus()}>
                <Game />
              </InGame>
            ) : (
              <InGameAlternate mode={appSettings.appMode} socketStatus={socketStore.topLevelStatus()}>
                <Game />
              </InGameAlternate>
            )
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  )
})
