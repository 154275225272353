import { Flex, Text } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import EditableField from '../../components/EditableField/EditableField'
import type { ExtrasProps } from '../../types/props'

export const Extras = observer(({ inning }: ExtrasProps) => {
  return (
    <Flex borderTop="solid 1px" borderTopColor="cls.backgroundGray" backgroundColor="white" padding="20px 10px">
      <Flex flex={{ base: 2, md: 4 }} align="center">
        <Text fontSize="xl" fontWeight="bold">
          Extras
        </Text>
        <Text fontSize="xl" marginLeft="7px" data-testid={`extrasTotal_${inning.inningsMatchOrder}`}>
          {inning.getTotalExtras}
        </Text>
      </Flex>
      <Flex flex={1} justify="center" align="center">
        <Text>NB</Text>
        <EditableField
          data-testid={`extrasNoBalls_${inning.inningsMatchOrder}`}
          value={inning.progressiveScores.noBalls ?? 0}
          marginLeft={'7px'}
          type="number"
          onChange={inning.setNoBalls}
          pattern="^\d+$"
          errorMessage="Please enter numbers only"
        ></EditableField>
      </Flex>
      <Flex flex={1} justify="center" align="center">
        <Text>WD</Text>
        <EditableField
          data-testid={`extrasWides${inning.inningsMatchOrder}`}
          value={inning.progressiveScores.wides ?? 0}
          marginLeft="7px"
          type="number"
          onChange={inning.setWides}
          pattern="^\d+$"
          errorMessage="Please enter numbers only"
        ></EditableField>
      </Flex>
      <Flex flex={1} justify="center" align="center">
        <Text>B</Text>
        <EditableField
          data-testid={`extrasByes_${inning.inningsMatchOrder}`}
          value={inning.progressiveScores.byes ?? 0}
          marginLeft="7px"
          type="number"
          onChange={inning.setByes}
          pattern="^\d+$"
          errorMessage="Please enter numbers only"
        ></EditableField>
      </Flex>
      <Flex flex={1} justify="center" align="center">
        <Text>LB</Text>
        <EditableField
          data-testid={`extrasLegByes_${inning.inningsMatchOrder}`}
          value={inning.progressiveScores.legByes ?? 0}
          marginLeft="7px"
          type="number"
          onChange={inning.setLegByes}
          pattern="^\d+$"
          errorMessage="Please enter numbers only"
        ></EditableField>
      </Flex>
      <Flex flex={1} justify="center" align="center">
        <Text>Pen. Runs</Text>
        <EditableField
          data-testid={`extrasPenaltyRuns_${inning.inningsMatchOrder}`}
          value={inning.progressiveScores.penaltyRuns ?? 0}
          marginLeft="7px"
          type="number"
          onChange={inning.setPenaltyRuns}
          pattern="^\d+$"
          errorMessage="Please enter numbers only"
        ></EditableField>
      </Flex>
    </Flex>
  )
})
