import { Box, Flex, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { format, utcToZonedTime } from 'date-fns-tz'
import { isNull, some, values } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '../components/Buttons/Button'
import { CustomButton } from '../components/Buttons/CustomButton'
import MatchBreak from '../components/ControlsPanel/MatchBreak'
import { EndMatch } from '../components/EndMatch/EndMatch'
import SubHeading from '../components/Headings/SubHeading'
import ProgressSteps from '../components/ProgressSteps/ProgressSteps'
import { SettingsContainer } from '../components/Settings/SettingsContainer'
import { TeamNames } from '../components/TeamNames/TeamNames'
import { useMst } from '../data/stores/rootStore'
import type { IClspMatchModel, IDetailedMatchStore, ISettingsModel } from '../types/models'
import type { SetupProps } from '../types/props'

const Setup = observer(
  ({
    mode = 'main',
    timelineEvents,
    closedInning,
    isMatchStartingModalConfirmed,
    setIsMatchStartingModalConfirmed,
    setIsMatchStartingModalOpen,
    setIsMatchStartingModalManual,
  }: SetupProps) => {
    const navigate = useNavigate()
    const { detailedMatches, appSettings }: { detailedMatches: IDetailedMatchStore; appSettings: ISettingsModel } =
      useMst()
    const [settingsConfirmed, setSettingsConfirmed] = useState(false)
    const [teamsConfirmed, setTeamsConfirmed] = useState(false)
    const { id } = useParams()
    if (!id) return null
    detailedMatches.getMatch(id, mode)
    const game: IClspMatchModel | undefined = detailedMatches.results.get(id)?.match
    let matchDate = undefined

    useEffect(() => {
      // after load, check if this match has innings, if it does then mark the settings as confirmed
      if ((game && game.getInningCount() > 0 && settingsConfirmed === false) || import.meta.env.VITE_ENV === 'DEV') {
        setSettingsConfirmed(true)
      }
      if (mode === 'betting' && game && game.getInningCount() > 0 && teamsConfirmed === false) {
        setTeamsConfirmed(true)
      }
      if (import.meta.env.VITE_ENV === 'DEV') {
        setIsMatchStartingModalConfirmed(2)
      }
    }, [game, mode, settingsConfirmed, teamsConfirmed, setIsMatchStartingModalConfirmed])

    useEffect(() => {
      // clear toss result if starting a match after another scorer has selected the toss result/winner
      // only do this if starting setup before the match begins. if the match has already begun, inherit the toss result as per usual
      if (
        mode === 'betting' &&
        game &&
        (game.matchStatusId || 0) === 0 &&
        (game.wonTossMatchTeamId !== null || game.tossDecisionId !== null)
      ) {
        game.setTossWonBy(null)
        game.setTossDecision(null)
      }
    }, [game, mode])

    const matchDates = game?.getDatesInOrder

    if (matchDates?.length) {
      matchDate = matchDates[0].startDateTime
    }

    const handleClick = () => {
      if (mode === 'fielding' && !game?.getActiveInning()) {
        // starting a game in fielding mode => create an inning
        const teamBattingId: string | undefined = game?.getBattingTeamIdAfterToss
        if (teamBattingId) game?.createInning(teamBattingId)
        navigate(`/game/${id}/${mode}/inning-bowling-setup`)
      } else if (mode === 'main' && appSettings.manualScoring.active) {
        // starting/resuming a manually scored game in main mode
        navigate(`/game/${id}/${mode}/scorebook/manual`)
      } else if (game?.getActiveInning(closedInning)) {
        // resuming a game in any mode
        navigate(`/game/${id}/${mode}/scoring`)
      } else {
        // starting a game in main/betting mode
        navigate(`/game/${id}/${mode}/inning-batting-setup`)
      }
    }

    const syncMatchManual = () => {
      if (!game) return
      appSettings.manualScoring.setForceSync(true)
      setTimeout(() => game.setTimestamp(), 500)
    }

    const homeTeamHasWicketKeeper: boolean = game?.getHomeTeam()?.getWicketkeeper ? true : false
    const awayTeamHasWicketKeeper: boolean = game?.getAwayTeam?.getWicketkeeper ? true : false
    const homeTeamHasMinimumPlayers: boolean = (game?.getHomeTeam()?.matchPlayers.length || 0) >= 11 ? true : false
    const awayTeamHasMinimumPlayers: boolean = (game?.getAwayTeam?.matchPlayers.length || 0) >= 11 ? true : false
    const conditions =
      values(game?.getNearestDate?.getLatestConditions).length > 0 &&
      some(values(game?.getNearestDate?.getLatestConditions), isNull) === false
    const toss = game?.wonTossMatchTeamId !== null && game?.tossDecisionId !== null

    const checkPreGameIsComplete: boolean =
      (mode === 'fielding' || appSettings.manualScoring.active || conditions) &&
      toss &&
      settingsConfirmed &&
      (mode !== 'fielding' || (homeTeamHasWicketKeeper && awayTeamHasWicketKeeper)) &&
      (mode !== 'fielding' || (homeTeamHasMinimumPlayers && awayTeamHasMinimumPlayers)) &&
      (mode !== 'betting' || isMatchStartingModalConfirmed === 2)

    const progressStepsItems = [
      mode === 'fielding'
        ? {
            text: 'Wicketkeepers selected',
            isComplete: homeTeamHasWicketKeeper && awayTeamHasWicketKeeper,
          }
        : {
            text: 'Conditions and Venue',
            isComplete: conditions,
          },
      {
        text: 'Match Settings',
        isComplete: settingsConfirmed,
      },
      {
        text: 'Toss Result',
        isComplete: toss,
      },
    ]
    if (mode === 'fielding') {
      progressStepsItems.splice(0, 0, {
        text: 'Team Selections (11+ players)',
        isComplete: homeTeamHasMinimumPlayers && awayTeamHasMinimumPlayers,
      })
    } else if (mode === 'betting' || (mode === 'main' && appSettings.manualScoring.active)) {
      progressStepsItems.splice(0, 0, {
        text: 'Team Selections',
        isComplete: teamsConfirmed,
      })
      if (mode === 'betting') {
        progressStepsItems.push({
          text: 'Confirm 15 mins',
          isComplete: isMatchStartingModalConfirmed === 2,
        })
      }
    }

    if (!game) return <Text>Missing the Game</Text>

    return (
      <Flex
        h="100%"
        marginX={mode !== 'main' ? '0px' : ['0', '0', '14px', '14px']}
        w={mode !== 'main' ? ['100%', '100%', 'auto', 'auto'] : undefined}
        justifyContent={mode !== 'main' ? 'center' : 'flex-start'}
      >
        <Flex
          flex="10"
          direction="column"
          w={mode !== 'main' ? ['100%', '100%', 'auto', 'auto'] : undefined}
          maxW={mode !== 'main' ? ['100%', '100%', '1280px', '1280px'] : undefined}
        >
          <Box paddingY="14px">
            <TeamNames game={game} mode={mode} />
          </Box>
          <Flex
            flex={1}
            direction="column"
            bg="white"
            borderTopLeftRadius={mode === 'main' ? '7px' : ['0px', '0px', '7px', '7px']}
            borderTopRightRadius={mode === 'main' ? '7px' : ['0px', '0px', '7px', '7px']}
            padding={mode === 'main' ? '14px 21px 0' : '14px 14px 0'}
          >
            <Flex direction="column" marginBottom="37px">
              <Box>
                <SubHeading text="Match Setup" />
              </Box>
              <Flex direction={mode !== 'main' ? ['column', 'column', 'row', 'row'] : 'row'}>
                <Flex flex={3} direction="column">
                  {matchDate && (
                    <Flex direction="row" padding="4px 0">
                      <Flex justifyContent="center">
                        <FontAwesomeIcon icon={['far', 'clock']} size="2x" />
                      </Flex>
                      <Flex ml="14px" direction="column">
                        <Text>Game commences at:</Text>
                        <Text ml="7px">
                          {format(utcToZonedTime(new Date(matchDate), game.timeZone || 'UTC'), 'do MMM yyyy hh:mm a')}
                          <small>{` (${game.timeZone || 'UTC'})`}</small>
                        </Text>
                        {Intl.DateTimeFormat().resolvedOptions().timeZone !== (game.timeZone || 'UTC') && (
                          <Text ml="7px">
                            {format(
                              utcToZonedTime(new Date(matchDate), Intl.DateTimeFormat().resolvedOptions().timeZone),
                              'do MMM yyyy hh:mm a'
                            )}
                            <small>{` (${Intl.DateTimeFormat().resolvedOptions().timeZone})`}</small>
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  )}
                </Flex>
                <Flex flex={2} direction="row" paddingTop={mode !== 'main' ? ['28px', '28px', '0px', '0px'] : '0px'}>
                  <Flex
                    flex={3}
                    justifyContent={mode !== 'main' ? ['center', 'center', 'flex-end', 'flex-end'] : 'flex-end'}
                    direction={['row', 'row', 'column', 'column']}
                    alignItems={['center', 'center', 'flex-end', 'flex-end']}
                  >
                    <Flex alignItems="flex-start">
                      <Flex direction="column" paddingRight="21px" borderRight="solid 1px #e2e8f0">
                        <ProgressSteps arrange="column" itemWidth="100%" items={progressStepsItems} />
                      </Flex>
                      <Flex direction="column" paddingLeft="21px">
                        <Button
                          colorScheme="cls.blue"
                          size="lg"
                          onClick={handleClick}
                          isDisabled={!checkPreGameIsComplete}
                          width={mode !== 'main' ? ['100%', '100%', 'auto', 'auto'] : undefined}
                          data-testid="readyToScoreButton"
                        >
                          Ready to score
                        </Button>
                        {import.meta.env.VITE_ENV_SOCKETS !== 'false' &&
                          mode === 'main' &&
                          appSettings.manualScoring.active && (
                            <Flex flex={1} marginTop="14px">
                              <Button
                                w="100%"
                                height="40px"
                                isDisabled={appSettings.manualScoring.forceSync}
                                colorScheme="teal"
                                onClick={syncMatchManual}
                              >
                                <FontAwesomeIcon
                                  icon={['far', 'cloud-upload']}
                                  size="sm"
                                  style={{ fontSize: '16px' }}
                                />
                              </Button>
                            </Flex>
                          )}
                        {mode !== 'fielding' && (
                          <Box marginTop="14px">
                            <Flex direction="row">
                              <Flex display={mode === 'betting' ? 'flex' : 'none'} flex={1} marginRight="7px">
                                <CustomButton
                                  data-testid="confirmFifteenMinutesButton"
                                  paddingX="2px"
                                  paddingXOuter="7px"
                                  isActive={isMatchStartingModalConfirmed === 2}
                                  onClick={() => {
                                    setIsMatchStartingModalOpen(true)
                                    setIsMatchStartingModalManual(true)
                                  }}
                                  hideCheck
                                >
                                  <Flex align="center">
                                    <FontAwesomeIcon
                                      icon={['fas', 'stopwatch']}
                                      size="sm"
                                      style={{ marginRight: '4px', fontSize: '14px' }}
                                    />
                                    15
                                  </Flex>
                                </CustomButton>
                              </Flex>
                              <Flex flex={2}>
                                <MatchBreak game={game} timelineEvents={timelineEvents} defaultValue="Break / Delay" />
                              </Flex>
                            </Flex>
                            <Box h="14px" />
                            <EndMatch game={game} mode={mode} timelineEvents={timelineEvents} />
                          </Box>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
            <SettingsContainer
              game={game}
              mode={mode}
              timelineEvents={timelineEvents}
              settingsConfirmed={settingsConfirmed}
              setSettingsConfirmed={setSettingsConfirmed}
              teamsConfirmed={teamsConfirmed}
              setTeamsConfirmed={setTeamsConfirmed}
              isSetup
              isScoringManually={mode === 'main' && appSettings.manualScoring.active}
            />
          </Flex>
        </Flex>
        <Flex flex="2" direction="column" display={mode !== 'main' ? 'none' : undefined}></Flex>
      </Flex>
    )
  }
)

export default Setup
