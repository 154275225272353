import type { MouseEventHandler } from 'react'

import LogoSVG from '../../assets/logo_blue_bg.svg'

type LogoProps = {
  clickHandler: MouseEventHandler<HTMLImageElement>
}

const Logo = ({ clickHandler }: LogoProps) => {
  return <img src={LogoSVG} style={{ height: '100%' }} alt="CLS+ Logo" onClick={clickHandler} data-testid="clsPlusLogo" />
}

export default Logo
