import { flow, types } from 'mobx-state-tree'

import Auth from '../../../helpers/auth'
import { RequestHandler } from '../../api/RequestHandler'
import OfficialsSearchMap from './OfficialsSearchMap'

export const OfficialsSearchStore = types
  .model('OfficialsSearchStore', {
    results: types.map(OfficialsSearchMap),
    state: types.enumeration('State', ['pending', 'done', 'error']),
  })
  .actions(self => {
    const getOfficials = async (dataProvider: string | null) => {
      const data = self.results.get('global')
      if (data) {
        return data
      }
      if (self.state === 'pending') {
        return null
      }
      return fetchOfficials(dataProvider)
    }
    const fetchOfficials = flow(function* fetchOfficials(dataProvider) {
      self.state = 'pending'

      // construct props
      const tokens = Auth.getTokens()
      const method = 'GET'
      const url = `${import.meta.env.VITE_API_URL}officials`
      const params: Record<string, string> = {}
      if (dataProvider === 'sds') params.checkSdsIntegration = 'true'

      // make request
      try {
        const response = yield RequestHandler({
          method,
          url,
          params,
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
        })
        if (response && response.ok) {
          const data = yield response.json()
          self.results.put(
            OfficialsSearchMap.create({
              type: 'global',
              data,
            })
          )
          self.state = 'done'
          return self.results.get('global')?.data
        } else {
          // we got a response but it was not ok
          // for now just mark this request as done
          // because we don't have scope to handle this better
          self.state = 'done'
        }
      } catch (error) {
        console.warn('Error getting Officials', error) // eslint-disable-line no-console
        self.state = 'error'
      }
    })
    return {
      getOfficials,
      fetchOfficials,
    }
  })

export default OfficialsSearchStore
