import { Flex } from '@chakra-ui/react'
import { capitalize } from 'lodash'
import { observer } from 'mobx-react-lite'

import { FootworkOptions } from '../../data/reference'
import type { FootworkProps } from '../../types/props'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import SubHeading from '../Headings/SubHeading'

const footworkOptions = ['FRONT_FOOT', 'BACK_FOOT', 'BATTER_MOVEMENT', 'BACKED_AWAY', 'DUCKED']

const Footwork = observer(({ ball, incompleteData }: FootworkProps) => {
  const changeFootwork = ({ value }: { value: string }) => {
    if (!ball) return
    ball.setFootwork(value)
  }

  return (
    <Flex flexDirection="column">
      <SubHeading text="Footwork" warning={incompleteData} tertiary />
      <Flex>
        {footworkOptions.map((option: string, index: number) => (
          <Flex key={`footwork${index}`} flexGrow={1} padding="0 7px 7px 0">
            <SimpleStateButton
              target="footwork"
              value={option}
              onClick={changeFootwork}
              data-testid={option + '_changeFootwork'}
              isActive={
                ball.battingAnalysis?.footworkTypeId !== null &&
                ball.battingAnalysis?.footworkTypeId !== undefined &&
                FootworkOptions[ball.battingAnalysis?.footworkTypeId] === option
                  ? true
                  : false
              }
              paddingX="2px"
              paddingXOuter="4px"
              hideCheck
              width="100%"
            >
              {capitalize(option.replace('_', ' ').replace('FOOT', 'FT').replace('MOVEMENT', 'MOVE').toLowerCase())}
            </SimpleStateButton>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
})

export default Footwork
