import { Box, Flex, Input, Text } from '@chakra-ui/react'
import type { IMatchNoteModel } from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import MatchHelpers from '../../helpers/matchHelpers'
import type { MatchNotesProps } from '../../types/props'
import { Button } from '../Buttons/Button'

export const MatchNotes = observer(({ game, notes, balls, isScoringManually }: MatchNotesProps) => {
  const handleGenerateMatchNotes = () => {
    const tempNotes = MatchHelpers.generateMatchNotes(game, balls, isScoringManually)
    game.setMatchNotes(tempNotes)
  }

  const handleMatchNotesChange = (value: string, note: IMatchNoteModel) => {
    note.setNoteDetail(value)
  }

  return (
    <Box>
      <Flex alignItems="center" marginBottom="20px">
        <Button onClick={handleGenerateMatchNotes} marginRight="5px" data-testid="generateMatchNotesButton">
          Generate Match Notes
        </Button>
        <Text fontSize="sm" fontStyle="italic">
          {`(${!isScoringManually ? 'Based on ball data. ' : ''}Generating will remove existing notes and recreate)`}
        </Text>
      </Flex>
      {notes?.map(inningsOfNotes => (
        <Box key={inningsOfNotes.inningsId} marginBottom="20px">
          <Text fontSize="lg">{inningsOfNotes.name}</Text>
          {inningsOfNotes.getNotesInOrder.map(note => (
            <Flex key={`${inningsOfNotes.inningsId}-${note.order}`}>
              <Input
                id={`${inningsOfNotes.inningsId}-${note.order}`}
                value={note.detail}
                onChange={event => handleMatchNotesChange(event.currentTarget.value, note)}
                marginBottom="3px"
                marginRight="3px"
              />
              {note.order !== Infinity && (
                <Button
                  colorScheme="red"
                  onClick={() => inningsOfNotes.removeNote(note.id)}
                  marginRight="3px"
                  data-testid="removeNoteButton"
                >
                  <FontAwesomeIcon icon={['far', 'trash-alt']} />
                </Button>
              )}
              {note.order !== Infinity && note.order > 0 && (
                <Button
                  onClick={() => inningsOfNotes.reorderNote('UP', note.id)}
                  marginRight="3px"
                  data-testid="reorderNoteUpButton"
                >
                  ▲
                </Button>
              )}
              {note.order !== Infinity && note.order < inningsOfNotes.notes.length - 1 && (
                <Button
                  onClick={() => inningsOfNotes.reorderNote('DOWN', note.id)}
                  marginRight="3px"
                  data-testid="reorderNoteDownButton"
                >
                  ▼
                </Button>
              )}
            </Flex>
          ))}
          <Button onClick={() => inningsOfNotes.createBlankNote()} data-testid="createBlankNoteButton">
            Create New Note
          </Button>
        </Box>
      ))}
    </Box>
  )
})
