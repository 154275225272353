import { Box, Flex, Text } from '@chakra-ui/react'
import type { IBattingPerformanceModel, IBowlingPerformanceModel } from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import Bowling from '../../assets/bowling.svg'
import MatchHelpers from '../../helpers/matchHelpers'
import type { DataRowsProps } from '../../types/props'

export const DataRows = observer(({ data, type, cols, short }: DataRowsProps) => {
  let playerPerfs: (IBowlingPerformanceModel | IBattingPerformanceModel)[] | undefined = []
  if (short) {
    if (type === 'Batting') {
      playerPerfs = data.getCurrentBatterPerformances
    } else {
      playerPerfs = data.getCurrentBowlerPerformances
    }
  }

  if (!playerPerfs) return null

  return (
    <Flex flex={1} direction="column" width="100%">
      {playerPerfs.map((player: IBattingPerformanceModel | IBowlingPerformanceModel, idx: number) => {
        const teamMember = player.getPlayerProfile
        return (
          <Flex
            key={`${player.playerMp.id}`}
            flex={1}
            width="100%"
            justify="flex-end"
            marginTop={short && idx > 0 ? '2px' : undefined}
          >
            {cols.map(col => (
              <Flex
                key={`${player.playerMp.id}_${col.key}`}
                data-testid={`${player.playerMp.id}_${col.key}`}
                flex={col.flexValue}
                justify={col.justifyValue}
              >
                <Text color="cls.black" fontSize="lg">
                  {col.key !== 'player_name' && MatchHelpers.getPlayerPerfValFromCol(col.key, player)}
                  {col.key === 'player_name' && (
                    <>{`${teamMember.getDisplayName()}${teamMember.getShirtNumber(true)}`}</>
                  )}
                  {type === 'Batting' && col.key === 'player_name' && player.onStrike && (
                    <FontAwesomeIcon icon={['fal', 'cricket']} size="sm" style={{ marginLeft: '7px' }} />
                  )}
                  {type === 'Bowling' && col.key === 'player_name' && player.onStrike && (
                    <Box width="17px" marginLeft="7px">
                      <img src={Bowling} style={{ height: 14, width: 14 }} alt="Bowling" />
                    </Box>
                  )}
                </Text>
              </Flex>
            ))}
          </Flex>
        )
      })}
    </Flex>
  )
})
