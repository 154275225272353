import { Flex } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import type { FieldingEventsProps } from '../../../types/props'
import { SimpleStateButton } from '../../Buttons/SimpleStateButton'

const FieldingEvents = observer(({ ball }: FieldingEventsProps) => {
  const changeMisfield = () => {
    if (!ball || !ball.fieldingAnalysis) return
    ball.fieldingAnalysis.setMisfield()
  }
  const changeMissedStumping = () => {
    if (!ball || !ball.fieldingAnalysis) return
    ball.fieldingAnalysis.setMissedStumping()
  }
  const changeDroppedCatch = () => {
    if (!ball || !ball.fieldingAnalysis) return
    ball.fieldingAnalysis.setDroppedCatch()
  }
  const changeRunOutMissed = () => {
    if (!ball || !ball.fieldingAnalysis) return
    ball.fieldingAnalysis.setMissedRunOut()
  }

  return (
    <Flex flexDirection="row" marginX="7px">
      <Flex direction="column" flex={1}>
        <Flex flex={1}>
          <SimpleStateButton
            target="misfield"
            value={ball.fieldingAnalysis?.misfielded}
            onClick={changeMisfield}
            isActive={ball.fieldingAnalysis?.misfielded}
            paddingX="7px"
            paddingXOuter="7px"
            hideCheck
            isFlex
            isFirst
            width="100%"
            data-testid="misfieldEvent"
          >
            Misfield
          </SimpleStateButton>
        </Flex>
        <Flex flex={1}>
          <SimpleStateButton
            target="dropped_catch"
            value={ball.fieldingAnalysis?.droppedCatch}
            onClick={changeDroppedCatch}
            isActive={ball.fieldingAnalysis?.droppedCatch}
            paddingX="7px"
            paddingXOuter="1px"
            hideCheck
            isFlex
            isFirst
            width="100%"
            data-testid="droppedCatchEvent"
          >
            Drop. Catch
          </SimpleStateButton>
        </Flex>
      </Flex>
      <Flex flexDirection="column">
        <Flex flex={1}>
          <SimpleStateButton
            target="stumping_missed"
            value={ball.fieldingAnalysis?.stumpingMissed}
            onClick={changeMissedStumping}
            isActive={ball.fieldingAnalysis?.stumpingMissed}
            paddingX="4px"
            paddingXOuter="4px"
            hideCheck
            isFlex
            isLast
            width="100%"
            data-testid="missedStumpingEvent"
          >
            Missed Stumping
          </SimpleStateButton>
        </Flex>
        <Flex flex={1}>
          <SimpleStateButton
            target="run_out_missed"
            value={ball.fieldingAnalysis?.runOutMissed}
            onClick={changeRunOutMissed}
            isActive={ball.fieldingAnalysis?.runOutMissed}
            paddingX="4px"
            paddingXOuter="4px"
            hideCheck
            isFlex
            isLast
            width="100%"
            data-testid="missedRunOutEvent"
          >
            Missed Run Out
          </SimpleStateButton>
        </Flex>
      </Flex>
    </Flex>
  )
})

export default FieldingEvents
