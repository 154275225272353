import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import type { StepperCallbackProps, StepperProps } from '../../types/props'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import type { TextFieldCallbackArgs } from '../TextField/TextField'
import TextField from '../TextField/TextField'

const Stepper = ({
  id,
  value,
  labels,
  min = null,
  max = null,
  increment = [1, 1],
  isRatio = false,
  ratioSeparator = ' of ',
  isUnlocked = false,
  isEditing = false,
  isDisabled = false,
  isWhite,
  ignoreState = false,
  onChange,
}: StepperProps) => {
  const [stepperValue, setStepperValue] = useState(value)

  const changeStepperValue = ({ value: operation }: StepperCallbackProps) => {
    if (operation === 'subtract' && (min === null || stepperValue > min)) {
      setStepperValue(stepperValue - increment[0])
      onChange(stepperValue - increment[0], operation)
    } else if (operation === 'add' && (max === null || stepperValue < max)) {
      setStepperValue(stepperValue + increment[1])
      onChange(stepperValue + increment[1], operation)
    }
  }
  const changeStepperValueText = ({ value }: TextFieldCallbackArgs) => {
    if (value !== null && (!isNaN(Number(value)) || value === '')) {
      if (value === '') value = '0'
      const operation = Number(value) >= stepperValue ? 'add' : 'subtract'
      setStepperValue(Number(value))
      onChange(Number(value), operation)
    }
  }

  useEffect(() => {
    if (value !== stepperValue) setStepperValue(value)
    const maxChanged: boolean = max !== null && stepperValue > max
    const minChanged: boolean = min !== null && stepperValue < min
    if (maxChanged || minChanged) {
      // min/max constraint has changed after value on stepper was set relative to previous limit...
      // ... then need to change it down/up to the new limit
      const maxMin = maxChanged && max !== null ? max : minChanged && min !== null ? min : null
      if (maxMin !== null) {
        const operation = maxMin >= stepperValue ? 'add' : 'subtract'
        setStepperValue(maxMin)
        onChange(maxMin, operation)
      }
    }
  }, [setStepperValue, onChange, min, max, stepperValue, value])

  return (
    <Flex w="100%" flexDirection="row">
      <Flex flex={1} flexDirection="column" justifyContent="flex-start">
        {labels && (
          <Text textAlign="center" fontSize="sm" letterSpacing="-0.5px" lineHeight="12px" paddingY="0px">
            {labels[0]}
          </Text>
        )}
        <SimpleStateButton
          isDisabled={!isWhite && ((min !== null && stepperValue === min) || isDisabled)}
          isActive={stepperValue < 0 && !isUnlocked && !isDisabled && !ignoreState}
          value="subtract"
          onClick={changeStepperValue}
          paddingX="2px"
          paddingXOuter="2px"
          hideCheck
          isWhite={isWhite}
          data-testid={`${id} Minus`}
        >
          {increment[0] !== increment[1] && increment[0] !== 1 && `–${increment[0]}`}
          {(increment[0] === increment[1] || increment[0] === 1) && '–'}
        </SimpleStateButton>
      </Flex>
      <Flex flex={2} flexDirection="column" justifyContent="flex-start">
        {labels && labels.length === 3 && (
          <Text textAlign="center" fontSize="sm" letterSpacing="-0.5px" lineHeight="12px" paddingY="0px">
            {labels[1]}
          </Text>
        )}
        <Flex
          height="100%"
          justifyContent="center"
          alignItems="center"
          paddingX="7px"
          paddingTop={labels && labels.length === 2 ? '12px' : undefined}
        >
          <TextField
            id={`stepperText${id}`}
            value={isRatio && max !== null ? `${stepperValue}${ratioSeparator}${max}` : `${stepperValue}`}
            defaultValue={isRatio && max !== null ? `0${ratioSeparator}${max}` : '0'}
            textAlign="center"
            delimiter={isRatio && max !== null ? ' ' : null}
            isReadOnly={!isUnlocked || isDisabled}
            onChange={changeStepperValueText}
            isEditing={isEditing}
            isStepper
            ignoreState={ignoreState}
            data-testid={`stepperText${id}`}
          />
        </Flex>
      </Flex>
      <Flex flex={1} flexDirection="column" justifyContent="flex-start">
        {labels && (
          <Text textAlign="center" fontSize="sm" letterSpacing="-0.5px" lineHeight="12px" paddingY="0px">
            {labels[labels.length - 1]}
          </Text>
        )}
        <SimpleStateButton
          isDisabled={(max !== null && stepperValue === max) || isDisabled}
          isActive={stepperValue > 0 && !isUnlocked && !isDisabled && !ignoreState}
          value="add"
          onClick={changeStepperValue}
          paddingX="2px"
          paddingXOuter="2px"
          hideCheck
          isWhite={isWhite}
          data-testid={`${id} Plus`}
        >
          {increment[0] !== increment[1] && increment[1] !== 1 && `+${increment[1]}`}
          {(increment[0] === increment[1] || increment[1] === 1) && '+'}
        </SimpleStateButton>
      </Flex>
    </Flex>
  )
}

export default Stepper
