import { useState } from 'react'

import type { SimpleStateButtonProps } from '../../types/props'
import { CustomButton } from './CustomButton'

export const SimpleStateButton = <TValue,>({
  value,
  target,
  onClick,
  isActive,
  isSwitch,
  ...props
}: SimpleStateButtonProps<TValue>) => {
  const [active, toggleActive] = useState(false)

  const handleClick = () => {
    if (onClick) {
      if (isSwitch) {
        onClick({ value: !value, target })
      } else {
        onClick({ value, target })
      }
    } else {
      // local state only
      toggleActive(!active)
    }
  }

  return <CustomButton onClick={handleClick} isActive={active || isActive} {...props} />
}
