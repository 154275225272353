import { Flex } from '@chakra-ui/react'

import type { FieldingProps } from '../../../types/props'
import SubHeading from '../../Headings/SubHeading'
import FieldingBasics from './FieldingBasics'
import FieldingEvents from './FieldingEvents'
import FieldingMisc from './FieldingMisc'

const Fielding = ({ ball, game, editBall = false, incompleteData }: FieldingProps) => {
  return (
    <Flex flexDirection="row">
      <Flex flexDirection="column" marginRight="7px">
        <SubHeading text="Fielding" warning={incompleteData.fielder} tertiary />
        <Flex flexDirection="row">
          <FieldingBasics ball={ball} game={game} editBall={editBall} />
          <FieldingEvents ball={ball} />
        </Flex>
      </Flex>
      <FieldingMisc ball={ball} editBall={editBall} incompleteData={incompleteData.byeBlame} />
    </Flex>
  )
}

export default Fielding
