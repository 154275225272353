import { Box } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'

import type { FieldingBasicsFieldedProps } from '../../../types/props'
import { SimpleStateButton } from '../../Buttons/SimpleStateButton'

const FieldingBasicsFielded = observer(
  ({ mode, ball, fielders, changeFirstFielder, disabled }: FieldingBasicsFieldedProps) => {
    const changeFielded = ({ value }: { value: boolean }) => {
      if (!ball) return
      ball.setFielded(value)
      if (!value) ball.setFieldedWicketkeeper(value)
    }
    const changeFieldedWK = ({ value }: { value: boolean }) => {
      if (!ball) return
      // mark fielded and fielded_wicket_keeper
      ball.setFieldedWicketkeeper(value)
      ball.setFielded(value)

      if (fielders && changeFirstFielder) {
        const wicketkeeper = fielders?.find(fielder => fielder.wicketKeeper === true)
        if (wicketkeeper) {
          if (value) {
            // set wicketkeeper as first (primary) fielder
            changeFirstFielder(wicketkeeper.id)
          } else if (mode === 'fielding' && !value) {
            // removing the wicketkeeper's fieldingPlayer entry
            ball.fieldingAnalysis?.removeFieldingPlayer(wicketkeeper.id)
          }
        }
      }
    }

    return (
      <>
        <Box marginBottom="7px">
          <SimpleStateButton
            width="100%"
            target="fielded"
            value={ball.fieldingAnalysis?.fielded || false}
            isActive={ball.fieldingAnalysis?.fielded}
            isDisabled={disabled ? true : false}
            onClick={changeFielded}
            paddingX="2px"
            paddingXOuter="2px"
            hideCheck
            isSwitch
            isWhite={mode === 'fielding'}
            data-testid="fieldedBasicFielding"
          >
            Fielded
          </SimpleStateButton>
        </Box>
        <Box marginBottom="7px">
          <SimpleStateButton
            width="100%"
            target="fielded_wicket_keeper"
            value={ball.fieldingAnalysis?.fieldedWicketKeeper || false}
            isActive={ball.fieldingAnalysis?.fieldedWicketKeeper}
            isDisabled={disabled ? true : false}
            onClick={changeFieldedWK}
            paddingX="2px"
            paddingXOuter="2px"
            hideCheck
            isSwitch
            isWhite={mode === 'fielding'}
            data-testid="fieldedWKBasicFielding"
          >
            Fielded (WK)
          </SimpleStateButton>
        </Box>
      </>
    )
  }
)

export default FieldingBasicsFielded
