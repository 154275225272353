import { Box, Flex, Select, Text } from '@chakra-ui/react'
import { isNil } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Button } from '../components/Buttons/Button'
import SubHeading from '../components/Headings/SubHeading'
import { MatchNotes } from '../components/MatchNotes/MatchNotes'
import { TeamNames } from '../components/TeamNames/TeamNames'
import { db } from '../data/dexie/Database'
import { MatchResultType } from '../data/reference'
import { useMst } from '../data/stores/rootStore'
import S3PHelpers from '../helpers/s3pHelpers'
import type { IBallStore, IClspMatchModel, IDetailedMatchStore, ISettingsModel } from '../types/models'
import type { PostMatchProps } from '../types/props'

export const PostMatch = observer(({ mode = 'main' }: PostMatchProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isScoringManually = location.pathname.search('manual') > -1
  const {
    detailedMatches,
    balls,
    appSettings,
  }: {
    detailedMatches: IDetailedMatchStore
    balls: IBallStore
    appSettings: ISettingsModel
  } = useMst()
  const { id } = useParams()
  if (!id) return null
  detailedMatches.getMatch(id, mode)
  const game: IClspMatchModel | undefined = detailedMatches.results.get(id)?.match
  const playerOfMatch = game?.getPlayerOfMatch
  const [isCompletingPostMatchMode, setIsCompletingPostMatchMode] = useState(false)

  const handlePlayerOfMatchChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    if (game && event.target.value) {
      game.setPlayerOfMatch(event.target.value)
      if ((mode === 'betting' || isScoringManually) && !isNil(game.matchResultTypeId)) {
        // update matchResult S3P message with our Player of the Match
        const winningTeam = !isNil(game.winningMatchTeamId) ? game.getTeamById(game.winningMatchTeamId) : null
        db.createS3PMessage(
          S3PHelpers.metadata(isScoringManually ? 'postMatch' : mode, game),
          S3PHelpers.matchResult(
            MatchResultType[game.matchResultTypeId],
            winningTeam || null,
            game,
            game.getPlayerOfMatch?.player.id,
            isScoringManually
          )
        )
      }
    }
  }

  const handleCompleteGame = () => {
    if (game && (mode === 'betting' || isScoringManually)) {
      db.createS3PMessage(
        S3PHelpers.metadata(isScoringManually ? 'postMatch' : mode, game),
        S3PHelpers.matchStatus('CONFIRMED', game)
      )
    }
    if (isScoringManually) {
      // send final match payload from "postMatch" scoring mode
      setIsCompletingPostMatchMode(true)
      appSettings.manualScoring.setForceSync(true)
      setTimeout(() => game?.setTimestamp(), 250)
      setTimeout(() => {
        appSettings.manualScoring.setForceSync(false)
        appSettings.manualScoring.setActive(false)
        setIsCompletingPostMatchMode(false)
        navigate('/games')
      }, 3000)
    } else {
      // otherwise, send final match payload as per normal
      game?.submitMatch()
      navigate('/games')
    }
  }

  if (!game) return <Text>Missing the Game</Text>

  return (
    <Flex
      h="100%"
      marginLeft={['0', '0', '14px', '14px']}
      w={mode !== 'main' ? ['100%', '100%', 'auto', 'auto'] : undefined}
    >
      <Flex flex="10" direction="column" w={mode !== 'main' ? ['100%', '100%', 'auto', 'auto'] : undefined}>
        <TeamNames game={game} mode={mode} />
        <Flex
          flex={1}
          direction="column"
          bg="white"
          borderTopLeftRadius={mode === 'main' ? '7px' : ['0px', '0px', '7px', '7px']}
          borderTopRightRadius={mode === 'main' ? '7px' : ['0px', '0px', '7px', '7px']}
          padding={mode === 'main' ? '14px 21px 0' : '14px 14px 0'}
        >
          <Flex direction="column" marginBottom="37px">
            <Box marginBottom="40px">
              <SubHeading text="Post-Match" />
              <Text data-testid="gameDescription">{game.description}</Text>
            </Box>
            <Box marginBottom="40px">
              <SubHeading text="Player Of The Match" />
              <Select
                maxWidth="400px"
                onChange={handlePlayerOfMatchChange}
                value={playerOfMatch ? playerOfMatch.id : 'default'}
              >
                <option value="default">-- Please Select --</option>
                <optgroup label={game.getHomeTeam()?.name || ''}>
                  {game.getHomeTeam()?.matchPlayers.map(mPlayer => (
                    <option key={mPlayer.id} value={mPlayer.id}>
                      {mPlayer.getFullName}
                    </option>
                  ))}
                </optgroup>
                <optgroup label={game.getAwayTeam?.name || ''}>
                  {game.getAwayTeam?.matchPlayers.map(mPlayer => (
                    <option key={mPlayer.id} value={mPlayer.id}>
                      {mPlayer.getFullName}
                    </option>
                  ))}
                </optgroup>
              </Select>
            </Box>
            <Box>
              <SubHeading text="Match Notes" />
              <MatchNotes game={game} notes={game.matchNotes} balls={balls} isScoringManually={isScoringManually} />
              <Flex marginTop="20px" justifyContent="center">
                <Button
                  size="lg"
                  colorScheme="blue"
                  onClick={handleCompleteGame}
                  data-testid="completeMatchButton"
                  isDisabled={isCompletingPostMatchMode}
                >
                  Complete Match
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex flex="2" direction="column" display={mode !== 'main' ? ['none', 'none', 'flex', 'flex'] : undefined}></Flex>
    </Flex>
  )
})
