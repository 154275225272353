import { Flex, useBreakpointValue } from '@chakra-ui/react'

import { MatchStatus } from '../../data/reference'
import type { GameDetailProps } from '../../types/props'
import GameSummary from '../GameSummary/GameSummary'
import { GameTeam } from '../GameTeam/GameTeam'

const GameDetail = ({ type, game, idx, onClick }: GameDetailProps) => {
  const isMobile = useBreakpointValue({ base: true, lg: false })

  if (!game) return null

  const homeTeam = game.getHomeTeam()
  const awayTeam = game.getAwayTeam
  const battingTeam = game.getBattingTeam()
  const gameStatus = game.matchStatusId !== null ? MatchStatus[game.matchStatusId] : 'NOT_YET_STARTED'

  return (
    <Flex
      w="100%"
      direction={['column', 'column', 'column', 'row']}
      marginTop={idx > 0 ? '14px' : '0px'}
      marginBottom="0"
      borderRadius="7px"
      backgroundColor={gameStatus === 'COMPLETED' ? 'cls.gray.200' : 'cls.backgroundGray'}
      shadow="cls.light"
      padding={['7px 7px', '7px 7px', '7px 14px', '7px 14px']}
      cursor="pointer"
      onClick={() => {
        if (type === 'local') onClick(game)
        if (type === 'system') onClick(game, gameStatus)
      }}
    >
      <Flex
        flex={1}
        justify={['flex-start', 'flex-start', 'flex-start', 'center']}
        align="center"
        marginLeft={['7px', '14px', '14px', '0px']}
      >
        {/* ALL: Home Team */}
        <GameTeam
          team={homeTeam}
          home={true}
          isBatting={homeTeam && battingTeam && homeTeam.id === battingTeam.id}
          maxOvers={game.matchConfigs?.maxOvers || null}
          dls={game.matchDls}
        />
      </Flex>
      {!isMobile && (
        <Flex flex={1} align="center" justify="center">
          {/* LAPTOP: MatchSummary */}
          <GameSummary
            status={gameStatus}
            venueName={game.venue?.fullName}
            matchDates={game.matchDates}
            dataProvider={game.dataProvider}
          />
        </Flex>
      )}
      <Flex
        flex={1}
        justify={['flex-start', 'flex-start', 'flex-start', 'center']}
        align="center"
        marginLeft={['7px', '14px', '14px', '0px']}
      >
        {/* ALL: Away Team */}
        <GameTeam
          team={awayTeam}
          isBatting={awayTeam && battingTeam && awayTeam.id === battingTeam.id}
          maxOvers={game.matchConfigs?.maxOvers || null}
          dls={game.matchDls}
        />
      </Flex>
      {isMobile && (
        <Flex marginLeft={['14px', '14px', '14px', '0px']} marginTop="7px" flex={1} align="center" justify="center">
          {/* MOBILE/TABLET: MatchSummary */}
          <GameSummary
            status={gameStatus}
            venueName={game.venue?.fullName}
            matchDates={game.matchDates}
            dataProvider={game.dataProvider}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default GameDetail
