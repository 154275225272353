import { useRegisterSW } from 'virtual:pwa-register/react'

import { NotificationBox } from '../NotificationBox/NotificationBox'

export const ServiceWorker = () => {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisterError(error) {
      // eslint-disable-next-line no-console
      console.log('Error registering service worker', error)
    },
  })

  if (!needRefresh) return null

  return (
    <NotificationBox
      onClick={() => updateServiceWorker(true)}
      id="updateAlert"
      text="App update pending"
      buttonText="Update"
      buttonIcon={['fas', 'exclamation-triangle']}
    />
  )
}
