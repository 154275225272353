import { indexOf } from 'lodash'
import { types } from 'mobx-state-tree'

import type { IClspMatchModel } from '../../../../types/models'
import { MatchStatus } from '../../../reference'
import { ClspMatchModel } from '../Match/MatchModel'

export const LocalMatchStore = types
  .model('LocalMatchStore', {
    results: types.array(types.maybe(types.reference(ClspMatchModel))),
  })
  .views(self => {
    const getNotYetStarted = (): IClspMatchModel[] =>
      self.results.filter(
        (match: IClspMatchModel | undefined): match is IClspMatchModel =>
          match?.matchStatusId === indexOf(MatchStatus, 'NOT_YET_STARTED')
      )
    const getInProgress = (): IClspMatchModel[] =>
      self.results.filter(
        (match: IClspMatchModel | undefined): match is IClspMatchModel =>
          match?.matchStatusId === indexOf(MatchStatus, 'IN_PROGRESS_PLAYING') ||
          match?.matchStatusId === indexOf(MatchStatus, 'IN_PROGRESS_IN_BREAK')
      )
    const getCompleted = (): IClspMatchModel[] =>
      self.results.filter(
        (match: IClspMatchModel | undefined): match is IClspMatchModel =>
          match?.matchStatusId === indexOf(MatchStatus, 'COMPLETED')
      )
    const getIsLocalMatch = (matchId: string): boolean =>
      !!self.results.find((match: IClspMatchModel | undefined) => match?.id === matchId)
    return {
      getNotYetStarted,
      getInProgress,
      getCompleted,
      getIsLocalMatch,
    }
  })
  .actions(self => {
    const addLocalMatch = (matchMap: { id: string; match: IClspMatchModel }) => {
      self.results.push(matchMap.id)
    }
    const removeLocalMatch = (matchId: string) => {
      const match = self.results.find(match => match?.id === matchId)
      if (match) {
        self.results.remove(match)
      }
    }
    return {
      addLocalMatch,
      removeLocalMatch,
    }
  })

export default LocalMatchStore
