import { Flex, Text } from '@chakra-ui/react'
import type { IInningModel, IMatchPlayerModel } from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router-dom'

import Bowling from '../assets/bowling.svg'
import { Button } from '../components/Buttons/Button'
import { db } from '../data/dexie/Database'
import { useMst } from '../data/stores/rootStore'
import { timeMachineDate } from '../helpers/generalHelpers'
import S3PHelpers from '../helpers/s3pHelpers'
import type { BowlingSetupProps } from '../types/props'
import SelectPlayer from './SelectPlayer'

// This screen has 2 responsibilities
// 1: create an inning if needed
// 2: create 2 batting perfs in that inning
const BowlingSetup = observer(({ mode, currentBall }: BowlingSetupProps) => {
  const navigate = useNavigate()
  const { detailedMatches, timelineEvents, appSettings } = useMst()
  const { id } = useParams()

  if (!id) {
    navigate('/')
    return null
  }

  detailedMatches.getMatch(id, mode)
  const game = detailedMatches.results.get(id)?.match
  timelineEvents.getEvents(id, mode, false, false)
  const gameTimelineEvents = timelineEvents.results.get(id)

  if (game?.tossDecisionId === null) {
    navigate('/')
  }

  const activeInning: IInningModel | undefined = game?.getActiveInning()

  const handlePlayerSelected = (player: IMatchPlayerModel) => {
    if (activeInning) {
      activeInning?.createNewBowlerPerformanceOrSpell(
        player,
        game?.matchConfigs.ballsPerOver || 6,
        undefined,
        undefined,
        undefined,
        currentBall,
        appSettings.timeMachine.baseline && appSettings.timeMachine.activated
          ? timeMachineDate(appSettings.timeMachine.baseline, appSettings.timeMachine.activated)
          : undefined
      )
      gameTimelineEvents?.addNewBowler(
        player,
        activeInning.inningsMatchOrder,
        appSettings.timeMachine.baseline,
        appSettings.timeMachine.activated
      )
      if (game && mode === 'betting') {
        // ensure 'about to start' status only set for first innings of match. Otherwise, persist with 'in progress'
        if (activeInning?.inningsMatchOrder === 1) {
          db.createS3PMessage(S3PHelpers.metadata(mode, game), S3PHelpers.matchStatus('ABOUT_TO_START', game))
        }
      }
    }
    navigate(`/game/${id}/${mode}/scoring`)
  }

  const handleGoBack = () => {
    // this handler delete's the latest inning, and either redirects back to setup or betweenInning
    game?.removeLatestInning()
    if (game?.getAllInningInOrder.length) {
      navigate(`/game/${id}/${mode}/scoring`)
    } else {
      navigate(`/game/${id}/${mode}/setup`)
    }
  }

  if (
    activeInning &&
    (activeInning.bowlingPerformances.length === 0 || !activeInning.bowlingPerformances.find(perf => perf.onStrike))
  ) {
    const players = game?.getBowlingPlayersInBattingOrder(true)
    return (
      <Flex direction="column" w="100%" alignItems="center" padding={mode === 'betting' ? '14px' : '28px 14px 14px'}>
        <Flex w="100%" maxW={['100%', '100%', 750, 750]} direction="column">
          <Flex w="100%" direction="row">
            {Number(activeInning.progressiveScores.oversBowled) === 0 && (
              <Flex paddingX="7px">
                <Button colorScheme="teal" paddingX="7px" onClick={handleGoBack}>
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-left']}
                    size="sm"
                    style={{ marginRight: '4px', fontSize: '10px' }}
                    data-testid="bowlingSetupBackButton"
                  />
                  Back
                </Button>
              </Flex>
            )}
            <Flex marginLeft="14px">
              <Text
                textTransform="uppercase"
                fontWeight={900}
                fontSize="xx-large"
                fontStyle="italic"
                letterSpacing="-0.5px"
                lineHeight="40px"
                padding={'0px 7px 14px'}
              >
                <img
                  src={Bowling}
                  style={{
                    height: '22px',
                    width: '22px',
                    marginRight: '14px',
                    display: 'inline',
                    verticalAlign: 'middle',
                  }}
                  alt="Bowling"
                />
                {activeInning.getBowlingTeam?.name}
              </Text>
            </Flex>
          </Flex>
          <SelectPlayer
            title={activeInning.bowlingPerformances.length === 0 ? 'Select first bowler' : 'Select new bowler'}
            primaryButton="Next"
            players={players}
            onChange={handlePlayerSelected}
            mode={mode}
            setup={true}
          />
        </Flex>
      </Flex>
    )
  }

  return <Text>Bowling setup</Text>
})

export default BowlingSetup
