import { flow, types } from 'mobx-state-tree'

import Auth from '../../../helpers/auth'
import { RequestHandler } from '../../api/RequestHandler'
import PlayersSearchMap from './PlayerSearchMapModel'

const PlayersSearchStore = types
  .model('PlayersSearchStore', {
    results: types.map(PlayersSearchMap),
    state: types.enumeration('State', ['pending', 'done', 'error']),
  })
  .actions(self => {
    const getPlayers = async (teamId: string, compId: string | null, dataProvider: string | null) => {
      const data = self.results.get(`${teamId}_${compId}`)
      if (data) {
        return data
      }
      if (self.state === 'pending') {
        return null
      }
      return fetchPlayers(teamId, compId, dataProvider)
    }
    const fetchPlayers = flow(function* fetchPlayers(
      teamId: string,
      compId: string | null,
      dataProvider: string | null
    ) {
      self.state = 'pending'

      // construct props
      const tokens = Auth.getTokens()
      const method = 'GET'
      const url = `${import.meta.env.VITE_API_URL}players`
      const params: Record<string, string> = {
        teamid: teamId,
        competitionid: compId || '',
      }
      if (dataProvider === 'sds') params.checkSdsIntegration = 'true'

      // make request
      try {
        const response = yield RequestHandler({
          method,
          url,
          params,
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
        })
        if (response && response.ok) {
          const data = yield response.json()
          self.results.put(
            PlayersSearchMap.create({
              teamId: `${teamId}_${compId}`,
              data,
            })
          )
          self.state = 'done'
          return self.results.get(`${teamId}_${compId}`)?.data
        } else {
          // we got a response but it was not ok
          // for now just mark this request as done
          // because we don't have scope to handle this better
          self.state = 'done'
        }
      } catch (error) {
        console.warn('Error getting Players', error) // eslint-disable-line no-console
        self.state = 'error'
      }
    })
    return {
      getPlayers,
      fetchPlayers,
    }
  })

export default PlayersSearchStore
