import { Box, Flex } from '@chakra-ui/react'
import Konva from 'konva'
import type { KonvaEventObject } from 'konva/lib/Node'
import { indexOf, round } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { Image, Layer, Stage } from 'react-konva'
import useImage from 'use-image'

import { HandedTypeOptions } from '../../data/reference'
import Theme from '../../theme/theme'
import type { ArrivalProps } from '../../types/props'
import SubHeading from '../Headings/SubHeading'
import ArrivalBackgroundLH from './arrival_background_LH.png'
import ArrivalBackgroundRH from './arrival_background_RH.png'

const Arrival = observer(({ ball, editBall, incompleteData }: ArrivalProps) => {
  const stageRef = useRef<Konva.Stage>(null)
  const layerRef = useRef<Konva.Layer>(null)
  const [arrivalBackground] = useImage(
    ball.batterMp?.player.battingHandedId === indexOf(HandedTypeOptions, 'LEFT')
      ? ArrivalBackgroundLH
      : ArrivalBackgroundRH
  )

  const clearComponent = () => {
    const stage = stageRef.current
    if (!stage) return

    stage.find('.circles').forEach(item => item.destroy())
    stage.draw()
  }

  const addCircle = (x: number, y: number) => {
    const stage = stageRef.current
    const layer = layerRef.current
    if (!layer || !stage) return

    clearComponent()
    // add the shape to the layer
    layer.add(
      new Konva.Circle({
        x,
        y,
        name: 'circles',
        radius: 6,
        fill: Theme.colors.cls.yellow['400'],
        stroke: Theme.colors.cls.yellow[800],
        strokeWidth: 2,
      })
    )
    layer.draw()
    ball.setArrival(round((x / stage.attrs.width) * 100, 4), round((y / stage.attrs.height) * 100, 4))
  }

  // TODO: layerX and layerY are being deprecated, so we should look at switching
  // them out for for offsetX and offsetY - this will also fix these TS issues
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLayerTap = (e: any): void => {
    const { evt } = e
    addCircle(evt.layerX, evt.layerY)
  }

  const handleLayerClick = (e: KonvaEventObject<MouseEvent>): void => {
    const { evt } = e
    addCircle(evt.offsetX, evt.offsetY)
  }

  const resetControl = () => {
    clearComponent()
    ball.setArrival()
  }

  useEffect(() => {
    clearComponent()
    if (ball && ball.battingAnalysis && ball.battingAnalysis.arrival) {
      const stage = stageRef.current
      const layer = layerRef.current
      if (stage && layer) {
        // add the shape to the layer
        layer.add(
          new Konva.Circle({
            x: (ball.battingAnalysis.arrival.x / 100) * stage.attrs.width,
            y: (ball.battingAnalysis.arrival.y / 100) * stage.attrs.height,
            name: 'circles',
            radius: 6,
            fill: Theme.colors.cls.yellow['400'],
            stroke: Theme.colors.cls.yellow[800],
            strokeWidth: 2,
          })
        )
        layer.draw()
      }
    }
  }, [ball])

  return (
    <Flex flexDirection="column">
      <SubHeading
        text="Arrival"
        buttonProps={
          ball && ball.battingAnalysis && ball.battingAnalysis.arrival && !editBall
            ? { text: 'Reset', onClick: resetControl }
            : undefined
        }
        warning={incompleteData}
        tertiary
      />
      <Box>
        <Stage width={215} height={328} ref={stageRef}>
          <Layer ref={layerRef} onClick={handleLayerClick} onTap={handleLayerTap}>
            <Image image={arrivalBackground} x={0} y={0} width={215} height={328} />
          </Layer>
        </Stage>
      </Box>
    </Flex>
  )
})

export default Arrival
