import { types } from 'mobx-state-tree'

const InningMetaModel = types
  .model('InningMetaModel', {
    id: types.identifier, // match_order (has to be string for map()...)
    alertedPassedScore: false,
    alertedPassedMaxOvers: false,
    alertedPassedMaxWickets: false,
  })
  .actions(self => {
    const updatePassedScore = (value: boolean) => {
      self.alertedPassedScore = value
    }
    const updatePassedOvers = (value: boolean) => {
      self.alertedPassedMaxOvers = value
    }
    const updatePassedWickets = (value: boolean) => {
      self.alertedPassedMaxWickets = value
    }
    return {
      updatePassedScore,
      updatePassedOvers,
      updatePassedWickets,
    }
  })

export default InningMetaModel
