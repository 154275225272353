import { Box, Flex, Text } from '@chakra-ui/react'
import type { GetDismissalMethodsType } from '@ias-shared/cricket-logic'
import { getDismissalMethods } from '@ias-shared/cricket-logic'
import { includes, indexOf, isNil, upperFirst } from 'lodash'
import { observer } from 'mobx-react-lite'

import { db } from '../../data/dexie/Database'
import {
  DismissalMethods,
  DismissalMethodsFielder1,
  DismissalMethodsForBall,
  DismissalMethodsForNonStrikeBatter,
  DismissalMethodsForStrikeBatter,
  DismissalMethodsReviewable,
  ExtrasTypeOptions,
  ReviewSourceOptions,
} from '../../data/reference'
import MatchHelpers from '../../helpers/matchHelpers'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { DropdownCallbackArgs, DropdownOption } from '../../types'
import type { WicketSectionProps } from '../../types/props'
import FieldingBasics from '../AdvancedScoring/Fielding/FieldingBasics'
import { CustomButton } from '../Buttons/CustomButton'
import { Checkbox } from '../Checkbox/Checkbox'
import Dropdown from '../Dropdown/Dropdown'
import SubHeading from '../Headings/SubHeading'
import ReviewSection from './ReviewSection'

const WicketSection = observer(
  ({
    ball,
    game,
    mode,
    editBall = false,
    insertBall = false,
    isPrimaryEditCheck,
    setBallRunsVal,
    setBallExtrasVal,
  }: WicketSectionProps) => {
    const battingPlayers: DropdownOption[] = []
    const dismissalDetails = getDismissalMethods({
      runs:
        ball.runsBat ||
        (ball.runsExtra &&
          !isNil(ball.extrasTypeId) &&
          (ExtrasTypeOptions[ball.extrasTypeId].search('LEG_BYE') > -1 ||
            ExtrasTypeOptions[ball.extrasTypeId].search('BYE') > -1 ||
            (ExtrasTypeOptions[ball.extrasTypeId] === 'WIDE' && ball.runsExtra > 1) ||
            (ExtrasTypeOptions[ball.extrasTypeId] === 'NO_BALL' && ball.runsExtra > 1)))
          ? true
          : false,
      wides:
        !isNil(ball.extrasTypeId) && ExtrasTypeOptions[ball.extrasTypeId] === 'WIDE' && ball.runsExtra ? true : false,
      noBalls:
        !isNil(ball.extrasTypeId) && ExtrasTypeOptions[ball.extrasTypeId].search('NO_BALL') > -1 && ball.runsExtra
          ? true
          : false,
    })
    const dismissalOptionsSource =
      editBall && ball.dismissal
        ? ball.dismissal.batterMp?.id === ball.batterMp?.id &&
          DismissalMethodsForStrikeBatter.indexOf(DismissalMethods[ball.dismissal.dismissalTypeId]) > -1
          ? DismissalMethodsForStrikeBatter
          : DismissalMethodsForNonStrikeBatter
        : DismissalMethodsForBall
    const dismissalOptions: DropdownOption[] = dismissalOptionsSource.map(method => {
      return { value: method, label: method === 'LBW' ? method : upperFirst(method.replace(/_/g, ' ').toLowerCase()) }
    })
    const reviewOptions: DropdownOption[] = DismissalMethodsReviewable.map(method => {
      return { value: method, label: method === 'LBW' ? method : upperFirst(method.replace(/_/g, ' ').toLowerCase()) }
    })
    const reviewSourceOptions: DropdownOption[] = ReviewSourceOptions.map((method, idx) => {
      return { value: `${idx}`, label: upperFirst(method.replace(/_/g, ' ').toLowerCase()) }
    })
    const activeInning = game.getActiveInning()

    if (ball.batterMp) {
      battingPlayers.push({
        value: ball.batterMp.id,
        label: `${ball.batterMp.getDisplayName()}`,
      })
    }
    if (ball.batterNonStrikeMp) {
      battingPlayers.push({
        value: ball.batterNonStrikeMp.id,
        label: `${ball.batterNonStrikeMp.getDisplayName()}`,
      })
    }
    const changeBatterOut = ({ value }: DropdownCallbackArgs) => {
      if (!ball || !ball.batterMp || !ball.batterNonStrikeMp) return
      const batterOut = [ball.batterMp, ball.batterNonStrikeMp].find(player => player.id === value)
      ball.setBatterOut(batterOut)
      if (isPrimaryEditCheck) isPrimaryEditCheck()
    }
    const changeBettingBatterOut = (value: string | null) => {
      if (!ball || !ball.batterMp || !ball.batterNonStrikeMp || !value) return
      ball.setBatterOut([ball.batterMp, ball.batterNonStrikeMp].find(player => player.id === value))
    }
    const changeDismissalType = ({ value, clearValue }: DropdownCallbackArgs) => {
      if (!ball) return
      if (!ball.dismissal || clearValue) ball.toggleDismissal({ progressiveScores: activeInning?.progressiveScores })
      ball.setDismissalType(value)
      if (!value || (value !== 'CAUGHT' && ball.fieldingAnalysis?.fieldedWicketKeeper)) {
        changeFieldedWK()
      }

      // check if we need to remove any runs/extras due to the dismissal type chosen
      if (value && !dismissalDetails.find((dismissal: GetDismissalMethodsType) => dismissal.id === value)) {
        ball.setRunsAndExtras(null, null, 'runs')
        setBallRunsVal(null)
        setBallExtrasVal(null)
      }

      if (mode === 'betting') createAppealMessage()
      if (isPrimaryEditCheck) isPrimaryEditCheck()
    }
    const changeReviewSource = ({ value, clearValue }: DropdownCallbackArgs) => {
      if (!ball) return
      if (!ball.predictives?.likelyWicket) {
        if (mode === 'betting') createLikelyWicketMessage()
        ball.setLikelyWicket(true)
      }
      if (clearValue) {
        ball.setReview(0, clearValue)
        ball.setPendingReview(false)
        ball.setAppeal(false)
        return
      }
      if (value) {
        ball.setReview(Number(value))
        ball.setPendingReview(true)
        if (ReviewSourceOptions[Number(value)] === 'PLAYER') {
          ball.setAppeal(true)
        }
      }
    }
    const changeReviewDismissalType = ({ value, clearValue }: DropdownCallbackArgs) => {
      if (!ball) return
      if (clearValue) {
        ball.setReview(0, clearValue)
        ball.setPendingReview(false)
        ball.setAppeal(false)
      }

      ball.setReviewType(value)
      if (mode === 'betting') createAppealMessage()
      if (isPrimaryEditCheck) isPrimaryEditCheck()
    }
    const changeFieldedWK = () => {
      if (!ball) return
      ball.setFieldedWicketkeeper(!ball.fieldingAnalysis?.fieldedWicketKeeper)
      ball.setFielded(!ball.fieldingAnalysis?.fieldedWicketKeeper)
    }
    const updateReviewMessaging = () => {
      if (mode === 'betting' && ball.review) {
        const type =
          isNil(ball.review.finalDecision) && isNil(ball.review.drsUmpiresCall)
            ? 'PENDING'
            : ball.review.originalDecision === ball.review.finalDecision || ball.review.drsUmpiresCall
            ? 'UNSUCCESSFUL'
            : 'SUCCESSFUL'
        const teamRequesting =
          ball.review.getReviewSource === 'PLAYER'
            ? ball.review.originalDecision
              ? activeInning?.getBattingTeam
              : activeInning?.getBowlingTeam
            : undefined
        db.createS3PMessage(
          S3PHelpers.metadata(mode, game),
          S3PHelpers.decisionReview(type, ball.review.getReviewSource || 'PLAYER', game, ball, teamRequesting)
        )
      }
    }
    const createAppealMessage = () => {
      if (!ball.predictives?.likelyWicket) {
        createLikelyWicketMessage()
        ball.setLikelyWicket(true)
      }
      ball.setAppeal(!ball.appeal)
      if (ball.appeal) {
        db.createS3PMessage(
          S3PHelpers.metadata(mode || 'betting', game),
          S3PHelpers.appeal(game, ball, ball.review?.getDismissalType || ball.dismissal?.getDismissalType || undefined)
        )
      }
    }
    const createLikelyWicketMessage = () => {
      db.createS3PMessage(S3PHelpers.metadata(mode || 'betting', game), S3PHelpers.possibleWicket(game))
    }

    return (
      <Flex flex={1} w="100%" direction="row">
        <Flex
          flex={1}
          width="100%"
          padding={mode !== 'main' ? '11px 0 0' : '7px 0'}
          direction="column"
          align={mode !== 'main' ? 'flex-start' : 'center'}
        >
          <Flex
            justify={mode !== 'main' ? undefined : 'space-between'}
            width={mode !== 'main' ? '100%' : undefined}
            marginBottom={mode !== 'main' ? '7px' : undefined}
            direction={mode === 'main' ? 'row' : 'column'}
            align={mode === 'main' ? undefined : 'flex-end'}
          >
            <Flex
              width="100%"
              direction={mode !== 'main' ? 'column' : 'row'}
              alignItems={mode !== 'main' ? 'center' : 'flex-start'}
              marginBottom={mode !== 'main' ? '7px' : undefined}
            >
              <Flex w="100%" flex={1} direction="row">
                <Flex w="100%" flex={1} direction={mode !== 'main' ? 'row' : 'column-reverse'}>
                  <Flex w="100%" flex={1}>
                    {mode === 'betting' && (
                      <Box marginRight="7px">
                        <CustomButton
                          height="44px"
                          width="80px"
                          onClick={createAppealMessage}
                          isActive={ball.appeal}
                          hideCheck
                          data-testid="appealWicketButton"
                        >
                          <Text fontSize={14}>Appeal</Text>
                        </CustomButton>
                      </Box>
                    )}
                    <Dropdown
                      options={reviewSourceOptions}
                      value={ball.review?.getReviewSource ? `Review (${ball.review.getReviewSource})` : 'REVIEW'}
                      origValue="REVIEW"
                      onChange={changeReviewSource}
                      height="44px"
                      width={mode === 'main' ? '127px' : '100%'}
                      placement={mode !== 'main' ? 'auto' : 'right'}
                      hideCheck={true}
                      globalDisable={editBall || insertBall}
                      buttonDisable={editBall || insertBall}
                      isClearable={!editBall && !insertBall}
                      isEditing={editBall && ball.review !== null}
                      isIncomplete={
                        ball.pendingReview && (!ball.review || !ball.review.reviewSourceTypeId === null) ? true : false
                      }
                      preserveCase
                      data-testid="wicketReviewSourceDropdown"
                    />
                  </Flex>
                  <Flex
                    flex={1}
                    marginLeft={mode !== 'main' ? '7px' : '0px'}
                    marginBottom={mode === 'main' ? '7px' : '0px'}
                  >
                    {mode === 'main' && (
                      <Box marginRight="7px">
                        <CustomButton
                          height="44px"
                          width="60px"
                          onClick={() => {
                            if (!ball.predictives?.likelyWicket) ball.setLikelyWicket(true)
                            ball.setAppeal(!ball.appeal)
                          }}
                          isActive={ball.appeal}
                          hideCheck
                          data-testid="appealWicketButton"
                        >
                          <Text fontSize={14}>Appeal</Text>
                        </CustomButton>
                      </Box>
                    )}
                    <CustomButton
                      height="44px"
                      width={mode === 'main' ? '60px' : undefined}
                      onClick={() => {
                        if (!ball.predictives?.likelyWicket) {
                          if (mode === 'betting') createLikelyWicketMessage()
                          ball.setLikelyWicket(true)
                        }
                        ball.togglePendingDismissal(activeInning?.progressiveScores)
                        if (!ball.pendingDismissal && ball.fieldingAnalysis?.fieldedWicketKeeper) {
                          changeFieldedWK()
                        }
                      }}
                      isActive={ball.pendingDismissal}
                      isDisabled={editBall || insertBall}
                      isFlex
                      hideCheck
                      data-testid="wicketButton"
                    >
                      <Text fontSize={14}>Wicket</Text>
                    </CustomButton>
                  </Flex>
                </Flex>
              </Flex>
              <Flex
                w="100%"
                flex={1}
                direction={mode !== 'main' ? 'row' : 'column-reverse'}
                marginLeft={mode === 'main' ? '7px' : '0px'}
                display={mode === 'main' || ball.pendingDismissal || ball.pendingReview ? 'flex' : 'none'}
              >
                <Flex flex={1} marginTop="7px">
                  <Dropdown
                    options={reviewOptions}
                    value={
                      ball.review?.dismissalTypeId !== null && ball.review?.dismissalTypeId !== undefined
                        ? `Review (${MatchHelpers.formatDismissalTypeToAbbrev(
                            DismissalMethods[ball.review.dismissalTypeId]
                          )})`
                        : 'REVIEW TYPE'
                    }
                    origValue="REVIEW TYPE"
                    onChange={changeReviewDismissalType}
                    height="44px"
                    width={mode === 'main' ? '105px' : '100%'}
                    placement={mode !== 'main' ? 'auto' : 'right'}
                    hideCheck={true}
                    globalDisable={editBall}
                    buttonDisable={editBall || !ball.pendingReview}
                    isClearable={!editBall}
                    isEditing={editBall && ball.review !== null}
                    isIncomplete={
                      ball.pendingReview && (!ball.review || isNil(ball.review.dismissalTypeId)) ? true : false
                    }
                    preserveCase
                    data-testid="wicketReviewTypeDropdown"
                  />
                </Flex>
                <Flex
                  flex={1}
                  direction="row"
                  marginLeft={mode !== 'main' ? '7px' : '0px'}
                  marginTop={mode !== 'main' ? '7px' : '0px'}
                >
                  <Dropdown
                    options={dismissalOptions}
                    value={
                      ball.dismissal?.dismissalTypeId !== null && ball.dismissal?.dismissalTypeId !== undefined
                        ? mode !== 'main'
                          ? `WICKET (${DismissalMethods[ball.dismissal.dismissalTypeId]})`
                          : DismissalMethods[ball.dismissal.dismissalTypeId]
                        : 'WICKET TYPE'
                    }
                    origValue="WICKET TYPE"
                    onChange={changeDismissalType}
                    height="44px"
                    width={mode === 'main' ? '105px' : '100%'}
                    listHeight={mode !== 'main' ? '215px' : undefined}
                    placement={mode !== 'main' ? 'auto' : 'right'}
                    hideCheck={true}
                    globalDisable={editBall && ball.dismissal === null}
                    buttonDisable={(editBall || insertBall) && (ball.dismissal === null || !ball.pendingDismissal)}
                    isClearable={!editBall}
                    isEditing={editBall && ball.dismissal !== null}
                    isIncomplete={
                      ball.pendingDismissal && (!ball.dismissal || isNil(ball.dismissal.dismissalTypeId)) ? true : false
                    }
                    preserveCase
                    data-testid="wicketDismissalTypeDropdown"
                  />
                </Flex>
              </Flex>
              <Flex
                flex={1}
                w="100%"
                direction={mode === 'main' ? 'column' : 'row'}
                marginLeft={mode === 'main' ? '7px' : undefined}
                marginTop={mode !== 'main' ? '14px' : undefined}
                display={mode === 'main' || ball.pendingDismissal || ball.pendingReview ? 'flex' : 'none'}
              >
                {mode === 'main' && (
                  <>
                    <Flex direction="row">
                      <Dropdown
                        options={battingPlayers}
                        value={ball.dismissal?.batterMp ? ball.dismissal?.batterMp?.getDisplayName() : 'Batter Out'}
                        origValue="Batter Out"
                        onChange={changeBatterOut}
                        buttonDisable={
                          !ball.dismissal ||
                          includes(DismissalMethodsForStrikeBatter, DismissalMethods[ball.dismissal.dismissalTypeId])
                        }
                        height="44px"
                        width="230px"
                        isEditing={editBall && ball.dismissal !== null}
                        isIncomplete={ball.dismissal && !ball.dismissal.batterMp ? true : false}
                        hideCheck
                        data-testid="wicketBatterOutDropdown"
                      />
                      <Flex direction="column" alignItems="center" marginLeft={mode === 'main' ? '14px' : '7px'}>
                        <SubHeading text="Crossed" tertiary noPadding />
                        <Flex align="center" height="28px">
                          <Checkbox
                            isDisabled={
                              !ball.dismissal ||
                              !includes(
                                ['RUN_OUT', 'CAUGHT', 'OBSTRUCTING_FIELD'],
                                DismissalMethods[ball.dismissal.dismissalTypeId]
                              )
                            }
                            colorScheme="cls.yellow"
                            isChecked={!!ball.dismissal && ball.dismissal.battersCrossed}
                            onChange={() => {
                              ball.setBattersCrossed(!ball.dismissal?.battersCrossed)
                              if (isPrimaryEditCheck) isPrimaryEditCheck()
                            }}
                            data-testid="crossedYes"
                          >
                            Yes
                          </Checkbox>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex direction="row" align="flex-start" marginTop="7px">
                      <ReviewSection
                        activeInning={activeInning}
                        ball={ball}
                        mode={mode}
                        editBall={editBall}
                        updateReviewMessaging={updateReviewMessaging}
                      />
                    </Flex>
                  </>
                )}
                {mode !== 'main' && (
                  <Flex direction="row" flex={1}>
                    <Flex direction="column" flex={4.5}>
                      <SubHeading
                        text="Batter Out"
                        tertiary
                        noPadding
                        warning={ball.dismissal && !ball.dismissal.batterMp ? true : false}
                      />
                      <Flex w="100%">
                        <Box w="50%" marginRight="7px">
                          <CustomButton
                            width={mode !== 'main' ? '100%' : undefined}
                            height="28px"
                            isDisabled={editBall || !ball.dismissal}
                            isActive={
                              ball.dismissal?.batterMp &&
                              ball.batterMp &&
                              ball.dismissal.batterMp.id === ball.batterMp.id
                                ? true
                                : false
                            }
                            onClick={() => changeBettingBatterOut(ball.batterMp ? ball.batterMp?.id : null)}
                            hideCheck={mode !== 'main'}
                            isFlex={mode !== 'main'}
                            data-testid={`${ball.batterMp?.getDisplayName()}Dismissal`}
                          >
                            <Text fontSize={14}>{ball.batterMp?.getDisplayName()}</Text>
                          </CustomButton>
                        </Box>
                        <Box w="50%" marginRight="7px">
                          <CustomButton
                            width={mode !== 'main' ? '100%' : undefined}
                            height="28px"
                            isDisabled={editBall || !ball.dismissal}
                            isActive={
                              ball.dismissal?.batterMp &&
                              ball.batterNonStrikeMp &&
                              ball.dismissal.batterMp.id === ball.batterNonStrikeMp.id
                                ? true
                                : false
                            }
                            onClick={() =>
                              changeBettingBatterOut(ball.batterNonStrikeMp ? ball.batterNonStrikeMp?.id : null)
                            }
                            hideCheck={mode !== 'main'}
                            isFlex={mode !== 'main'}
                            data-testid={`${ball.batterNonStrikeMp?.getDisplayName()}Dismissal`}
                          >
                            <Text fontSize={14}>{ball.batterNonStrikeMp?.getDisplayName()}</Text>
                          </CustomButton>
                        </Box>
                      </Flex>
                    </Flex>
                    <Flex direction="row" flex={2}>
                      <Flex direction="column" flex={1} alignItems={['flex-start', 'center', 'center', 'center']}>
                        <SubHeading text="C (WK)" tertiary noPadding />
                        <Checkbox
                          isDisabled={!ball.dismissal || DismissalMethods[ball.dismissal.dismissalTypeId] !== 'CAUGHT'}
                          colorScheme="cls.yellow"
                          isChecked={ball.fieldingAnalysis?.fieldedWicketKeeper}
                          onChange={() => {
                            changeFieldedWK()
                          }}
                          data-testid="caughtWicket"
                        >
                          Yes
                        </Checkbox>
                      </Flex>
                      <Flex
                        flex={1}
                        direction="column"
                        alignItems={['flex-start', 'center', 'center', 'center']}
                        marginLeft="7px"
                      >
                        <SubHeading text="Crossed" tertiary noPadding />
                        <Checkbox
                          isDisabled={
                            !ball.dismissal ||
                            !includes(
                              ['RUN_OUT', 'CAUGHT', 'OBSTRUCTING_FIELD'],
                              DismissalMethods[ball.dismissal.dismissalTypeId]
                            )
                          }
                          colorScheme="cls.yellow"
                          isChecked={!!ball.dismissal && ball.dismissal.battersCrossed}
                          onChange={() => {
                            ball.setBattersCrossed(!ball.dismissal?.battersCrossed)
                            if (isPrimaryEditCheck) isPrimaryEditCheck()
                          }}
                          data-testid="crossedYes"
                        >
                          Yes
                        </Checkbox>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
          {mode !== 'main' && (
            <Flex w="100%" direction="row">
              <Flex
                flex={4.5}
                width="100%"
                margin="0 14px 14px 0"
                direction="column"
                align="center"
                display={ball.pendingDismissal || ball.pendingReview ? 'flex' : 'none'}
              >
                <ReviewSection
                  activeInning={activeInning}
                  ball={ball}
                  mode={mode}
                  editBall={editBall}
                  updateReviewMessaging={updateReviewMessaging}
                />
              </Flex>
              {(ball.pendingDismissal || ball.dismissal || ball.pendingReview) && (
                <Flex flex={2}>
                  <Flex w="100%" direction="column" paddingX={['7px', '14px', '7px', '28px']} align="flex-start">
                    <SubHeading text="Fielders" tertiary noPadding />
                    <FieldingBasics
                      ball={ball}
                      game={game}
                      mode="betting"
                      editBall={editBall}
                      isDisabled={
                        !ball.dismissal ||
                        (!isNil(ball.dismissal.dismissalTypeId) &&
                          indexOf(DismissalMethodsFielder1, DismissalMethods[ball.dismissal.dismissalTypeId]) === -1)
                          ? true
                          : false
                      }
                    />
                  </Flex>
                </Flex>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
    )
  }
)

export default WicketSection
