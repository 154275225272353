import { Box, Flex } from '@chakra-ui/react'
import Konva from 'konva'
import type { KonvaEventObject } from 'konva/lib/Node'
import { round } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { Image, Layer, Stage } from 'react-konva'
import useImage from 'use-image'

import { getAngleAndLength } from '../../helpers/fieldingHelpers'
import Theme from '../../theme/theme'
import type { WagonWheelProps } from '../../types/props'
import SubHeading from '../Headings/SubHeading'
import FieldLH from './FieldLH.png'
import FieldRH from './FieldRH.png'
import Person from './Person.png'

const WagonWheel = observer(({ ball, editBall, incompleteData }: WagonWheelProps) => {
  const stageRef = useRef<Konva.Stage>(null)
  const layerRef = useRef<Konva.Layer>(null)
  const [fielder] = useImage(Person)
  const [field] = useImage(ball.batterMp?.getBattingHanded === 'LEFT' ? FieldLH : FieldRH)
  const stageDimensions = { width: 303, height: 328 }
  const stageOrigin = {
    x: stageDimensions.width / 2,
    y: stageDimensions.height * 0.435,
  }

  const clearComponent = (type?: string) => {
    const stage = stageRef.current
    if (!stage) return

    // remove any lines that exist
    if (!type || type === 'lines') {
      stage.find('.lines').forEach(item => item.destroy())
    }

    // remove any circles that exist
    if (!type || type === 'circles') {
      stage.find('.circles').forEach(item => item.destroy())
    }

    stage.draw()
  }
  const addCircle = (x: number, y: number) => {
    const layer = layerRef.current
    if (!layer) return

    clearComponent('circles')

    // add the shape to the layer
    layer.add(
      new Konva.Circle({
        x,
        y,
        name: 'circles',
        radius: 6,
        fill: Theme.colors.cls.yellow['400'],
        stroke: Theme.colors.cls.yellow[800],
        strokeWidth: 2,
      })
    )
    layer.draw()
  }
  const addLine = (x: number, y: number) => {
    const layer = layerRef.current
    if (!layer) return

    clearComponent('lines')

    // add the line to the layer
    layer.add(
      new Konva.Line({
        name: 'lines',
        points: [stageOrigin.x, stageOrigin.y, x, y],
        stroke: 'black',
        strokeWidth: 2,
        lineCap: 'round',
        lineJoin: 'round',
      })
    )
    layer.draw()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLayerTap = (e: any): void => {
    const stage = stageRef.current
    if (!stage) return

    const { evt } = e
    addLine(evt.layerX, evt.layerY)
    addCircle(evt.layerX, evt.layerY)
    const sP = getAngleAndLength(
      evt.layerX,
      evt.layerY,
      stageOrigin.x,
      stageOrigin.y,
      stageDimensions.width,
      stageDimensions.height
    )
    ball.battingAnalysis?.shots.updateWagonWheel({
      x: round((evt.offsetX / stage.attrs.width) * 100, 2),
      y: round((evt.offsetY / stage.attrs.height) * 100, 2),
      angle: sP.angle,
      length: sP.length,
    })
  }
  const handleLayerClick = (e: KonvaEventObject<MouseEvent>): void => {
    const stage = stageRef.current
    if (!stage) return

    const { evt } = e
    addLine(evt.offsetX, evt.offsetY)
    addCircle(evt.offsetX, evt.offsetY)
    const sP = getAngleAndLength(
      evt.offsetX,
      evt.offsetY,
      stageOrigin.x,
      stageOrigin.y,
      stageDimensions.width,
      stageDimensions.height
    )
    ball.battingAnalysis?.shots.updateWagonWheel({
      x: round((evt.offsetX / stage.attrs.width) * 100, 2),
      y: round((evt.offsetY / stage.attrs.height) * 100, 2),
      angle: sP.angle,
      length: sP.length,
    })
  }

  const resetControl = () => {
    clearComponent()
    ball.battingAnalysis?.shots.updateWagonWheel()
  }

  useEffect(() => {
    const stage = stageRef.current
    if (!stage) return

    clearComponent()

    if (ball && ball.battingAnalysis?.shots.wagonWheel) {
      addLine(
        (ball.battingAnalysis.shots.wagonWheel.x / 100) * stage.attrs.width,
        (ball.battingAnalysis.shots.wagonWheel.y / 100) * stage.attrs.height
      )
      addCircle(
        (ball.battingAnalysis.shots.wagonWheel.x / 100) * stage.attrs.width,
        (ball.battingAnalysis.shots.wagonWheel.y / 100) * stage.attrs.height
      )
    }
  })

  return (
    <Flex flexDirection="column">
      <SubHeading
        text="Wagon Wheel"
        buttonProps={
          ball && ball.battingAnalysis?.shots.wagonWheel && !editBall
            ? { text: 'Reset', onClick: resetControl }
            : undefined
        }
        warning={incompleteData}
        tertiary
      />
      <Box>
        <Stage width={stageDimensions.width} height={stageDimensions.height} ref={stageRef}>
          <Layer ref={layerRef} onClick={handleLayerClick} onTap={handleLayerTap}>
            <Image image={field} x={0} y={0} width={stageDimensions.width} height={stageDimensions.height} />
            {ball &&
              ball.fieldingAnalysis &&
              ball.fieldingAnalysis.fieldingPositions?.map((coords, idx: number) => (
                <Image
                  key={idx}
                  name="person"
                  image={fielder}
                  x={(coords.placement.x / 100) * stageDimensions.width}
                  y={(coords.placement.y / 100) * stageDimensions.height}
                  width={22}
                  height={22}
                  opacity={0.6}
                />
              ))}
          </Layer>
        </Stage>
      </Box>
    </Flex>
  )
})

export default WagonWheel
