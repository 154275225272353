import { Flex, Tag, TagLabel, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import type { ProgressStepsProps } from '../../types/props'

const ProgressSteps = ({ arrange, itemWidth, items }: ProgressStepsProps) => {
  return (
    <Flex direction={arrange} w={arrange === 'column' ? '100%' : 'auto'}>
      {items.map((item, idx) => (
        <Flex
          key={`progressSteps${idx}`}
          w={arrange === 'column' ? '100%' : 'auto'}
          marginLeft={arrange === 'row' && idx > 0 ? '7px' : '0px'}
          marginTop={arrange === 'column' && idx > 0 ? '7px' : '0px'}
          justifyContent={arrange === 'column' ? 'center' : 'flex-start'}
        >
          <Tag
            size="lg"
            width={itemWidth || 'auto'}
            borderRadius="full"
            variant={item.isComplete ? 'solid' : 'outline'}
            colorScheme={item.isComplete ? 'green' : 'red'}
            justifyContent={itemWidth ? 'center' : 'flex-start'}
          >
            <Flex direction="row" alignItems="center">
              <FontAwesomeIcon
                icon={['fas', item.isComplete ? 'check-circle' : 'exclamation-circle']}
                color={item.isComplete ? 'white' : 'red'}
                size="sm"
                style={{ marginRight: '7px', fontSize: '12px' }}
              />
              <TagLabel>{item.text}</TagLabel>
              {item.isClearable && item.onClear && item.isComplete && (
                <Flex
                  direction="row"
                  alignItems="center"
                  marginLeft="10px"
                  backgroundColor="cls.yellow.400"
                  borderRadius="7px"
                  onClick={() => {
                    if (item.onClear) item.onClear()
                  }}
                  cursor="pointer"
                >
                  <Text fontSize="xs" padding="4px 5px" color="cls.black">
                    {item.labelClear || 'Reset'}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Tag>
        </Flex>
      ))}
    </Flex>
  )
}

export default ProgressSteps
