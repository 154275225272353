import type { ITimelineMatchMapModel } from '@clsplus/cls-plus-data-models'
import { TimelineMatchMapModel } from '@clsplus/cls-plus-data-models'
import { destroy, detach, flow, getRoot, types } from 'mobx-state-tree'

import Auth from '../../../../helpers/auth'
import { RequestHandler } from '../../../api/RequestHandler'
import { db } from '../../../dexie/Database'
import type { IRootStore } from '../../rootStore'

const TimelineStore = types
  .model('TimelineStore', {
    results: types.map(TimelineMatchMapModel),
    state: types.enumeration('State', ['pending', 'done', 'error']),
  })
  .actions(self => {
    const getEvents = (
      matchId: string,
      mode: string,
      matchIsLocal: boolean,
      force = false
    ): ITimelineMatchMapModel | Promise<void> | undefined => {
      const data = self.results.get(matchId)
      if (data && !force) {
        return data
      }
      if (self.state === 'pending') {
        return
      }
      if (force) {
        // set socket force back to false
        try {
          const root: IRootStore = getRoot(self)
          root.socketStore.setDataForceReloadEvents(false)
          if (data) destroy(data)
        } catch {
          // eslint-disable-next-line
          console.error('unable to get parent of MatchStore')
        }
        return fetchTimelineEvents(matchId, mode)
      }
      if (matchIsLocal) {
        return fetchLocalTimelineEvents(matchId)
      }
      return fetchTimelineEvents(matchId, mode)
    }
    const fetchTimelineEvents = flow(function* fetchTimelineEvents(matchId: string, mode: string) {
      let dataAdded = false
      self.state = 'pending'
      const tokens = Auth.getTokens()
      const method = 'GET'
      const url = `${import.meta.env.VITE_API_URL}events/${matchId}?mode=${mode}`
      const params = {}
      // make request
      try {
        const response = yield RequestHandler({
          method,
          url,
          params,
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
        })
        if (response && response.ok) {
          const data = yield response.json()
          self.results.put(
            TimelineMatchMapModel.create({
              id: matchId,
              events: data,
            })
          )
          db.events.put({ key: matchId, events: data })
          dataAdded = true
          self.state = 'done'
        } else {
          self.state = 'done'
          self.results.put(
            TimelineMatchMapModel.create({
              id: matchId,
              events: [],
            })
          )
        }
      } catch (error) {
        console.warn('Error getting Timeline Events', error) // eslint-disable-line no-console
        self.state = 'error'
      }
      if (!dataAdded) {
        self.results.put(
          TimelineMatchMapModel.create({
            id: matchId,
            events: [],
          })
        )
      }
    })

    const fetchLocalTimelineEvents = flow(function* fetchLocalTimelineEvents(matchId: string) {
      self.state = 'pending'
      const timelineEvents = yield db.events.get(matchId)
      if (timelineEvents) {
        self.results.put(
          TimelineMatchMapModel.create({
            id: matchId,
            events: timelineEvents.events,
          })
        )
      } else {
        self.results.put(
          TimelineMatchMapModel.create({
            id: matchId,
            events: [],
          })
        )
      }
      self.state = 'done'
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loadMockData = (data: any, matchId: string) => {
      if (data.length && data.length > 0) {
        self.results.put(
          TimelineMatchMapModel.create({
            id: matchId,
            events: data,
          })
        )
        db.events.put({ key: matchId, events: data })
      }
    }

    const deleteMatchData = (matchID: string) => {
      // check if we have any ballInnings for this match, if so remove them
      const events = self.results.get(matchID)

      if (events) {
        detach(events)
        destroy(events)
      }
    }

    return {
      getEvents,
      fetchTimelineEvents,
      loadMockData,
      deleteMatchData,
    }
  })

export default TimelineStore
