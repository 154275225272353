import { Flex } from '@chakra-ui/react'
import { capitalize } from 'lodash'
import { observer } from 'mobx-react-lite'

import { BowlerBallTypeOptions, BowlerBallTypeOptionsBracketed, BowlerTypeOptions } from '../../data/reference'
import type { DeliveryProps } from '../../types/props'
import { SimpleStateButton } from '../Buttons/SimpleStateButton'
import SubHeading from '../Headings/SubHeading'

const Delivery = observer(({ ball }: DeliveryProps) => {
  const changeDelivery = (value: string) => {
    if (!ball) return
    ball.setDelivery(value)
  }
  const checkDeliveryAgainstBowlerType = (option: string) => {
    if (!ball) return
    const bowlerType =
      ball.bowlingAnalysis?.bowlerTypeId !== null && ball.bowlingAnalysis?.bowlerTypeId !== undefined
        ? BowlerTypeOptions[ball.bowlingAnalysis.bowlerTypeId]
        : null
    const isPace: boolean = bowlerType === 'MEDIUM' || bowlerType === 'MEDIUM_FAST' || bowlerType === 'FAST'
    return bowlerType !== null && ((option === 'WRONG_UN' && isPace) || (option === 'SLOWER' && !isPace))
  }

  return (
    <Flex flexDirection="column" paddingRight="7px">
      <SubHeading text="Delivery" tertiary />
      <Flex>
        {BowlerBallTypeOptionsBracketed.map((bracket: string[], idx: number) => (
          <Flex key={`ballTypeB${idx}`} flexDirection="row" flexWrap="wrap">
            {bracket.map((option: string, index: number) => (
              <Flex key={`ballType${index}`} flexGrow={1} paddingBottom="7px" marginRight="7px">
                <SimpleStateButton
                  data-testid={option + '_changeDelivery'}
                  target="bowler_ball_type"
                  value={option}
                  onClick={({ value }) => changeDelivery(value)}
                  isActive={
                    ball.bowlingAnalysis?.bowlerBallTypeId !== null &&
                    ball.bowlingAnalysis?.bowlerBallTypeId !== undefined &&
                    BowlerBallTypeOptions[ball.bowlingAnalysis?.bowlerBallTypeId] === option
                      ? true
                      : false
                  }
                  isDisabled={checkDeliveryAgainstBowlerType(option)}
                  width="100%"
                  hideCheck
                >
                  {capitalize(option.replace('_', ' ').toLowerCase())}
                </SimpleStateButton>
              </Flex>
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
})

export default Delivery
