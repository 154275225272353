import type { IBallModel } from '@clsplus/cls-plus-data-models'
import { replace } from 'lodash'

import { ExtrasTypeAbbreviations } from '../../data/reference'

const BallSummary = (ball: IBallModel, noBallValue: number = 1) => {
  let summary: string | number = 0

  if (ball.dismissal) {
    summary = 'W'
    if (ball.runsBat) {
      summary = `${summary} + ${ball.runsBat}`
    }
    if (ball.runsExtra) {
      const abbr = ExtrasTypeAbbreviations.find(ref => ref.key === ball.getExtraType)
      if (!abbr) return `${summary} + ${ball.runsExtra}`
      if (ball.getExtraType === 'NO_BALL_LEG_BYE' || ball.getExtraType === 'NO_BALL_BYE') {
        abbr.value = replace(`${ball.runsExtra - 1}`, '#', abbr.value)
        return `${summary}+${abbr.value}`
      }
      summary = `${summary}+${ball.runsExtra === 1 ? '' : ball.runsExtra}${abbr.value}`
    }
    return summary
  }

  if (ball.runsBat) {
    summary = `${ball.runsBat}`
  }
  if (ball.runsExtra) {
    const abbr = ExtrasTypeAbbreviations.find(ref => ref.key === ball.getExtraType)
    if (!abbr) {
      return `${ball.getExtraType}`
    }
    if (ball.getExtraType === 'NO_BALL_LEG_BYE' || ball.getExtraType === 'NO_BALL_BYE') {
      summary = replace(abbr.value, '#', ball.runsExtra - noBallValue > 1 ? `${ball.runsExtra - noBallValue}` : '')
    } else {
      if (summary !== 0) {
        summary = `${abbr.value}+${summary}`
      } else {
        summary = `${
          (ball.runsExtra === 1 && ball.getExtraType !== 'NO_BALL') ||
          (ball.getExtraType === 'NO_BALL' && ball.runsExtra === noBallValue)
            ? ''
            : ball.runsExtra
        }${abbr.value}`
      }
    }
  }
  return summary.toString()
}

export default BallSummary
