import { Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Auth from '../../helpers/auth'

const Profile = () => {
  const profile = Auth.getUserProfile()
  const handleLogout = () => {
    // auth Auth.logout
    Auth.logout()
    // reload the page - so we force the App.tsx to load again
    if (window) {
      window?.location?.reload()
    }
  }
  return (
    <Flex align="center">
      <Menu>
        <MenuButton>
          <Flex align="center">
            <FontAwesomeIcon icon={['fad', 'user-circle']} size="lg" color="white" />
            <Text
              display={['none', 'block', 'block', 'block']}
              as="span"
              fontSize="md"
              fontWeight={400}
              color="white"
              paddingLeft="7px"
            >
              {profile && `${profile.given_name} ${profile.family_name}`}
            </Text>
          </Flex>
        </MenuButton>
        <MenuList borderColor="gray.200">
          <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}

export default Profile
