import { MatchModelSolid } from '@clsplus/cls-plus-data-models'
import { flatten, isNil } from 'lodash'
import { castToSnapshot, flow, types } from 'mobx-state-tree'

import Auth from '../../../../helpers/auth'
import { RequestHandler } from '../../../api/RequestHandler'

const tempMatchModel = MatchModelSolid.named('tempMatchModel')
  .preProcessSnapshot(sn => {
    if (!sn) return sn
    // check if the match sn has DLS, if it does then we need to check if it's got the old data model
    // and make sure it has inningsId rather than inningsMatchOrder
    if (sn.matchDls) {
      sn.matchDls.matchDlsSuspensions = sn.matchDls.matchDlsSuspensions?.map(susp => {
        if (susp.hasOwnProperty('inningsMatchOrder') && !susp.inningsId && sn.matchTeams) {
          // @ts-ignore
          const inningsMatchOrder = susp.inningsMatchOrder
          // find the actual inningsId that this inningsMatchOrder represented
          const inningsAndOrders = flatten(
            sn.matchTeams.map(t =>
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              t.innings ? t.innings.map((i: any) => ({ id: i.id, order: i.inningsMatchOrder })) : []
            )
          )
          if (inningsAndOrders.length > 0) {
            const match = inningsAndOrders.find(o => o.order === inningsMatchOrder)
            if (match) {
              susp.inningsId = match?.id
            }
          }
        }
        return susp
      })
    }
    if (isNil(sn.likelyRainDelay)) sn.likelyRainDelay = false
    if (isNil(sn.wasLiveScored)) sn.wasLiveScored = true
    if (isNil(sn.isLiveScore)) sn.isLiveScore = true
    // convert the match notes into a JS object, rather than being a string
    if (sn.matchNotes && typeof sn.matchNotes === 'string') {
      // @ts-ignore
      sn.matchNotes = JSON.parse(sn.matchNotes)
    }
    // If this is a OneDay or T20 match we are going to set the match config to auto free hit after no ball
    if (!isNil(sn.matchFormatId) && sn.matchFormatId > 1) {
      sn.matchConfigs.freeHitAfterNoBall = true
    }
    // If our description for the match is null, but the title is not => populate description from title
    if (isNil(sn.description) && !isNil(sn.title)) {
      sn.description = sn.title
    }
    return sn
  })
  .actions(self => {
    const removeLatestInning = () => {
      const latestInning = self.getActiveInning()
      if (!latestInning) return
      removeInning(latestInning.id.toString())
    }
    const removeInning = (inningId: string) => {
      if (import.meta.env.VITE_ENV_SOCKETS !== 'false') {
        deleteInning(inningId)
      }
      // remove the innings locally
      const teams = self.matchTeams.map(team => {
        const innings = team.innings.filter(i => i.id !== inningId)
        team.innings = castToSnapshot(innings)
        return team
      })
      self.matchTeams = castToSnapshot(teams)
    }
    const deleteInning = flow(function* deleteInning(inningsId: string) {
      if (!inningsId) return
      const tokens = Auth.getTokens()
      const method = 'DELETE'
      const url = `${import.meta.env.VITE_API_URL}innings/${inningsId}`

      // make request
      try {
        yield RequestHandler({
          method,
          url,
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
        })
      } catch (error) {
        console.warn('Error deleting Innings', error) // eslint-disable-line no-console
      }
    })
    const postOfficial = flow(function* postOfficial(
      officialId: string,
      matchOfficialTypeId: number,
      matchId: string,
      setAdding?: React.Dispatch<React.SetStateAction<boolean>>
    ) {
      if (!matchId) return
      // construct props
      const tokens = Auth.getTokens()
      const method = 'POST'
      const url = `${import.meta.env.VITE_API_URL}matchofficial`
      const params = {}
      const body = {
        matchId: matchId,
        officialId: officialId,
        matchOfficialTypeId: matchOfficialTypeId,
      }

      // make request
      try {
        const response = yield RequestHandler({
          method,
          url,
          params,
          body: JSON.stringify(body),
          headers: { Authorization: `Bearer ${tokens?.accessToken}` },
        })
        if (response && response.ok) {
          const data = yield response.json()
          if (data && data.matchId === body.matchId && data.officialId === body.officialId) {
            self.addOfficial(data)
          }
        } else {
          // we got a response but it was not ok
          // for now just mark this request as done
          // because we don't have scope to handle this better
        }
        if (setAdding) setAdding(false)
      } catch (error) {
        console.warn('Error posting MatchOfficial', error) // eslint-disable-line no-console
        if (setAdding) setAdding(false)
      }
    })

    return {
      removeLatestInning,
      removeInning,
      deleteInning,
      postOfficial,
    }
  })

export const ClspMatchModel = types.compose(tempMatchModel, MatchModelSolid)
