import { Box, Flex } from '@chakra-ui/react'
import Konva from 'konva'
import type { KonvaEventObject } from 'konva/lib/Node'
import { round } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { Image, Layer, Stage } from 'react-konva'
import useImage from 'use-image'

import Theme from '../../theme/theme'
import type { PitchMapProps } from '../../types/props'
import SubHeading from '../Headings/SubHeading'
import Pitch from './pitch_map_background.png'

// Pitch Map Notes:
// 1. All measurements for the SVG graphic are at a 1:2 ratio.
// 2. That means for every 1 metre on the X axis, there are 2 metres on the Y axis (in the same amount of pixels)
// 3. There is also "20% of the pitch width" as padding to the left and right of the pitch.

const PitchMap = observer(({ ball, editBall, incompleteData }: PitchMapProps) => {
  const stageRef = useRef<Konva.Stage>(null)
  const layerRef = useRef<Konva.Layer>(null)
  const [pitchBackground] = useImage(Pitch)

  const clearComponent = () => {
    const stage = stageRef.current
    if (!stage) return
    const circles = stage.find('.circles')
    circles.forEach(circle => circle.destroy())
    stage.draw()
  }

  const addCircle = (x: number, y: number) => {
    const stage = stageRef.current
    const layer = layerRef.current
    if (!stage || !layer) return

    clearComponent()
    layer.add(
      new Konva.Circle({
        x,
        y,
        name: 'circles',
        radius: 6,
        fill: Theme.colors.cls.yellow['400'],
        stroke: Theme.colors.cls.yellow[800],
        strokeWidth: 1,
      })
    )
    layer.draw()
    ball.setPitchMap(round((x / stage.attrs.width) * 100, 4), round((y / stage.attrs.height) * 100, 4))
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLayerTap = (e: any): void => {
    const { evt } = e
    addCircle(evt.layerX, evt.layerY)
  }

  const handleLayerClick = (e: KonvaEventObject<MouseEvent>): void => {
    const { evt } = e
    addCircle(evt.offsetX, evt.offsetY)
  }

  const resetControl = () => {
    clearComponent()
    ball.setPitchMap()
  }

  useEffect(() => {
    const stage = stageRef.current
    const layer = layerRef.current
    if (!stage || !layer) return

    clearComponent()
    if (ball && ball.bowlingAnalysis && ball.bowlingAnalysis.pitchMap) {
      layer.add(
        new Konva.Circle({
          x: (ball.bowlingAnalysis.pitchMap.x / 100) * stage.attrs.width,
          y: (ball.bowlingAnalysis.pitchMap.y / 100) * stage.attrs.height,
          name: 'circles',
          radius: 6,
          fill: Theme.colors.cls.yellow['400'],
          stroke: Theme.colors.cls.yellow[800],
          strokeWidth: 2,
        })
      )
      layer.draw()
    }
  }, [ball])

  return (
    <Flex flexDirection="column">
      <SubHeading
        text="Pitch Map"
        buttonProps={
          ball && ball.bowlingAnalysis && ball.bowlingAnalysis.pitchMap && !editBall
            ? { text: 'Reset', onClick: resetControl }
            : undefined
        }
        warning={incompleteData}
        tertiary
      />
      <Box>
        <Stage width={212} height={328} ref={stageRef}>
          <Layer ref={layerRef} onClick={handleLayerClick} onTap={handleLayerTap}>
            <Image image={pitchBackground} x={0} y={0} width={212} height={328} />
          </Layer>
        </Stage>
      </Box>
    </Flex>
  )
})

export default PitchMap
