import { Box, Button, Menu, MenuButton, MenuDivider, MenuItem, MenuList, MenuOptionGroup } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { ReactElement } from 'react'
import React, { useState } from 'react'

import Theme from '../../theme/theme'
import type { DropdownOption } from '../../types'
import type { DropdownProps } from '../../types/props'

const is2DOptionsArray = (options: DropdownProps['options']): options is DropdownOption[][] => Array.isArray(options[0])

const Dropdown = ({
  id,
  target,
  icon,
  options,
  value,
  origValue,
  ignoreState,
  onChange,
  globalDisable = false,
  placement,
  hideCheck,
  isClearable,
  isEditing = false,
  isWhite,
  onOpen,
  onClose,
  justifyContent,
  width,
  listHeight,
  listWidth,
  preserveCase = false,
  forceChanged = false,
  fontSize = 'md',
  truncateButton,
  isIncomplete,
  buttonDisable,
  lastOptionSeparated = false,
  colorScheme,
  defaultIsOpen = false,
  ...props
}: DropdownProps) => {
  const [originalValue] = useState<string | ReactElement>(origValue || value)

  const valueIsElement = React.isValidElement(value)
  const originalValueIsElement = React.isValidElement(originalValue)
  const valueHasChanged =
    valueIsElement && originalValueIsElement
      ? originalValue?.props.children?.[0] !== value?.props.children?.[0]
      : originalValue !== value
  const hasChanged = forceChanged || valueHasChanged

  let buttonText = value
  if (typeof value === 'string') {
    buttonText = value.replace(/_/g, ' ').toLowerCase()
    if (truncateButton) {
      buttonText =
        buttonText.length > truncateButton
          ? `${buttonText.substr(0, buttonText.length === truncateButton + 1 ? truncateButton + 1 : truncateButton)}${
              buttonText.length > truncateButton + 1 ? '…' : ''
            }`
          : buttonText.substr(0, truncateButton)
    }
  }

  const optionsGroups = is2DOptionsArray(options) ? options : [options]

  return (
    <Menu
      onOpen={onOpen}
      onClose={onClose}
      placement={placement || 'bottom-start'}
      autoSelect={false}
      defaultIsOpen={defaultIsOpen}
      data-testid={props['data-testid']}
    >
      <MenuButton
        as={Button}
        textTransform="capitalize"
        boxShadow="cls.light"
        position="relative"
        backgroundColor={
          !colorScheme
            ? (hasChanged || isEditing) && !ignoreState
              ? Theme.colors.cls.yellow['400']
              : isWhite
              ? Theme.colors.cls.white['400']
              : Theme.colors.gray[100]
            : undefined
        }
        colorScheme={colorScheme}
        width={width ? width : '100%'}
        justifyContent={justifyContent ? justifyContent : 'center'}
        size="sm"
        fontSize={fontSize}
        color={!colorScheme ? (isIncomplete ? 'cls.negativeRed' : 'cls.black') : undefined}
        isDisabled={buttonDisable}
        data-testid={`${buttonText}Button`}
        {...props}
      >
        {(hasChanged || isEditing) && !ignoreState && !hideCheck && (
          <Box display="inline" position="absolute" left="4px">
            <FontAwesomeIcon icon={['fas', 'check-circle']} size="sm" />
          </Box>
        )}
        {icon && (
          <Box display="inline" width="16px" marginRight="4px">
            <FontAwesomeIcon icon={icon} size="sm" />
          </Box>
        )}
        {isIncomplete && (
          <Box display="inline" marginRight="4px">
            <FontAwesomeIcon
              icon={['fas', 'exclamation-circle']}
              color={Theme.colors.cls.negativeRed}
              size="sm"
              style={{ fontSize: '12px' }}
            />
          </Box>
        )}
        <Box display="inline">{buttonText}</Box>
        {(hasChanged || isEditing) && !ignoreState && (
          <Box
            position="absolute"
            left="3%"
            bottom="2px"
            margin="auto"
            w="94%"
            h="3px"
            backgroundColor="cls.transparentHighlightDark"
            borderBottomRightRadius="2.5px"
            borderBottomLeftRadius="2.5px"
          />
        )}
      </MenuButton>
      <MenuList
        zIndex={20}
        minWidth={listWidth ? 0 : undefined}
        width={listWidth || undefined}
        height={listHeight ? [listHeight, listHeight, 'auto', 'auto'] : undefined}
        overflowY={listHeight ? ['scroll', 'scroll', 'auto', 'auto'] : undefined}
        borderColor="gray.200"
      >
        {isClearable && (hasChanged || isEditing) && (
          <>
            <MenuOptionGroup>
              <MenuItem
                key={'menuClearBottom'}
                onClick={() => {
                  onChange({ id, target, clearValue: true })
                }}
                data-testid="clearMenuSelection"
              >
                Clear selection
              </MenuItem>
            </MenuOptionGroup>
            <MenuDivider borderColor="gray.200" />
          </>
        )}
        {optionsGroups.map((group, gIdx) => {
          return (
            <React.Fragment key={`group_${gIdx}`}>
              <MenuOptionGroup>
                {group.map((option, idx) => {
                  const displayName = option.label || option.value
                  const formattedDisplayName = !preserveCase
                    ? displayName.replace(/_/g, ' ').toLowerCase()
                    : displayName
                  const isLastOption = idx === options.length - 1
                  return (
                    <React.Fragment key={`${formattedDisplayName}_${idx}`}>
                      {isLastOption && lastOptionSeparated && <MenuDivider borderColor="gray.200" />}
                      <MenuItem
                        textTransform={!preserveCase ? 'capitalize' : undefined}
                        isDisabled={globalDisable || option.disabled}
                        onClick={() => {
                          onChange({
                            id,
                            target,
                            value: option.value,
                          })
                        }}
                        data-testid={formattedDisplayName.replace(/ /g, '_') + 'DropdownMenuItem'}
                      >
                        {formattedDisplayName}
                      </MenuItem>
                    </React.Fragment>
                  )
                })}
              </MenuOptionGroup>
              {gIdx < optionsGroups.length - 1 && <MenuDivider borderColor="gray.200" />}
            </React.Fragment>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default Dropdown
