import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import type { IInningModel } from '@clsplus/cls-plus-data-models'
import { findIndex } from 'lodash'
import { useState } from 'react'

import { Fielding } from '../components/MetricsPanels/Fielding'
import type { MetricsProps } from '../types/props'

const Metrics = ({ game }: MetricsProps) => {
  const activeInning: IInningModel | undefined = game.getActiveInning()
  const allInnings: IInningModel[] = game.getAllInningInOrder
  const activeIndex = findIndex(allInnings, i => i.inningsMatchOrder === activeInning?.inningsMatchOrder)
  const [tabIndex, setTabIndex] = useState(activeIndex !== -1 ? activeIndex : 0)
  return (
    <Flex width="100%" align="center" justify="center">
      <Tabs
        isFitted
        variant="unstyled"
        w="100%"
        margin={{ base: '21px 14px', md: '21px 200px' }}
        index={tabIndex}
        onChange={i => setTabIndex(i)}
      >
        <TabList borderColor="gray.200" marginBottom="7px">
          {allInnings.map((i, index) => (
            <Tab
              key={index}
              _first={{ borderTopLeftRadius: '4px', borderBottomLeftRadius: '4px' }}
              _last={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }}
              backgroundColor={'white'}
              _selected={{ color: 'white', bg: 'cls.blue.400' }}
            >
              {`${i.getBattingTeamShortName} ${index < 2 ? '1st Innings' : '2nd Innings'}`}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {allInnings.map((i, index) => (
            <TabPanel padding="7px" key={index}>
              <Box marginY="14px">
                <Fielding inning={i} game={game} />
              </Box>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

export default Metrics
