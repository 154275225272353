import { Box, Flex, Text } from '@chakra-ui/react'
import type { IBattingPerformanceModel, IMatchPlayerModel } from '@clsplus/cls-plus-data-models'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNil, orderBy } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import type { Row } from 'react-table'
import { useTable } from 'react-table'

import EditableField from '../../components/EditableField/EditableField'
import { db } from '../../data/dexie/Database'
import S3PHelpers from '../../helpers/s3pHelpers'
import Theme from '../../theme/theme'
import type { BattingProps } from '../../types/props'
import { Button } from '../Buttons/Button'

type BattingRow = {
  player: IMatchPlayerModel
  performance: IBattingPerformanceModel | undefined
  fowWicketNumber: number | null | undefined
  fowRuns: number | null | undefined
  fowOver: string | null | undefined
  firstPerformanceOrder: number | null
}

export const Batting = observer(
  ({
    inning,
    game,
    mode,
    matchSettings,
    scoreManually,
    triggerInningsState,
    setEditDismissalPerf,
    setEditDismissalOpen,
    setDeletePerf,
    setDeletePerfOpen,
  }: BattingProps) => {
    const teamPlayers: IMatchPlayerModel[] | undefined = inning.getBattingTeam.getPlayersInBattingOrder()
    const battingPerformances: IBattingPerformanceModel[] = inning.battingPerformances

    // table columns
    const columns = useMemo(
      () => [
        {
          id: 'batterNames',
          accessor: (row: BattingRow) => row.player.getDisplayName(),
          flexGrow: 10,
          Header: (
            <Box>
              Batting
              {!scoreManually && (
                <Box as="span" paddingLeft="10px" fontSize="14px">
                  {inning.battingReviewsRemaining} of {game.matchConfigs.maxBattingReviewsPerInnings} reviews remaining
                </Box>
              )}
            </Box>
          ),
          Cell: ({ row }: { row: Row<BattingRow> }) => {
            return (
              <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems="center">
                {scoreManually && (
                  <Box marginRight="14px">
                    {!row.original.performance ? (
                      <Button
                        colorScheme="gray"
                        fontSize="xl"
                        isDisabled={row.original.performance ? true : false}
                        onClick={() =>
                          inning.createNewBatterPerformance(row.original.player, 1, null, null, null, true)
                        }
                        width={30}
                        data-testid={`addBatter_${inning.inningsMatchOrder}_${row.original.player.id}`}
                      >
                        +
                      </Button>
                    ) : (
                      <Button
                        colorScheme="red"
                        fontSize="xl"
                        onClick={() => {
                          if (row.original.performance?.isEmpty) {
                            inning.removePerformance(row.original.performance)
                          } else {
                            setDeletePerfOpen(true)
                            setDeletePerf(row.original.performance || null)
                          }
                        }}
                        width={30}
                      >
                        <FontAwesomeIcon icon={['far', 'trash-alt']} size="xs" />
                      </Button>
                    )}
                  </Box>
                )}
                <Flex flex={1} justifyContent="center" direction="column">
                  <Text fontSize="xl">{row.original.player.getDisplayName()}</Text>
                  {scoreManually &&
                    row.original.performance &&
                    row.original.performance.dismissal &&
                    !isNil(row.original.performance.dismissal.wicketNumber) &&
                    !isNil(row.original.performance.dismissal.fowRuns) && (
                      <Text fontSize="sm">
                        {matchSettings.scoreFormat === 'RUNS-WICKETS'
                          ? row.original.performance.dismissal.fowRuns
                          : row.original.performance.dismissal.wicketNumber}
                        {'/'}
                        {matchSettings.scoreFormat === 'RUNS-WICKETS'
                          ? row.original.performance.dismissal.wicketNumber
                          : row.original.performance.dismissal.fowRuns}
                        {` (${row.original.performance.dismissal.fowOver || '?'})`}
                      </Text>
                    )}
                </Flex>
                <Flex
                  flex={1}
                  alignItems="center"
                  _hover={
                    (scoreManually || (game.matchStatusId && game.matchStatusId > 2)) && row.original.performance
                      ? { cursor: 'pointer', backgroundColor: '#edf2f8', borderRadius: '8px' }
                      : undefined
                  }
                  onClick={() => {
                    if ((scoreManually || (game.matchStatusId && game.matchStatusId > 2)) && row.original.performance) {
                      setEditDismissalPerf(row.original.performance)
                      setEditDismissalOpen(true)
                    }
                  }}
                >
                  <Text
                    data-testid={`batterDismissal_${inning.inningsMatchOrder}_${row.original.performance?.order}`}
                    fontSize="md"
                  >
                    {row.original.performance?.text}
                  </Text>
                </Flex>
              </Flex>
            )
          },
        },
        {
          accessor: (row: BattingRow) => row.performance?.allRuns,
          flexGrow: 1,
          Header: 'R',
          Cell: ({ row }: { row: Row<BattingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`batRuns${row.original.performance.id}`}
                data-testid={`batterRuns_${inning.inningsMatchOrder}_${row.original.performance?.order}`}
                value={row.original.performance.allRuns}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  if (row.original.performance) {
                    const val = value - (row.original.performance.allRuns || 0)
                    if (val !== 0 && mode === 'betting') {
                      db.createS3PMessage(
                        S3PHelpers.metadata(mode, game),
                        S3PHelpers.manualScoreChange(val, game, row.original.performance.playerMp?.id, inning)
                      )
                      triggerInningsState()
                    }
                    row.original.performance.updateRuns(value)
                  }
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BattingRow) => row.performance?.allBalls,
          flexGrow: 1,
          Header: 'B',
          Cell: ({ row }: { row: Row<BattingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`batBalls${row.original.performance.id}`}
                data-testid={`batterBalls_${inning.inningsMatchOrder}_${row.original.performance?.order}`}
                value={row.original.performance.allBalls}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.updateBalls(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BattingRow) => row.performance?.allStrikeRate,
          flexGrow: 1,
          Header: 'SR',
          Cell: ({ row }: { row: Row<BattingRow> }) => {
            if (!row.original.performance) return null
            return (
              <Text
                width={'14'}
                data-testid={`batterStrikeRate_${inning.inningsMatchOrder}_${row.original.performance?.order}`}
                margin="0 auto"
              >
                {row.original.performance?.allStrikeRate || 0}
              </Text>
            )
          },
        },
        {
          accessor: (row: BattingRow) => row.performance?.allDotBalls,
          flexGrow: 1,
          Header: '\u2022',
          Cell: ({ row }: { row: Row<BattingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`batDotBalls${row.original.performance.id}`}
                data-testid={`batterDotBalls_${inning.inningsMatchOrder}_${row.original.performance?.order}`}
                value={row.original.performance.allDotBalls}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.updateDotBalls(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BattingRow) => row.performance?.allOnes,
          flexGrow: 1,
          Header: '1',
          Cell: ({ row }: { row: Row<BattingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`batOnes${row.original.performance.id}`}
                data-testid={`batterOnes_${inning.inningsMatchOrder}_${row.original.performance?.order}`}
                value={row.original.performance.allOnes}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.updateAllOnes(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BattingRow) => row.performance?.allTwos,
          flexGrow: 1,
          Header: '2',
          Cell: ({ row }: { row: Row<BattingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`batTwos${row.original.performance.id}`}
                data-testid={`batterTwos_${inning.inningsMatchOrder}_${row.original.performance?.order}`}
                value={row.original.performance.allTwos}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.updateAllTwos(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BattingRow) => row.performance?.allThrees,
          flexGrow: 1,
          Header: '3',
          Cell: ({ row }: { row: Row<BattingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`batThrees${row.original.performance.id}`}
                data-testid={`batterThrees_${inning.inningsMatchOrder}_${row.original.performance?.order}`}
                value={row.original.performance.allThrees}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.updateAllThrees(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BattingRow) => row.performance?.allFours,
          flexGrow: 1,
          Header: '4',
          Cell: ({ row }: { row: Row<BattingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`batFours${row.original.performance.id}`}
                data-testid={`batterFours_${inning.inningsMatchOrder}_${row.original.performance?.order}`}
                value={row.original.performance.allFours}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.updateAllFours(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BattingRow) => row.performance?.allSixes,
          flexGrow: 1,
          Header: '6',
          Cell: ({ row }: { row: Row<BattingRow> }) => {
            if (!row.original.performance) return null
            return (
              <EditableField
                key={`batSixes${row.original.performance.id}`}
                data-testid={`batterSixes_${inning.inningsMatchOrder}_${row.original.performance?.order}`}
                value={row.original.performance.allSixes}
                type="number"
                width={'14'}
                onChange={(value: number) => {
                  row.original.performance?.updateAllSixes(value)
                  triggerInningsState()
                }}
                pattern="^\d+$"
                errorMessage="Please enter numbers only"
              />
            )
          },
        },
        {
          accessor: (row: BattingRow) => row.performance?.allMins,
          flexGrow: 1,
          Header: 'Mins',
          Cell: ({ row }: { row: Row<BattingRow> }) => {
            if (row.original.performance) {
              return (
                <Text
                  width={'14'}
                  margin="0 auto"
                  data-testid={`batterMins_${inning.inningsMatchOrder}_${row.original.performance?.order}`}
                >
                  {row.original.performance?.allMins || 0}
                </Text>
              )
            } else {
              return <Box width={'14'} />
            }
          },
        },
      ],
      [
        game,
        mode,
        scoreManually,
        inning,
        triggerInningsState,
        setEditDismissalOpen,
        setEditDismissalPerf,
        setDeletePerf,
        setDeletePerfOpen,
        matchSettings.scoreFormat,
      ]
    )
    // player data
    const data = orderBy(
      teamPlayers.map(p => {
        const perf = battingPerformances.find(b => b.playerMp.id === p.id)
        return {
          player: p,
          performance: perf,
          fowWicketNumber: perf?.dismissal?.wicketNumber,
          fowRuns: perf?.dismissal?.fowRuns,
          fowOver: perf?.dismissal?.fowOver,
          firstPerformanceOrder: perf ? perf.order : null,
        }
      }),
      ['firstPerformanceOrder'],
      ['asc']
    )
    const dataPerfLength = data.filter(d => d.performance !== undefined)?.length || 0

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

    const moveRow = (index: number, dir: string) => {
      const perfUp = battingPerformances.find(b => (dir === 'up' ? b.order === index : b.order === index + 1))
      const perfDown = battingPerformances.find(b => (dir === 'down' ? b.order === index : b.order === index - 1))
      perfUp?.updateOrder(perfUp.order - 1)
      perfDown?.updateOrder(perfDown.order + 1)
    }

    return (
      <table {...getTableProps()} style={{ width: '100%', backgroundColor: 'white' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()} style={{ backgroundColor: Theme.colors.cls.backgroundGray }}>
              {scoreManually && <th style={{ flexGrow: 1, width: '120px' }}></th>}
              {headerGroup.headers.map((column, idx) => (
                // eslint-disable-next-line react/jsx-key
                <th
                  {...column.getHeaderProps()}
                  style={{
                    // @ts-ignore
                    flexGrow: column.flexGrow || 1,
                    padding: scoreManually ? '10px 0' : '10px',
                    fontWeight: 'normal',
                    fontSize: idx === 0 ? '20px' : '16px',
                    fontStyle: 'italic',
                    textAlign: idx === 0 ? 'left' : 'center',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            const scoreStatus = row.original.performance?.text
            prepareRow(row)
            return (
              // eslint-disable-next-line react/jsx-key
              <tr
                {...row.getRowProps()}
                style={{
                  borderTop: `solid 1px ${Theme.colors.cls.backgroundGray}`,
                  backgroundColor: scoreStatus && scoreStatus !== 'not out' ? '#ffd5d5' : undefined,
                }}
              >
                {scoreManually && (
                  <td style={{ textAlign: 'center' }}>
                    {index < dataPerfLength && (
                      <>
                        {index > 0 && (
                          <Button
                            onClick={() => moveRow(index + 1, 'up')}
                            marginX="2px"
                            size="xs"
                            data-testid="moveRowUpButton"
                          >
                            ▲
                          </Button>
                        )}
                        {index + 1 < dataPerfLength && (
                          <Button
                            onClick={() => moveRow(index + 1, 'down')}
                            marginX="2px"
                            size="xs"
                            data-testid="moveRowDownButton"
                          >
                            ▼
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                )}
                {row.cells.map((cell, idx) => {
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: scoreManually ? '10px 0' : '10px',
                        textAlign: idx === 0 ? 'left' : 'center',
                        minWidth: idx === 0 ? '305px' : undefined,
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }
)
