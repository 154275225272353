import { Flex, Textarea } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react-lite'

import CommsHelpers from '../../helpers/commsHelpers'
import theme from '../../theme/theme'
import type { BallSummaryProps } from '../../types/props'
import { Button } from '../Buttons/Button'

export const BallSummary = observer(
  ({
    mode,
    game,
    ball,
    lastBall,
    detailed,
    enabled,
    editBall,
    backgroundColor = 'cls.backgroundGray',
    commentaryChanged,
    setCommentaryChanged,
    isDisabled,
  }: BallSummaryProps) => {
    const ballSimple = () => {
      const desc = CommsHelpers.generateBallSummary(ball)
      ball.setTextDescription(desc)
      return desc
    }
    const ballDescription = () => {
      if (commentaryChanged || (editBall && ball.textDescription !== '')) {
        return ball.textDescription
      }
      const desc = CommsHelpers.generateBallCommentary(game, ball, lastBall)
      ball.setTextDescription(desc)
      return desc
    }
    const handleManualChange = () => {
      setCommentaryChanged(true)
    }
    const handleValueChange = (
      event: React.ChangeEvent<HTMLTextAreaElement> | React.FocusEvent<HTMLTextAreaElement, Element>
    ) => {
      if (commentaryChanged) {
        ball.setTextDescription(event.target.value)
      }
    }
    const clearManualChange = () => {
      if (editBall) {
        ball.setTextDescription('')
      }
      setCommentaryChanged(false)
    }

    if (!enabled) return null

    return (
      <Flex flex={1} flexDirection="row" borderRadius="7px" backgroundColor={backgroundColor}>
        <Flex flex={0.25} paddingLeft="7px" justify="center" align="center">
          <FontAwesomeIcon icon={['far', 'comment-dots']} size="lg" />
        </Flex>
        <Flex flex={10} paddingX="7px" flexDirection="row" alignItems="center">
          <Textarea
            value={
              commentaryChanged || (editBall && ball.textDescription !== '')
                ? ball.textDescription
                : detailed
                ? ballDescription()
                : ballSimple()
            }
            padding={mode !== 'main' ? '4px 7px' : '7px'}
            borderColor="gray.200"
            resize="none"
            textTransform={!detailed ? 'capitalize' : undefined}
            style={{ backgroundColor: 'transparent' }}
            onChange={detailed && !isDisabled ? handleValueChange : undefined}
            onFocus={detailed && !isDisabled ? handleManualChange : undefined}
            onBlur={detailed && !isDisabled ? handleValueChange : undefined}
            minHeight={mode !== 'main' ? '50px' : '72px'}
            data-testid="ballSummaryTextArea"
          />
          {(commentaryChanged || editBall) && (
            <Button
              colorScheme={mode === 'main' ? 'cls.white' : 'gray'}
              onClick={clearManualChange}
              data-testid="redoBallSummaryButton"
            >
              <FontAwesomeIcon icon={['far', 'redo']} size="lg" color={theme.colors.cls.black} />
            </Button>
          )}
        </Flex>
      </Flex>
    )
  }
)
