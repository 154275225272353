import { Flex, Text } from '@chakra-ui/react'

import type { HeaderRowProps } from '../../types/props'

export const HeaderRow = ({ headings }: HeaderRowProps) => {
  if (headings.length === 0) return null
  return (
    <Flex width="100%" justify="flex-end">
      {headings.map((heading, idx: number) => (
        <Flex key={`${idx}_${heading.label}`} flex={heading.flexValue} justify={heading.justifyValue}>
          <Text color="cls.textGray" fontSize="sm" letterSpacing="-0.5px">
            {heading.label}
          </Text>
        </Flex>
      ))}
    </Flex>
  )
}
