import { Box, Flex, Text } from '@chakra-ui/react'
import { observer } from 'mobx-react-lite'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useMst } from '../../data/stores/rootStore'
import type { IDetailedMatchStore, ISocketStore } from '../../types/models'
import type { InGameHeaderProps } from '../../types/props'
import { InGameTabs } from './InGameTabs'
import Logo from './Logo'
import Profile from './Profile'
import SocketLights from './SocketLights'

export const InGameHeader = observer(({ preGame = false, preInnings = false, mode = 'main' }: InGameHeaderProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { socketStore, detailedMatches }: { socketStore: ISocketStore; detailedMatches: IDetailedMatchStore } = useMst()

  const { id } = useParams()
  const handleLogoClick = async () => {
    if (preInnings && id) {
      // we are setting up an innings for a game and are now abandoning that, so we need to delete the innings
      detailedMatches.getMatch(id, mode)
      const game = await detailedMatches.results.get(id)?.match
      if (Number(game?.getActiveInning()?.progressiveScores.oversBowled ?? 0) === 0) {
        game?.removeLatestInning()
      }
    }
    socketStore.reset()
    navigate('/')
  }
  const isScoringManually = location.pathname.search('manual') > -1

  return (
    <Flex
      h="100%"
      w="100%"
      justify={mode !== 'main' ? ['flex-start', 'flex-start', 'center', 'center'] : undefined}
      maxW="100%"
    >
      <Flex flex={2} maxWidth={260} h="100%" justify="flex-start" align="center" paddingLeft="14px">
        <Box height="70%" _hover={{ cursor: 'pointer' }} marginBottom="1%">
          <Logo clickHandler={handleLogoClick} />
        </Box>
        <Text as="span" fontSize="14px" marginLeft="14px" color="white" fontWeight="600">
          v{__APP_VERSION__}
        </Text>
      </Flex>
      <Flex flex="6" h="100%" justify="center" align="center">
        <InGameTabs mode={mode} preGame={preGame} preInnings={preInnings} isScoringManually={isScoringManually} />
      </Flex>
      <Flex
        flex={0.7}
        maxW={mode === 'main' ? '150px' : undefined}
        h="100%"
        borderLeft="1px solid"
        borderLeftColor="cls.transparentHighlightLight"
        justify="center"
        align="center"
      >
        {id && (
          <SocketLights
            connected={socketStore.connected()}
            primary={socketStore.primary}
            assignedRole={socketStore.assignedRole}
            mode={mode}
            setPromptUser={socketStore.setPromptUser}
          />
        )}
      </Flex>
      <Flex
        flex={1}
        maxW="160px"
        paddingX="7px"
        h="100%"
        borderLeft="1px solid"
        borderLeftColor="cls.transparentHighlightLight"
        borderRight={mode !== 'main' ? '1px solid' : undefined}
        borderRightColor={mode !== 'main' ? 'cls.transparentHighlightLight' : undefined}
        justify="center"
        align="center"
      >
        <Profile />
      </Flex>
    </Flex>
  )
})
