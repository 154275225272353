import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserCircle as fadUserCircle,
  faWifi as fadWifi,
  faWifi1 as fadWifi1,
  faWifi2 as fadWifi2,
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faCricket as falCricket,
  faHandPaper as falHandPaper,
  faTimesCircle as falTimesCircle,
} from '@fortawesome/pro-light-svg-icons'
import {
  faClock,
  faCloudDrizzle,
  faCloudShowers,
  faCloudUpload,
  faCoin,
  faCommentCheck,
  faCommentDots,
  faExpandArrowsAlt,
  faFilter,
  faFluxCapacitor,
  faGripLines,
  faMagic,
  faMapMarkerAlt,
  faRedo,
  faRepeatAlt,
  faRulerTriangle,
  faSeedling,
  faSunCloud,
  faTachometerAltFast,
  faTag,
  faTemperatureHot,
  faTint,
  faTrashAlt,
  faUndo,
  faUser,
  faWind,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faArrowLeft,
  faArrowsAlt,
  faCheckCircle,
  faCog,
  faDatabase,
  faEllipsisV,
  faExchange,
  faExclamationCircle,
  faExclamationTriangle,
  faGripHorizontal,
  faHourglassEnd,
  faHourglassStart,
  faPlusCircle,
  faSearch,
  faStopwatch,
  faSyncAlt,
  faTimesCircle,
  faUserCircle,
} from '@fortawesome/pro-solid-svg-icons'

export const IconLibrary = () => {
  library.add(
    faClock,
    faTemperatureHot,
    faSunCloud,
    faWind,
    faCloudShowers,
    faTint,
    faSeedling,
    faRulerTriangle,
    faTachometerAltFast,
    faExpandArrowsAlt,
    faTag,
    faUser,
    faMapMarkerAlt,
    faCoin,
    faCommentCheck,
    faSearch,
    faCommentDots,
    faPlusCircle,
    faCheckCircle,
    faUserCircle,
    faCog,
    fadUserCircle,
    fadWifi,
    fadWifi1,
    fadWifi2,
    falCricket,
    faRepeatAlt,
    faUndo,
    faRedo,
    faGripHorizontal,
    faExclamationCircle,
    faExclamationTriangle,
    faFilter,
    faTrashAlt,
    falTimesCircle,
    faGripLines,
    faCloudDrizzle,
    faHourglassStart,
    faHourglassEnd,
    faArrowsAlt,
    falHandPaper,
    faMagic,
    faTimesCircle,
    faExchange,
    faSyncAlt,
    faArrowLeft,
    faCloudUpload,
    faFluxCapacitor,
    faStopwatch,
    faDatabase,
    faEllipsisV
  )
}
