import {
  Box,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import { useState } from 'react'

import Theme from '../../theme/theme'

type TextFieldCommonProps = {
  id: string
  target?: string
  value: string
  delimiter?: string | null
  onChange: (args: TextFieldCallbackArgs) => void
  isDisabled?: boolean
  isEditing?: boolean
  isReadOnly?: boolean
  textAlign?: React.CSSProperties['textAlign']
  ignoreState?: boolean
  width?: number | string
}

export type TextFieldCallbackArgs = {
  id?: TextFieldCommonProps['id']
  target?: TextFieldCommonProps['target']
  value: TextFieldCommonProps['value']
}

type TextInputProps = {
  type?: never
  min?: never
  max?: never
  isStepper?: boolean
  isStepperLarge?: boolean
  defaultValue?: string
} & TextFieldCommonProps

type NumberInputProps = {
  type: 'number'
  min?: number
  max?: number
  isStepper?: never
  isStepperLarge?: never
  defaultValue?: never
} & TextFieldCommonProps

const TextField = ({
  type,
  id,
  target,
  value,
  defaultValue,
  textAlign = 'left',
  delimiter = null,
  onChange,
  isDisabled = false,
  isEditing = false,
  isReadOnly = false,
  isStepper = false,
  isStepperLarge = false,
  min,
  max,
  width,
  ignoreState = false,
  ...otherParams
}: TextInputProps | NumberInputProps) => {
  const valueIncludesDelimiter =
    value && typeof value === 'string' && delimiter !== null && value.indexOf(delimiter) > -1
  const [originalValue] = useState(valueIncludesDelimiter ? value.substring(0, value.indexOf(delimiter)) : value)
  const hasChanged = valueIncludesDelimiter
    ? originalValue !== value.substring(0, value.indexOf(delimiter))
    : originalValue !== value

  return (
    <Box
      position="relative"
      style={{
        width: width || '100%',
      }}
    >
      {type === 'number' && (
        <NumberInput
          value={value ?? ''}
          isDisabled={isDisabled}
          min={min}
          max={max}
          onChange={value => onChange({ id, target, value })}
          borderColor="gray.200"
          style={{
            width: '100%',
          }}
        >
          <NumberInputField
            style={{
              backgroundColor: (hasChanged && !ignoreState) || isEditing ? Theme.colors.cls.yellow['400'] : '#FFF',
            }}
            {...otherParams}
          />
          <NumberInputStepper>
            <NumberIncrementStepper style={{ borderStyle: 'none' }} />
            <NumberDecrementStepper style={{ borderStyle: 'none' }} />
          </NumberInputStepper>
        </NumberInput>
      )}
      {type !== 'number' && (
        <Input
          variant={isReadOnly ? 'unstyled' : undefined}
          value={value}
          isReadOnly={isReadOnly}
          isDisabled={isDisabled}
          onChange={event => onChange({ id, target, value: event.target.value })}
          borderColor="gray.200"
          style={{
            minWidth: isStepper ? '30px' : '100%',
            backgroundColor:
              (hasChanged && !ignoreState) || (isEditing && defaultValue !== null && value !== defaultValue)
                ? Theme.colors.cls.yellow['400']
                : '#FFF',
            textAlign: textAlign,
            height: isStepper ? '33px' : isStepperLarge ? '70px' : undefined,
            paddingLeft: isStepper || isStepperLarge ? '2px' : undefined,
            paddingRight: isStepper || isStepperLarge ? '2px' : undefined,
          }}
          {...otherParams}
        />
      )}
      {((hasChanged && !ignoreState) || (isEditing && defaultValue !== null && value !== defaultValue)) && (
        <Box
          position="absolute"
          bottom="2px"
          marginLeft="2%"
          w="96%"
          h="3px"
          backgroundColor="cls.transparentHighlightDark"
          borderBottomRightRadius="2.5px"
          borderBottomLeftRadius="2.5px"
        />
      )}
    </Box>
  )
}

export default TextField
