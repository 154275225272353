import { FieldingPlacementStoreModel } from '@clsplus/cls-plus-data-models'
import { debounce, includes, isNil, orderBy } from 'lodash'
import type { Instance } from 'mobx-state-tree'
import { flow, getEnv, onAction, onSnapshot, tryResolve, types } from 'mobx-state-tree'
import { parse } from 'query-string'
import type { ReactNode } from 'react'
import { createContext, useContext } from 'react'
import { v4 as uuid } from 'uuid'

import Auth from '../../helpers/auth'
import { formatSocketMessage, idCleaner } from '../../helpers/dataHelpers'
import { timeMachineDate } from '../../helpers/generalHelpers'
import type { KinesisRecord } from '../../helpers/kinesisHelper'
import { sendDataToKinesis } from '../../helpers/kinesisHelper'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { IClspMatchModel } from '../../types/models'
import { RequestHandler } from '../api/RequestHandler'
import type { DBEvent, DBInningsBalls, DBMatch, DBTimelineMessage } from '../dexie/Database'
import { db } from '../dexie/Database'
import { ScoreFormats } from '../reference'
import BallStore from './balls/BallStore/BallStore'
import TimelineStore from './events/TimelineStore/TimelineStore'
import { LocalMatchStore } from './match/LocalMatchStore/LocalMatchStore'
import MatchStore from './match/Match/MatchStore'
import MatchesSearchStore from './match/MatchesSearch/MatchesSearchStore'
import { OfficialsSearchStore } from './MatchOfficial/OfficialsSearchStore'
import PlayersSearchStore from './Player/PlayersSearchStore'
import SettingsModel from './SettingsModel/SettingsModel'
import { SocketStore } from './SocketStore'

type SingletonType = {
  match: string | undefined
  matchUpdateType: string | undefined
  ballChange: BallDetails | undefined
  ballConfirm: BallDetails | undefined
  ballRemove: BallRemoveDetails | undefined
  eventUpdate: string | undefined
  eventMatchId: string | undefined
}

const singleton: SingletonType = {
  match: undefined,
  matchUpdateType: undefined,
  ballChange: undefined,
  ballConfirm: undefined,
  ballRemove: undefined,
  eventUpdate: undefined,
  eventMatchId: undefined,
}

let s3pSyncInProgress = false

const matchSetupActions = ['updateUmpireField', 'updatePlayerField', 'updateVenueEndField']

const socketConnector = {
  connect: (queryString: string) => {
    return new WebSocket(`${import.meta.env.VITE_WSS_URL}?${queryString}`)
  },
}

type BallDetails = {
  inningsId: string
  matchId: string
  ballId: string
  overId?: string
}

type BallRemoveDetails = BallDetails & {
  deadBall: boolean
  ballComplete: boolean
}

const RootModel = types
  .model('RootModel', {
    initialised: false,
    detailedMatches: types.optional(MatchStore, { results: {}, state: 'done' }),
    summaryMatches: types.optional(MatchesSearchStore, { results: {}, state: 'done' }),
    localMatches: types.optional(LocalMatchStore, { results: [] }),
    balls: types.optional(BallStore, { results: {} }),
    timelineEvents: types.optional(TimelineStore, { results: {}, state: 'done' }),
    playersSearch: types.optional(PlayersSearchStore, { results: {}, state: 'done' }),
    officialsSearch: types.optional(OfficialsSearchStore, { results: {}, state: 'done' }),
    socketStore: types.optional(SocketStore, {}),
    fieldingPlacements: types.optional(FieldingPlacementStoreModel, { results: [] }),
    appSettings: types.optional(SettingsModel, {
      manualScoring: {
        active: false,
        forceSync: false,
      },
      timeMachine: {
        baseline: null,
        activated: null,
      },
      defaultMatch: {
        scoreFormat: ScoreFormats[1],
        fielderPlacement: true,
        fielderEvents: true,
        fielderShirtNumbers: true,
        ballPreview: true,
      },
    }),
  })
  .actions(self => {
    // volatile state
    let socket: WebSocket | undefined = undefined

    const initialiseStore = flow(function* initialiseStore() {
      // Here we are going to load local matches that this browser has into memory.
      // At this stage we won't bother loading Balls or Timeline events - we only need
      // to do that if they load the actual match - may as well keep JS memory as light
      // as we can
      const matches: DBMatch[] = yield db.matches.toArray()
      matches.forEach(m => {
        const match = self.detailedMatches.createMatch(m.matchSN)
        if (match) self.localMatches.addLocalMatch(match)
      })
      self.fieldingPlacements.createDefaultField()
      self.initialised = true
    })

    const loadMockData = flow(function* loadMockData() {
      try {
        const gameResponse = yield fetch(`/mock/match.json`)
        if (gameResponse && gameResponse.ok) {
          const gameData = yield gameResponse.json()
          self.detailedMatches.loadMockData(gameData)
        }
      } catch (e) {
        // eslint-disable-next-line
        console.warn('Error loading local data', e)
      }
    })

    const handleMessage = (ev: MessageEvent) => {
      if (ev.data) {
        try {
          const data = JSON.parse(ev.data)
          if (data.messageId && data.action && data.matchId) {
            db.syncMessage(data.action, data.messageId, data.matchId)
          }
          // Primary/Secondary role
          if (
            data.mode &&
            (self.appSettings.appMode === data.mode ||
              (self.appSettings.manualScoring.active && data.mode === 'postMatch')) &&
            data.matchId === self.socketStore.matchId
          ) {
            const hadRole = self.socketStore.assignedRole
            // Role is being assigned here, either automatically, or from a request
            if (!isNil(data.primaryScorer)) {
              self.socketStore.setPrimary(data.primaryScorer)
              self.socketStore.setAssignedRole(true)

              if (data.socketRequestState) {
                self.socketStore.setSocketRequestState(data.socketRequestState)
                self.socketStore.setPromptUser(true)
              } else if (hadRole) {
                // automatic re-assignment
                self.socketStore.setSocketRequestState('APPROVE_PRIMARY_REQUEST')
                self.socketStore.setPromptUser(true)
              }
            }

            // Role not being assigned, check for PRIMARY_REQUEST if not already primary, for this mode and match
            if (data.socketRequestState === 'PRIMARY_REQUEST') {
              self.socketStore.setSocketRequestState(data.socketRequestState)
              self.socketStore.setPromptUser(true)
            }
          }
        } catch (e) {
          // unable to parse message, or failed db update
          // TODO: consider what to do here
        }
      }
    }

    const handleOpen = () => {
      self.socketStore.setStatus(1)
      const parsed = parse(self.socketStore.queryString || '')
      if (parsed.matchId) {
        db.getTimelineMessageToSync(parsed.matchId.toString())
          .then(async items => {
            for (const item of items) {
              await sendMessage(item.message.toString())
            }
          })
          .catch(reason => {
            console.log('Error reading timeline messages from Database', reason) // eslint-disable-line no-console
          })
        db.matches.get(parsed.matchId.toString()).then(item => {
          if (item?.syncRequired) {
            sendMessage(item.message.toString())
          }
        })
      }
    }

    const handleError = (ev: Event) => {
      self.socketStore.setStatus(3)
      new Error(JSON.stringify(ev))
    }

    const handleClose = () => {
      self.socketStore.setStatus(3)
      self.socketStore.setPrimary(false)
      self.socketStore.setAssignedRole(false)
      if (self.socketStore.queryString) {
        reconnect()
      }
    }

    const connectSocket = (queryString: string, matchId: string) => {
      // if queryString = what we already have, and we are trying to connect, OR connected, then don't interrupt
      if (queryString === self.socketStore.queryString && socket && socket.readyState <= 1) {
        return
      }

      if (import.meta.env.VITE_ENV_SOCKETS !== 'false' && (!socket || socket.readyState > 1)) {
        if (socket) socket.close()
        socket = undefined
        if (queryString && matchId) {
          self.socketStore.setStatus(0)
          self.socketStore.setQueryString(queryString)
          self.socketStore.setMatchId(matchId)
          socket = getEnv(self).socketConnector.connect(queryString)
        }
      }
      if (!socket) return

      // setup socket handlers
      socket.onopen = handleOpen
      socket.onmessage = handleMessage
      socket.onerror = handleError
      socket.onclose = handleClose
    }
    const closeSocket = () => {
      if (socket) {
        self.socketStore.setQueryString(undefined)
        self.socketStore.setMatchId(undefined)
        self.socketStore.setPrimary(false)
        self.socketStore.setAssignedRole(false)
        socket.close()
      }
    }
    const reconnect = () => {
      if (import.meta.env.VITE_ENV_SOCKETS !== 'false' && self.socketStore.queryString && self.socketStore.matchId) {
        connectSocket(self.socketStore.queryString, self.socketStore.matchId)
      }
    }

    const sendMessage = async (message: string) => {
      const parsed = JSON.parse(message)
      if (socket && socket.readyState === 1) {
        // only send comms if socket is connected, so backend knows whether user is primary or not
        // if payload is match payload, send via POST because eventually match payload exceeds AWS API Gateway socket
        // quota sizes (128kb payload, 32kb frame)
        if (parsed.matchId && parsed.action === 'updateMatch') {
          const tokens = Auth.getTokens()
          const profile = Auth.getUserProfile()
          const response = await RequestHandler({
            method: 'POST',
            url: `${import.meta.env.VITE_API_URL}live-data/match`,
            params: {},
            headers: { Authorization: `Bearer ${tokens?.accessToken}` },
            body: JSON.stringify({ email: profile?.email, ...parsed }),
          })
          if (response instanceof Response && response.ok) {
            db.syncMessage('updateMatch', '123', parsed.matchId)
          }
        } else if (parsed.matchId && parsed.action === 'updateBall') {
          const tokens = Auth.getTokens()
          const profile = Auth.getUserProfile()
          const response = await RequestHandler({
            method: 'POST',
            url: `${import.meta.env.VITE_API_URL}live-data/ball`,
            params: {},
            headers: { Authorization: `Bearer ${tokens?.accessToken}` },
            body: JSON.stringify({ email: profile?.email, ...parsed }),
          })
          if (response instanceof Response && response.ok) {
            db.syncMessage('updateBall', parsed.messageId, parsed.matchId)
          }
        } else {
          socket.send(message)
        }
      }
      if (rootStore.appSettings.manualScoring.forceSync) {
        rootStore.appSettings.manualScoring.setForceSync(false)
      }
    }

    const debouncedSend = debounce(sendMessage, 750, { maxWait: 2000 })

    const sendPing = (id: string, appMode: string) => {
      if (socket && socket.readyState === 1) {
        const message = {
          action: 'ping',
          matchId: id,
          scoringMode: appMode,
          messageTimestamp: new Date().toISOString(),
        }
        socket.send(JSON.stringify(message))
      }
    }

    const s3pSyncCheck = async (matchId: string | undefined) => {
      if (!matchId) return

      if (!s3pSyncInProgress && import.meta.env.VITE_ENV_SOCKETS !== 'false') {
        s3pSyncInProgress = true
        // sync the next batch of messages
        const messages = await db.s3p.where('[matchId+syncRequired]').equals([matchId, 1]).sortBy('timestamp')
        if (!messages.length) {
          s3pSyncInProgress = false
          return
        }
        const batch = messages.slice(0, 100)
        const transformedBatch = batch.map((message): KinesisRecord => {
          return {
            Data: new TextEncoder().encode(JSON.stringify(message)),
            PartitionKey: matchId,
          }
        })

        const res = await sendDataToKinesis(transformedBatch)
        if (res === 'ok') {
          batch.forEach(message => {
            db.s3p.where({ id: message.id }).modify(changes => (changes.syncRequired = 0))
          })
        }
        s3pSyncInProgress = false
        return
      }
    }

    return {
      initialiseStore,
      loadMockData,
      connectSocket,
      closeSocket,
      sendMessage,
      debouncedSend,
      sendPing,
      s3pSyncCheck,
    }
  })

export const addBallToTimeline = (ball: BallDetails, match: IClspMatchModel) => {
  const innings = rootStore.balls.results.get(ball.inningsId)
  if (innings) {
    const ballModel = innings.balls.find(b => b.id === ball.ballId)
    if (ballModel) {
      const messageId = uuid()
      const objToSave: DBTimelineMessage = {
        // eslint-disable-next-line max-len
        timestamp: new Date().toISOString(),
        messageId: messageId,
        matchId: ball.matchId,
        syncRequired: 1,
        type: 'BALL_UPDATE',
        message: formatSocketMessage(
          'updateBall',
          ball.matchId,
          rootStore.appSettings.manualScoring.active ? 'postMatch' : rootStore.appSettings.appMode,
          match.matchConfigs.coverageLevelId ?? null,
          messageId,
          {
            ...idCleaner(ballModel, 'BALL'),
            battingTeamId: match.getInningById(innings.id)?.battingTeamId,
            bowlingTeamId: match.getInningById(innings.id)?.getBowlingTeam?.id,
            superOver: match.getInningById(innings.id)?.superOver,
          },
          innings.id,
          match.getCompetitionId || '',
          match.getInningById(innings.id)?.inningsMatchOrder
        ),
      }
      db.timeline.put(objToSave)
      if (rootStore.appSettings.appMode !== 'fielding' || ballModel.confirmed) {
        rootStore.sendMessage(objToSave.message)
      }
    }
  }
}

const addBallRemoveToTimeline = (ball: BallDetails, deadBall = false, ballComplete = false, match: IClspMatchModel) => {
  const innings = rootStore.balls.results.get(ball.inningsId)
  if (innings) {
    const messageId = uuid()
    const objToSave: DBTimelineMessage = {
      timestamp: new Date().toISOString(),
      messageId: messageId,
      matchId: ball.matchId,
      syncRequired: 1,
      type: 'BALL_DELETE',
      message: formatSocketMessage(
        'deleteBall',
        ball.matchId,
        rootStore.appSettings.manualScoring.active ? 'postMatch' : rootStore.appSettings.appMode,
        match.matchConfigs.coverageLevelId ?? null,
        messageId,
        { id: ball.ballId, overId: ball.overId, deadBall: deadBall, ballComplete: ballComplete },
        innings.id,
        match.getCompetitionId || '',
        match.getInningById(innings.id)?.inningsMatchOrder
      ),
    }
    db.timeline.put(objToSave)
    rootStore.sendMessage(objToSave.message)
  }
}

export interface IRootStore extends Instance<typeof RootModel> {}

const rootStore = RootModel.create({}, { socketConnector: socketConnector })
onSnapshot(rootStore.timelineEvents.results, sn => {
  if (singleton.eventMatchId && singleton.eventUpdate) {
    const matchId = singleton.eventMatchId
    // Save event in timeline messages
    const eventMap = rootStore.timelineEvents.results.get(singleton.eventMatchId)
    let newEvents = eventMap?.events.filter(ev => singleton.eventUpdate && ev.eventTime >= singleton.eventUpdate)
    newEvents = orderBy(newEvents, ['eventTime'], ['asc'])
    newEvents.forEach(ev => {
      const messageId = uuid()
      const objToSave: DBTimelineMessage = {
        timestamp: new Date().toISOString(),
        messageId: messageId,
        matchId: matchId,
        syncRequired: 1,
        type: ev.getMatchEventType,
        message: formatSocketMessage(
          'updateEvent',
          singleton.eventMatchId || '',
          rootStore.appSettings.manualScoring.active ? 'postMatch' : rootStore.appSettings.appMode,
          null,
          messageId,
          idCleaner(ev, 'EVENT')
        ),
      }
      db.timeline.put(objToSave)
      rootStore.sendMessage(objToSave.message)
    })
  }
  if (singleton.eventMatchId) {
    // Save events snapshot
    // Save allBalls snapshot to balls db
    const matchEvents = sn[singleton.eventMatchId]
    if (matchEvents) {
      const objToSave: DBEvent = {
        key: singleton.eventMatchId,
        events: matchEvents.events,
      }
      db.events.put(objToSave)
    }
  }
  singleton.eventMatchId = undefined
  singleton.eventUpdate = undefined
})
onSnapshot(rootStore.balls.results, sn => {
  let ball: BallDetails
  if (singleton.ballChange) {
    const matchResult = rootStore.detailedMatches.results.get(singleton.ballChange.matchId)
    if (matchResult) {
      // Save ball toJS to timelineBalls db
      ball = {
        inningsId: singleton.ballChange.inningsId,
        matchId: singleton.ballChange.matchId,
        ballId: singleton.ballChange.ballId,
      }
      addBallToTimeline(ball, matchResult?.match)
    }
    singleton.ballChange = undefined
  }
  if (singleton.ballConfirm) {
    // Save allBalls snapshot to balls db
    ball = {
      inningsId: singleton.ballConfirm.inningsId,
      matchId: singleton.ballConfirm.matchId,
      ballId: singleton.ballConfirm.ballId,
    }
    const key = ball.inningsId
    const inningSN = sn[key]
    if (inningSN) {
      const objToSave: DBInningsBalls = {
        inningsId: key,
        matchId: ball.matchId,
        balls: inningSN.balls,
      }
      db.balls.put(objToSave)
    }
    singleton.ballConfirm = undefined
  }
  if (singleton.ballRemove) {
    const matchResult = rootStore.detailedMatches.results.get(singleton.ballRemove.matchId)
    if (matchResult) {
      // Save allBalls snapshot to balls db
      ball = {
        inningsId: singleton.ballRemove.inningsId,
        matchId: singleton.ballRemove.matchId,
        ballId: singleton.ballRemove.ballId,
        overId: singleton.ballRemove.overId,
      }
      const key = ball.inningsId
      const inningSN = sn[key]
      if (inningSN) {
        const objToSave: DBInningsBalls = {
          inningsId: key,
          matchId: ball.matchId,
          balls: inningSN.balls,
        }
        db.balls.put(objToSave)
      }
      addBallRemoveToTimeline(
        ball,
        singleton.ballRemove.deadBall,
        singleton.ballRemove.ballComplete,
        matchResult?.match
      )
    }
    singleton.ballRemove = undefined
  }
})
onSnapshot(rootStore.detailedMatches.results, sn => {
  if (singleton.match) {
    const matchSN = sn[singleton.match]
    if (matchSN) {
      const messageId = uuid()
      const objToSave: DBMatch = {
        matchId: singleton.match,
        syncRequired: 1,
        matchSN: matchSN.match,
        message: formatSocketMessage(
          'updateMatch',
          singleton.match,
          rootStore.appSettings.manualScoring.active ? 'postMatch' : rootStore.appSettings.appMode,
          matchSN.match.matchConfigs.coverageLevelId ?? null,
          messageId,
          idCleaner(matchSN.match, 'MATCH')
        ),
      }
      db.matches.put(objToSave)
      if (!rootStore.appSettings.manualScoring.active || rootStore.appSettings.manualScoring.forceSync) {
        rootStore.debouncedSend(objToSave.message)
      }
    }
  } else if (!singleton.match && rootStore.appSettings.manualScoring.forceSync) {
    // manual sync fallback if singleton is invalid
    setTimeout(() => rootStore.appSettings.manualScoring.setForceSync(false), 5000)
  }
  singleton.match = undefined
})

let ballEventTimeout: ReturnType<typeof setTimeout> | null = null
onAction(rootStore, call => {
  // NB: onAction will not fire for store actions that are called from another store action. call.name is the name of the originally-called action
  const path = call.path

  // if call is just us updating the timestamp on the match object then move on (unless doing a manualScoring sync)
  if (call.name === 'setTimestamp' && !rootStore.appSettings.manualScoring.forceSync) return
  // Don't update match for first input action -OR- match note changes
  if (includes(['setFirstInput', 'setNoteDetail', 'removeNote', 'createBlankNote', 'reorderNote'], call.name)) return

  // ON Ball Model Action
  if (call.name !== 'setTextDescription' && call.name !== 'removeBall') {
    // Before action on a ball, look to update the first_input timestamp if not done
    const pathMatch = path?.match(/\/balls\/results\/([A-Za-z0-9_-]*)\/balls\/([0-9]*)/g)
    if (pathMatch) {
      const ball = tryResolve(rootStore, pathMatch[0])
      if (ball) {
        singleton.ballChange = {
          inningsId: ball.getInningsId,
          matchId: ball.getMatchId,
          ballId: ball.id,
        }
      }
      if (ball && ball.timestamps && ball.timestamps.bowlerRunningIn && ball.timestamps.bowlerRunningIn !== '') {
        // set firstInput timestamp if it doesn't yet exist
        if (!ball.timestamps.firstInput) {
          const now =
            rootStore.appSettings.timeMachine.baseline && rootStore.appSettings.timeMachine.activated
              ? timeMachineDate(rootStore.appSettings.timeMachine.baseline, rootStore.appSettings.timeMachine.activated)
              : new Date().toISOString()
          setTimeout(ball.setFirstInput, 10, now)
        }
        const game = tryResolve(rootStore, `/detailedMatches/results/${ball.getMatchId}/match`)

        // ensure multiple requests made in quick succession from ball actions are squashed to just the newest one
        // this prevents duplicate S3P payloads being created
        if (ballEventTimeout) clearTimeout(ballEventTimeout)
        ballEventTimeout = setTimeout(() => {
          if (
            includes(
              [
                'setRunsAndExtras',
                'setShortRun',
                'setStrikeBatter',
                'setAttacking',
                'setInTheAir',
                'setThroughField',
                'setShotType',
                'setShotContact',
                'setBodyContact',
                'setArrival',
                'updateWagonWheel',
              ],
              call.name
            )
          ) {
            db.createS3PMessage(
              S3PHelpers.metadata(rootStore.appSettings.appMode, game),
              S3PHelpers.batting(rootStore.appSettings.appMode, ball, game)
            )
          }
          if (
            includes(
              [
                'setDelivery',
                'setBowlerHand',
                'setBowlerApproach',
                'setBowlerType',
                'setBowler',
                'setStrikeBatter',
                'setShotContact',
                'setPitchMap',
                'setArrival',
              ],
              call.name
            )
          ) {
            db.createS3PMessage(
              S3PHelpers.metadata(rootStore.appSettings.appMode, game),
              S3PHelpers.bowling(rootStore.appSettings.appMode, ball, game, !ball.timestamps.firstInput)
            )
          }
          if (
            includes(
              [
                'setFielded',
                'setFieldedWicketkeeper',
                'setWicketKeeperPosition',
                'setRunsSaved',
                'setOverthrows',
                'setMisfield',
                'setMissedStumping',
                'setDroppedCatch',
                'setMissedRunOut',
                'setFielder',
                'setPressure',
                'setDifficultyRating',
              ],
              call.name
            )
          ) {
            db.createS3PMessage(
              S3PHelpers.metadata(rootStore.appSettings.appMode, game),
              S3PHelpers.fielding(rootStore.appSettings.appMode, ball, game)
            )
          }
          ballEventTimeout = null
        }, 25)
      }
    }
  }
  // ON Ball Model Complete (complete/incomplete, edit complete/incomplete)
  if ((call.name === 'setBallConfirm' || call.name === 'setInningsProgress' || call.name === 'setEndOfOver') && path) {
    const ball = tryResolve(rootStore, path)
    singleton.ballConfirm = {
      inningsId: ball.getInningsId,
      matchId: ball.getMatchId,
      ballId: ball.id,
    }
  }

  // ON Ball Model Remove (undo)
  if (call.name === 'removeBall' && call.args) {
    const inningsId = call.args[0].inningsId
    const matchId = call.args[0].matchId
    const ballId = call.args[0].ballId
    const overId = call.args[0].overId
    singleton.ballRemove = {
      inningsId: inningsId,
      matchId: matchId,
      ballId: ballId,
      overId: overId,
      deadBall: call.args[0].deadBall,
      ballComplete: call.args[0].ballComplete,
    }
  }

  // ON Match Model Action
  if (path && path.match(/detailedMatches\/results\/([A-Za-z0-9_-]*)\/match\/*/g)) {
    const matchPath = path.split('/match')
    const match = tryResolve(rootStore, `${matchPath[0]}/match`)
    if (match) {
      match.setTimestamp()
      singleton.match = match.id

      if (includes(matchSetupActions, call.name)) {
        singleton.matchUpdateType = 'MATCH_SETUP_UPDATE'
      } else {
        singleton.matchUpdateType = 'MATCH_SCORE_UPDATE'
      }
    } else if (!match && rootStore.appSettings.manualScoring.forceSync) {
      rootStore.appSettings.manualScoring.setForceSync(false)
    }
  }

  // ON Timeline Event Action (not getEvents)
  if (path && call.name !== 'getEvents' && path.match(/timelineEvents\/results\/([A-Za-z0-9_-]*)/g)) {
    const pathMatch = path.match(/timelineEvents\/results\/([A-Za-z0-9_-]*)/g)
    if (pathMatch) {
      const splitPath = pathMatch[0].split('/')
      singleton.eventUpdate = new Date().toISOString()
      singleton.eventMatchId = splitPath[2]
    }
  }
})

rootStore.initialiseStore()
const references = new Map()
const appStateMap = new Map()

const appContext = {
  store: rootStore,
  refs: references,
  appState: appStateMap,
}

const RootStoreContext = createContext(appContext)

export const GlobalProvider = (props: { children: ReactNode }) => {
  return <RootStoreContext.Provider value={appContext}>{props.children}</RootStoreContext.Provider>
}

// shortcut hook to access the MST store
export function useMst() {
  const { store } = useContext(RootStoreContext)
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider')
  }
  return store
}
