import {
  Alert,
  AlertDescription,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import type { IDLSModel, IDLSSuspensionModel, IPenaltyRunsModel } from '@clsplus/cls-plus-data-models'
import {
  convertBallsTotalToOvers,
  convertOversToBallsTotal,
  getDlsValue,
  getOversRemaining,
} from '@ias-shared/cricket-logic'
import { each, filter, find, isNil, sortBy } from 'lodash'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useMemo, useRef, useState } from 'react'

import { db } from '../../data/dexie/Database'
import { useMst } from '../../data/stores/rootStore'
import MatchHelpers from '../../helpers/matchHelpers'
import S3PHelpers from '../../helpers/s3pHelpers'
import type { ISettingsModel } from '../../types/models'
import type { DlsProps } from '../../types/props'
import { Button } from '../Buttons/Button'
import { Checkbox } from '../Checkbox/Checkbox'
import EditableField from '../EditableField/EditableField'
import type { TextFieldCallbackArgs } from '../TextField/TextField'
import TextField from '../TextField/TextField'
import { DlsSuspensions } from './DlsSuspensions'

export const Dls = observer(({ game, inningsInOrder, dlsOpen, setDlsOpen, inSuperOver }: DlsProps) => {
  const { appSettings }: { appSettings: ISettingsModel } = useMst()
  const [powerPlayWarningWasShown, setPowerPlayWarningWasShown] = useState(game.matchDls?.active ? true : false)
  const [powerPlayWarningOpen, setPowerPlayWarningOpen] = useState(false)
  const [isChangingTargetScore, setIsChangingTargetScore] = useState(false)
  const [hasChangedTargetScore, setHasChangedTargetScore] = useState(false)
  const [hasChangedTargetOvers, setHasChangedTargetOvers] = useState(false)
  const nonStandardGame = !isNil(game.matchConfigs.ballsPerOver) && game.matchConfigs.ballsPerOver !== 6
  const standardGameWithManualDlsExisting =
    (!isNil(game.matchDls?.targetOvers) || !isNil(game.matchDls?.targetScore)) &&
    (game.matchDls?.matchDlsSuspensions?.length ?? 0) === 0
  const [isManual, setIsManual] = useState(nonStandardGame || standardGameWithManualDlsExisting)
  const [preManualDlsObject, setPreManualDlsObject] = useState<IDLSModel | null>(null)
  const cancelPowerPlayWarningRef = useRef(null)

  const matchSettings = appSettings.getMatchSettings(game.id)

  const closeDls = () => {
    setDlsOpen(false)
    if (game.matchDls && (hasChangedTargetScore || hasChangedTargetOvers) && appSettings.appMode === 'betting') {
      const inningsOrder = find(inningsInOrder, { isActiveInning: true })?.inningsMatchOrder
      if (inningsOrder !== undefined) {
        db.createS3PMessage(
          S3PHelpers.metadata(appSettings.appMode, game),
          S3PHelpers.dls(game, inningsOrder < 2 ? true : false)
        )
      }
    }
    setHasChangedTargetScore(false)
    setHasChangedTargetOvers(false)
    if (game.matchDls?.active && game.matchConfigs.maxOvers && !powerPlayWarningWasShown) {
      // for limited overs games, show warning about power play activation now that DLS is turned ON
      setPowerPlayWarningWasShown(true)
      setPowerPlayWarningOpen(true)
    }
  }
  const addSuspension = (inningsId: string, inningsNum: number) => {
    game.createDlsSuspension(inningsId, inningsNum, inningsNum === 1 ? totalOvers1() : startingOvers2())
    if (inningsNum === 1) totalOvers1()
    calculateDlsTargetOvers()
  }
  const updateTargetOversManual = (value: number) => {
    if (isNaN(value)) return
    if (!game.matchDls) game.createDlsObject()
    game.updateDlsTargetOvers(Number(value))
    if (nonStandardGame || standardGameWithManualDlsExisting) setHasChangedTargetOvers(true)
  }
  const updateTargetScoreManual = ({ value }: TextFieldCallbackArgs) => {
    setIsChangingTargetScore(true)
    if (isNaN(Number(value)) || value === '') return
    if (!game.matchDls) game.createDlsObject()
    game.updateDlsTargetScore(Number(value))
    game.setDescription(MatchHelpers.gameDescriptionString(game))
    if (nonStandardGame || standardGameWithManualDlsExisting) setHasChangedTargetScore(true)
    setIsChangingTargetScore(false)
  }
  const clearTargetOversScoreManual = () => {
    setIsChangingTargetScore(false)
    if (preManualDlsObject) {
      // if DLS object existed before manual was switched on, rehydrate it now that we are resetting
      game.createDlsObject(preManualDlsObject)
      setPreManualDlsObject(null)
    } else {
      // otherwise, just clear the entire DLS object
      game.clearDlsObject()
    }
  }
  const updateManualOverride = () => {
    if (!isManual) {
      // turn ON manual
      if (game.matchDls) {
        // save copy of existing DLS object, then clear game.matchDls
        setPreManualDlsObject(toJS(game.matchDls))
        game.clearDlsObject()
      }
      updateTargetOversManual(startingOvers2() || game.matchConfigs?.maxOvers || 20)
      const startingTargetScore = !isNil(game.getInningByNum(1))
        ? (game.getInningByNum(1)?.progressiveScores.runs || 0) + 1
        : null
      if (startingTargetScore) updateTargetScoreManual({ value: `${startingTargetScore}` })
      setHasChangedTargetOvers(true)
      setHasChangedTargetScore(true)
    } else if (isManual && preManualDlsObject) {
      // turn OFF manual - rehydrate copy of DLS object
      clearTargetOversScoreManual()
    }
    setIsManual(!isManual)
  }

  // dls data points
  const totalOvers1 = () => {
    if (!inningsInOrder) return null
    if (isManual) {
      const batTeam1TotalOvers = game.getInningByNum(1)?.progressiveScores.oversBowled
      return !isNil(batTeam1TotalOvers) ? Math.floor(Number(batTeam1TotalOvers)) : game.matchConfigs?.maxOvers || 0
    }
    let baseline = game.matchConfigs?.maxOvers || 0
    // get latest suspension period in the innings
    const suspensions = sortBy(
      filter(game.matchDls?.matchDlsSuspensions, { inningsId: inningsInOrder[0].value }),
      (s: IDLSSuspensionModel) => Number(s.oversAt)
    )
    if (suspensions && suspensions.length > 0) {
      // work out the total overs by when the final suspension period started, and how many overs left after it finished
      baseline = convertBallsTotalToOvers({
        balls:
          convertOversToBallsTotal({ overs: Number(suspensions[suspensions.length - 1].oversAt) }) +
          convertOversToBallsTotal({ overs: Number(suspensions[suspensions.length - 1].oversRemaining) }),
      })
    }
    return baseline
  }
  const startingOvers2 = () => {
    if (!inningsInOrder) return null
    let baseline = totalOvers1()
    each(game.matchDls?.matchDlsSuspensions, suspension => {
      if (
        baseline &&
        inningsInOrder.length > 1 &&
        suspension.inningsId === inningsInOrder[1].value &&
        Number(suspension.oversAt) === 0
      ) {
        baseline = Number(suspension.oversRemaining)
      }
    })
    return Math.floor(Number(baseline))
  }
  const penaltyRuns2 = () => {
    if (!inningsInOrder || inningsInOrder.length < 2) return 0
    let penaltyRunsTeam1InTeam2Innings = 0
    game.penaltyRuns.forEach((pr: IPenaltyRunsModel) => {
      if (!pr.timestamp || !inningsInOrder[1].startTime) return
      if (pr.inningsNum === 1 && pr.timestamp >= inningsInOrder[1].startTime) {
        // only include penalty runs given to 1st batting team during 2nd batting team's innings
        penaltyRunsTeam1InTeam2Innings += pr.runs
      }
    })
    return penaltyRunsTeam1InTeam2Innings
  }

  // dls calculations
  const calculateDlsTargetOvers = () => {
    if (!inningsInOrder) return null
    if (!game.matchDls?.active) return null
    let baseline = totalOvers1() || game.matchConfigs?.maxOvers || 0
    // get latest suspension period in the innings
    const suspensions = sortBy(
      filter(game.matchDls?.matchDlsSuspensions, {
        inningsId:
          find(inningsInOrder, { isActiveInning: true })?.value ||
          inningsInOrder[inningsInOrder.length > 1 ? 1 : 0].value,
      }),
      (s: IDLSSuspensionModel) => Number(s.oversAt)
    )
    if (suspensions && suspensions.length > 0) {
      // work out the total overs by when the final suspension period started, and how many overs left after it finished
      baseline = convertBallsTotalToOvers({
        balls:
          convertOversToBallsTotal({ overs: Number(suspensions[suspensions.length - 1].oversAt) }) +
          convertOversToBallsTotal({ overs: Number(suspensions[suspensions.length - 1].oversRemaining) }),
      })
    }
    const oversValue = Math.floor(Number(baseline))
    if (oversValue !== (game.matchDls?.targetOvers || 0) && !hasChangedTargetOvers) setHasChangedTargetOvers(true)
    game.updateDlsTargetOvers(oversValue)
    if (inningsInOrder.length === 1) {
      calculateDlsValue(false, true, true)
    }
  }
  const calculateDlsValue = (par: boolean, target: boolean, ignoreReturn?: boolean) => {
    if (!inningsInOrder) return null
    const suspensionsM = toJS(game.matchDls?.matchDlsSuspensions)
    const hasInvalidSuspension = find(suspensionsM, { oversRemaining: null })
    if (!hasInvalidSuspension) {
      let dlsValue = getDlsValue(
        par,
        target,
        {
          nb3: game.matchConfigs?.maxOvers,
          ng3: startingOvers2(),
          nb4: (game.getInningByNum(inningsInOrder[0].inningsMatchOrder)?.progressiveScores.runs || 0) - penaltyRuns2(),
          olft: par
            ? getOversRemaining({
                oversPerInnings: game.matchDls?.targetOvers || game.matchConfigs?.maxOvers || 0,
                oversBowled:
                  game.getInningByNum(inningsInOrder[1].inningsMatchOrder)?.progressiveScores.oversBowled || 0,
              })
            : undefined,
          wkt: par ? game.getInningByNum(inningsInOrder[1].inningsMatchOrder)?.progressiveScores.wickets : undefined,
          ni39: penaltyRuns2(),
        },
        suspensionsM?.map((s: IDLSSuspensionModel) => {
          return { ...s, inningsMatchOrder: s.inningsId === inningsInOrder[0].value ? 1 : 2 }
        })
      )
      if (target) {
        if (dlsValue !== (game.matchDls?.targetScore || 0) && !hasChangedTargetScore) setHasChangedTargetScore(true)
        game.updateDlsTargetScore(dlsValue)
        game.setDescription(MatchHelpers.gameDescriptionString(game))
      }
      if (par && (dlsValue < 0 || isNaN(dlsValue))) dlsValue = 0
      if (!ignoreReturn) return dlsValue
    } else {
      if (!ignoreReturn) return ''
    }
  }

  if (!isManual) calculateDlsTargetOvers()

  const dlsValueIssue = (): boolean => {
    if (!inningsInOrder) return false
    const suspensions = filter(game.matchDls?.matchDlsSuspensions, {
      inningsId: inningsInOrder[inningsInOrder.length - 1].value,
    })
    if (!suspensions) return false
    let existingValues: IDLSSuspensionModel = suspensions[0]
    let hasIssue = false
    suspensions.map((s: IDLSSuspensionModel) => {
      if (
        s.oversAt !== null &&
        s.runs !== null &&
        s.wickets !== null &&
        s.oversRemaining !== null &&
        existingValues.runs !== null &&
        existingValues.wickets !== null &&
        (Number(s.oversAt) < Number(existingValues.oversAt) ||
          Number(s.oversAt) > (game.matchConfigs?.maxOvers || 50) ||
          s.runs < existingValues.runs ||
          s.wickets < existingValues.wickets ||
          s.wickets > 10 ||
          Number(s.oversRemaining) > Number(existingValues.oversRemaining) ||
          Number(s.oversRemaining) > (game.matchConfigs?.maxOvers || 50))
      ) {
        hasIssue = true
      }
      existingValues = s
      return true
    })
    return hasIssue
  }

  // suspension data
  const data: IDLSSuspensionModel[] | null = useMemo(() => {
    return game.matchDls?.matchDlsSuspensions || null
  }, [game.matchDls, preManualDlsObject]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Modal isOpen={dlsOpen} onClose={closeDls} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Duckworth-Lewis-Stern</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {inningsInOrder && dlsOpen && (
              <Tabs size="md" colorScheme="cls.blue" defaultIndex={inningsInOrder.length > 1 ? 1 : 0}>
                <TabList borderColor="gray.200">
                  {inningsInOrder?.map((inning, idx) => (
                    <Box key={`innsTab${idx}`}>{!inning.superOver && <Tab>{`${inning.label} Innings`}</Tab>}</Box>
                  ))}
                </TabList>
                <TabPanels>
                  <TabPanel padding="7px" marginTop="14px">
                    <Text display="none">{JSON.stringify(data)}</Text>
                    <Flex h="40px" direction="row" align="center">
                      <Flex flex={1}>
                        <Text fontWeight="bold">Overs:</Text>
                      </Flex>
                      <Flex flex={1.75}>
                        <Text>{`${
                          game.getInningByNum(inningsInOrder[0].inningsMatchOrder)?.progressiveScores.oversBowled
                        } ${
                          game.getInningByNum(inningsInOrder[0].inningsMatchOrder)?.getInningStatus !== 'COMPLETED'
                            ? `(${totalOvers1()})`
                            : ''
                        }
                        `}</Text>
                      </Flex>
                    </Flex>
                    <Flex h="40px" direction="row" align="center">
                      <Flex flex={1}>
                        <Text fontWeight="bold">Runs:</Text>
                      </Flex>
                      <Flex flex={1.75}>
                        <Text>
                          {game.getInningByNum(inningsInOrder[0].inningsMatchOrder)?.progressiveScores.runs || 0}
                        </Text>
                      </Flex>
                    </Flex>
                    {isManual && inningsInOrder.length === 1 && (
                      <Flex h="40px" direction="row" align="center">
                        <Flex flex={1}>
                          <Text fontWeight="bold">Target Overs:</Text>
                        </Flex>
                        <Flex flex={1.75}>
                          <EditableField
                            key="overs2"
                            data-testid="dls-overs1"
                            height="32px"
                            width="100%"
                            textAlign="left"
                            value={game.matchDls?.targetOvers || game.matchConfigs?.maxOvers || 20}
                            onChange={(value: string) => {
                              if (
                                (!isNil(game.matchDls?.targetOvers) && Number(value) !== game.matchDls?.targetOvers) ||
                                (isNil(game.matchDls?.targetOvers) &&
                                  Number(value) !== (game.matchConfigs?.maxOvers || 20))
                              ) {
                                // if target overs is already set, and we are changing it
                                // OR if target overs is not already set, and we are setting it for the first time
                                updateTargetOversManual(Number(value))
                              }
                            }}
                            pattern="^\d{0,4}(?:\.[0-5]{1})?$"
                            errorMessage="Please enter numbers, or decimals up to 1 digit"
                          />
                        </Flex>
                      </Flex>
                    )}
                    {!isManual && (
                      <Flex direction="column">
                        <Flex h="40px" w="100%" direction="row" align="center" justify="space-between" mb="10px">
                          <Text fontWeight="bold">Suspension Periods:</Text>
                          {game.getInningByNum(inningsInOrder[0].inningsMatchOrder)?.getInningStatus !==
                            'COMPLETED' && (
                            <Button
                              onClick={() => {
                                addSuspension(inningsInOrder[0].value, 1)
                              }}
                              h="30px"
                              colorScheme="green"
                              ml={3}
                              isDisabled={inSuperOver}
                              data-testid="addSuspensionButton"
                            >
                              Add Suspension
                            </Button>
                          )}
                        </Flex>
                        <DlsSuspensions
                          game={game}
                          inningsId={inningsInOrder[0].value}
                          data={filter(data, { inningsId: inningsInOrder[0].value })}
                          inSuperOver={inSuperOver}
                        />
                      </Flex>
                    )}
                  </TabPanel>
                  {inningsInOrder.length > 1 && (
                    <TabPanel padding="7px" marginTop="14px">
                      <Flex h="40px" align="center" direction="row">
                        <Flex flex={1}>
                          <Text fontWeight="bold">{!isManual ? 'Starting Overs:' : 'Target Overs:'}</Text>
                        </Flex>
                        <Flex flex={1.75}>
                          {isManual ? (
                            <Box w="50%">
                              <EditableField
                                key="overs2"
                                data-testid="dls-overs2"
                                height="32px"
                                width="100%"
                                textAlign="center"
                                value={game.matchDls?.targetOvers || game.matchConfigs?.maxOvers || 20}
                                onChange={(value: string) => updateTargetOversManual(Number(value))}
                                pattern="^\d{0,4}(?:\.[0-5]{1})?$"
                                errorMessage="Please enter numbers, or decimals up to 1 digit"
                              />
                            </Box>
                          ) : (
                            <Text>{startingOvers2()}</Text>
                          )}
                        </Flex>
                      </Flex>
                      <Flex h="40px" align="center" direction="row">
                        <Flex flex={1} alignItems={isManual ? 'center' : undefined}>
                          <Text fontWeight="bold">Target Score:</Text>
                        </Flex>
                        <Flex flex={1.75} direction="row" alignItems="flex-start">
                          {isManual ? (
                            <>
                              <Flex flex={1}>
                                <TextField
                                  id="score2"
                                  value={
                                    isChangingTargetScore
                                      ? ''
                                      : `${
                                          game.matchDls?.targetScore ||
                                          (!isNil(game.getInningByNum(1))
                                            ? `${(game.getInningByNum(1)?.progressiveScores.runs || 0) + 1}`
                                            : '-')
                                        }`
                                  }
                                  onChange={updateTargetScoreManual}
                                  textAlign="center"
                                  ignoreState
                                  data-testid="manualUpdateTargetScoreTextField"
                                />
                              </Flex>
                              <Flex flex={1} justifyContent="flex-end">
                                {game.matchDls !== null && (
                                  <Button
                                    onClick={() => {
                                      if (!nonStandardGame) setIsManual(false)
                                      clearTargetOversScoreManual()
                                    }}
                                    colorScheme="red"
                                    data-testid="resetTargetOversScore"
                                  >
                                    Reset
                                  </Button>
                                )}
                              </Flex>
                            </>
                          ) : (
                            <>
                              <Text fontSize="xl" fontWeight="bold">
                                {calculateDlsValue(false, true) || '-'}
                              </Text>
                              <Text margin="1px 0 0 7px">{`(${
                                game.matchDls?.targetOvers || startingOvers2() || game.matchConfigs?.maxOvers
                              } overs)`}</Text>
                            </>
                          )}
                        </Flex>
                      </Flex>
                      {!isManual && game.matchDls?.active && (
                        <Flex h="40px" align="center" direction="row">
                          <Flex flex={1}>
                            <Text fontWeight="bold">Par Score:</Text>
                          </Flex>
                          <Flex flex={1.75}>
                            {matchSettings?.scoreFormat === 'RUNS-WICKETS' && (
                              <Text>{`${calculateDlsValue(true, false) || '0'}/${
                                game.getInningByNum(inningsInOrder[1].inningsMatchOrder)?.progressiveScores.wickets
                              } (${
                                game.getInningByNum(inningsInOrder[1].inningsMatchOrder)?.progressiveScores.oversBowled
                              } overs)`}</Text>
                            )}
                            {matchSettings?.scoreFormat === 'WICKETS-RUNS' && (
                              <Text>{`${
                                game.getInningByNum(inningsInOrder[1].inningsMatchOrder)?.progressiveScores.wickets
                              }/${calculateDlsValue(true, false) || '0'} (${
                                game.getInningByNum(inningsInOrder[1].inningsMatchOrder)?.progressiveScores.oversBowled
                              } overs)`}</Text>
                            )}
                          </Flex>
                        </Flex>
                      )}
                      {!isManual && (
                        <Flex direction="column">
                          <Flex h="40px" w="100%" direction="row" align="center" justify="space-between" mb="10px">
                            <Text fontWeight="bold">Suspension Periods:</Text>
                            <Button
                              onClick={() => {
                                addSuspension(inningsInOrder[1].value, 2)
                              }}
                              h="30px"
                              colorScheme="green"
                              ml={3}
                              isDisabled={inSuperOver}
                              data-testid="addSuspensionButton"
                            >
                              Add Suspension
                            </Button>
                          </Flex>
                          <DlsSuspensions
                            game={game}
                            inningsId={inningsInOrder[1].value}
                            data={filter(data, { inningsId: inningsInOrder[1].value })}
                            inSuperOver={inSuperOver}
                          />
                        </Flex>
                      )}
                    </TabPanel>
                  )}
                </TabPanels>
              </Tabs>
            )}
          </ModalBody>
          <ModalFooter>
            {dlsValueIssue() && (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription mr={2}>Suspensions are invalid or ordered incorrectly</AlertDescription>
              </Alert>
            )}
            {isManual && nonStandardGame && (
              <Alert status="info">
                <AlertIcon />
                <AlertDescription mr={2}>Non-standard games can only use DLS manually</AlertDescription>
              </Alert>
            )}
            {!nonStandardGame && !dlsValueIssue() && (inningsInOrder?.length || 0) > 1 && (
              <Flex w="100%" justify="flex-start" align="center">
                <Checkbox
                  colorScheme="cls.yellow"
                  isChecked={isManual}
                  onChange={updateManualOverride}
                  size="lg"
                  data-testid="dlsIsManual"
                >
                  Manual Override
                </Checkbox>
              </Flex>
            )}
            <Button onClick={closeDls} size="md" colorScheme="cls.gray" ml={3} data-testid="doneDlSButton">
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <AlertDialog
        isOpen={powerPlayWarningOpen}
        leastDestructiveRef={cancelPowerPlayWarningRef}
        onClose={() => setPowerPlayWarningOpen(false)}
        closeOnOverlayClick={false}
        closeOnEsc={false}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Power Play Information
          </AlertDialogHeader>

          <AlertDialogBody>
            Due to DLS, Power Plays will now need to be manually stopped and started for the remainder of the match
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              colorScheme="green"
              onClick={() => setPowerPlayWarningOpen(false)}
              ml={3}
              textTransform="capitalize"
              data-testid="powerPlayInfoDialogDone"
            >
              OK
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
})
